import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
function DialogueBox(props) {
    
  return (
    <div>
       <Dialog open={props.confirmDialog} onClose={props.confirmDialogClose}>
       <DialogTitle>Delete {props.message}</DialogTitle>
                <Divider />
                <DialogContent>
                    <div className='my-2'>
                    Are you sure you want to delete?
                       
                    </div>
                </DialogContent>
                <Divider />
                    <DialogActions>
                        <div className=' '>
                            <Button variant="outlined" onClick={props.confirmDialogClose}>
                               No
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" onClick={props.confirmDelete}>
                                Yes
                            </Button>
                        </div>
                    </DialogActions>
            </Dialog>
    </div>
  );
}
export default DialogueBox;
