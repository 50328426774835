import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as React from "react";
import PropTypes from "prop-types";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Panel(props) {
  const [tabValue, setTabValue] = React.useState(0);
  React.useEffect(() => { 
      setTabValue(props?.tabIndex)
    
    // eslint-disable-next-line
  }, [props?.tabIndex]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // props.tabChanged(newValue)
  };

  return (
    <div className='h-full w-full'>
        <div>
          <Box sx={{ borderColor: "divider" }}>
            <Tabs
            
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab className="p-0" label={props?.labelOne} {...a11yProps(0)} />
              {props?.secondPanel&& <Tab className="p-0 ml-5" label={props?.labelTwo} {...a11yProps(1)} />}
             {props?.thirdPanel&& <Tab className="p-0 ml-5" label={props?.lableThree} {...a11yProps(2)} />}
             {props?.forthPanel&& <Tab className="p-0 ml-5" label={props?.lableFour} {...a11yProps(3)} />}
            </Tabs>
          </Box>
        </div>
      <TabPanel value={tabValue} index={0}>
        <div className="my-6">{props?.tabOne}</div>
      </TabPanel>
      {props?.secondPanel&& <TabPanel value={tabValue} index={1}>
        <div className="my-6">{props?.tabTwo}</div>
      </TabPanel>}
     {props?.thirdPanel&& <TabPanel value={tabValue} index={2}>
        <div className="my-6">{props?.tabThree}</div>
      </TabPanel>}
     {props?.forthPanel&& <TabPanel value={tabValue} index={3}>
        <div className="my-6">{props?.tabFour}</div>
      </TabPanel>}
    </div>
  );
}
export default Panel;
