import { FormControl, IconButton, InputAdornment, InputLabel, TextField, Tooltip } from "@mui/material";
import DateInput from "../Components/DateInput";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from 'react'
import { data } from "autoprefixer";
import ClearIcon from '@mui/icons-material/Clear';

function DateSearchFilter(props) {
  var d = new Date('1/1/2021');
  // d.setMonth(d.getMonth() - 1);
  const [startDate, setStartDate] = React.useState(d.toISOString());
  const [dateFilterApplied, setDateFilterApplied] = React.useState(false);
  const [endDate, setEndDate] = React.useState(new Date().toISOString());
  const [inputSearch, setInputSearch] = useState("")

  const handleDateChange = async (data) => {

    if (data.from && data.from != false) {
      const d = data?.date
      d.setHours(0, 0, 0);
      setStartDate(d.toISOString())
    }
    if (data.to && data.to != false) {
      const d = data?.date
      d.setHours(23, 59, 59);
      setEndDate(d.toISOString())
    }
    if (data.target) {
      setInputSearch(data.target.value)
    }
  }

  useEffect(() => {
    let searchData = {
      startDate: startDate, endDate: new Date().toISOString(), input: inputSearch
    }
    props.handleInputDateChange(searchData)
  }, [startDate, endDate, inputSearch])

  const dateFilterRemove = () => {
    setDateFilterApplied(false);
    var d = new Date('1/1/2021');
    // d.setMonth(d.getMonth() - 1);
    setStartDate(d.toISOString())
    setEndDate(new Date().toISOString())
  }


  return (
    <div>
      <div className="flex items-center">
        <InputLabel className='w-20' id="demo-simple-select-label">From</InputLabel>
        &nbsp;&nbsp;
        <DateInput from={true} height={props?.height} white={props?.white} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <InputLabel className='w-10' id="demo-simple-select-label">To</InputLabel>
        &nbsp;&nbsp;
        <DateInput to={true} height={props?.height} white={props?.white} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
        &nbsp;&nbsp;&nbsp;
        {dateFilterApplied ? <>
          <Tooltip title="Clear Date Filter">
            <IconButton aria-label="clear" onClick={dateFilterRemove}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </> : null}
        &nbsp;&nbsp;
        <FormControl sx={{ width: "70%" }}>
          <TextField
            size="small"
            sx={{ backgroundColor: "white" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="sm" />
                </InputAdornment>
              ),
            }}
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            onChange={(e) => handleDateChange(e)}
          />
        </FormControl>
      </div>
    </div>
  );
}
export default DateSearchFilter;
