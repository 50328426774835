

import "./App.css";
import './index.css';
import { BrowserRouter } from "react-router-dom";

import { RecoilRoot } from 'recoil'
import { createContext, useState } from "react";
import MainRoutes from "./utilities/routes/MainRoutes";
export const successEvents = createContext();
function App() {
  const [me, setMe] = useState("sanjay"); 
  const [success, setSuccess] = useState("");
  const [filterData, setFilterData] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [snackBarOptions, setSnackBarOptions] = useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
});

  const theme = {
    token: { colorPrimary: '#1F75FE' }
  }

  return (
    <div >
      <successEvents.Provider value={{ snackBarOptions,setSnackBarOptions ,success, setSuccess, filterData, setFilterData, setCurrentPage, currentPage, firstPage, setFirstPage }}>
        <BrowserRouter>
          <RecoilRoot >
            <div className="h-full flex flex-col ">
              <MainRoutes />
            </div>
          </RecoilRoot>
        </BrowserRouter>
      </successEvents.Provider>
    </div>
  );
}

export default App;
