import * as React from 'react';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function CustomInput(props) {
  var d = props.date || new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
  const [startValue, setStartValue] = React.useState(d);
  const [endValue, setEndValue] = React.useState(new Date(new Date().setHours(23, 59)))

  React.useEffect(() => {
    if (!props?.filter) {
      var d = props.date || new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
      setStartValue(d)
      setEndValue(new Date(new Date(new Date().setHours(23, 59))))
    }
  }, [props?.filter])

  // const [value, setValue] = React.useState();
  const handleChange = (newValue) => {

    if (props?.from) {
      setStartValue(newValue)
      // props?.filterChange(true)
      props?.handleDateChange({ "date": newValue, "from": props?.from != undefined ? props?.from : false, "to": props?.to != undefined ? props?.to : false })
    }
    if (props?.to) {
      setEndValue(newValue)
      // props?.filterChange(true)
      props?.handleDateChange({ "date": newValue, "from": props?.from != undefined ? props?.from : false, "to": props?.to != undefined ? props?.to : false })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        keyboardIcon={<CalendarMonthIcon />}
        inputFormat="dd/MM/yyyy"
        label="Custom input"
        value={props.from ? startValue : endValue}
        onChange={(newValue) => handleChange(newValue)}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', svg: { fontSize: '20px' } }}>
            {props.white ? <input className='bg-white border w-32 h-7' ref={inputRef} {...inputProps} /> :
              props.height ? <input className='bg-white border border-gray-300 rounded w-40 p-3 h-14' ref={inputRef} {...inputProps} /> :
                <input className='bg-white border w-32 p-3 h-7' ref={inputRef} {...inputProps} />}
            {InputProps?.endAdornment}
          </Box>
        )}
      />
    </LocalizationProvider>
  );
}
