import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { httpGet } from "../Services/api-services";
import CONSTANTS from "../constant.json";
import EditIcon from '@mui/icons-material/Edit';
export default function CaseDetailsContentCard(props) {
  const [currency, setCurrency] = React.useState('');
  const [refuns, setRefuns] = React.useState()
  const [openCases, setOpenCases] = React.useState([]);

  let adProcess = [
    'Down payment',
    'Register form submitted',
    'Payment schedules',
    'Welcome call',
    'Whatsapp group joined'
  ]



  async function fetchCurrency() {
    const url = `${CONSTANTS.baseUrl}/countries/${props?.subscribedCourse?.courses?.currency}`
    const response = await httpGet(url);
    setCurrency(response?.currencyName);
  }

  let pendings = 0
  // props.subscribedCourse?.transaction?.map((a)=>pendings += (a?.paymentType=='fee')?a.amount:0)
  function onArrowClick(processName, i) {
    if (processName === 'Down payment') {
      props.openSideNav()
      props.onDownpaymentClick()
    }
    else if (processName === 'Payment schedules')
      props.openSideNav()
  }

  return (
    <Box onClick={() => props.openSideNav(props?.subscribedCourse)} sx={{ marginX: "20px", cursor: 'pointer' }}>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardContent sx={{ width: '100%' }}>
          <div className="flex justify-between flex-wrap">
            <div className="w-fit">
              <p>{props.subscribedCourse?.courses?.name}</p>

              <div className="flex gap-3">
                <p className="text-gray-500 text-sm">Role number : </p>   <p className="text-gray-500 text-sm">{props.subscribedCourse?.roleNumber}</p>
              </div>
              {props?.subscribedCourse?.reference ?
                <div className="flex gap-3">
                  <p className="text-gray-500 text-sm">Reference : </p>   <p className="text-gray-500 text-sm">{props.subscribedCourse?.reference}</p>
                </div> : ""}
              <p className="text-gray-500 text-sm">
                {props.subscribedCourse?.batches?.name}{" "}
                {moment(
                  props.subscribedCourse?.batch?.batchStartDate
                ).format('MMMM Do YYYY')}<br />{props.subscribedCourse?.batch?.from}-{props.subscribedCourse?.batch?.to}
              </p>
              <br />

              <progress
                className="caseDetails w-full rounded"
                id="file"
                value={(props.subscribedCourse?.paidAmount * 100) / props.subscribedCourse?.totalFee}
                max="100"
              ></progress>
              <div className="flex justify-between items-center text-xs -mt-1">
                <p className="text-green-700"><b>Paid {props.subscribedCourse?.currency?.currencyName} {props.subscribedCourse?.paidAmount ? props.subscribedCourse?.paidAmount : 0}.00</b></p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <p className="text-red-600"><b>Due {props.subscribedCourse?.currency?.currencyName} {props.subscribedCourse?.unpaidAmount ? props.subscribedCourse?.unpaidAmount : props.subscribedCourse?.totalFee}</b></p>
              </div>
            </div>
            <div className="w-fit">
              <p className="text-gray-500">Admission Process</p>
              <div className="text-sm text-slate-400 w-52 pt-2">
                <span className="flex gap-2 "style={{  color:props.subscribedCourse?.downPayment? 'green':"" }}><CheckCircleIcon  /> <p>Down payment</p>  </span>
                <span className="flex gap-2" style={{  color:props.subscribedCourse?.paymentSchedule? 'green':"" }}><CheckCircleIcon  />   <p>Payment schedules</p></span>
                <span className="flex gap-2" style={{  color:props.subscribedCourse?.welcomeCall? 'green':"" }}><CheckCircleIcon  /> <p>Welcome call</p> </span>
                <span className="flex gap-2" style={{  color:props.subscribedCourse?.whatsappGroup ?'green':"" }}><CheckCircleIcon  />  <p>Whatsapp groups joined</p></span>
                
              </div>
            </div>
            <div className="w-fit"> 
              <p className="text-gray-500 ">Subjects</p>
              <div className="w-fit pt-2">
                {props.subscribedCourse?.subjects?.map(
                  (_subject, i) => {
                    return (
                      <div key={i}>
                        <span>
                          <CheckCircleIcon
                            color="success"
                            sx={{ fontSize: "20px" }}
                          />
                        </span>
                        &nbsp;&nbsp;
                        <span className="text-sm">{_subject?.name}</span>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="w-fit">
              <p className="text-gray-500 ">AddOns</p>
              <div className="pt-2 w-fit">
                {props.subscribedCourse?.addOns?.map(
                  (_addons, i) => {
                    return (
                      <div key={i}>
                        <span>
                          <CheckCircleIcon
                            color="success"
                            sx={{ fontSize: "20px" }}
                          />
                        </span>
                        &nbsp;&nbsp;
                        <span className="text-sm">{_addons?.name}</span>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="w-44">
              <p className="text-red-600 "><b>
                {props.subscribedCourse?.currency?.currencyName} {props.subscribedCourse?.unpaidAmount ? props.subscribedCourse?.unpaidAmount : 0} <span className="text-black">/ {props.subscribedCourse?.totalFee}</span>
              </b></p>
              {props?.subscribedCourse?.isRefund ? <>
                <p className="flex justify-center items-center my-2 h-10" style={{ border: "3px solid #efcc00", borderRadius: "30px", color: "#efcc00", fontSize: "15px" }}>

                  Refund - {props.subscribedCourse?.currency?.currencyName} {props?.subscribedCourse?.refundAmount}
                </p>
              </> : null
              }
            </div>
            <div style={{ display: !(props.openCases?.write || props.openCases?.update) || props.fetchCase?.case?.deleted || props?.subscribedCourse?.isRefund ? "none" : "block" }}>
              <EditIcon className="text-[#3366ff] cursor-pointer" onClick={(e) => { e.stopPropagation(); props.handleEditForm(props.subscribedCourse) }} />
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}
