import React, { useEffect, useState } from 'react'
import DynamicTable from "./DynamicTable";
import moment from 'moment';
const SMSLogsPanel = (props) => {
    const headCells = [
        {
            id: "sno",
            numeric: false,
            disablePadding: false,
            sort: false,
            label: "S.no",
        },
        {
            id: "title",
            numeric: false,
            disablePadding: false,
            sort: true,
            label: "Title",
        },
        {
            id: "module",
            numeric: false,
            disablePadding: false,
            sort: true,
            label: "Module",
        },
        {
            id: "event",
            numeric: true,
            disablePadding: false,
            sort: true,
            label: "Event",
        },
        {
            id: "message",
            numeric: true,
            disablePadding: false,
            sort: true,
            label: "Message",
        },
        {
            id: "staffName",
            numeric: true,
            disablePadding: false,
            sort: false,
            label: "Staff Name",
        },
        {
            id: "date",
            numeric: true,
            disablePadding: false,
            sort: false,
            label: "Date",
        },
    ];
    const [smsLogDataSource, setSmsLogDataSource] = useState()
    const [smsLog, setSmsLog] = useState()

    const fetchData = async () => {

        const _dataSource = await createTableData(
            smsLog
        );
        setSmsLogDataSource(_dataSource);
    }
    useEffect(() => {
        setSmsLog(props?.currentCourse?.smsLogs)

    }, [])
    useEffect(() => {
        fetchData()
    }, [smsLog])
    const createTableData = (data) => {
        // CREATE TABLE DATA - ADDONS
        let returnArray = [];
        return new Promise((resolve, reject) => {
            if (data && data.length) {
                for (let index = 0; index < data.length; index++) {
                    const row = data[index];
                    let obj;
                    obj = {
                        sno: index + 1,
                        title: row.smsTemplate?.title,
                        module: row.smsTemplate?.module,
                        event: row.smsTemplate?.event,
                        message: row.message,
                        staffName: row.admin?.name,
                        date: moment(
                            row.createdAt
                        ).format('DD/MM/YYYY')
                    };
                    returnArray.push(obj);
                    if (index === data.length - 1) resolve(returnArray);
                }
            } else resolve([]);
        });
    };

    return (<>
        <div>
            {smsLog?.length>0 ?
                <DynamicTable
                    dataSource={smsLogDataSource}
                    headCells={headCells}
                /> : "No SMS Log Found. "}
        </div>

    </>)



}
export default SMSLogsPanel