import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { baseUrl, frontEndUrl, patch, post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CasesDialog from '../../Popups/CasesDialog';
import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DialogueBox from '../../Components/DialogueBox';
import CustomizedSnackbars from '../../Components/Snackbar';
import { successEvents } from '../../App';
import AddTags from './AddTags';
export default function Tags() {
  const [access, setAccess] = useState();
  const [cases, setCases] = React.useState(null)

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
  const [searchInput, setSearchInput] = useState();
  const [dates, setDates] = useState();
  const [row, setRow] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filterRowCount, setFilterRowCount] = useState(0);
  const [openCaseDialog, setOpenCaseDialog] = useState(false)
  const [edit, setEdit] = useState();
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [casesData, setCasesData] = useState(
    {
      name: "",

    }
  );
  const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)

  const [authState, setAuthState] = useRecoilState(UserAuthState);
  // const [clearDate,setClearDate] =useState()

  const navigate = useNavigate();


  const handlesendRegistrationLink = async (id) => {
    let url = `${frontEndUrl}/Registration/${id}`
    console.log(url)
  }
  const columns = [

    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 150,
      flex: 1,
    },


    {
      field: 'createdAt',
      headerName: 'CREATED AT',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {


        return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
      },
    },
    {
      field: 'deleted',
      headerName: 'ACTION',

      minWidth: 150,

      renderCell: (params) => {


        return <div className='flex items-center gap-6' >
          <EditIcon className={access?.update || access?.write ? 'text-[#3366ff] cursor-pointer' : " opacity-20"}
            onClick={(e) => {
              if (access?.update || access?.write) {
                e.stopPropagation();
                setEdit(params?.row?.id)
                setCasesData({
                  name: params?.row?.name,

                })
                setOpenCaseDialog(true)
              }

            }}
          />
          <Tooltip title='Delete from Open cases but you can access from old cases'>
            <Switch disabled={!(access?.update || access?.write)} checked={params?.row?.status} onClick={() => { handleupdateStatus(params?.row?.id, params?.row?.status) }} />
            {/* <DeleteIcon className='text-[#3366ff]' onClick={(e) => {
                            e.stopPropagation();
                            setConfirmDialog(params?.row?.id)
                        }} style={{ display: access?.update || access?.write ? "block" : "none" }} /> */}
          </Tooltip>
        </div>
      },
    },

  ];

  // const cancelDialogAction = () => {
  //     setSearchActionDialog(false)
  //     setRefundReqActionDialog(false)
  // }
  const confirmDialogClose = () => {
    setConfirmDialog(null);
  }

  const getDate = (data) => {
    console.log(data)
    if (data.startDate?.length === 0 && data.endDate?.length === 0) {
      setDates(data);
    }
    else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
      setDates(data)
    }

  }
  const getCases = async (search, dates, page) => {
    console.log(search)
    console.log(dates)
    const res = await post("tags/getAllTags", { searchText: search, dateFilter: dates, page: page });
    if (res.statusCode === 200) {
      setRow(res?.data?.data);
      setTotalRowCount(res?.data?.totalCount);
      setFilterRowCount(res?.data?.filterCount)
    } else {
      setSnackBarOptions({
        message: "something went wrong refresh page",
        show: true,
        severity: "error",
        duration: 5000,
      });
    }
  }
  const handleupdateStatus = async (id, status) => {
    const res = await patch(`tags/${id}`, { status: !status })
    if (res.statusCode === 200) {
      getCases(searchInput, dates)

    } else {
      setSnackBarOptions({
        message: "something went wrong try again",
        show: true,
        severity: "error",
        duration: 5000,
      });
    }
    setConfirmDialog(null)
  }
  const debounceSearch = useCallback(debounce(getCases, 300), []);
  const handleOnRowClick = async (row) => {
    console.log(row.row)
    navigate('caseDetails', { state: { id: row.row.id } })

  }
  useEffect(() => {
    debounceSearch(searchInput, dates, paginationModel);
  }, [searchInput, dates, paginationModel])
  useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const rolesArr = modules?.find((m) => m?.name === "Tags");
    console.log(rolesArr);

    setAccess(rolesArr)
  }, [])

  return (<>
    <div className=" flex flex-col flex-1" style={{ width: "100%" }}  >
      <div className='flex items-center justify-between  flex-wrap'>
        <Typography className='font-normal text-xl text-gray-500'>Tags ({totalRowCount})</Typography>
        <div className="flex items-center gap-5 flex-wrap">
          {console.log(access, "shfjsjljjjfj")}


          <FormControl sx={{ width: "300px" }}>
            <TextField
              size="small"
              sx={{ backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="sm" />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
              placeholder="Search"
              variant="outlined"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </FormControl>
          <DateRangePiker getData={getDate} />

        </div>
      </div>


      <DataGidTable handleEvent={""} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />



      {/* <SearchFilterDailog
                openSearchActionDialog={searchActionDialog}
                cancelDialogAction={() => cancelDialogAction()}
                handleDateChange={(data) => handleDateChange(data)}
                cases={cases}
                title={"REFUND REQUEST FILTER"}
            // onChangeHandler={(obj, selectOption) => onSelectFilterData(obj, selectOption)}
            /> */}
      <div  className=' fixed  bottom-32 right-20'>

        <Fab style={{ display: access?.write ? "block" : "none" }} color="primary" aria-label="add" onClick={() => setOpenCaseDialog(true)}>
          <AddIcon />
        </Fab>
      </div>
      <AddTags setSnackBarOptions={setSnackBarOptions} access={access} edit={edit} setEdit={setEdit} getCases={getCases} setCasesData={setCasesData} casesData={casesData} open={openCaseDialog} setOpen={setOpenCaseDialog} />

    </div>
  </>)

}

