import { Button, Typography } from "@mui/material";
import BreadCrumb from "../../Components/BreadCrumbs";
import ErrorIcon from "@mui/icons-material/Error";
import CaseDetailsHeadedCard from "../../Components/CaseDetailsHeaderCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CaseDetailsContentCard from "../../Components/CaseDetailsContentCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  _getCase,
  httpGet,
  httpPost,
  httpDelete,
} from "../../Services/api-services";
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import SideNav, { MenuIcon } from "react-simple-sidenav";
import CONSTANTS from "../../constant.json";
import CasesSidenav from "../../Components/CasesSidenav";
import PaymentScheduleDialog from "../../Popups/PaymentScheduleDialog";
import CasesDialog from "../../Popups/CasesDialog";
import AddIcon from "@mui/icons-material/Add";
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import AddCourse from "./AddCourse";
import { get } from "../../Services/services";

function CaseDetails() {
  const [fetchCase, setFetchCase] = React.useState(null);
  const [currency, setCurrency] = React.useState('');
  const [currentCourse, setCurrentCourse] = React.useState(0);
  const [openCases, setOpenCases] = React.useState([]);
  const [caseDialog, setCaseDialog] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [discountRequest, setDiscountRequest] = React.useState(null);
  const [showNav, setShowNav] = React.useState(false);
  const [subscribeCourseId, setSubscribeCourseId] = React.useState("");
  const [PaymentDialog, setPaymentDialog] = React.useState(false);
  const [showAddButton, setShowAddButton] = React.useState(false);
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const [edit, setEdit] = useState();
  const [courseFormData, setCourseFormData] = useState({
    batchModeId: "",
    batchId: "",
    courseId: "",
    subjectIds: [],
    subjectNames: [],
    addOnsId: [],
    addOnsNames: [],
    note: "",
    currency: "",
    courseAmount: 0,
    bundleDiscount: 0,
    addonAmount: 0,
    studentDiscount: 0,
    referenceId: "",
  })
  const { state } = useLocation();
  console.log(state.id)
  let caseId = state.id;

  let { dialog, _courseId } = state ? state : false
  const [opnDialog, setOpnDialog] = React.useState(true);
  // console.log(dialog, _courseId,_caseId)

  const addtionalData = async (data) => {
    let totalFee = 0;
    let bundleDiscount = 0;


    const subjectData = await get(`courses/${data?.courseId}/subjects/count`);
    if (data?.subjects?.length === subjectData?.data?.count) {

    }
    console.log(subjectData);

  }

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const openCasesArr = modules.find((m) => m?.name === "Open cases");

    setOpenCases(openCasesArr);
    fetchData();
    // eslint-disable-next-line
  }, [showNav]);

  async function fetchData() {
    try {
      let body = {
        caseId: caseId,
      };
      const response = await _getCase(body);
      console.log(response)

      blockAddButton(response);
      setFetchCase(response);
      if (opnDialog) {
        let index = response?.subscribedCourses?.findIndex(s => s?.courses?._id === _courseId)

        const url = `${CONSTANTS.baseUrl}/countries/${response?.subscribedCourses[index].courses?.currency}`

        const currResponse = await httpGet(url);

        setCurrency(currResponse);
        openSideNav(response?.subscribedCourses[index]['_id'], index)
        setTabIndex(1)
      }

    } catch (error) {
    }
  }

  function blockAddButton(row) {

    let subscribe = row?.subscribedCourses, paid = true, checkPay = true;

    for (let i = 0; i < subscribe.length && paid; i++) {
      // let totalFee = subscribe[i].totalFee;
      // let withoutBounce = subscribe[i]?.paymentSchedules?.filter(e => {
      //   if (e?.status == 'paid' && e?.type == undefined) {
      //     return true
      //   } else return false;
      // })?.map(e => e?.amount)?.reduce((a, b) => { return a + b }, 0);
      // if (withoutBounce < totalFee) {
      //   paid = false;
      //   checkPay = false;
      //   continue;
      // }
      // let checkBouncePay = subscribe[i]?.paymentSchedules?.filter(e => e?.type == 'bounceCharges');
      // let checkPaidBounce = subscribe[i]?.paymentSchedules?.filter(e => e?.type == 'bounceCharges' && e?.type == 'paid');
      // if (checkBouncePay?.length != checkPaidBounce?.length) {
      //   paid = false;
      //   checkPay = false;
      //   continue;
      // }
      // if (i == subscribe[i].length - 1) {
      //   paid = false;
      // }
      if (!subscribe[i]?.feePaid) {
        if (!subscribe[i]?.refunds) {
          checkPay = false;
        }
      }
    }
    setShowAddButton(checkPay)
  }

  let navigate = useNavigate();
  let currentSubsCourseId;
  function openSideNav(id, index) {

    setCurrentCourse(index);
    currentSubsCourseId = id;
    setSubscribeCourseId(id);
    fetchDiscountRequest(id);
    setShowNav(true);
    //FETCH ALL DATA TO BE USED IN SIDENAV
  }

  async function fetchDiscountRequest(id) {
    const filter = `?filter={"where": {"subscribedCoursesId": "${id}"}}`;
    const url = `${CONSTANTS.baseUrl}/discountRequests${filter}`;
    const response = await httpGet(url);
    setDiscountRequest(response);
  }

  let params = useParams();
  // const { caseId } = params;

  async function updateRequest(obj, discountReq) {
    const url = `${CONSTANTS.baseUrl}/discountRequests`;
    const body = obj;
    const response = await httpPost(url, body);
    fetchDiscountRequest(subscribeCourseId);
  }

  async function deleteDiscountReq(i, discountId) {
    const url = `${CONSTANTS.baseUrl}/discountRequests/${discountId}`;
    const response = await httpDelete(url);
    fetchDiscountRequest(subscribeCourseId);
  }

  function closePaymentDialog() {
    setShowNav(false);
    setPaymentDialog(false);
  }

  async function onDownpaymentClick(index) {

    const url = `${CONSTANTS.baseUrl}/countries/${fetchCase?.subscribedCourses[index].courses?.currency}`
    const response = await httpGet(url);
    setCurrency(response?.currencyName);

    setTabIndex(index);
    setPaymentDialog(true);
  }

  function refreshCase() {
    fetchData();
    console.log("refress", caseId)
    // setShowNav(false)
  }

  function cancelDialogAction() {
    setCaseDialog(false);
  }

  function openCase() {
    navigate("/home/open_cases");
  }
  function getcase() {
    fetchData()
  }
  const handleEditForm = (data) => {
    console.log("ashish+++++++++++++++", data?.bundleDiscount);
    let subjectNames = [];
    let subjectIds = [];
    let addOnsNames = [];
    let addOnsId = [];
    data?.subjects?.map((item) => {
      subjectNames.push(item?.name)
      subjectIds.push(item?._id);
    })
    data?.addOns?.map((item) => {
      addOnsNames.push(item?.name)
      addOnsId.push(item?._id);
    })
    console.log("aj", subjectIds)
    setCourseFormData({
      batchModeId: data?.batchMode?._id,
      batchId: data?.batchId,
      courseId: data?.courseId,
      subjectIds: subjectIds,
      subjectNames: subjectNames,
      addOnsId: addOnsId,
      addOnsNames: addOnsNames,
      note: data?.note,
      currency: "",
      courseAmount: data?.courseAmount,
      bundleDiscount: data?.bundleDiscount,
      addonAmount: data?.addonAmount,
      studentDiscount: data?.studentDiscount,
      referenceId: data?.referenceId
    })
    setEdit(data?._id);
    setCaseDialog(true);
  }
  const cases = [
    <Typography
      onClick={openCase}
      component={"div"}
      sx={{ fontSize: "20px" }}
      key="1"
    >
      Open Cases
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Case Details
    </Typography>,
  ];


  return (
    <div className="h-full w-full overflow-auto">
      <div className="p-4 flex items-center">
        <ArrowBackIcon
          onClick={openCase}
          sx={{
            color: "gray",
            fontSize: "18px",
            marginRight: "20px",
            cursor: "pointer",
          }}
        />
        <BreadCrumb openCase={openCase} data={cases} />
        {!fetchCase?.case?.isRegistered && <div>
          <span className='ml-20'><ErrorIcon sx={{ color: "red", fontSize: "15px" }} /></span>
          <span className='ml-2 text-sm text-gray-400'>Not registered...</span>
        </div>}
      </div>
      {fetchCase ? (
        <div className="mt-3 mb-16">
          <CaseDetailsHeadedCard openCases={openCases} fetchCase={fetchCase} />
          {console.log(fetchCase)}
        </div>
      ) : null}
      {fetchCase?.subscribedCourses.map((course, index) => {
        return (
          <div className="mt-8" key={index}>
            <CaseDetailsContentCard
              openCases={openCases}
              handleEditForm={handleEditForm}
              onDownpaymentClick={() => onDownpaymentClick(index)}
              subscribedCourse={course}
              openSideNav={() => openSideNav(course._id, index)}
              fetchCase={fetchCase}
            />
          </div>
        );
      })}

      <SideNav
        className="z-10"
        style={{ zIndex: 1 }}
        openFromRight={true}
        showNav={showNav}
        onHideNav={() => { setOpnDialog(false); setShowNav(false) }}
        title={null}
        children={[
          <div key={0} className="cursor-default overflow-auto ">
            <CasesSidenav
              openCases={openCases}
              caseId={caseId}
              refreshCase={refreshCase}
              courseData={fetchCase?.subscribedCourses[currentCourse]}
              showNav={showNav}
              fetchCase={fetchCase}
            />
          </div>
        ]}
        navStyle={{ maxWidth: "65%" }}
      />

      {/* <PaymentScheduleDialog
        currency={currency}
        PaymentDialog={PaymentDialog}
        closePaymentDialog={closePaymentDialog}
        currentCourse={fetchCase?.subscribedCourses[currentCourse]}
        fetchCase={fetchCase}
        dialogTitle={"DOWN PAYMENT"}
        refreshCase={refreshCase}
      /> */}

      <div style={{ display: !(openCases?.write || openCases?.update) || fetchCase?.case?.deleted ? "none" : "block" }}>
        <Button
          onClick={() => setCaseDialog(true)}
          variant="contained"

          sx={{
            borderRadius: "50px",
            height: "60px",
            width: "60px",
            position: "fixed",
            bottom: "40px",
            right: "40px",
          }}
        >
          <AddIcon sx={{ fontSize: "30px" }} />
        </Button>
      </div>

      <AddCourse fetchCase={fetchCase} edit={edit} setEdit={setEdit} fetchData={fetchData} caseId={caseId} setCourseFormData={setCourseFormData} courseFormData={courseFormData} open={caseDialog} setOpen={setCaseDialog} />
    </div>
  );
}
export default CaseDetails;
