import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Checkbox, Fab, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilState } from 'recoil';
import { set } from 'lodash';
import { post } from '../Services/services';
import { wholeNumberRegex } from '../Regex/Regex';
import { UserAuthState } from '../Services/RecoilService';
import moment from 'moment';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function RefundRequestDialog(props) {

    const [refundReason, setRefundReason] = React.useState();

    const [authState, setAuthState] = useRecoilState(UserAuthState);





    const handleAddDiscountRequest = async () => {
        let body = {
            adminId : authState?.userId,
            status : "pending",
            subscribedCoursesId : props?.courseData?._id,
            batchId : props?.courseData?.batchId,
            deleted : false,
            courseId : props?.courseData?.courseId,
            caseId : props?.courseData?.caseId,
            refundReason:refundReason,
            requestedAmount:props?.courseData?.paidAmount,
            requestedDate: new Date(),
        };

        console.log(body);
        const res = await post("refunds", body);
        if (res?.statusCode === 200) {
            props.setDiscountDialog(false);
            props.refreshCase();
        }

        // body.
    }
    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                onClose={() => { props.setDiscountDialog(false) }}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Refund request
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { props.setDiscountDialog(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-10 p-5'>


                        <Box>

                            <Box className='flex gap-10'>
                                <Box className='flex items-center gap-1'>
                                    <p className="text-[#7A7474] text-ms">Total fee -</p>
                                    <p className="text-[#1C46F2] text-ms">{props?.courseData?.currency?.currencyName} {props?.courseData?.totalFee}</p>
                                </Box>
                                <Box className='flex items-center gap-1'>
                                    <p className="text-[#7A7474] text-ms">Paid -</p>
                                    <p className="text-[#4CAF50] text-ms">{props?.courseData?.currency?.currencyName} {props?.courseData?.paidAmount ? props?.courseData?.paidAmount : 0}</p>
                                </Box>
                            </Box>
                            <Box className='py-5' fullWidth>
                                <TextField
                                    required
                                    label="REASON"
                                    fullWidth
                                    multiline
                                    minRows={8}
                                    value={refundReason}
                                    onChange={(e) => setRefundReason(e.target.value)}
                                />
                            </Box>
                        </Box>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={!refundReason || refundReason?.length === 0}
                        autoFocus
                        onClick={handleAddDiscountRequest}
                    >
                        SEND REQUEST
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}