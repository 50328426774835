import { TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
export default function DateFilter(props) {

    const inputRef = React.useRef(null);
    const inputRefs = React.useRef(null);



    // useEffect(()=>{
    //     if(useEffect)
    // },[props.clearDate])
    return (
        <div className='flex  justify-center items-center'>

            <TextField type='date' size='small' className=' w-40 bg-white'
                value={props.filterDatas.dateFilter.startDate}
                inputRef={inputRef}
                InputProps={{ inputProps: { max: props.filterDatas.dateFilter.endDate } }}
                onChange={(event) => props.setFilterDatas(prevState => (
                    { ...prevState, dateFilter: { ...prevState.dateFilter, startDate: event.target.value } }))}
            />
            <Typography>-</Typography>
            <TextField type='date' size='small' className=' w-40 bg-white'
                onChange={(event) => props.setFilterDatas(prevState => (
                    { ...prevState, dateFilter: { ...prevState.dateFilter, endDate: event.target.value } }))}
                value={props.filterDatas.dateFilter.endDate}
                inputRef={inputRefs}
                InputProps={{ inputProps: { min: props.filterDatas.dateFilter.startDate } }}
            />
            {
                props.filterDatas.dateFilter.endDate || props.filterDatas.dateFilter.startDate ?
                    (<ClearIcon className='text-[#3366ff] cursor-pointer hover:text-black'
                        onClick={() => {
                            props.setFilterDatas(prevState => (
                                { ...prevState, dateFilter: { ...prevState.dateFilter, startDate: '' } }));
                            props.setFilterDatas(prevState => (
                                { ...prevState, dateFilter: { ...prevState.dateFilter, endDate: '' } }));

                            if (inputRef.current) {
                                inputRef.current.value = '';
                                inputRefs.current.value = '';
                            }

                        }} />) :
                    (<div></div>)
            }
        </div>
    )
}
