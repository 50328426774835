import React from "react";
import Chip from "@mui/material/Chip";
import DynamicTable from "../Components/DynamicTable";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import DateInput from "../Components/DateInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DateSearchFilter from "./DateSearchFilter";
import CourseDialog from "../Popups/CourseDialog";
import Edit from "@mui/icons-material/Edit";
import { UserAuthState } from "../Services/RecoilService";
import { useRecoilState } from "recoil";

const CourseSideBar = (props) => {
  const [dataSource, setDataSource] = React.useState([]);
  const [coursesM, setCoursesM] = React.useState([]);
  const [editCourse, setEditCourse] = React.useState(false);
  const [updatedCourse, setUpdatedCourse] = React.useState([]);
  const [courseDialogue, setCourseDialogue] = React.useState(false);
  const [courseIndex, setCourseIndex] = React.useState(0);
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  function refreshcourse(data) {
    fetchData(data);
    props.refreshcourse(data)
  }

  const fetchData = async () => {
    // console.log(props)
    const _dataSource = await createTableData(props?.courses);
    setDataSource(_dataSource);

  };
  const _closeCourseDialogue = () => {
    setCourseDialogue(false);
    setEditCourse(false);
  };

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const coursesArr = modules.find((m) => m?.name === "Courses");
    setCoursesM(coursesArr);
    fetchData();
  }, [props?.showSideBar, props?.updateCourse]);
  const headCellsCases = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "S.No",
    },

    {
      id: "name",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Subject Name",
    },
    {
      id: "actualPrice",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Actual Price",
    },
 
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Created On",
    },
   
  ];

  if (coursesM?.update !== true) {
    headCellsCases.pop()
  }
  const createTableData = (data) => {
    // CREATE TABLE DATA - ADDONS
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data?.subjects && data?.subjects?.length) {
        for (let index = 0; index < data?.subjects?.length; index++) {

          const row = data?.subjects[index];
          const obj = {
            id: row.id,
            courseId: data?.id,
            sno: index + 1,
            name: row?.name,
            actualPrice: row?.price,
            bundleDiscounts:
              row?.price -
              (data?.bundleDiscount / data?.subjects?.length).toFixed(2),
            date: row?.createdAt,
            action: false,
            tax:data?.tax,
            batchmode: data?.batchmode?.name,
            currency: data?.country?.currencyName,
            courseCategoryId: data?.courseCategory?.id,
            batchmodeId: data?.batchmode?.id,
            currencyId: data?.country?.id,
            bundleDiscount: data?.bundleDiscount,
            studentDiscount: data?.studentDiscount,
            subjects: data?.subjects.map((s) => s),
            courseCategory: data?.courseCategory?.name,
            price: data?.price,
          };
          returnArray.push(obj);
          if (index === data?.subjects.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };

  function editCourseDialog(e, data) {
    console.log("shivani ma'am",data);
    e.stopPropagation();
    setUpdatedCourse(data);
    setCourseIndex(data.id);
    setEditCourse(true);
    setCourseDialogue(true);
  }

  return (
    <>
      <div className="h-full w-full my-24 px-10">
        {console.log(props.courses)}
        <div className="font-bold rounded-md border-gray-300 border py-6 px-7 h-auto w-auto flex-col">
          <div className="flex">
            <div>
              <p className="tColor">{props?.courses?.name} </p>
              <Chip
                sx={{ height: "20px" }}
                label={`${props?.courses?.country?.currencyName} ${props?.courses?.price}`}
                color="success"
              />
              <div className="flex items-center mt-10">
                <div className="text-xs ">
                  <p className="text-gray-500">Student Discount </p>
                  <p>{props.courses?.studentDiscount}</p>
                </div>
                <div className="text-xs ml-10  ">
                  <p className="text-gray-500">Bundle discount </p>
                  <p>{props.courses?.bundleDiscount}</p>
                </div>
              </div>
            </div>
            <div className="ml-20">
              <p className="text-gray-500">Subjects</p>
              {props?.courses?.subjects?.map((sub, i) => {
                return (
                  <div key={i}>
                    <span>
                      <CheckCircleIcon
                        sx={{ fontSize: "20px", color: "green" }}
                      />
                    </span>
                    &nbsp;&nbsp;
                    <span className="text-sm">{sub.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div>
          <div className="mt-10 flex justify-between">
            <div className="flex items-center">
              <p className="text-lg text-gray-500">SUBJECTS</p>
              &nbsp;
              <p className="text-lg text-gray-500">
                ({props.courses?.subjects?.length})
              </p>
            </div>
            {/* <DateSearchFilter white={true} /> */}
          </div>
          <div className="mt-10">
            {props?.courses?.subjects?.length == 0 ? <>
              <Button
                onClick={() => { setCourseDialogue(true); setEditCourse(true) }} variant="contained"
                sx={{
                  borderRadius: "50px", height: "60px", width: "60px", position: "fixed", bottom: "40px", right: "40px"
                }}>
                <Edit sx={{ fontSize: "30px" }} />
              </Button>
            </> : null}
            <DynamicTable
              dataSource={dataSource}
              headCells={headCellsCases}
              edit={false}
              actionOnEdit={(e, data) => editCourseDialog(e, { ...data, courseName: props?.courses.name })}
              // actionOnSend={(e, data) => onActionSend(e, data)}
              // thirdAction={true}
              pagination={true}
            />
          </div>
        </div>
        <CourseDialog
        
          refreshcourse={refreshcourse}
          editCourse={editCourse}
          updatedCourse={updatedCourse}
          courseDialogOpen={courseDialogue}
          courseDialogClose={_closeCourseDialogue}
        />
      </div>
    </>
  );
};
export default CourseSideBar;
