import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Button,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { patch, post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CasesDialog from '../../Popups/CasesDialog';

import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomizedSnackbars from '../../Components/Snackbar';
import { successEvents } from '../../App';
export default function Cases() {
    const [access, setAccess] = useState();
    const [cases, setCases] = React.useState(null)

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 })
    const [searchInput, setSearchInput] = useState();
    const [dates, setDates] = useState();
    const [row, setRow] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterRowCount, setFilterRowCount] = useState(0);
    const [openCaseDialog, setOpenCaseDialog] = useState(false)
    const [edit, setEdit] = useState();
    const { setCurrentPage } = React.useContext(successEvents)
    const [tableLoading, setTableLoading] = useState(true);

    const [casesData, setCasesData] = useState(
        {
            name: "",
            email: "",
            countryId: "",
            mobile: "",
            gender: "",
            buddyId: "",
        }
    );
    const [snackBarOptions, setSnackBarOptions] = React.useState({
        message: "",
        duration: 5000,
        show: false,
        severity: "",
    });
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    // const [clearDate,setClearDate] =useState()

    const navigate = useNavigate();
    const columns = [

        {
            field: 'name',
            headerName: 'NAME',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'gender',
            headerName: 'GENDER',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'mobile',
            headerName: 'MOBILE',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'email',
            headerName: 'EMAIL',

            minWidth: 150,
            flex: 1,
        },




        {
            field: 'createdAt',
            headerName: 'CREATED AT',
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
            },
        },
        {
            field: 'deleted',
            headerName: 'STATUS',
            minWidth: 150,
            renderCell: (params) => {
                const _currentModule = authState?.userData?.assignModules?.assignModule.find(module => module.name == "Old cases");
                return (
                    <div className='flex items-center gap-6'>
                        {console.log(_currentModule)}
                        {_currentModule?.update ?
                            <Tooltip title='Add old cases to open cases'>
                                <PersonAddIcon onClick={(e) => { e.stopPropagation(); handlAddtoOpenCases(params.row.id) }} className='text-[#3366ff]' style={{ display: access?.update || access?.write ? "block" : "none" }} />
                            </Tooltip>
                            :
                            <PersonAddIcon className='text-gray-400 cursor-not-allowed' style={{ display: access?.update || access?.write ? "block" : "none" }} />}
                    </div>
                );
            },
        },
    ];

    // const cancelDialogAction = () => {
    //     setSearchActionDialog(false)
    //     setRefundReqActionDialog(false)
    // }
    const handlAddtoOpenCases = async (id) => {
        const ress = await patch(`cases/${id}`, { deleted: false });
        if (ress.statusCode === 200) {
            setSnackBarOptions({
                message: "Case Added to Open Cases successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
            getCases(searchInput, dates, paginationModel);
        }
        else if (ress.statusCode === 422) {
            console.log(ress.message)
            setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }
    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }
    const getCases = async (search, dates, page) => {
        setTableLoading(true)
        const res = await post("cases/getAllOldCases", { searchText: search, dateFilter: dates, page: page });
        if (res.statusCode === 200) {
            setRow(res?.data?.data);
            setTotalRowCount(res?.data?.totalCount);
            setFilterRowCount(res?.data?.filterCount)
        }
        setTableLoading(false)
    }
    const debounceSearch = useCallback(debounce(getCases, 300), []);
    const handleOnRowClick = async (row) => {

        setCurrentPage("open_cases")
        navigate('/home/old_cases/caseDetails', { state: { id: row.row.id } })
    }
    useEffect(() => {
        debounceSearch(searchInput, dates, paginationModel);
    }, [searchInput, dates, paginationModel])
    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const rolesArr = modules?.find((m) => m?.name === "Open cases");
        const old = modules?.find((m) => m?.name === "Open cases");
        setCases(rolesArr)

        setAccess(old)
    }, [])

    return (<>
        <div className=" flex flex-col flex-1" style={{ width: "100%" }}  >
            <div className='flex items-center justify-between flex-wrap '>
                <Typography className='font-normal text-xl text-gray-500'>Old cases ({totalRowCount})</Typography>
                <div className="flex items-center gap-5 flex-wrap">

                    <FormControl sx={{ width: "300px" }}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="sm" />
                                    </InputAdornment>
                                ),
                            }}
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </FormControl>
                    <DateRangePiker getData={getDate} />

                </div>
            </div>

            <CustomizedSnackbars
                message={snackBarOptions.message}
                open={snackBarOptions.show}
                close={() => {
                    setSnackBarOptions({ ...snackBarOptions, show: false });
                }}
                hideDur={5000}
                severity={snackBarOptions.severity}
            />
            <DataGidTable loading={tableLoading} cursor={cases?.read || cases?.update || cases?.write ? true : false} handleEvent={cases?.read || cases?.update || cases?.write ? handleOnRowClick : ""} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />

        </div>
    </>)

}

