import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


export default function BreadCrumb(props) {
  

  return (
    <Stack spacing={2}>
     
      <Breadcrumbs
        className='cursor-pointer'
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {props.data}
      </Breadcrumbs>
    </Stack>
  );
}