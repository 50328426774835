import { Avatar, Button, Divider, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../Components/BreadCrumbs";
import CONSTANTS from "../constant.json";
import * as React from "react";
import { httpGet, httpPost } from "../Services/api-services";
import DynamicTable from "../Components/DynamicTable";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { ViewModule } from "@mui/icons-material";
import AssignModulesDialog from "../Popups/AssignModulesDialog";
import CustomizedSnackbars from "../Components/Snackbar";
import { useRecoilState } from "recoil";
import { UserAuthState } from "../Services/RecoilService";

function StaffDetails() {
  const [Staff, setStaff] = React.useState([]);
  const [rolesM, setRolesM] = React.useState([]);
  const [moduleLength, setModuleLength] = React.useState();
  const [modulesArray, setModulesArray] = React.useState();
  const [staffDataSource, setStaffDataSource] = React.useState([]);
  const [assignModulesDialog, setAssignModulesDialog] = React.useState(false);
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  const [authState, setAuthState] = useRecoilState(UserAuthState);

  function oncloseAction() {
    setAssignModulesDialog(false);
  }

  // let params = useParams();
  const { state } = useLocation();
  // const {  } = params;
  let staffId = state?.data?.id;
  console.log(staffId);
  let modulesData = [
    {
      name: "Dashboard",
      id: false,
      no: 0,
      children: [
        {
          name: "Analytics",
          padding: true,
          id: false,
          no: 1,
        },
        {
          name: "Reports",
          padding: true,
          id: false,
          no: 2,
        },
      ],
    },
    {
      name: "Cases",
      id: false,
      no: 3,
      children: [
        {
          name: "Open cases",
          padding: true,
          id: false,
          no: 4,
        },
        {
          name: "Enrollments",
          padding: true,
          id: false,
          no: 5,
        },
      ],
    },
    {
      name: "Pendings",
      id: false,
      no: 6,
      children: [
        {
          name: "Discount Requests",
          padding: true,
          id: false,
          no: 7,
        },
        {
          name: "Refund Requests",
          padding: true,
          id: false,
          no: 8,
        },
        {
          name: "Payment due",
          padding: true,
          id: false,
          no: 9,
        },
      ],
    },
    {
      name: "Roles",
      id: false,
      no: 10,
    },
    {
      name: "Master",
      id: false,
      no: 11,
      children: [
        {
          name: "Batch",
          padding: true,
          id: false,
          no: 12,
        },
        {
          name: "Course categories",
          padding: true,
          id: false,
          no: 13,
        },
        {
          name: "Courses",
          padding: true,
          id: false,
          no: 14,
        },

        {
          name: "Addons",
          padding: true,
          id: false,
          no: 15,
        },
        {
          name: "Tags",
          padding: true,
          id: false,
          no: 16,
        },
        {
          name: "Cheque bouncing charges",
          padding: true,
          id: false,
          no: 17,
        },
        {
          name: "Sms template",
          padding: true,
          id: false,
          no: 18,
        },
      ],
    },
  ];

  let navigate = useNavigate();

  const roles = [
    <Typography
      onClick={() => navigate("/roles")}
      component={"div"}
      sx={{ fontSize: "20px" }}
      key="1"
    >
      Roles
    </Typography>,
    <Typography
      onClick={() => navigate("/roles")}
      component={"div"}
      sx={{ fontSize: "20px" }}
      key="2"
    >
      Staff
    </Typography>,
    <Typography
      component={"div"}
      sx={{ fontSize: "20px", color: "#2B44FF;" }}
      key="2"
    >
      {Staff.name}
    </Typography>,
  ];

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;

    console.log(modules);
    const rolesArr = modules.find((m) => m?.name === "Roles");
    setRolesM(rolesArr);
    getStaff();
    // eslint-disable-next-line
  }, []);

  const filterModules = async (modules) => {
    const arr = []
    return new Promise(resolve => {
      for (let index = 0; index < modules.length; index++) {
        if (modules[index]['children']) {
          arr.push(...modules[index]['children'])
        }
        else {
          arr.push(modules[index])
        }
        if (index === modules.length - 1) resolve(arr)
      }
    })
  }

  const returnIndex = (name, staticArr) => {
    let _foundIndex = -1
    return new Promise(resolve => {
      for (let i = 0; i < staticArr.length; i++) {
        if (staticArr[i]['name'] === name) {
          console.log(name, staticArr[i]['name'], i)
          _foundIndex = i
        }
        if (i === staticArr.length - 1) resolve(_foundIndex)
      }
    })
  }

  const __assignModules = (staticArr, dynamic) => {
    return new Promise(async (resolve) => {
      for (let i = 0; i < dynamic.length; i++) {
        const _index = await returnIndex(dynamic[i]['name'], staticArr)
        // console.log(_index)
        if (_index < 0) {
          dynamic[i] = { name: dynamic[i]['name'], read: false, write: false, update: false }
        }
        else {
          console.log()
          dynamic[i] = staticArr[_index]
        }
      }
      resolve(dynamic)
    })
  }

  async function getStaff() {
    const filter = `?filter={ "include": "assignModules"}`;
    const url = `${CONSTANTS.baseUrl}/Admins/${staffId}${filter}`;
    const response = await httpGet(url);
    console.log(response);
    setStaff(response);
    let arr = response.assignModules.assignModule;
    console.log(arr)
    setModuleLength(arr.length);
    const dynamicModules = await filterModules(modulesData)
    console.log('dynamicModules --- ', dynamicModules)
    const tableArr = await __assignModules(arr, dynamicModules)
    console.log(tableArr)
    const _dataSourceStaff = await createTableDataStaff(tableArr);
    setStaffDataSource(_dataSourceStaff);
  }

  const headCellsStaff = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "S.no",
    },
    {
      id: "modules",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "Modules",
    },
    {
      id: "viewAccess",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "View Access",
    },
    {
      id: "createAccess",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "Create Access",
    },
    {
      id: "updateAccess",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "Update Access",
    },
  ];

  function viewModule(row) {
    console.log(row)
    return (
      <div>
        {row?.read === true ? (
          <DoneIcon sx={{ color: "green" }} />
        ) : (
          <CloseIcon sx={{ color: "red" }} />
        )}
      </div>
    );
  }

  function createModule(row) {
    return (
      <div>
        {row?.write === true ? (
          <DoneIcon sx={{ color: "green" }} />
        ) : (
          <CloseIcon sx={{ color: "red" }} />
        )}
      </div>
    );
  }

  function updateModule(row) {
    return (
      <div>
        {row?.update === true ? (
          <DoneIcon sx={{ color: "green" }} />
        ) : (
          <CloseIcon sx={{ color: "red" }} />
        )}
      </div>
    );
  }

  const createTableDataStaff = (data) => {
    // CREATE TABLE DATA - Staff
    console.log(data)
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const row = data[index];
          const obj = {
            sno: index + 1,
            action: true,
            status: true,
            modules: row?.name,
            viewAccess: viewModule(row),
            createAccess: createModule(row),
            updateAccess: updateModule(row),
          };
          returnArray.push(obj);
          if (index === data.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };

  async function onUpdateClick(staffId, modules) {
    setAssignModulesDialog(false);
    console.log(staffId, modules);
    const body = {
      adminId: staffId,
      assignModule: modules,
    };
    console.log(body);
    const where = `?where={"adminId": "${staffId}"}`;
    const url = `${CONSTANTS.baseUrl}/assignModules/upsertWithWhere${where}`;
    const response = await httpPost(url, body);
    let res = await httpPost(`${CONSTANTS.baseUrl}/cases/LogOutByidFromEveryWhereStaff`, { staffId: staffId });


    console.log(response);
    setSnackBarOptions({
      message: "Modules updated succesfully",
      show: true,
      severity: "success",
      duration: 5000,
    });
    getStaff();
  }

  return (
    <div className="h-full w-full overflow-auto">
      <div className="m-4">
        <BreadCrumb data={roles} />
        <div className="bg-white mt-7">
          <div className="flex p-4 items-center">
            <Avatar
              sx={{ width: "60px", height: "60px" }}
              alt="Cindy Baker"
              src={`${CONSTANTS.baseUrl}/profilePics/staff/download/${Staff.image}`}
            />
            <div className="pl-5">
              <h1>
                <b>{Staff.name}</b>
              </h1>
              <h1 className="text-xs">
                {Staff.countryCode} {Staff.mobile}
              </h1>
            </div>
          </div>
          {Staff?.assignModules && <div>
            <Divider />
            <div className="p-4 flex justify-between items-center">
              <h1>Assign Modules ({moduleLength})</h1>
              <Button
                disabled={rolesM?.update ? false : true}
                onClick={() => setAssignModulesDialog(true)}
                variant="contained"
              >
                Edit Module Access
              </Button>
            </div>
            <div className="my-5">
              <DynamicTable
                dataSource={staffDataSource}
                headCells={headCellsStaff}
                actionOnDelete={(e, data) => console.log(e, data)}
                actionOnEdit={(e, data) => console.log(e, data)}
                edit={true}
                delete={true}
              />
            </div>
          </div>}
        </div>
      </div>
      {assignModulesDialog ? <div><AssignModulesDialog
        onUpdateClick={(staffId, modulesData) =>
          onUpdateClick(staffId, modulesData)
        }
        staff={Staff}
        assignModulesDialog={assignModulesDialog}
        oncloseAction={oncloseAction}
      /></div> : null}
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default StaffDetails;
