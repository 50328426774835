import { Sms, AddToPhotos, Discount, LocalLibrary, Sell, Dashboard, Analytics, Hail, Person, Description, Campaign, ScreenShare, BarChart, HowToReg, HourglassTop, Error,AccountBalance, Public, Settings, LibraryAddCheck, PersonRemove  } from '@mui/icons-material';

const IconList = {
    Analytics: Analytics,
    Reports: BarChart,
    Roles: Hail,
    Opencases: Person,
    Enrollments: HowToReg,
    DiscountRequests: HourglassTop,
    RefundRequests: HourglassTop,
    Paymentdue: Error,
    Batchs: Campaign,
    Coursecategories: ScreenShare,
    Courses: LocalLibrary,
    Addons: AddToPhotos,
    Tags: Sell,
    Chequebouncingcharges: AccountBalance,
    Smstemplates: Sms,
    Oldcases: PersonRemove,
    Fields:Settings,
    Country:Public,
    ApprovedRefunds:LibraryAddCheck,
}
const Icon = ({ icon, ...props }) => {
    const Component = IconList[icon];
    return <Component {...props} />;
};
export default Icon;