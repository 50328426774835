import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, FormControlLabel, FormGroup, IconButton, InputAdornment, Menu, MenuItem, Radio, TextField, Tooltip } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { get, post } from '../Services/services';
// import DateRangePiker from './DateRangePiker';
// import { UserAuthState } from '../service/RecoilService';

import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DateFilter from './DateFilter';
// import SnackbarCrm from './SnackbarCrm';
// import DateRangePikerForfilter from './DateRangePikerForfilter';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function StudentFilter({ state, getStudent, setState, data, setFilterCount, filterCount, setFilterDatas, filterDatas, filterData, paginationModel, setPaginationModel, statusFilter, groupsList, city }) {
    const [expanded, setExpanded] = React.useState('panel1');
    const [copyFilter, setCopyFilter] = React.useState(filterDatas);
    const [stageList, setStageList] = React.useState([]);
    const [clearDate, setClearDate] = React.useState(true);
    // const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [snackbars, setSnackbars] = React.useState({
        open: false,
        msg: "",
        severity: ""
    });

    const handleOnSelect = (item, title) => {

        if (title === "payment modes") {
            const __index = filterDatas.selectedPaymentModes.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedPaymentModes];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedPaymentModes: array }));
            } else {
                const xindex = filterDatas.excludedPaymentModes?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedPaymentModes];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedPaymentModes: arrays }));

                }
                const array = [...filterDatas.selectedPaymentModes, item];

                setFilterDatas(prevState => (
                    { ...prevState, selectedPaymentModes: array }));


            }
        }
        else if (title === "reference") {
            const __index = filterDatas.selectedReference.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedReference];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedReference: array }));
            } else {
                const xindex = filterDatas.excludedReference?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedReference];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedReference: arrays }));

                }
                const array = [...filterDatas.selectedReference, item];

                setFilterDatas(prevState => (
                    { ...prevState, selectedReference: array }));


            }
        }
        else if (title === "career options") {
            const __index = filterDatas.selectedCareerOptions.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedCareerOptions];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedCareerOptions: array }));
            } else {
                const xindex = filterDatas.excludedCareerOptions?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedCareerOptions];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedCareerOptions: arrays }));

                }
                const array = [...filterDatas.selectedCareerOptions, item];

                setFilterDatas(prevState => (
                    { ...prevState, selectedCareerOptions: array }));


            }
        }
        else if (title === "source") {
            const __index = filterDatas.selectedSource.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedSource];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedSource: array }));
            } else {
                const xindex = filterDatas.excludedSource?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedSource];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedSource: arrays }));

                }
                const array = [...filterDatas.selectedSource, item];

                setFilterDatas(prevState => (
                    { ...prevState, selectedSource: array }));


            }
        }
        else if (title === "next expected course") {
            const __index = filterDatas.selectedNextExpectedCource.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedNextExpectedCource];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedNextExpectedCource: array }));
            } else {
                const xindex = filterDatas.exculdedNextExpectedCource?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.exculdedNextExpectedCource];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, exculdedNextExpectedCource: arrays }));

                }
                const array = [...filterDatas.selectedNextExpectedCource, item];

                setFilterDatas(prevState => (
                    { ...prevState, selectedNextExpectedCource: array }));


            }
        }
        else if (title === "board") {
            const __index = filterDatas.selectedBoard.indexOf(item);
            if (__index >= 0) {

                let array = [...filterDatas.selectedBoard];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedBoard: array }));

            } else {

                const xindex = filterDatas.excludedBoard?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedBoard];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedBoard: arrays }));


                }
                const array = [...filterDatas.selectedBoard, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedBoard: array }));

            }

        }
        else if (title === "annual family income") {
            const __index = filterDatas.selectedFamilyIncome.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedFamilyIncome];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedFamilyIncome: array }));

            } else {
                const xindex = filterDatas.excludedFamilyIncome?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedFamilyIncome];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedFamilyIncome: arrays }));

                }
                const array = [...filterDatas.selectedFamilyIncome, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedFamilyIncome: array }));
            }
        }
        else if (title === "buddy") {
            const __index = filterDatas.selectedBuddy?.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedBuddy];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedBuddy: array }));

            } else {
                const xindex = filterDatas.excludedBuddy?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedBuddy];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedBuddy: arrays }));

                }
                const array = [...filterDatas.selectedBuddy, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedBuddy: array }));
            }
        }
        else if (title === "batch Mode") {
            const __index = filterDatas.selectedBatchMode.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedBatchMode];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedBatchMode: array }));
            } else {
                const xindex = filterDatas.excludedBatchMode?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedBatchMode];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedBatchMode: arrays }));

                }
                const array = [...filterDatas.selectedBatchMode, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedBatchMode: array }));
            }
        }
        else if (title === "Addons Taken") {


            const __index = filterDatas.selectedAddon.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedAddon];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedAddon: array }));
            } else {
                const xindex = filterDatas.excludedAddon?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedAddon];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedAddon: arrays }));

                }
                const array = [...filterDatas.selectedAddon, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedAddon: array }));
            }




        }
        else if (title === "stage category") {
            const __index = filterDatas.selectedBatchModeGroup.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.selectedBatchModeGroup];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedBatchModeGroup: array }));
            } else {
                const xindex = filterDatas.excludedBatchModeGroup?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedBatchModeGroup];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedBatchModeGroup: arrays }));

                }
                const array = [...filterDatas.selectedBatchModeGroup, item];

                setFilterDatas(prevState => (
                    { ...prevState, selectedBatchModeGroup: array }));


            }
        }
        else if (title === "batches") {
            const __index = filterDatas.selectedBatches.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedBatches];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedBatches: array }));
            } else {
                const xindex = filterDatas.excludedBatches?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedBatches];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedBatches: arrays }));

                }
                const array = [...filterDatas.selectedBatches, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedBatches: array }));
            }
        }

        else if (title === "tags") {
            const __index = filterDatas.selectedTag.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedTag];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedTag: array }));
            } else {
                const xindex = filterDatas.excludedTag?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedTag];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedTag: arrays }));

                }
                const array = [...filterDatas.selectedTag, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedTag: array }));
            }
        }
        else if (title === "school") {
            const __index = filterDatas.selectedSchool.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedSchool];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedSchool: array }));
            } else {
                const xindex = filterDatas.excludedSchool?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedSchool];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedSchool: arrays }));

                }
                const array = [...filterDatas.selectedSchool, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedSchool: array }));
            }
        }
        else if (title === "source") {
            const __index = filterDatas.selectedSource.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedSource];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedSource: array }));
            } else {
                const xindex = filterDatas.excludedSource?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedSource];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedSource: arrays }));

                }
                const array = [...filterDatas.selectedSource, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedSource: array }));
            }
        }

        else if (title === "parent occupation") {
            const __index = filterDatas.selectedOccupation.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedOccupation];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedOccupation: array }));
            } else {
                const xindex = filterDatas.excludedOccupation?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedOccupation];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedOccupation: arrays }));

                }
                const array = [...filterDatas.selectedOccupation, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedOccupation: array }));
            }
        }

        else if (title === "state") {
            const __index = filterDatas.selectedState.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedState];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedState: array }));
            } else {
                const xindex = filterDatas.excludedState?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedState];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedState: arrays }));

                }
                const array = [...filterDatas.selectedState, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedState: array }));
            }
        }
        else if (title === "country") {
            const __index = filterDatas.selectedCountry.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedCountry];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedCountry: array }));
            } else {
                const xindex = filterDatas.excludedCountry?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedCountry];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedCountry: arrays }));

                }
                const array = [...filterDatas.selectedCountry, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedCountry: array }));
            }
        }
        else if (title === "payment status") {
            const __index = filterDatas.selectedPaymentStatus.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedPaymentStatus];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedPaymentStatus: array }));
            } else {
                const xindex = filterDatas.excludedPaymentStatus?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedPaymentStatus];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedPaymentStatus: arrays }));

                }
                const array = [...filterDatas.selectedPaymentStatus, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedPaymentStatus: array }));
            }
        }
        else if (title === "admission process done") {
            console.log(item)
            const __index = filterDatas.selectedAddmisonProcess.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedAddmisonProcess];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedAddmisonProcess: array }));
            } else {
                const xindex = filterDatas.excludedAddmisonProcess?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedAddmisonProcess];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedAddmisonProcess: arrays }));

                }
                const array = [...filterDatas.selectedAddmisonProcess, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedAddmisonProcess: array }));
            }
        }
        else if (title === "courses") {
            const __index = filterDatas.selectedCourse.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedCourse];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedCourse: array }));
            } else {
                const xindex = filterDatas.excludedCourse?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedCourse];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedCourse: arrays }));

                }
                const array = [...filterDatas.selectedCourse, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedCourse: array }));
            }
        }
        else if (title === "city") {
            const __index = filterDatas.selectedCity.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedCity];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedCity: array }));
            } else {
                const xindex = filterDatas.excludedCity?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedCity];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedCity: arrays }));

                }
                const array = [...filterDatas.selectedCity, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedCity: array }));
            }
        }
        else if (title === "case created by") {
            const __index = filterDatas.selectedCreatedBy.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.selectedCreatedBy];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, selectedCreatedBy: array }));
            } else {
                const xindex = filterDatas.excludedCreatedBy?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.excludedCreatedBy];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, excludedCreatedBy: arrays }));

                }
                const array = [...filterDatas.selectedCreatedBy, item];
                setFilterDatas(prevState => (
                    { ...prevState, selectedCreatedBy: array }));
            }
        }
    }

    const handleOnSelectForExclude = (item, title) => {
        console.log(item)

        if (title === "payment modes") {
            const __index = filterDatas.excludedPaymentModes.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedPaymentModes];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedPaymentModes: array }));
            } else {
                const xindex = filterDatas.selectedPaymentModes?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedPaymentModes];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedPaymentModes: arrays }));
                }
                const array = [...filterDatas.excludedPaymentModes, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedPaymentModes: array }));


            }
        }
        else if (title === "reference") {
            const __index = filterDatas?.excludedReference?.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedReference];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedReference: array }));
            } else {
                const xindex = filterDatas.selectedReference?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedReference];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedReference: arrays }));
                }
                const array = [...filterDatas.excludedReference, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedReference: array }));
            }
        }
        else if (title === "career options") {
            const __index = filterDatas?.excludedCareerOptions?.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedCareerOptions];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedCareerOptions: array }));
            } else {
                const xindex = filterDatas.selectedCareerOptions?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedCareerOptions];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedCareerOptions: arrays }));
                }
                const array = [...filterDatas.excludedCareerOptions, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedCareerOptions: array }));
            }
        }
        else if (title === "source") {
            const __index = filterDatas?.excludedSource?.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedSource];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedSource: array }));
            } else {
                const xindex = filterDatas.selectedSource?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedSource];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedSource: arrays }));
                }
                const array = [...filterDatas.excludedSource, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedSource: array }));
            }
        }
        else if (title === "next expected course") {
            const __index = filterDatas?.exculdedNextExpectedCource?.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.exculdedNextExpectedCource];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, exculdedNextExpectedCource: array }));
            } else {
                const xindex = filterDatas.selectedNextExpectedCource?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedNextExpectedCource];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedNextExpectedCource: arrays }));
                }
                const array = [...filterDatas.exculdedNextExpectedCource, item];
                setFilterDatas(prevState => (
                    { ...prevState, exculdedNextExpectedCource: array }));
            }
        }
        else if (title === "board") {
            const __index = filterDatas.excludedBoard.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedBoard];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedBoard: array }));
            } else {
                const xindex = filterDatas.selectedBoard?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedBoard];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedBoard: arrays }));
                }
                const array = [...filterDatas.excludedBoard, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedBoard: array }));
            }
        }
        else if (title === "annual family income") {
            const __index = filterDatas.excludedFamilyIncome.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedFamilyIncome];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedFamilyIncome: array }));
            } else {
                const xindex = filterDatas.selectedFamilyIncome?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedFamilyIncome];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedFamilyIncome: arrays }));
                }
                const array = [...filterDatas.excludedFamilyIncome, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedFamilyIncome: array }));
            }
        }
        else if (title === "buddy") {
            const __index = filterDatas.excludedBuddy.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedBuddy];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedBuddy: array }
                ));
            } else {
                const xindex = filterDatas.selectedBuddy?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedBuddy];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedBuddy: arrays }
                    ));
                }
                const array = [...filterDatas.excludedBuddy, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedBuddy: array }
                ));
            }
        }
        else if (title === "batch Mode") {
            const __index = filterDatas.excludedBatchMode.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedBatchMode];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedBatchMode: array }
                ));
            } else {
                const xindex = filterDatas.selectedBatchMode?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedBatchMode];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedBatchMode: arrays }
                    ));
                }
                const array = [...filterDatas.excludedBatchMode, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedBatchMode: array }
                ));
            }
        }
        else if (title === "Addons Taken") {

            const __index = filterDatas.excludedAddon.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedAddon];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedAddon: array }));
            } else {
                const xindex = filterDatas.selectedAddon?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedAddon];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedAddon: arrays }));
                }
                const array = [...filterDatas.excludedAddon, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedAddon: array }));


            }


        }
        else if (title === "stage category") {
            const __index = filterDatas.excludedBatchModeGroup.indexOf(item);
            if (__index >= 0) {
                let array = [...filterDatas.excludedBatchModeGroup];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedBatchModeGroup: array }));
            } else {
                const xindex = filterDatas.selectedBatchModeGroup?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedBatchModeGroup];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedBatchModeGroup: arrays }));
                }
                const array = [...filterDatas.excludedBatchModeGroup, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedBatchModeGroup: array }));


            }
        }
        else if (title === "batches") {
            const __index = filterDatas.excludedBatches.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedBatches];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedBatches: array }
                ));
            } else {
                const xindex = filterDatas.selectedBatches?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedBatches];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedBatches: arrays }
                    ));
                }
                const array = [...filterDatas.excludedBatches, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedBatches: array }
                ));
            }
        }
        else if (title === "tags") {
            const __index = filterDatas.excludedTag.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedTag];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedTag: array }
                ));
            } else {
                const xindex = filterDatas.selectedTag?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedTag];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedTag: arrays }
                    ));
                }
                const array = [...filterDatas.excludedTag, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedTag: array }
                ));
            }
        }
        else if (title === "school") {
            const __index = filterDatas.excludedSchool.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedSchool];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedSchool: array }
                ));
            } else {
                const xindex = filterDatas.selectedSchool?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedSchool];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedSchool: arrays }
                    ));
                }
                const array = [...filterDatas.excludedSchool, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedSchool: array }
                ));
            }
        }
        else if (title === "source") {
            const __index = filterDatas.excludedSource.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedSource];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedSource: array }
                ));
            } else {
                const xindex = filterDatas.selectedSource?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedSource];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedSource: arrays }
                    ));
                }
                const array = [...filterDatas.excludedSource, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedSource: array }
                ));
            }
        }
        else if (title === "parent occupation") {
            const __index = filterDatas.excludedOccupation.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedOccupation];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedOccupation: array }
                ));
            } else {
                const xindex = filterDatas.selectedOccupation?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedOccupation];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedOccupation: arrays }
                    ));
                }
                const array = [...filterDatas.excludedOccupation, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedOccupation: array }
                ));
            }
        }
        else if (title === "payment status") {
            console.log("ljslkjlfjlksj", item)
            const __index = filterDatas.excludedPaymentStatus.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedPaymentStatus];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedPaymentStatus: array }
                ));
            } else {
                const xindex = filterDatas.selectedPaymentStatus?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedPaymentStatus];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedPaymentStatus: arrays }
                    ));
                }
                const array = [...filterDatas.excludedPaymentStatus, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedPaymentStatus: array }
                ));
            }
        }
        else if (title === "state") {
            const __index = filterDatas.excludedState.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedState];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedState: array }
                ));
            } else {
                const xindex = filterDatas.selectedState?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedState];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedState: arrays }
                    ));
                }
                const array = [...filterDatas.excludedState, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedState: array }
                ));
            }
        }
        else if (title === "country") {
            const __index = filterDatas.excludedCountry.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedCountry];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedCountry: array }
                ));
            } else {
                const xindex = filterDatas.selectedCountry?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedCountry];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedCountry: arrays }
                    ));
                }
                const array = [...filterDatas.excludedCountry, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedCountry: array }
                ));
            }
        }
        else if (title === "admission process done") {
            const __index = filterDatas.excludedAddmisonProcess.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedAddmisonProcess];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedAddmisonProcess: array }
                ));
            } else {
                const xindex = filterDatas.selectedAddmisonProcess?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedAddmisonProcess];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedAddmisonProcess: arrays }
                    ));
                }
                const array = [...filterDatas.excludedAddmisonProcess, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedAddmisonProcess: array }
                ));
            }
        }
        else if (title === "courses") {
            const __index = filterDatas.excludedCourse.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedCourse];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedCourse: array }
                ));
            } else {
                const xindex = filterDatas.selectedCourse?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedCourse];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedCourse: arrays }
                    ));
                }
                const array = [...filterDatas.excludedCourse, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedCourse: array }
                ));
            }
        }
        else if (title === "city") {
            const __index = filterDatas.excludedCity.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedCity];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedCity: array }
                ));
            } else {
                const xindex = filterDatas.selectedCity?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedCity];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedCity: arrays }
                    ));
                }
                const array = [...filterDatas.excludedCity, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedCity: array }
                ));
            }
        }
        else if (title === "case created by") {
            const __index = filterDatas.excludedCreatedBy.indexOf(item)
            if (__index >= 0) {
                let array = [...filterDatas.excludedCreatedBy];
                array.splice(__index, 1)
                setFilterDatas(prevState => (
                    { ...prevState, excludedCreatedBy: array }
                ));
            } else {
                const xindex = filterDatas.selectedCreatedBy?.indexOf(item);
                if (xindex >= 0) {
                    let arrays = [...filterDatas.selectedCreatedBy];
                    arrays.splice(xindex, 1)
                    setFilterDatas(prevState => (
                        { ...prevState, selectedCreatedBy: arrays }
                    ));
                }
                const array = [...filterDatas.excludedCreatedBy, item];
                setFilterDatas(prevState => (
                    { ...prevState, excludedCreatedBy: array }
                ));
            }
        }
    }


    const handleFilter = (e) => {
        e.stopPropagation();

        setState({ ...state, right: false });
        // toggleDrawer(anchor, false);
        // setState({ ...state, right: false });
        setFilterCount(

            filterDatas.selectedCourse?.length +
            filterDatas.selectedAddmisonProcess?.length +
            filterDatas.selectedPaymentModes?.length +
            filterDatas.selectedFamilyIncome?.length +
            filterDatas.selectedBuddy?.length +
            filterDatas.selectedBatchMode?.length +
            filterDatas.selectedTag?.length +
            filterDatas.selectedBatches?.length +
            filterDatas.selectedOccupation?.length +
            filterDatas.selectedCountry?.length +
            filterDatas.selectedPaymentStatus?.length +
            filterDatas.selectedAddon?.length +
            filterDatas.selectedCity?.length +
            filterDatas.selectedState?.length +
            filterDatas.selectedBoard?.length +
            filterDatas.selectedReference?.length +
            filterDatas.selectedCareerOptions?.length +
            filterDatas.selectedSource?.length +
            filterDatas.selectedNextExpectedCource?.length +
            filterDatas.selectedSchool?.length +
            filterDatas.selectedCreatedBy?.length +
            filterDatas.excludedPaymentModes?.length +
            filterDatas.excludedFamilyIncome?.length +
            filterDatas.excludedBuddy?.length +
            filterDatas.excludedTag?.length +
            filterDatas.excludedBatches?.length +
            filterDatas.excludedOccupation?.length +
            filterDatas.excludedCountry?.length +
            filterDatas.excludedPaymentStatus?.length +
            filterDatas.excludedCourse?.length +
            filterDatas.excludedAddmisonProcess?.length +
            filterDatas.excludedAddon?.length +
            filterDatas.excludedBatchMode?.length +
            filterDatas.excludedState?.length +
            filterDatas.excludedCity?.length +
            filterDatas.excludedBoard?.length +
            filterDatas.excludedReference?.length +
            filterDatas.excludedCareerOptions?.length +
            filterDatas.excludedSource?.length +
            filterDatas.exculdedNextExpectedCource?.length +
            filterDatas.excludedSchool?.length +
            filterDatas.excludedCreatedBy?.length

        );


        data = { ...filterDatas }
        setPaginationModel({ page: 0, pageSize: 15 })
        setCopyFilter(data);

        getStudent(data);

    }
    const clearFilterData = (e) => {
        setClearDate(clearDate ? false : true);
        e.stopPropagation();
        setCopyFilter();
        setFilterDatas({
            selectedCourse: [],
            selectedAddmisonProcess: [],
            selectedPaymentModes: [],
            selectedFamilyIncome: [],
            selectedBuddy: [],
            selectedBatchMode: [],
            selectedTag: [],
            selectedBatches: [],
            selectedOccupation: [],
            selectedCountry: [],
            selectedPaymentStatus: [],
            selectedAddon: [],
            selectedCity: [],
            selectedState: [],
            selectedBoard: [],
            selectedReference: [],
            selectedCareerOptions: [],
            selectedSource: [],
            selectedNextExpectedCource: [],
            selectedSchool: [],
            selectedCreatedBy: [],
            excludedPaymentModes: [],
            excludedFamilyIncome: [],
            excludedBuddy: [],
            excludedTag: [],
            excludedBatches: [],
            excludedOccupation: [],
            excludedCountry: [],
            excludedPaymentStatus: [],
            excludedCourse: [],
            excludedAddmisonProcess: [],
            excludedAddon: [],
            excludedBatchMode: [],
            excludedState: [],
            excludedCity: [],
            excludedBoard: [],
            excludedReference: [],
            excludedCareerOptions: [],
            excludedSource: [],
            exculdedNextExpectedCource: [],
            excludedSchool: [],
            excludedCreatedBy: [],
            dateFilter: { startDate: '', endDate: '' },

        })


        getStudent({
            dateFilter: { stateDate: "", endDate: "" },
            selectedCourse: [],
            selectedAddmisonProcess: [],
            selectedPaymentModes: [],
            selectedFamilyIncome: [],
            selectedBuddy: [],
            selectedBatchMode: [],
            selectedTag: [],
            selectedBatches: [],
            selectedOccupation: [],
            selectedCountry: [],
            selectedPaymentStatus: [],
            selectedAddon: [],
            selectedCity: [],
            selectedState: [],
            selectedBoard: [],
            selectedReference: [],
            selectedCareerOptions: [],
            selectedSource: [],
            selectedNextExpectedCource: [],
            selectedSchool: [],
            selectedCreatedBy: [],
            excludedPaymentModes: [],
            excludedFamilyIncome: [],
            excludedBuddy: [],
            excludedTag: [],
            excludedBatches: [],
            excludedOccupation: [],
            excludedCountry: [],
            excludedPaymentStatus: [],
            excludedCourse: [],
            excludedAddmisonProcess: [],
            excludedAddon: [],
            excludedBatchMode: [],
            excludedState: [],
            excludedCity: [],
            excludedBoard: [],
            excludedReference: [],
            excludedCareerOptions: [],
            excludedSource: [],
            exculdedNextExpectedCource: [],
            excludedSchool: [],
            excludedCreatedBy: [],
        });
        setFilterCount(0);
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setFilterDatas(prevState => (
                { ...prevState, dateFilter: data }));
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setFilterDatas(prevState => (
                { ...prevState, dateFilter: data }));
        }

    }
    const capitalizeString = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div className=' w-96 flex flex-col overflow-hidden'>
            <div className='flex-1 overflow-auto'>
                <div className=' py-3'>
                    <p className='pl-6 py-2 self-center' variant='h6'>Registration Date filter</p>
                    <DateFilter setFilterDatas={setFilterDatas} filterDatas={filterDatas} setStartDate={setStartDate} startDate={startDate} getData={getDate} clearDate={clearDate} />
                </div>
                <div className='flex flex-1 flex-col' >
                    {statusFilter === "cases" || statusFilter === "payments" || statusFilter === "courses" ? <div>
                        {filterData?.student?.map((item) => {
                            return (
                                <Accordion expanded={expanded === item.title} onChange={handleChange(item.title)}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='flex justify-between'>

                                        <Typography>{capitalizeString(item.title)}</Typography>


                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='flex justify-end gap-2'>       <Typography >include</Typography>        <Typography>exclude</Typography></div>
                                        <FormGroup >

                                            {
                                                item?.field?.map((items) =>
                                                    <div className='flex justify-between items-center'>

                                                        <Tooltip title={item.title === "next expected course" || item.title === "country" || item.title === "case created by" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}>

                                                            <Typography className=' w-32 overflow-clip text-ellipsis '>{item.title === "next expected course" || item.title === "country" || item.title === "case created by" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}</Typography>
                                                        </Tooltip>
                                                        <div className='flex'>
                                                            <FormControlLabel
                                                                checked={
                                                                    item.title === "buddy" ?
                                                                        (filterDatas.selectedBuddy?.includes(items.id))
                                                                        : item.title === "parent occupation" ?
                                                                            (filterDatas?.selectedOccupation?.includes(items.id))
                                                                            : item.title === "reference" ?
                                                                                (filterDatas?.selectedReference?.includes(items.id))
                                                                                : item.title === "case created by" ?
                                                                                    (filterDatas?.selectedCreatedBy?.includes(items.id))
                                                                                    : item.title === "reference" ?
                                                                                        (filterDatas?.selectedReference?.includes(items.id))
                                                                                        : item.title === "career options" ?
                                                                                            (filterDatas?.selectedCareerOptions?.includes(items.id))

                                                                                            : item.title === "country" ?
                                                                                                (filterDatas?.selectedCountry?.includes(items.id))
                                                                                                : item.title === "city" ?
                                                                                                    (filterDatas?.selectedCity?.includes(items.id))
                                                                                                    : item.title === "state" ?
                                                                                                        (filterDatas?.selectedState?.includes(items.id))
                                                                                                        : item.title === "board" ?
                                                                                                            (filterDatas?.selectedBoard?.includes(items.id))
                                                                                                            : item.title === "school" ?
                                                                                                                (filterDatas?.selectedSchool?.includes(items.id))
                                                                                                                : item.title === "next expected course" ?
                                                                                                                    (filterDatas?.selectedNextExpectedCource?.includes(items.id))
                                                                                                                    : item.title === "courses" ?
                                                                                                                        (filterDatas?.selectedCourse?.includes(items.id))
                                                                                                                        : item.title === "source" ?
                                                                                                                            (filterDatas?.selectedSource?.includes(items.id))
                                                                                                                            : (filterDatas?.selectedTag.includes(items.id))

                                                                }
                                                                sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.name === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                onClick={() => { handleOnSelect(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                            />
                                                            <FormControlLabel
                                                                checked={
                                                                    item.title === "buddy" ?
                                                                        (filterDatas.excludedBuddy?.includes(items.id))

                                                                        : item.title === "parent occupation" ?
                                                                            (filterDatas?.excludedOccupation?.includes(items.id))
                                                                            : item.title === "reference" ?
                                                                                (filterDatas?.excludedReference?.includes(items.id))
                                                                                : item.title === "career options" ?
                                                                                    (filterDatas?.excludedCareerOptions?.includes(items.id))
                                                                                    : item.title === "source" ?
                                                                                        (filterDatas?.excludedSource?.includes(items.id))
                                                                                        : item.title === "case created by" ?
                                                                                            (filterDatas?.excludedCreatedBy?.includes(items.id))
                                                                                            : item.title === "next expected course" ?
                                                                                                (filterDatas?.exculdedNextExpectedCource?.includes(items.id))
                                                                                                : item.title === "country" ?
                                                                                                    (filterDatas?.excludedCountry?.includes(items.id))
                                                                                                    : item.title === "city" ?
                                                                                                        (filterDatas?.excludedCity?.includes(items.id))
                                                                                                        : item.title === "state" ?
                                                                                                            (filterDatas?.excludedState?.includes(items.id))
                                                                                                            : item.title === "board" ?
                                                                                                                (filterDatas?.excludedBoard?.includes(items.id))
                                                                                                                : item.title === "school" ?
                                                                                                                    (filterDatas?.selectedSchool?.includes(items.id))
                                                                                                                    : item.title === "Addons Taken" ?
                                                                                                                        (filterDatas?.excludedAddon?.includes(items.id))
                                                                                                                        : item.title === "courses" ?
                                                                                                                            (filterDatas?.excludedCourse?.includes(items.id))
                                                                                                                            : item.title === "admission process done" ?
                                                                                                                                (filterDatas?.excludedAddmisonProcess?.includes(items.title))
                                                                                                                                : (filterDatas?.excludedTag.includes(items.id))

                                                                }
                                                                sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.title === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                onClick={() => { handleOnSelectForExclude(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                            />
                                                        </div>
                                                    </div>


                                                )

                                            }

                                        </FormGroup>

                                    </AccordionDetails>
                                </Accordion>
                            )



                        }


                        )}
                    </div> : ""}

                    {statusFilter === "payments" || statusFilter === "courses" ? <div>
                        {filterData?.course?.map((item) => {

                            return (
                                <Accordion expanded={expanded === item.title} onChange={handleChange(item.title)}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='flex justify-between'>

                                        <Typography>{capitalizeString(item.title)}</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='flex justify-end gap-2'>       <Typography >include</Typography>        <Typography>exclude</Typography></div>
                                        <FormGroup >
                                            {
                                                item?.field?.map((items) =>
                                                    <div className='flex justify-between items-center'>

                                                        <Tooltip title={item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}>

                                                            <Typography className=' w-32 overflow-clip text-ellipsis '>{item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}</Typography>
                                                        </Tooltip>
                                                        <div className='flex '>
                                                            <FormControlLabel
                                                                checked={item.title === "batches" ?
                                                                    (filterDatas?.selectedBatches.includes(items.id))
                                                                    : item.title === "batch Mode" ?
                                                                        (filterDatas?.selectedBatchMode?.includes(items.id))
                                                                        : item.title === "Addons Taken" ?
                                                                            (filterDatas?.selectedAddon?.includes(items.id))
                                                                            : item.title === "courses" ?
                                                                                (filterDatas?.selectedCourse?.includes(items.id))
                                                                                : item.title === "admission process done" ?
                                                                                    (filterDatas?.selectedAddmisonProcess?.includes(items.title))
                                                                                    : (filterDatas?.selectedTag.includes(items.id))
                                                                }
                                                                sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.name === "admission process done" ? items.title : items.id} control={<Radio />}
                                                                // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                onClick={() => { handleOnSelect(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                            />
                                                            <FormControlLabel
                                                                checked={item.title === "batches" ?
                                                                    (filterDatas?.excludedBatches.includes(items.id))

                                                                    : item.title === "batch Mode" ?
                                                                        (filterDatas?.excludedBatchMode?.includes(items.id))


                                                                        : item.title === "Addons Taken" ?
                                                                            (filterDatas?.excludedAddon?.includes(items.id))
                                                                            : item.title === "courses" ?
                                                                                (filterDatas?.excludedCourse?.includes(items.id))
                                                                                : item.title === "admission process done" ?
                                                                                    (filterDatas?.excludedAddmisonProcess?.includes(items.title))
                                                                                    : (filterDatas?.excludedTag.includes(items.id))

                                                                }
                                                                sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.title === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                onClick={() => { handleOnSelectForExclude(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                            />
                                                        </div>
                                                    </div>


                                                )

                                            }




                                        </FormGroup>

                                    </AccordionDetails>
                                </Accordion>
                            )



                        }


                        )}
                    </div> : ""}

                    {statusFilter === "payments" ? <div>
                        {filterData?.payment?.map((item) => {
                            return (
                                <Accordion expanded={expanded === item.title} onChange={handleChange(item.title)}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='flex justify-between'>

                                        <Typography>{capitalizeString(item.title)}</Typography>


                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='flex justify-end gap-2'>       <Typography >include</Typography>        <Typography>exclude</Typography></div>
                                        <FormGroup >

                                            {
                                                item?.field?.map((items) =>
                                                    <div className='flex justify-between items-center'>

                                                        <Tooltip title={item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}>

                                                            <Typography className=' w-32 overflow-clip text-ellipsis '>{item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}</Typography>
                                                        </Tooltip>
                                                        <div className='flex'>
                                                            <FormControlLabel
                                                                checked={item.title === 'payment modes' ?
                                                                    (filterDatas?.selectedPaymentModes?.includes(items.id))

                                                                    :
                                                                    (filterDatas?.selectedPaymentStatus?.includes(items.title))

                                                                }
                                                                sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.name === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                onClick={() => { handleOnSelect(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                            />
                                                            <FormControlLabel
                                                                checked={item.title === 'payment modes' ?
                                                                    (filterDatas?.excludedPaymentModes?.includes(items.id))
                                                                    :
                                                                    (filterDatas?.excludedPaymentStatus?.includes(items.title))


                                                                }
                                                                sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.title === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                onClick={() => { handleOnSelectForExclude(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                            />
                                                        </div>
                                                    </div>


                                                )

                                            }




                                        </FormGroup>

                                    </AccordionDetails>
                                </Accordion>
                            )



                        }


                        )}
                    </div> : ""}

                    {filterDatas?.selectedCountry?.length > 0 ?
                        <div>
                            {groupsList?.map((item) => {
                                return (
                                    <Accordion expanded={expanded === item.title} onChange={handleChange(item.title)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='flex justify-between'>
                                            {console.log(filterDatas)}
                                            <Typography>{capitalizeString(item.title)}</Typography>


                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='flex justify-end gap-2'>       <Typography >include</Typography>        <Typography>exclude</Typography></div>
                                            <FormGroup >

                                                {
                                                    item?.field?.map((items) =>
                                                        <div className='flex justify-between items-center'>

                                                            <Tooltip title={item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}>

                                                                <Typography className=' w-32 overflow-clip text-ellipsis '>{item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}</Typography>
                                                            </Tooltip>
                                                            <div className='flex'>
                                                                <FormControlLabel
                                                                    checked={item.title === 'state' ?
                                                                        (filterDatas?.selectedState?.includes(items.id))
                                                                        : item.title === 'board' ?
                                                                            (filterDatas?.selectedBoard?.includes(items.id))
                                                                            :
                                                                            (filterDatas?.selectedSchool.includes(items.id))
                                                                    }
                                                                    sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.name === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                    // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                    onClick={() => { handleOnSelect(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                                />
                                                                <FormControlLabel
                                                                    checked={item.title === 'state' ?
                                                                        (filterDatas?.excludedState?.includes(items.id))
                                                                        : item.title === 'board' ?
                                                                            (filterDatas?.excludedBoard?.includes(items.id))
                                                                            :
                                                                            (filterDatas?.excludedSchool.includes(items.id))
                                                                    }
                                                                    sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.title === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                    // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                    onClick={() => { handleOnSelectForExclude(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                                />
                                                            </div>
                                                        </div>


                                                    )

                                                }




                                            </FormGroup>

                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                            )}
                        </div>
                        :
                        ""}

                    {filterDatas?.selectedState?.length > 0 ?
                        <div>
                            {city?.map((item) => {

                                return (
                                    <Accordion expanded={expanded === item.title} onChange={handleChange(item.title)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='flex justify-between'>

                                            <Typography>{capitalizeString(item.title)}</Typography>


                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='flex justify-end gap-2'>       <Typography >include</Typography>        <Typography>exclude</Typography></div>
                                            <FormGroup >

                                                {
                                                    item?.field?.map((items) =>
                                                        <div className='flex justify-between items-center'>

                                                            <Tooltip title={item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}>

                                                                <Typography className=' w-32 overflow-clip text-ellipsis '>{item.title === "courses" || item.title === "country" || item.title === "batches" || item.title === "batch Mode" || item.title === 'tags' || item.title === 'Addons Taken' ? items.name : items.title}</Typography>
                                                            </Tooltip>
                                                            <div className='flex'>
                                                                <FormControlLabel
                                                                    checked={
                                                                        (filterDatas?.selectedCity?.includes(items.id))

                                                                    }
                                                                    sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.name === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                    // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                    onClick={() => { handleOnSelect(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                                />
                                                                <FormControlLabel
                                                                    checked={
                                                                        (filterDatas?.excludedCity?.includes(items.id))

                                                                    }
                                                                    sx={{ display: 'inline-block', }} value={item.name === "payment status" || item.title === "admission process done" ? items.title : items.id} control={<Radio />}


                                                                    // label={item.title === "stage" || item.title === "group" ? items.title : items.title}
                                                                    onClick={() => { handleOnSelectForExclude(item.title === "payment status" || item.title === "admission process done" ? items.title : items.id, item.title) }}
                                                                />
                                                            </div>
                                                        </div>


                                                    )

                                                }




                                            </FormGroup>

                                        </AccordionDetails>
                                    </Accordion>
                                )



                            }


                            )}
                        </div>
                        :
                        ""}

                    <div className=' h-16'></div>

                </div>
            </div>
            <div className='py-2 w-80 flex justify-evenly'>
                <Button sx={{ backgroundColor: "white", color: 'black', textTransform: 'none' }} variant='contained' className=' w-2/5 z-50' onClick={clearFilterData}>Clear All</Button>
                <Button sx={{ backgroundColor: '#3366FF', textTransform: 'none' }} variant='contained' className=' w-1/2 z-50' onClick={handleFilter}>Apply</Button>
            </div>

        </div >
    );
}