import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormLabel, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { addCourses, fetchCurrency, getBatchModes, getCourseCategory, httpPatch } from "../Services/api-services";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import DialogueBox from "../Components/DialogueBox";
import CustomizedSnackbars from "../Components/Snackbar";
import CONSTANTS from "../constant.json";
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';

function CourseDialog(props) {
  const [addCourse, setAddcourse] = React.useState(false);
  const [courseId, setCourseId] = React.useState(false);
  const [courseCategory, setCourseCategory] = React.useState("");
  const [subjectsTotalPrice, setSubjectsTotalPrice] = React.useState(0);
  const [allCourseCategory, setAllCourseCategory] = React.useState([]);
  const [courseName, setCourseName] = React.useState("");
  const [batchMode, setBatchMode] = React.useState("");
  const [allBatchModes, setAllBatchModes] = React.useState([]);
  const [courseCurrency, setCourseCurrency] = React.useState("");
  const [coursePrice, setCoursePrice] = React.useState("");
  const [addSubject, setAddSubject] = React.useState([]);
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectPrice, setSubjectPrice] = React.useState("");
  const [bundleDiscount, setBundleDiscount] = React.useState();
  const [studentDiscount, setStudentDiscount] = React.useState();
  const [allCurrencies, setAllCurrencies] = React.useState();
  const [subId, setSubId] = useState('')
  const [subIndex, setSubIndex] = React.useState(0);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [deleteSubject, setDeleteSubject] = React.useState(false);
  const [deleteCourse, setDeleteCourse] = React.useState(false);
  const [deletedSubjects, setDeletedSubjects] = React.useState([])
  const [tax, setTax] = useState("");
  const [isLoading, setIsLoading] = React.useState(false)
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  React.useEffect(() => {
    let editCourse = props?.updatedCourse
    if (props?.editCourse) {
      setCourseId(editCourse?.courseId)
      setCourseCategory(editCourse?.courseCategoryId)
      setCourseName(editCourse?.courseName)
      setCoursePrice(editCourse?.price)
      setBatchMode(editCourse?.batchmodeId)
      setTax(editCourse?.tax)
      setCourseCurrency(editCourse?.currencyId)
      setBundleDiscount(editCourse?.bundleDiscount)
      setStudentDiscount(editCourse?.studentDiscount)
      let x = 0
      editCourse?.subjects.map((s) => {
        x += parseInt(s.price)
      })
      setSubjectsTotalPrice(x)
      let _subject = []
      editCourse?.subjects.map((s) => {
        let arr = {
          name: s.name,
          price: s.price,
          id: s.id,
          courseId: editCourse?.courseId
        };
        _subject.push(arr)
      })
      // let _arr = addSubject;
      // _arr.push(_subject);
      setAddSubject(_subject);
    }

    fetchData();
    // eslint-disable-next-line
  }, [props?.editCourse]);

  async function fetchData() {
    try {
      const currencies = await fetchCurrency();
      const batchModes = await getBatchModes();
      const courseCategory = await getCourseCategory();
      setAllCurrencies(currencies);
      setAllBatchModes(batchModes);
      setAllCourseCategory(courseCategory);
    } catch (err) {
      console.log(err);
    }
  }

  function _editSubject(e, index) {
    setSubId('')
    console.log('Gotacha')
    setSubIndex(index);
    const _subject = addSubject[index];
    setSubjectName(_subject.name);
    setSubjectPrice(_subject.price);
    if (_subject?.id) {
      setSubId(_subject.id)
    }

    // _deleteSubject();
    const subject = addSubject;
    subject.splice(index, 1);
    setAddSubject(subject);
    _subjectDialogueClose();
    let x = 0
    addSubject.map((s) => {
      x += parseInt(s.price)
    })
    setSubjectsTotalPrice(x)
  }

  const _subjectDialogueOpen = (index) => {
    setSubIndex(index);
    setConfirmDialog(true);
  };

  function _deleteSubject() {
    const _subject = addSubject;
    let delSub = [...deletedSubjects]
    if (props?.editCourse && _subject[subIndex].id) {
      let mapToSub = delSub.map(e => e.id)
      if (!mapToSub.includes(_subject[subIndex].id)) {
        delSub.push(_subject[subIndex])
      }
    }
    _subject.splice(subIndex, 1);
    setDeletedSubjects(delSub)
    setAddSubject(_subject);
    _subjectDialogueClose();
    let x = 0
    addSubject.map((s) => {
      x += parseInt(s.price)
    })
    setSubjectsTotalPrice(x)
  }

  const _subjectDialogueClose = () => {
    setConfirmDialog(false);
  };

  const subjectExists = () => {
    // VERIFIES THE EXISTENCE OF SUBJECT IN addSubject ARRAY
    let found = false;
    if (addSubject.length === 0) return false;
    for (let index = 0; index < addSubject.length; index++) {
      const _subject = addSubject[index];
      if (
        _subject.name &&
        _subject.name.toLowerCase() === subjectName.toLowerCase()
      ) {
        found = true;
        return found;
      }
      if (index === addSubject.length - 1) {
        // LAST ITERATION
        return found;
      }
    }
  };

  const _addSubjects = async () => {
    if (subjectName && subjectPrice) {
      const exists = subjectExists(subjectName);
      if (!exists) {
        let _subject = {
          name: subjectName,
          price: parseFloat(subjectPrice),
          courseId: courseId
          // delete: deleteSubject,
        };
        if (subId != '') {
          _subject = { ..._subject, id: subId }
        }
        let _arr = addSubject;
        _arr.push(_subject);
        setAddSubject(_arr);
        setSubjectName("");
        setSubjectPrice("");
      } else {
        // ALREADY ADDED SUBJECT NAME
        setSnackBarOptions({
          message: "Subject name already existed",
          show: true,
          severity: "error",
          duration: 5000,
        });
      }
      let x = 0
      addSubject.map((s) => {
        x += parseInt(s.price)
      })
      setSubjectsTotalPrice(x)
    } else {
      // SHOW ERROR IN TOAST FOR SUBJECT NAME AND PRICE
    }
  };

  function _addCourse() {
    // console.log(parseInt(bundleDiscount)>=0 ,parseInt(studentDiscount)>=0,bundleDiscount,studentDiscount)

    // console.log(courseName &&
    //   coursePrice &&
    //   courseCurrency &&
    //   batchMode &&
    //   parseInt(bundleDiscount)>=0 && 
    //   parseInt(studentDiscount)>=0 &&
    //   courseCategory &&
    //   addSubject.length > 0)
    // console.log(courseName,coursePrice,courseCurrency,batchMode,courseCategory,addSubject)
    if (
      courseName &&
      coursePrice &&
      courseCurrency &&
      batchMode &&

      courseCategory &&
      addSubject.length > 0 &&
      tax &&
      tax > 0
    ) {
      let obj = {
        course: {
          name: courseName,
          price: parseFloat(coursePrice),
          currency: courseCurrency,
          batchMode: batchMode,
          bundleDiscount: parseFloat(bundleDiscount ? bundleDiscount : 0),
          studentDiscount: parseFloat(studentDiscount ? studentDiscount : 0),
          tax: tax,
          courseCategoryId: courseCategory,
        },
        subjects: addSubject
      };

      fetchCourse(obj);
    } else {
      setAddcourse(true);
    }
  }

  function clearCourseForm() {
    setCourseCategory('')
    setSubjectName("");
    setSubjectPrice("");
    setAddSubject([]);
    setAddcourse(false);
    setCourseName("");
    setCoursePrice("");
    setBundleDiscount(0);
    setStudentDiscount(0);
    setCourseCurrency("");
    setBatchMode("");
    setSubjectsTotalPrice(0)
    setTax("")
  }

  async function fetchCourse(obj) {
    setIsLoading(true)
    try {
      if (props?.editCourse) {
        console.log(obj)
        obj = { ...obj, deletedSubjects: deletedSubjects, purpose: 'update' }
        obj.course['id'] = courseId

        const url = `${CONSTANTS.baseUrl}/courses/updateCourse`;
        const course = await httpPatch(url, obj)
        setIsLoading(false)
        console.log('checkered course', course)
        if (course.statusCode == 200) {
          setSnackBarOptions({
            message: "Course updated successfully!",
            show: true,
            severity: "success",
            duration: 5000,
          });
          props.refreshcourse('added')
          props.courseDialogClose()
          clearCourseForm();
        }
        else {
          setSnackBarOptions({
            message: "Internal server error!",
            show: true,
            severity: "error",
            duration: 5000,
          });
        }
      }
      else {
        const course = await addCourses(obj)
        setIsLoading(false)
        if (course.statusCode === 201) {
          setSnackBarOptions({
            message: "Course added successfully!",
            show: true,
            severity: "success",
            duration: 5000,
          });
          props.refreshcourse('added');
          props.courseDialogClose()
          clearCourseForm();
        }
        else {
          setSnackBarOptions({
            message: "Internal server error!",
            show: true,
            severity: "error",
            duration: 5000,
          });
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  function courseDialogClose() {
    props.courseDialogClose()
    clearCourseForm()
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" open={props.courseDialogOpen}>
        <DialogTitle>{props?.editCourse ? 'EDIT COURSE' : 'ADD COURSE'}</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="px-7">
            <FormControl required fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Course Category
              </InputLabel>
              <Select
                error={addCourse && courseCategory.length < 1 ? true : false}

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={courseCategory}
                label="Select Course Category"
                onChange={(e) => setCourseCategory(e.target.value)}
              >
                {allCourseCategory?.map((_courseCategory, i) => {
                  return (
                    <MenuItem key={i} value={_courseCategory.id}>
                      {_courseCategory.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ marginTop: "30px" }} fullWidth>
              <FormLabel required>Course Title / Name</FormLabel>

              <TextField
                error={addCourse && courseName.length < 1 ? true : false}
                helperText={
                  addCourse && courseName.length < 1
                    ? "Course name cannot be empty"
                    : ""
                }
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                required={true}
                id="outlined-basic"
                variant="outlined"
              />
            </FormControl>
            <div className="flex items-center mt-10">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Batch Mode
                </InputLabel>
                <Select
                  error={addCourse && batchMode.length < 1 ? true : false}
                  required={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={batchMode}
                  label="Select Batch Mode"
                  onChange={(e) => setBatchMode(e.target.value)}
                >
                  {allBatchModes?.map((_batchMode, i) => {
                    return (
                      <MenuItem key={i} value={_batchMode.id}>
                        {_batchMode.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              &nbsp;&nbsp;
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Currency
                </InputLabel>
                <Select
                  error={addCourse && courseCurrency.length < 1 ? true : false}
                  required={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseCurrency}
                  label="Select Currency"
                  onChange={(e) => setCourseCurrency(e.target.value)}
                >
                  {allCurrencies?.map((_currency, i) => {
                    return (
                      <MenuItem key={i} value={_currency.id}>
                        <p> {_currency?.currencyName}</p>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              &nbsp;&nbsp;
              <FormControl fullWidth>
                <TextField
                  value={coursePrice}
                  onChange={(e) => setCoursePrice(e.target.value)}
                  label="Course Price"
                  error={addCourse && coursePrice.length < 1 ? true : false}
                  required={true}
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                />
              </FormControl>
              &nbsp;&nbsp;
              <FormControl fullWidth>
                <TextField
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                  label="Tax %"
                  error={(!tax || !tax.length > 0 || tax >= 100) && tax?.length !== 0}
                  required={true}
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                />
              </FormControl>
            </div>
            <div className="mt-10">
              <FormControl fullWidth>
                <fieldset className="border-2">
                  <legend className="ml-7 p-2 mb-2 bg-gray-100 w-44 h-10">
                    Add Subjects
                  </legend>
                  <div>
                    <div className="px-5 flex text-center">
                      {addSubject?.length > 0 ? (
                        <List
                          sx={{
                            borderWidth: 1,
                            borderColor: "#eee",
                            width: "100%",
                            bgcolor: "rgb(243 244 246)",
                          }}
                        >
                          {addSubject?.length > 0 &&
                            addSubject?.map((x, index) => {
                              return (
                                <div key={index}>
                                  <ListItem
                                    key={index.toString()}
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <ListItemText sx={{ width: "100%" }}>
                                      {" "}
                                      <CheckCircleIcon
                                        color="success"
                                        fontSize="medium"
                                      />
                                      &nbsp;{x.name}&nbsp;&nbsp; {x.price}
                                      /-
                                    </ListItemText>
                                    <ListItemText>
                                      <div className="flex">
                                        <Button
                                          onClick={(e) =>
                                            _editSubject(e, index)
                                          }
                                          size="small"
                                          sx={{ minWidth: "0px" }}
                                          variant="text"
                                        >
                                          <EditIcon fontSize="small" />
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            _subjectDialogueOpen(index)
                                          }
                                          sx={{ minWidth: "0px" }}
                                          variant="text"
                                        >
                                          <DeleteIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </Button>
                                      </div>
                                    </ListItemText>
                                  </ListItem>
                                  {index < addSubject.length - 1 ? (
                                    <Divider />
                                  ) : null}
                                </div>
                              );
                            })}
                        </List>
                      ) : null}

                      <DialogueBox
                        confirmDialog={confirmDialog}
                        confirmDialogClose={() =>
                          setConfirmDialog(false)
                        }
                        confirmDelete={_deleteSubject}
                      />
                    </div>

                    <div className="p-5 flex items-center">
                      <FormControl fullWidth>
                        <FormLabel required>Subject Title / Name</FormLabel>
                        <TextField
                          value={subjectName}
                          onChange={(e) => setSubjectName(e.target.value)}
                          id="outlined-basic"
                          variant="outlined"
                        />
                      </FormControl>
                      &nbsp;&nbsp;
                      <FormControl fullWidth>
                        <FormLabel required>Price</FormLabel>
                        <TextField
                          value={subjectPrice}
                          onChange={(e) => setSubjectPrice(e.target.value)}
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    </div>
                    <div className="text-right">
                      <Button onClick={_addSubjects} variant="text">
                        <b>
                          add <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                        </b>
                      </Button>
                    </div>
                  </div>
                </fieldset>
              </FormControl>
            </div>

            <div className="mt-10">
              <FormControl fullWidth>
                <fieldset className="border-2">
                  <legend className="ml-7 p-2 bg-gray-100 w-44 h-10">
                    Add Discounts
                  </legend>
                  <div className="p-5 flex">
                    <FormControl fullWidth>
                      <FormLabel >Bundle Discount</FormLabel>
                      <TextField
                        value={bundleDiscount}
                        onChange={(e) => setBundleDiscount(e.target.value)}
                        error={
                          addCourse && bundleDiscount.length < 1 || (parseInt(bundleDiscount) >= parseInt(coursePrice)) ? true : false
                        }
                        helperText={
                          addCourse && bundleDiscount.length < 1
                            ? "Bundle discount cannot be empty"
                            : parseInt(bundleDiscount) >= parseInt(coursePrice) ? "Bundle discount cannot be greater than Course price"
                              :
                              ""
                        }
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                      />
                    </FormControl>
                    &nbsp;&nbsp;
                    <FormControl fullWidth>
                      <FormLabel >Student Discount</FormLabel>
                      <TextField
                        value={studentDiscount}
                        onChange={(e) => setStudentDiscount(e.target.value)}
                        error={
                          addCourse && studentDiscount.length < 1 || (parseInt(studentDiscount) >= parseInt(coursePrice)) ? true : false
                        }
                        helperText={
                          addCourse && studentDiscount.length < 1 ? "Student discount cannot be empty"
                            : parseInt(studentDiscount) >= parseInt(coursePrice) ? "Student discount cannot be greater than Course price"
                              : ""
                        }
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                      />
                    </FormControl>
                  </div>
                </fieldset>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            sx={{ marginY: "10px" }}
            variant="outlined"
            onClick={courseDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={_addCourse}
            sx={{ marginY: "10px" }}
            variant="contained"
            disabled={parseInt(subjectsTotalPrice) !== parseInt(coursePrice) || parseInt(coursePrice) <= 0 || (parseInt(studentDiscount) >= parseInt(coursePrice)) || (parseInt(bundleDiscount) >= parseInt(coursePrice)) || !courseCategory || !courseName || isLoading}
          >
            {isLoading ? <CircularProgress size={16} /> :
              props?.editCourse ? 'Update Course' : 'Add Course'
            }
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default CourseDialog;
