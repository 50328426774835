import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Button,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CasesDialog from '../../Popups/CasesDialog';

import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { successEvents } from '../../App';
import moment from 'moment';
export default function Cases() {
    const [access, setAccess] = useState();
    const [cases, setCases] = React.useState(null)
    const { setCurrentPage, } = React.useContext(successEvents)

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
    const [searchInput, setSearchInput] = useState();
    const [dates, setDates] = useState();
    const [row, setRow] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterRowCount, setFilterRowCount] = useState(0);
    const [openCaseDialog, setOpenCaseDialog] = useState(false)
    const [edit, setEdit] = useState();
    const [tableLoading, setTableLoading] = useState(true);

    const [casesData, setCasesData] = useState(
        {
            name: "",
            email: "",
            countryId: "",
            mobile: "",
            gender: "",
            buddyId: "",
        }
    );
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    // const [clearDate,setClearDate] =useState()

    const navigate = useNavigate();
    const columns = [

        {
            field: 'caseName',
            headerName: 'NAME',
            minWidth: 150,
            flex: 1,
        },
       
        {
            field: 'caseMobile',
            headerName: 'MOBILE',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'caseEmail',
            headerName: 'EMAIL',

            minWidth: 150,
            flex: 1,
        },
        {
            field: 'courseName',
            headerName: 'COURSE',

            minWidth: 200,
            flex: 1,
        },
        {
            field: 'batchName',
            headerName: 'BATCH',

            minWidth: 200,
            flex: 1,
        },
        
        {
            field: 'enrollmentDate',
            headerName: 'ENROLLMENT DATE',

            minWidth: 200,
          
            valueGetter: (params) => {


                return moment(params?.value).format('LL') ;
            },
        },
        
        
       

    ];

    // const cancelDialogAction = () => {
    //     setSearchActionDialog(false)
    //     setRefundReqActionDialog(false)
    // }

    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }
    const getCases = async (search, dates,page) => {
        setTableLoading(true)
        const res = await post("cases/getAllEnrroledCases", { searchText: search, dateFilter: dates ,page:page});
        if (res.statusCode === 200) {
            setRow(res?.data?.data);
            setTotalRowCount(res?.data?.totalCount);
            setFilterRowCount(res?.data?.filterCount)
        }
        setTableLoading(false)
    }
    const debounceSearch = useCallback(debounce(getCases, 300), []);
    const handleOnRowClick = async (row) => {
        console.log(row.row)
        setCurrentPage("open_cases")
        navigate('/home/open_cases/caseDetails', { state: { id: row.row.caseId } })

    }
    useEffect(() => {
        debounceSearch(searchInput, dates,paginationModel);
    }, [searchInput, dates,paginationModel])
    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const rolesArr = modules?.find((m) => m?.name === "Open cases");
        console.log(rolesArr);

        setAccess(rolesArr)
    }, [])

    return (<>
        <div className=" flex flex-col flex-1" style={{width:"100%"}}  >
            <div className='flex items-center justify-between flex-wrap '>
                <Typography className='font-normal text-xl text-gray-500'>Enrollments ({totalRowCount})</Typography>
                <div className="flex items-center gap-5 flex-wrap">



                    <FormControl sx={{ width: "300px" }}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="sm" />
                                    </InputAdornment>
                                ),
                            }}
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </FormControl>
                    <DateRangePiker getData={getDate} />

                </div>
            </div>


            <DataGidTable loading={tableLoading} handleEvent={access?.read || access?.update || access?.write ?handleOnRowClick:""} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />



           
           
        </div>
    </>)

}

