
import { Avatar, Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Panel from "./Panel";
import { httpGet, _getCase } from "../Services/api-services";
import { useParams } from "react-router-dom";
import * as React from "react";
import moment from "moment";
import PaymentsPanel from "./PaymentsPanel";
import OverviewPanel from "./OverviewPanel";
import CONSTANTS from "../constant.json";
import SMSLogsPanel from "./SMSLogsPanel";
function CasesSidenav(props) {

  let caseId = props.caseId
  const [fetchCase, setFetchCase] = React.useState([]);
  const [currency, setCurrency] = React.useState('');
  const [currentTab, setCurrentTabs] = React.useState(0);

  function refreshCase() {
    props.refreshCase();
  }


  return (
    <div className="sidebar-content w-full py-7 my-16 px-6 ">

      <Box className=" flex flex-col flex-wrap   gap-5">
        <Box className="flex-wrap gap-5 px-2 flex items-center justify-between flex-1 shadow-md py-5 bg-[#F7F7F7] border-1 border-[#E8E8E8]">

          <Box className='flex items-center'>
            <p className="text-[#7A7474] text-sm">Course- </p>
            <p className="text-[#000000] text-lg">{props?.courseData?.courseName}</p>
          </Box>
          <Box className='flex items-center'>
            <p className="text-[#7A7474] text-sm">Batch- </p>
            <p className="text-[#000000] text-lg">{props?.courseData?.batches?.name}</p>
          </Box>
          <Box className='flex items-center'>
            <p className="text-[#7A7474] text-sm">Batch Time- </p>
            <p className="text-[#000000] text-lg">{props?.courseData?.batches?.from}-{props?.courseData?.batches?.to}</p>
          </Box>
          <Box className='flex items-center gap-1'>
            <p className="text-[#7A7474] text-sm">Total Fee</p>
            <p className="text-[#1C46F2] text-lg">{props?.courseData?.currency?.currencyName}{props?.courseData?.totalFee}</p>
          </Box>
          <Box className='flex items-center gap-1'>
            <p className="text-[#7A7474] text-sm">Paid </p>
            <p className="text-[#4CAF50] text-lg">{props?.courseData?.currency?.currencyName}{props?.courseData?.paidAmount ? props?.courseData?.paidAmount : 0}</p>
          </Box>
          <Box className='flex items-center gap-1'>
            <p className="text-[#7A7474] text-sm">Due </p>
            <p className="text-[#ED342D] text-lg">{props?.courseData?.currency?.currencyName}{props?.courseData?.unpaidAmount}</p>
          </Box>
          <Box className='flex items-center gap-1'>
            <p className="text-[#7A7474] text-sm">Overdue </p>
            <p className="text-[#ED342D] text-lg">{props?.courseData?.currency?.currencyName}{props?.courseData?.DueFee}</p>
          </Box>
        </Box>

      </Box>
      <Box className='flex text-[#7A7474] mt-5 text-sm'>
        <p>Note:-</p>
        <p>{props.courseData?.note}</p>
      </Box>
      <Box sx={{ width: "100%", height: "100%", marginY: "20px" }}>
        <Panel
          tabIndex={currentTab}
          labelOne="OVERVIEW"
          labelTwo="PAYMENTS"
          lableThree="SMS LOGS"
          secondPanel={true}
          thirdPanel={true}
          tabOne={
            <OverviewPanel refreshCase={refreshCase}
              openCases={props.openCases}
              showNav={props?.showNav}
              currency={currency}
              courseData={props?.courseData}
              tabInd={currentTab}
              fetchCase={props?.fetchCase}
            />}
          tabTwo={
            <PaymentsPanel
              openCases={props.openCases}
              showNav={props?.showNav}
              refreshCase={refreshCase}
              tabInd={currentTab}
              courseData={props?.courseData}
              fetchCase={fetchCase}
            />
          }
          tabThree={<SMSLogsPanel
            openCases={props.openCases}
            refreshCase={refreshCase}
            showNav={props?.showNav}
            courseData={props?.courseData}
            tabInd={currentTab}
            fetchCase={fetchCase ? fetchCase : null}

          />}
        />
      </Box>
    </div>
  );
}
export default CasesSidenav;
