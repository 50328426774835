import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { patch, post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DialogueBox from '../../Components/DialogueBox';
import CustomizedSnackbars from '../../Components/Snackbar';
import { successEvents } from '../../App';
import FilterDrawer from '../../Components/FilterDrawer';
import StudentFilter from '../../Components/StudentFilter';
import moment from 'moment';
export default function Cases() {
  const [access, setAccess] = useState();
  const [filterCount, setFilterCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 })
  const [searchInput, setSearchInput] = useState();
  const [dates, setDates] = useState();
  const [row, setRow] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filterRowCount, setFilterRowCount] = useState(0);
  const [openCaseDialog, setOpenCaseDialog] = useState(false)
  const [edit, setEdit] = useState();
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [casesData, setCasesData] = useState(
    {
      name: "",
      email: "",
      countryId: "",
      mobile: "",
      gender: "",
      buddyId: "",
    }
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)
  const [statusFilter, setStatusFilter] = useState("payments")
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const [paidUnpaidData, setPaidUnpaidData] = useState();
  // const [clearDate,setClearDate] =useState()
  const { setCurrentPage } = React.useContext(successEvents)

  const navigate = useNavigate();
  const columns = [

    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'MOBILE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'batchName',
      headerName: 'BATCH',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'courseName',
      headerName: 'COURSE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'totalFee',
      headerName: 'TOTAL FEE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'EMI',
      minWidth: 150,

      flex: 1,
      renderCell: (params) => {


        return <div className={new Date(params.row.date) < new Date() && params?.row?.status === "pending" ? ' w-full bg-[rgb(255,153,51)]' : ""}  >
          {params.value}
        </div>
      },

    },
    {
      field: 'paymentModeName',
      headerName: 'PAYMENT MODE',
      minWidth: 150,

      flex: 1,
    },

    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 150,
      // valueGetter: (params) => {


      //   return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
      // },
    },
    // {
    //   field: 'deleted',
    //   headerName: 'STATUS',

    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => {


    //     return <div className='flex items-center gap-6' >
    //       <SendIcon onClick={(e) => { e.stopPropagation(); navigate(`/Registration/${params?.row?.id}`) }} />
    //       <EditIcon style={{ display: access?.update || access?.write ? "block" : "none" }} className='text-[#3366ff] cursor-pointer'
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setEdit(params?.row?.id)
    //           setCasesData({
    //             name: params?.row?.name,
    //             email: params?.row?.email,
    //             mobile: params?.row?.mobile,
    //             gender: params?.row?.gender,
    //             countryId: params?.row?.countryId,
    //             buddyId: params?.row?.buddyId
    //           })
    //           setOpenCaseDialog(true)

    //         }}
    //       />
    //       <Tooltip title='Delete from Open cases but you can access from old cases'>

    //         <DeleteIcon className='text-[#3366ff]' onClick={(e) => {
    //           e.stopPropagation();
    //           setConfirmDialog(params?.row?.id)
    //         }} style={{ display: access?.update || access?.write ? "block" : "none" }} />
    //       </Tooltip>
    //     </div>
    //   },
    // },
    {
      field: 'date',
      headerName: 'SCHEDULE DATE ',
      minWidth: 200,

      flex: 1,
      valueGetter: (params) => {


        return moment(params?.value).format('LL')
      },
    },
  ];
  const columnsCourse = [

    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'MOBILE',
      minWidth: 150,

      flex: 1,
    },

    {
      field: 'batchModeName',
      headerName: 'BATCH MODE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'batchName',
      headerName: 'BATCH',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'courseName',
      headerName: 'COURSE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'totalFee',
      headerName: 'TOTAL FEE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'paidAmount',
      headerName: 'PAID AMOUNT',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'unpaidAmount',
      headerName: 'DUE FEE',
      minWidth: 150,

      flex: 1,
      renderCell: (params) => {


        return <div className={params.row.DueFee > 0 ? 'text-[#d96140]' : ""} >
          {params.value}


        </div>
      },
    },





  ];
  const columnsCase = [

    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'MOBILE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'batchName',
      headerName: 'BATCH',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'courseName',
      headerName: 'COURSE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'totalFee',
      headerName: 'FEE',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'paidAmount',
      headerName: 'PAID AMOUNT',
      minWidth: 150,

      flex: 1,
    },
    {
      field: 'DueFee',
      headerName: 'DUE FEE',
      minWidth: 150,

      flex: 1,
    },

    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 150,
      // valueGetter: (params) => {


      //   return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
      // },
    },
    // {
    //   field: 'deleted',
    //   headerName: 'STATUS',

    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => {


    //     return <div className='flex items-center gap-6' >
    //       <SendIcon onClick={(e) => { e.stopPropagation(); navigate(`/Registration/${params?.row?.id}`) }} />
    //       <EditIcon style={{ display: access?.update || access?.write ? "block" : "none" }} className='text-[#3366ff] cursor-pointer'
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setEdit(params?.row?.id)
    //           setCasesData({
    //             name: params?.row?.name,
    //             email: params?.row?.email,
    //             mobile: params?.row?.mobile,
    //             gender: params?.row?.gender,
    //             countryId: params?.row?.countryId,
    //             buddyId: params?.row?.buddyId
    //           })
    //           setOpenCaseDialog(true)

    //         }}
    //       />
    //       <Tooltip title='Delete from Open cases but you can access from old cases'>

    //         <DeleteIcon className='text-[#3366ff]' onClick={(e) => {
    //           e.stopPropagation();
    //           setConfirmDialog(params?.row?.id)
    //         }} style={{ display: access?.update || access?.write ? "block" : "none" }} />
    //       </Tooltip>
    //     </div>
    //   },
    // },

  ];
  // const cancelDialogAction = () => {
  //     setSearchActionDialog(false)
  //     setRefundReqActionDialog(false)
  // }
  const confirmDialogClose = () => {
    setConfirmDialog(null);
  }
  const confirmDelete = () => {
    handleDeleteCases(confirmDialog)
  }
  const getDate = (data) => {
    console.log(data)
    if (data.startDate?.length === 0 && data.endDate?.length === 0) {
      setDates(data);
    }
    else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
      setDates(data)
    }

  }



  const getCases = async (data) => {
    // console.log(search)
    // console.log(dates)
    setLoading(true)
    const res = await post("paymentSchedules/getReports", data);
    if (res.statusCode === 200) {
      setRow(res?.data?.data);
      setTotalRowCount(res?.data?.totalDataCount);
      setFilterRowCount(res?.data?.filterCount);
      setPaidUnpaidData(res?.data?.paidData[0])
    }
    setLoading(false);
  }
  const handleDeleteCases = async (id) => {
    const res = await patch(`cases/${id}`, { deleted: true })
    if (res.statusCode === 200) {
      getCases(searchInput, dates)
      setSnackBarOptions({
        message: "Case deleted successfully!",
        show: true,
        severity: "success",
        duration: 5000,
      });
    }
    setConfirmDialog(null)
  }
  const debounceSearch = useCallback(debounce(getCases, 400), []);
  const handleOnRowClick = async (row) => {
    setCurrentPage("open_cases")

    navigate('/home/open_cases/caseDetails', { state: { id: row.row.caseId ? row.row.caseId : row.row.id } })

  }
  // useEffect(() => {
  //   debounceSearch(searchInput, dates, statusFilter);
  // }, [searchInput, dates, statusFilter])
  useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const rolesArr = modules?.find((m) => m?.name === "Open cases");
    console.log(rolesArr);

    setAccess(rolesArr)
  }, [])

  return (

    <div className=" flex flex-col flex-1" style={{ width: "100%" }}  >
      <div className='flex items-center justify-between flex-wrap '>
        <Typography className='font-normal text-xl text-gray-500'>Reports</Typography>
        <div className="flex items-center gap-5 flex-wrap">


          <FormControl sx={{ width: "300px" }}>
            <TextField
              size="small"
              sx={{ backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="sm" />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
              placeholder="Search"
              variant="outlined"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </FormControl>


          <DateRangePiker getData={getDate} />
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Group by</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={statusFilter}
              label="Group by"
              onChange={(e) => {
                setStatusFilter(e.target.value)
              }}
            >
              <MenuItem value={"payments"}>Payments</MenuItem>
              <MenuItem value={"courses"}>Courses</MenuItem>
            </Select>
          </FormControl>
          <FilterDrawer paginationModel={paginationModel} searchText={searchInput} dates={dates} getReport={debounceSearch} statusFilter={statusFilter} setPaginationModel={setPaginationModel} filterCount={filterCount} setFilterCount={setFilterCount} />

        </div>

      </div>
      <div className='flex gap-2 justify-end pt-10'> <p className=' text-green-700'>{paidUnpaidData?.paid}</p><p className=' text-red-600'>{paidUnpaidData?.unpaid}</p> </div>

      <DataGidTable loading={loading} handleEvent={access?.write || access?.update || access?.view ? handleOnRowClick : ""} row={row} totalRowCount={filterRowCount} columns={statusFilter === "payments" ? columns : statusFilter === "courses" ? columnsCourse : columnsCase} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />

      <DialogueBox confirmDialog={confirmDialog} confirmDialogClose={confirmDialogClose} confirmDelete={confirmDelete} />

    </div>
  )

}

