import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
function AnalyticsPendingsCard(props) {
  let navigate = useNavigate();

  return (
    <div>
      <Card sx={{ minWidth: 200, height: 168, }}>
        <div className="h-32 flex-col px-6 py-10">
          <h2 className="text-xl text-gray-500">{props.headText}</h2>
          <h2 className="text-3xl">{props?.number}</h2>
        </div>
        <div className={props.ViewStatus ? "bg-slate-100 p-2 pl-4 " : "bg-slate-100 p-2 pl-4 cursor-pointer"}
          onClick={() => {

            if (!props.ViewStatus) { navigate(`${props.url}`);props.setCurrentPage((props.headText)?.toLowerCase()) }
            
          }}>
          <p className={props.ViewStatus ? "test-black" : "tColor"}>View all</p>
        </div>
      </Card>
    </div>
  );
}
export default AnalyticsPendingsCard;
