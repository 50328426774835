import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoCompletes(props) {
    // const _label = props?.lebel;
    const [_label, setLabel] = React?.useState(null);

    React.useEffect(() => {
        setLabel(props?.label);
    }, [props?.label]);

    return (
        <Autocomplete

            id="country-select-demo"
            fullWidth
            disabled={props?.disabled?true:false}
            value={props?.value}
            options={props.options}
            freeSolo={props?.freeSolo}
            autoHighlight
            isOptionEqualToValue={(option, value) => option?.id === value?.id}

            getOptionLabel={(option) => option?.title ? option?.title : option?.name ? option?.name : ""}
            onChange={(event, newValue) => {
                console.log(newValue);
                props.setValue(newValue);
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {_label === 'NEXT EXPECTED COURSE' ?
                        (
                            <>
                                {option?.title ? option?.title : option?.name}
                                {' '}
                                {option?.batchModeData[0]?.name && `(${option?.batchModeData[0]?.name})`}
                            </>
                        )
                        :
                        (option?.title ? option?.title : option?.name)
                    }
                </Box>
            )}
            renderInput={(params) => (
                <TextField

                    required={props?.notR ? false : true}
                    {...params}
                    label={props?.label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}