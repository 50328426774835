import { Country, State, City } from 'country-state-city';

import { Avatar, Button, Checkbox, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'

import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import { baseUrl, get, post } from '../../Services/services';
import CountryAutoComplete from '../country/CountryAutoComplete';
import statesData from "../../states.json";
import AutoCompletes from './AutoCompletes';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { DesimalValue, PercentRegex, StringWithSpace, aadharNumberRegex, addressRegex, wholeNumberRegex, zipCodeRegex } from '../../Regex/Regex';
import { successEvents } from '../../App';
import AutoCompletesformState from './AutoCompletesformState';
import { Label } from '@mui/icons-material';
const aptLogo = require('../../utilities/aptLogo.png')
export default function RagistrationForm() {
    const [caseData, setCasesData] = useState();
    const [cityData, setCityData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [boardData, setBoardData] = useState([]);
    const [careerOptionData, setcareerOptionData] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [bloodGroup, setBloodGroup] = useState([]);
    const [previousClass, setPreviousClass] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [currentCityData, setCurrentCityData] = useState([]);
    const [additional, setAdditional] = useState(false);
    const [image, setImage] = useState();
    const [notFound, setNotFound] = useState();
    const [submit, setSubmit] = useState(false);
    const ima = require('../../utilities/formImg.png')
    const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)

    const [formData, setFormData] = useState({
        dob: "",
        school: "",
        board: "",
        prevYearMark: "",
        state: "",
        city: "",
        streetAddress: "",
        postalCode: "",
        aadharNo: "",
        fatherName: "",
        fatherMobile: "",
        fatherOccupationId: "",
        motherName: "",
        motherMobile: "",
        motherOccupationId: "",
        guardianName: "",
        guardianMobile: "",
        guardianOccupationId: "",
        sourceId: "",
        careerOptionId: "",
        course: "",
        currentStreetAddress: "",
        currentPostalCode: "",
        acknowledgement: false,
    })
    const imageRef = useRef();

    let params = useParams();

    const { id } = params;

    const getCaseData = async () => {

        console.log(id, "print")
        const res = await post("cases/getCaseDetails", { caseId: id });
        if (res?.statusCode === 200) {
            console.log(res?.data?.statusCode)
            if (res?.data?.statusCode === 200) {

                setCasesData(res?.data?.cases[0]);
                getState(res?.data?.cases[0]?.countryId)
            } else if (res?.data?.statusCode === 402) {
                setNotFound(res?.data)
            }
        }
        //  = Object.keys(statesData[res?.data?.cases[0]?.country?.name]);


    }
    const getState = async (id) => {
        const res = await get(`states?filter={"where":{"countryId":"${id}","status":true}}`)
        const ress = await get(`school?filter={"where":{"countryId":"${id}","status":true}}`)
        const resss = await get(`boards?filter={"where":{"countryId":"${id}","status":true}}`)
        const rest = await post(`courses/getCourseForRegistrationForm`, { id: id })
        if (res.statusCode === 200 && ress.statusCode === 200 && resss.statusCode === 200 && rest.statusCode === 200) {
            setStateData(res.data);
            setSchoolData(ress.data);
            setBoardData(resss.data);
            setCourseData(rest?.data);
        }
    }
    const getCity = async (id) => {
        const res = await get(`cities?filter={"where":{"stateId":"${id}", "status":true}}`)
        if (res.statusCode === 200) {
            setCityData(res?.data);
        }
    }
    const getCurrentCity = async (id) => {
        const res = await get(`cities?filter={"where":{"stateId":"${id}", "status":true}}`)
        if (res.statusCode === 200) {
            setCurrentCityData(res?.data);
        }
    }
    const getDesignation = async () => {
        const res = await post("fieldTypes/getAllFieldTypeWithField", { status: true });


        if (res.statusCode === 200) {
            res.data?.map((item) => {
                if (item.title === "career options") {
                    setcareerOptionData(item?.field);
                }
                else if (item.title === "source") {
                    setSourceData(item?.field);
                }


                else if (item.title === "parent occupation") {
                    setOccupation(item?.field)
                } else if (item.title === 'blood group') {
                    setBloodGroup(item?.field)
                } else if (item.title === 'previous class/course') {
                    setPreviousClass(item?.field)
                } else if (item.title === 'post/designation') {
                    setDesignation(item?.field)
                }


            }
            )

        }
    }
    const setStateValue = async (value) => {
        setFormData(prevState => (
            { ...prevState, state: value }));
        setFormData(prevState => (
            { ...prevState, city: null }));
        console.log('clink')
        if (additional) {
            console.log('enter')

            setFormData(prevState => (
                { ...prevState, currentState: value }));
            setFormData(prevState => (
                { ...prevState, currentCity: null }));
            getCurrentCity(value?.id)
        }
        if (value?.id) {

            getCity(value?.id)
        }

    }
    const setCityValue = async (value) => {
        setFormData(prevState => (
            { ...prevState, city: value }));
        if (additional) {
            setFormData(prevState => (
                { ...prevState, currentCity: value }));
        }
    }
    const setCurrentCityValue = async (value) => {
        setFormData(prevState => (
            { ...prevState, currentCity: value }));
    }
    const setCurrentStateValue = async (value) => {
        setFormData(prevState => (
            { ...prevState, currentState: value }));
        setFormData(prevState => (
            { ...prevState, currentCity: null }));
        if (value?.id) {
            getCurrentCity(value?.id)

        }
    }
    const setSchoolValue = async (value) => {
        setFormData(prevState => (
            { ...prevState, school: value }));
    }
    const setboardValue = async (value) => {
        setFormData(prevState => (
            { ...prevState, board: value }));
    }
    const setCareerOption = async (value) => {
        setFormData(prevState => (
            { ...prevState, careerOptionId: value }));
    }
    const setSource = async (value) => {
        setFormData(prevState => (
            { ...prevState, sourceId: value }));
    }
    const setCourse = async (value) => {
        setFormData(prevState => (
            { ...prevState, course: value }));
    }

    const submitForm = async () => {
        let body = { ...formData };
        let formdata = new FormData();
        formdata.append("file", image, image.name);
        const ress = await post(`uploads/user/upload`, formdata);
        if (ress.statusCode === 200) { body.image = ress.data?.result?.files?.file[0].name; }
        else {
            return
        }

        if (!additional) {
            delete body.guardianName
            delete body.guardianMobile
            delete body.guardianOccupationId

        }
        body.futureCourses = body.course.id;
        body.cityId = body.city.id;
        body.stateId = body.state.id;
        body.boardId = body.board.id
        body.careerOptionId = body.careerOptionId._id;
        body.sourceId = body.sourceId._id
        body.schoolId = body.school.id;
        body.futureCourse = body.course._id
        delete body.school
        delete body.board
        delete body.city
        delete body.state
        delete body.course

        const res = await post('cases/RegistrationFormSubmit', { form: body, caseId: id })
        if (res.statusCode === 200) {

            getCaseData();
            setSubmit(true)
        }
        else if (res.statusCode === 422) {
            setSnackBarOptions({
                message: res.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }

    useEffect(() => {
        getCaseData();
        getDesignation();
    }, [])

    useEffect(() => {
        if (additional) {

            setFormData(prevState => (
                { ...prevState, currentPostalCode: formData?.postalCode, currentStreetAddress: formData?.streetAddress, currentCity: { ...formData?.city }, currentState: { ...formData?.state } }));
        } else {
            setFormData(prevState => (
                { ...prevState, currentPostalCode: "", currentStreetAddress: '', currentCity: null, currentState: null }));
        }
    }, [additional])
    return (
        <div className=' bg-gray-100 flex flex-col  min-h-screen  w-screen items-center'>
            <div  className={` ${caseData?.isRegistered || notFound ? "hidden":'flex'} p-8 m-4  xl:w-[1200px] w-full flex-col sm:flex-row justify-between items-center`}>
                <img className='h-[80px]' src={aptLogo} />
                <h1 className=' text-2xl'>Student Registration</h1>
            </div>
            {
                submit ?
                    <div className='flex flex-col flex-1 justify-center items-center gap-5'>
                        <img className='w-72 ' src={ima} />
                        <p style={{ fontWeight: 900 }} className='text-[#0CA727] text-3xl' >Congratulations</p>
                        <p className='text-[#2B2B2B] font-semibold	text-xl' >You have successfully registered!</p>
                    </div>
                    :
                    caseData?.isRegistered || notFound ?
                        <div className='flex flex-col flex-1 justify-center items-center gap-5'>
                            <div className=' flex justify-center items-center bg-[#1C46F21C]  opacity-70 text-[#1C46F2]  rounded-full w-32 h-32 text-8xl border-2 border-[#1C46F21C]'>!</div>
                            <p style={{ fontWeight: 900 }} className='text-[#3366FF] text-3xl' >Oops!</p>
                            <p className='text-[#2B2B2B] font-semibold	text-xl' >{notFound ? notFound?.message : 'You have already registered!'}</p>
                        </div>
                        :
                        caseData ?
                            <div className=' bg-white p-8 m-4  xl:w-[1200px]' >



                                <Grid container spacing={2} rowGap={1}  >

                                    <Grid item xs={12}  >
                                        <Box className="flex flex-col gap-2 ">
                                            <Box className="flex  justify-center">


                                                {image ? <div className='flex '>
                                                    <Avatar sx={{ width: 70, height: 70 }} src={URL.createObjectURL(image)} />
                                                    <CloseIcon className=' cursor-pointer' onClick={() => { imageRef.current.value = null; setImage(null) }} />
                                                </div>
                                                    :
                                                    <div className=' relative cursor-pointer' onClick={() => imageRef.current.click()}>
                                                        <Avatar src={`${baseUrl}profilePics/staff/download/${image}`} sx={{ width: 70, height: 70, }} />
                                                        <EditIcon className=' absolute bottom-0 right-0' />
                                                    </div>
                                                }
                                                <input className=' hidden' ref={imageRef} type="file" accept="image/*" onChange={(e) => {
                                                    const fileType = e.target?.files[0]?.type?.split('/');

                                                    if (fileType[0] === 'image') {
                                                        setImage(e.target.files[0]);
                                                    }
                                                    else {
                                                        // props.setSnackBarOptions({
                                                        //     message: "Select Vaild image File!",
                                                        //     show: true,
                                                        //     severity: "error",
                                                        //     duration: 5000,
                                                        // });
                                                    }
                                                }} />

                                            </Box>
                                            <p className={image ? ' self-center text-sm' : 'text-[red] text-sm self-center'}>Profile image is Mandatory *</p>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}  >
                                        <p >Student Personal Information</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <TextField
                                            required
                                            label="NAME"
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            value={caseData?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            required
                                            label="MOBILE"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">+{caseData?.country?.phoneCode}</InputAdornment>,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            disabled
                                            value={caseData?.mobile}
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="EMAIL"
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            disabled
                                            value={caseData?.email}
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <TextField
                                            required
                                            label='DATE OF BIRTH'
                                            InputLabelProps={{ shrink: true }}
                                            type='date'
                                            value={formData?.dob}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, dob: e.target.value }))
                                            }
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <TextField

                                            label='AADHAR NO'
                                            fullWidth
                                            value={formData?.aadharNo}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, aadharNo: e.target.value }))
                                            }
                                            error={!aadharNumberRegex.test(formData?.aadharNo) && formData?.aadharNo?.length !== 0}
                                            helperText={!aadharNumberRegex.test(formData?.aadharNo) && formData?.aadharNo?.length !== 0 ? "Enter Valid aadhar no." : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">BLOOD GROUP</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.bloodGroupId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, bloodGroupId: e.target.value }))
                                                }
                                                label="BLOOD GROUP"
                                            >
                                                {bloodGroup?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="INSTAGRAM ID"

                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, instaId: e.target.value }))
                                            }
                                            value={formData?.instaId}
                                            fullWidth />
                                    </Grid>




                                    {console.log(formData)}


                                    <Grid item xs={12}  >
                                        <p >Permanent Address</p>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes value={formData?.state} label={"STATE"} options={stateData} setValue={setStateValue} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes value={formData?.city} label={"CITY"} options={cityData} setValue={setCityValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            required
                                            label='ZIP/POSTAL'
                                            fullWidth
                                            error={!zipCodeRegex.test(formData?.postalCode) && formData?.postalCode?.length !== 0}
                                            helperText={!zipCodeRegex.test(formData?.postalCode) && formData?.postalCode?.length !== 0 ? "Enter Valid zip/postal" : ""}
                                            value={formData?.postalCode}
                                            onChange={(e) => {

                                                setFormData(prevState => (
                                                    { ...prevState, postalCode: e.target.value }))
                                                if (additional) {
                                                    setFormData(prevState => (
                                                        { ...prevState, currentPostalCode: e.target.value }))
                                                }
                                            }


                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            error={formData?.streetAddress?.length < 6 && formData?.streetAddress?.length !== 0}
                                            helperText={formData?.streetAddress?.length < 6 && formData?.streetAddress?.length !== 0 ? "street Address contain at least 6 characters " : ""}
                                            value={formData?.streetAddress}
                                            onChange={(e) => {

                                                setFormData(prevState => (
                                                    { ...prevState, streetAddress: e.target.value }))
                                                if (additional) {
                                                    setFormData(prevState => (
                                                        { ...prevState, currentStreetAddress: e.target.value }))
                                                }
                                            }
                                            }
                                            required
                                            label='STREET ADDRESS'
                                            fullWidth />
                                    </Grid>



                                    <Grid item xs={12}  >
                                        <div className='flex justify-between items-center'>
                                            <p  >Current Address</p>
                                            <label className='text-sm' onClick={() => setAdditional(!additional)}>
                                                <Checkbox checked={additional} />
                                                Same as permanent address
                                            </label>
                                        </div>
                                    </Grid>

                                    <Grid style={{ display: additional ? "none" : "block" }} item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes disabled={additional} value={formData?.currentState} label={"STATE"} options={stateData} setValue={setCurrentStateValue} />
                                    </Grid>

                                    <Grid style={{ display: additional ? "none" : "block" }} item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes disabled={additional} value={formData?.currentCity} label={"CITY"} options={currentCityData} setValue={setCurrentCityValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: additional ? "none" : "block" }}  >
                                        <TextField
                                            required
                                            label='ZIP/POSTAL'
                                            fullWidth
                                            disabled={additional}
                                            error={!zipCodeRegex.test(formData?.currentPostalCode) && formData?.currentPostalCode?.length !== 0}
                                            helperText={!zipCodeRegex.test(formData?.currentPostalCode) && formData?.currentPostalCode?.length !== 0 ? "Enter Valid zip/postal" : ""}
                                            value={formData?.currentPostalCode}
                                            onChange={(e) => {

                                                setFormData(prevState => (
                                                    { ...prevState, currentPostalCode: e.target.value }))

                                            }
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: additional ? "none" : "block" }}  >
                                        <TextField
                                            error={formData?.currentStreetAddress?.length < 6 && formData?.currentStreetAddress?.length !== 0}
                                            helperText={formData?.currentStreetAddress?.length < 6 && formData?.currentStreetAddress?.length !== 0 ? "street Address contain at least 6 characters " : ""}
                                            value={formData?.currentStreetAddress}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, currentStreetAddress: e.target.value }))
                                            }
                                            required
                                            disabled={additional}
                                            label='STREET ADDRESS'
                                            fullWidth />
                                    </Grid>



                                    <Grid item xs={12}  >
                                        <p >School Information</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes value={formData?.board} label={"BOARD"} options={boardData} setValue={setboardValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes value={formData?.school} label={"SCHOOL"} freeSolo={false} options={schoolData} setValue={setSchoolValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">PREVIOUS CLASS/COURSE</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.previousClassId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, previousClassId: e.target.value }))
                                                }
                                                label="PREVIOUS CLASS/COURCE"
                                            >
                                                {previousClass?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            required
                                            error={!PercentRegex.test(formData?.prevYearMark) && formData?.prevYearMark?.length !== 0}
                                            helperText={!PercentRegex.test(formData?.prevYearMark) && formData?.prevYearMark?.length !== 0 ? "Enter Valid previous year mark" : ""}
                                            label="PREVIOUS YEAR MARK %"
                                            value={formData?.prevYearMark}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, prevYearMark: e.target.value }))
                                            }
                                            fullWidth
                                        />
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes notR={false} value={formData?.careerOptionId} label={"CAREER OPTION"} options={careerOptionData} setValue={setCareerOption} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes notR={false} value={formData?.sourceId} label={"SOURCE"} options={sourceData} setValue={setSource} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <AutoCompletes notR={false} value={formData?.course} label={"NEXT EXPECTED COURSE"} options={courseData} setValue={setCourse} />
                                    </Grid>

                                    <Grid item xs={12}  >
                                        <p >Family Details</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="FATHER'S NAME"
                                            error={!StringWithSpace.test(formData?.fatherName) && formData?.fatherName?.length !== 0}
                                            helperText={!StringWithSpace.test(formData?.fatherName) && formData?.fatherName?.length !== 0 ? "name contain at least 3 alphabet" : ""}
                                            required
                                            fullWidth
                                            value={formData?.fatherName}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, fatherName: e.target.value }))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="FATHER'S MOBILE NO."
                                            required
                                            fullWidth
                                            value={formData?.fatherMobile}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, fatherMobile: e.target.value }))
                                            }
                                            error={!(DesimalValue.test(formData?.fatherMobile) && formData?.fatherMobile?.length === caseData?.country?.phoneLength) && formData?.fatherMobile?.length !== 0}
                                            helperText={!(DesimalValue.test(formData?.fatherMobile) && formData?.fatherMobile?.length === caseData?.country?.phoneLength) && formData?.fatherMobile?.length !== 0 ? `father mobile number contains ${caseData?.country?.phoneLength} digit number` : ""}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">FATHER'S OCCUPATION</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.fatherOccupationId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, fatherOccupationId: e.target.value }))
                                                }
                                                label="FATHER'S OCCUPATION"
                                            >
                                                {occupation?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>


                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">FATHER'S POST/DESIGNATION</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.FatherDesignationId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, FatherDesignationId: e.target.value }))
                                                }
                                                label="FATHER'S POST/DESIGNATION"
                                            >
                                                {designation?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="MOTHER'S NAME"
                                            required
                                            fullWidth
                                            value={formData?.motherName}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, motherName: e.target.value }))
                                            }
                                            error={!StringWithSpace.test(formData?.motherName) && formData?.motherName?.length !== 0}
                                            helperText={!StringWithSpace.test(formData?.motherName) && formData?.motherName?.length !== 0 ? "name contain at least 3 alphabet" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="MOTHER'S MOBILE NO"
                                            required
                                            fullWidth
                                            value={formData?.motherMobile}
                                            onChange={(e) =>
                                                setFormData(prevState => (
                                                    { ...prevState, motherMobile: e.target.value }))
                                            }
                                            error={!(DesimalValue.test(formData?.motherMobile) && formData?.motherMobile?.length === caseData?.country?.phoneLength) && formData?.motherMobile?.length !== 0}
                                            helperText={!(DesimalValue.test(formData?.motherMobile) && formData?.motherMobile?.length === caseData?.country?.phoneLength) && formData?.motherMobile?.length !== 0 ? `mother's mobile number contains ${caseData?.country?.phoneLength} digit number` : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">MOTHER'S OCCUPATION</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.motherOccupationId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, motherOccupationId: e.target.value }))
                                                }
                                                label="MOTHER'S OCCUPATION"
                                            >
                                                {occupation?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">MOTHER'S POST/DESIGNATION</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.motherDesignationId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, motherDesignationId: e.target.value }))
                                                }
                                                label="MOTHER'S POST/DESIGNATION"
                                            >
                                                {designation?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="GUARDIAN'S NAME"
                                            required={formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId}
                                            fullWidth
                                            value={formData?.guardianName}
                                            onChange={(e) => setFormData(prevState => (
                                                { ...prevState, guardianName: e.target.value }))
                                            }
                                            error={!StringWithSpace.test(formData?.guardianName) && formData?.guardianName?.length !== 0}
                                            helperText={!StringWithSpace.test(formData?.guardianName) && formData?.guardianName?.length !== 0 ? "name contain at least 3 alphabet" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <TextField
                                            label="GUARDIAN'S MOBILE NO"
                                            required={formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId}

                                            error={!(DesimalValue.test(formData?.guardianMobile) && formData?.guardianMobile?.length === caseData?.country?.phoneLength) && formData?.guardianMobile?.length !== 0}
                                            helperText={!(DesimalValue.test(formData?.guardianMobile) && formData?.guardianMobile?.length === caseData?.country?.phoneLength) && formData?.guardianMobile?.length !== 0 ? `guardian's mobile number contains ${caseData?.country?.phoneLength} digit number` : ""}

                                            value={formData?.guardianMobile}
                                            onChange={(e) => setFormData(prevState => (
                                                { ...prevState, guardianMobile: e.target.value }))
                                            }
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required={formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId} fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">GUARDIAN'S OCCUPATION</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.guardianOccupationId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, guardianOccupationId: e.target.value }))
                                                }
                                                label="GUARDIAN'S OCCUPATION"
                                            >
                                                <MenuItem value={null}>{'None'}</MenuItem>
                                                {occupation?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <FormControl required={formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId} fullWidth sx={{ minWidth: 120 }} >
                                            <InputLabel id="demo-select-small-label">GUARDIAN'S POST/DESIGNATION</InputLabel>
                                            <Select

                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={formData?.guardianDesignationId}
                                                onChange={(e) =>
                                                    setFormData(prevState => (
                                                        { ...prevState, guardianDesignationId: e.target.value }))
                                                }
                                                label="GUARDIAN'S POST/DESIGNATION"
                                            >
                                                <MenuItem value={null}>{'None'}</MenuItem>
                                                {designation?.map((item) =>
                                                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} >
                                        <div className='flex   py-5 items-center pl-2 cursor-pointer   '
                                            onClick={() =>
                                                setFormData(prevState => (
                                                    { ...prevState, acknowledgement: !formData?.acknowledgement }))

                                            }


                                        >
                                            <Checkbox checked={formData?.acknowledgement} />
                                            <p>I CONFIRM ALL THE INFORMATION PROVIDED ABOVE IS CORRECT TO THE BEST OF MY KNOWLEDGE.</p>
                                        </div>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Box fullWidth className='flex justify-center  '>
                                            <Button
                                                disabled={
                                                    !formData?.dob
                                                    || !formData?.school
                                                    || !formData?.board
                                                    || !formData?.state
                                                    || !formData?.city
                                                    || !formData?.currentState
                                                    || !formData?.currentCity
                                                    || !formData?.currentStreetAddress
                                                    || !formData?.currentPostalCode
                                                    ||!formData?.bloodGroupId
                                                    || formData?.streetAddress?.length < 6
                                                    || !formData?.acknowledgement
                                                    || (!PercentRegex.test(formData?.prevYearMark))
                                                    || (!zipCodeRegex.test(formData?.postalCode))
                                                    || (!aadharNumberRegex.test(formData?.aadharNo) && formData?.aadharNo?.length > 0)
                                                    || (!StringWithSpace.test(formData?.fatherName))
                                                    || !(DesimalValue.test(formData?.fatherMobile) && formData?.fatherMobile?.length === caseData?.country?.phoneLength)
                                                    || !formData?.fatherOccupationId
                                                    || (!StringWithSpace.test(formData?.motherName))
                                                    || !(DesimalValue.test(formData?.motherMobile) && formData?.motherMobile?.length === caseData?.country?.phoneLength)
                                                    || !formData?.motherOccupationId
                                                    || !(image)
                                                    || (formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId ? !StringWithSpace.test(formData?.guardianName) : false)
                                                    || (formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId ? !(DesimalValue.test(formData?.guardianMobile) && formData?.guardianMobile?.length === caseData?.country?.phoneLength) && formData?.guardianMobile?.length !== 0 : false)
                                                    || (formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId ? !formData?.guardianOccupationId  : false)
                                                    || (formData?.guardianName || formData?.guardianMobile || formData?.guardianOccupationId || formData?.guardianDesignationId ? !formData?.guardianDesignationId : false)


                                                }

                                                onClick={submitForm}
                                                variant='contained'
                                                sx={{ backgroundColor: "#3366FF" }}
                                            >submit</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            :
                            ""
            }
        </div>
    )
}
