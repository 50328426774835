import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';




export default function DataGidTable(props) {
    const tc = React.useRef(null);
    return (
        <Box ref={tc} className=" bg-white   mt-10 flex flex-1 flex-col " sx={{ maxHeight: tc?.current?.offsetHeight }} >
            <DataGrid

                slots={{ toolbar: GridToolbar }}
                onRowClick={props.handleEvent}
                unstable_cellSelection
                rowSelection={false}
                loading={props?.loading}
                rows={props.row}
                rowHeight={props.rowHeight ? props.rowHeight : 90}
                rowCount={props.totalRowCount}
                columns={props.columns}
                columnHeaderHeight={80}
                density={"compact"}
                paginationModel={props.paginationModel}
                onPaginationModelChange={props.setPaginationModel}
                initialState={{
                    columns: {
                        columnVisibilityModel: props.columnVisibilityModel ? props.columnVisibilityModel : {}
                    },
                    pagination: {
                        paginationModel: { pageSize: 15, page: 0 },
                    },
                }}
                paginationMode="server"
                pageSizeOptions={[10, 15, 20, 25, 30, 50, 100]}
                sx={{

                    '&>.css-wop1k0-MuiDataGrid-footerContainer': {
                        // display: 'flex',
                        // justifyContent: 'flex-start',
                        // display: showAll ? 'none' : "flex",

                    },
                    '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            // borderBottom: 'none',
                            backgroundColor: '#FAFAFA',
                            // color:"red",
                            fontWeight: 800,
                            minHeight: 500,
                            fontSize: "15px"


                        },

                        '& div div div div >.MuiDataGrid-cell': {
                            // borderBottom: 'none',
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#4B4B4B'

                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: props?.cursor ? props?.cursor : "default"
                        }
                    }
                }}
            />
        </ Box>
    );
}