import Card from '@mui/material/Card';
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';

function Cards(props) {

  const [courseCategoriesM, setCourseCategoriesM] = React.useState([]);
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const courseCategoriesArr = modules.find(
      (m) => m?.name === "Course categories"
    );
    setCourseCategoriesM(courseCategoriesArr);
    // eslint-disable-next-line
  }, []);

  let navigate = useNavigate();
  // onClick={()=>navigate('/courseCard')}
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const edit = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    props.editCourseCategory()
  };
  const deleteCourseCategory = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    props.deleteCoursesCategory()
  };

  return (
    <div className='m-3'>
      <Card onClick={props.courseCategoryId} sx={{ minWidth: 300, minHeight: 100, cursor: 'pointer' }}>
        <div>
          <Button
            disabled={courseCategoriesM?.update  ? false : true}
            sx={{ float: 'right', minWidth: '0px' }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={edit}>Edit</MenuItem>
            <MenuItem onClick={deleteCourseCategory}>Delete</MenuItem>
          </Menu>
        </div>
        <div className='flex justify-center items-center h-20 w-72'>
          <h2>
            {props.courseName}
          </h2>
        </div>
        <div className='bg-slate-100 h-10 mt-3 p-2'>
          {/* <p className='tColor'>View</p> */}
        </div>
      </Card>
    </div>
  )
}
export default Cards;