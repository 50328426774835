import { useState, useEffect } from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    TextField,
    FormLabel
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
    addSmsCip
} from "../Services/api-services";
const AddSmsChipsDialog = (props) => {
    const [chipName, setChipName] = useState("")
    useEffect(() => {
        setChipName("")
    }, [])
    const addNewSmsChip = async () => {

        let allchips = props?.allChips?.chips
        if (allchips.includes(chipName)) {

        } else {
            allchips?.push(chipName)
            let body = {
                chips: allchips
            }

            let response = await addSmsCip(body, props?.allChips?.id)
            props?.handleAddChip(allchips)
        }
        props?.closeAddSmsChipDialog()
        setChipName("")
    }
    const closeAddChipDialog = () => {
        props.closeAddSmsChipDialog()
    }
    return (<>
        <Box sx={{ width: "100%" }}>

            <Dialog fullWidth={true} maxWidth="md" open={props?.addChipDialog}>
                <DialogTitle>ADD TAG</DialogTitle>
                <Divider />
                <DialogContent>
                    <div className="px-7">
                        <FormControl fullWidth>
                            <FormLabel required>Tag Name</FormLabel>

                            <TextField
                                // error={addTag && tagName.length < 1 ? true : false}
                                // helperText={
                                //     addTag && tagName.length < 1
                                //         ? "Tag name cannot be empty"
                                //         : ""
                                // }
                                value={chipName}
                                onChange={(e) => setChipName(e.target.value)}
                                required={true}
                                id="outlined-basic"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        sx={{ marginY: "10px" }}
                        variant="outlined"
                        onClick={closeAddChipDialog}
                    >
                        Cancel
                    </Button>
                    {/* {updateTag === false ? (
                        <Button
                            sx={{ marginY: "10px" }}
                            variant="contained"
                            onClick={addNewTag}
                        >
                            Add
                        </Button>
                    ) : ( */}
                    <Button
                        onClick={addNewSmsChip}
                        sx={{ marginY: "10px" }}
                        variant="contained"
                    >
                        Add
                    </Button>
                    {/* )} */}
                </DialogActions>
            </Dialog>
            {/* <div>
                <Button
                    // onClick={() => setTagDialog(true)}
                    variant="contained"
                    sx={{
                        borderRadius: "50px",
                        height: "60px",
                        width: "60px",
                        position: "fixed",
                        bottom: "40px",
                        right: "40px",
                    }}
                >
                    <AddIcon sx={{ fontSize: "30px" }} />
                </Button> */}
            {/* </div> */}
        </Box>
    </>)
}
export default AddSmsChipsDialog