import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Chip,
	CircularProgress,
	Tooltip,
	IconButton
} from "@mui/material";
import BreadCrumb from "../../Components/BreadCrumbs";
import DateInput from "../../Components/DateInput";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from "@mui/icons-material/Add";
// import Chip from '@mui/material/Chip';
import * as React from "react";
import {
	getSmsModulesAndEvents,
	getAllSmsTemplate,
	_addSmsTemplate,
	_deleteSmsTemplate,
	_changeStatusOfSmsTemplate,
	_updateSmsTemplate,
	getAllSmsChips,
	addSmsCip
} from "../../Services/api-services";
import CustomizedSnackbars from "../../Components/Snackbar";
import DynamicTable from "../../Components/DynamicTable";
import DialogueBox from "../../Components/DialogueBox";
import NodataInTable from "../../Components/NoDataInTable";
import DateSearchFilter from "../../Components/DateSearchFilter";
import AddSmsChipsDialog from "../../Popups/AddSmsChipsDialog";
import Key from "@mui/icons-material/Key";
import ClearIcon from '@mui/icons-material/Clear';
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";

function SmsTemplate() {
	const [allSmsTemplate, setAllSmsTemplate] = React.useState([]);
	const [smsTemplateIndex, setSmsTemplateIndex] = React.useState()
	const [smsDialog, setSmsDialog] = React.useState(false);
	const [editDialog, setEditDialog] = React.useState(false)
	const [confirmDialog, setConfirmDialog] = React.useState(false);
	const [dataSource, setDataSource] = React.useState(null);
	const [smsModuleAndEvent, setSmsModuleAndEvent] = React.useState([])
	const [event, setEvent] = React.useState('')
	const [eventArr, setEventArr] = React.useState([])
	const [selectedModule, setSelectedModule] = React.useState('')
	const [selectedEvent, setSelectedEvent] = React.useState()
	const [smsTitle, setSmsTitle] = React.useState('')
	const [smsMessage, setSmsMessage] = React.useState("")
	const [allSmsChips, setAllSmsChips] = React.useState()
	const [addChipDialog, setAddChipDialog] = React.useState(false)
	const [misVariable, setMisVariable] = React.useState("@@*@@")
	const [dltVariable, setdltVariable] = React.useState("#Var")
	const [textLocalVariable, setTextLocalVariable] = React.useState('%%|*^{"inputtype" : "text", "maxlength" : "50"}%%')
	const [misTemplate, setMisTemplate] = React.useState()
	const [dltTemplate, setdltTemplate] = React.useState("")
	const [textLocalTemplate, setTextLocalTemplate] = React.useState("")
	const [chipReplace, setChipReplace] = React.useState(false)
	const [switchCase, setSwitchCase] = React.useState([]);
	const [moduleUpdate, setModuleUpdate] = React.useState(null)
	const [eventUpdate, setEventUpdate] = React.useState(null)

	const [templateVar, setTemplateVar] = React.useState([])

	var d = new Date();
	d = new Date(d.setFullYear(2021));
	const [startDate, setStartDate] = React.useState(d.toISOString());
	const [endDate, setEndDate] = React.useState(new Date().toISOString());
	const [input, setInputSearch] = React.useState("")
	const [toggle, setToggle] = React.useState(false)
	const [smstemplateM, setSmstemplateM] = React.useState([]);
	const [snackBarOptions, setSnackBarOptions] = React.useState({
		message: "",
		duration: 5000,
		show: false,
		severity: "",
	});
    const [authState, setAuthState] = useRecoilState(UserAuthState);

	const [update, setUpdate] = React.useState(true);
	const xEvents = [
		{
			module: 'cases',
			title: 'Cases',
			events: [
				{
					event: 'Registration Link',
					key: 'registration',
					variables: [
						{
							key: 'name',
							var: 'Name'
						},
						{
							key: 'course',
							var: 'Course Title'
						},
						{
							key: 'link',
							var: 'Link'
						}
					]
				}
			]
		},
		{
			module: 'payment',
			title: 'Payment',
			events: [
				{
					event: 'Reminder',
					key: 'reminder',
					variables: [
						{
							key: 'name',
							var: 'Name'
						},
						{
							key: 'course',
							var: 'Course Title'
						},
						{
							key: 'amount',
							var: 'Amount'
						},
						{
							key: 'date',
							var: 'Date'
						}
					]
				},
				{
					event: 'Cheque Bounced',
					key: 'bounced',
					variables: [
						{
							key: 'name',
							var: 'Name'
						},
						{
							key: 'course',
							var: 'Course Title'
						},
						{
							key: 'amount',
							var: 'Amount'
						},
						{
							key: 'date',
							var: 'Date'
						}
					]
				},
				{
					event: 'Loan Rejected',
					key: 'rejected',
					variables: [
						{
							key: 'name',
							var: 'Name'
						},
						{
							key: 'course',
							var: 'Course Title'
						},
						{
							key: 'amount',
							var: 'Amount'
						},
						{
							key: 'date',
							var: 'Date'
						}
					]
				},
				{
					event: 'Payment Link',
					key: 'paymentLink',
					variables: [
						{
							key: 'name',
							var: 'Name'
						},
						{
							key: 'course',
							var: 'Course Title'
						},
						{
							key: 'amount',
							var: 'Amount'
						},
						{
							key: 'date',
							var: 'Date'
						},
						{
							key: 'link',
							var: 'Link'
						}
					]
				}
			]
		}
	]
	const getAccessOFVeiw = async () => {
        const modules = authState?.userData?.assignModules?.assignModule;
		modules?.map((item) => {
			if (item?.name === "Sms template" && (item?.update === true)) {
				setUpdate(false);
			}

		})
	}

	const inputRef = React.useRef()
	const [dateFilterApplied, setDateFilterApplied] = React.useState(false);

	const sms = [
		
		<Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
			SMS Template
		</Typography>,
	];

	React.useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
		console.log(modules);
		const smsTemplateArr = modules.find((m) => m?.name === "Sms templates");
		console.log(smsTemplateArr)
		setSmstemplateM(smsTemplateArr);
		fetchData("added");
	}, [startDate, endDate]);

	React.useEffect(() => {
		fetchData();
	}, [editDialog, toggle]);

	React.useEffect(() => {
		fetchData();
		getAccessOFVeiw();
	}, []);

	async function fetchData(param) {
		try {
			let data = {
				startDate: startDate,
				endDate: param === 'added' ? new Date().toISOString() : endDate,
				input: input
			}
			const events = await getSmsModulesAndEvents()
			const allSmsTemplate = await getAllSmsTemplate(data)
			const response = await getAllSmsChips()
			setAllSmsTemplate(allSmsTemplate);
			setAllSmsChips(response[0])
			const _dataSource = await createTableData(allSmsTemplate);
			setDataSource(_dataSource);
			setSmsModuleAndEvent(events)
		} catch (err) {
			console.log(err);
		}
	}

	function closesmsDialog() {
		setEvent('')
		setSmsDialog(false)
		setEventArr([])
		setSelectedModule('')
		setSmsTitle('')
		setSmsMessage('')
		setEditDialog(false);
		setdltTemplate('');
		setTextLocalTemplate('')
	}

	async function addSmsTemplate() {
		if (smsTitle && smsMessage && event && selectedModule) {
			const body = {
				title: smsTitle,
				module: selectedModule,
				event: event,
				message: smsMessage,
			};
			console.log(body)
			try {
				const response = await _addSmsTemplate(body);
				setEditDialog(false)
				if (!response) {
					console.log('error')
				} else {
					closesmsDialog();
					fetchData("added")
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			// setSnackBarOptions({
			// 	message: "Please fill valid values!",
			// 	show: true,
			// 	severity: "failure",
			// 	duration: 5000,
			// });
		}
	}

	function confirmDialogSms(e, data) {
		setSmsTemplateIndex(data.id);
		setConfirmDialog(true);
	}

	async function deleteSmsTemplate() {
		const body = {
			deleted: true,
			status: false,
		};
		_deleteSmsTemplate(body, smsTemplateIndex).then(
			(response) => {
				fetchData('added');
			}
		).catch((err) => console.log(err));
		setConfirmDialog(false);
	}

	function openEditDialog(e, data) {
		setEditDialog(true)
		setSmsDialog(true)
		setSelectedModule(data.module)
		setSmsTemplateIndex(data.id);
		setSmsTitle(data.title)
		setSmsMessage(data.message)
		setdltTemplate(data?.message?.replace(/@@@[a-zA-Z]{1,}@@@/g, " {#var#} "))
		setTextLocalTemplate(data?.message?.replace(/@@@/g, " %%|").replace(/ %%\| /g, '^{"inputtype" : "text", "maxlength" : "50"}%% '))
		const index = xEvents.findIndex(ev => ev.module === data.module)
		setEventArr(xEvents[index]['events'])
		setEvent(data.event)
	}

	async function updateSMSTemplate(data) {
		try {
			const body = {
				title: smsTitle,
				module: selectedModule,
				event: eventUpdate,
				message: smsMessage,
			};
			const response = await _updateSmsTemplate(body, smsTemplateIndex);
			setEditDialog(false)
			console.log(response);
			fetchData("added");
			if (response) {
				setSnackBarOptions({
					message: "SMS Template Updated Successfully !",
					duration: 5000,
					show: true,
					severity: "success",
				});
				closesmsDialog();
			}



		} catch (err) {
			setSnackBarOptions({
				message: "SMS Template Failed  !",
				duration: 5000,
				show: true,
				severity: "error",
			});
			// fetchData("added");
			closesmsDialog();
		}
	}

	const headCells = [
		{
			id: "sno",
			numeric: false,
			disablePadding: false,
			sort: false,
			label: "S.no",
		},
		{
			id: "title",
			numeric: false,
			disablePadding: false,
			sort: true,
			label: "Title",
		},
		{
			id: "module",
			numeric: false,
			disablePadding: false,
			sort: true,
			label: "Module",
		},
		{
			id: "event",
			numeric: false,
			disablePadding: false,
			sort: true,
			label: "Event",
		},
		{
			id: "message",
			numeric: false,
			disablePadding: false,
			sort: true,
			label: "Message",
		},

		{
			id: "createdAt",
			numeric: true,
			disablePadding: false,
			sort: true,
			label: "Created On",
		},
		{
			id: "status",
			numeric: false,
			disablePadding: false,
			sort: false,
			label: "Status",
		},
		{
			id: "action",
			numeric: true,
			disablePadding: false,
			sort: false,
			label: "Action",
		},
	];
	if (smstemplateM?.update !== true) {
		headCells.pop()
	}

	const createTableData = (data) => {
		// CREATE TABLE DATA - ADDONS
		let returnArray = [];
		console.log("---1--")
		console.log(data)
		console.log("----2-")
		return new Promise((resolve, reject) => {
			if (data && data.length) {
				for (let index = 0; index < data.length; index++) {

					const row = data[index];

					const obj = {
						sno: index + 1,
						id: row.id,
						title: row.title,
						module: row.module,
						event: row.event,
						message: row.message,
						createdAt: row.createdAt,
						status: row.status,
						action: true,
					};
					returnArray.push(obj);
					if (index === data.length - 1) resolve(returnArray);
				}
			} else resolve([]);
		});
	};

	const handleDeleteClick = async (value) => {
		console.log(value)

		let allChips = allSmsChips?.chips?.filter((item) => item != value)
		console.log(allSmsChips)
		let body = {
			chips: []
		}
		let response1 = await addSmsCip(body, allSmsChips?.id)
		body = {
			chips: allChips
		}
		let response2 = await addSmsCip(body, allSmsChips?.id)
		// setAllSmsChips(allChips)
		const response = await getAllSmsChips()
		console.log(response)
		setAllSmsChips(response[0])
		console.log(response)
	}


	const handlOnChipClick = (key) => {
		console.log(key)
		// ON CHIP CLICK - SELECTED VARIABLE FRO SMS TEMPLATE
		setSmsMessage(smsMessage + "@@@" + key?.key + "@@@")
		setdltTemplate(dltTemplate + "{#var#}")
		// setTextLocalTemplate(textLocalTemplate + ` %%|${key}^{"inputtype" : "text", "maxlength" : "50"}%% `)
	}

	const handleMessageChange = (e) => {
		setSmsMessage(e.target.value)
		console.log(e.target.value.lastIndexOf("@@@"))
		let value = e.target.value.replace(/@@@[a-zA-Z]{1,}@@@/g, "{#var#}")
		setdltTemplate(value)
		value = e.target.value.replace(/@@@/g, " %%|").replace(/ %%\| /g, '^{"inputtype" : "text", "maxlength" : "50"}%% ')
		setTextLocalTemplate(value)
	}
	const closeAddSmsChipDialog = () => {
		setAddChipDialog(false)
	}

	async function changeStatus(e, i, data) {

		console.log(data);
		let _switch = switchCase;
		_switch[i] = e.target.checked;
		setSwitchCase(_switch);
		console.log(_switch, switchCase);
		let body = {
			status: e.target.checked,
		};
		let response = await _changeStatusOfSmsTemplate(body, data?.id)
		console.log(response)
		fetchData("added");
	}

	const handleDateChange = (data) => {
		if (data?.from == true) {
			const d = data?.date
			d.setHours(0, 0, 0)
			setStartDate(d.toISOString());
		}
		if (data?.to == true) {
			const d = data?.date
			d.setHours(23, 59, 59)
			setEndDate(d.toISOString());
		}
		let searchData = {
			startDate: startDate, endDate: endDate, input: input
		}
		setToggle(!toggle)
	};

	const eventSelectChange = (e) => {
		if (editDialog) {
			setEventUpdate([e.target.value])
		}
		else {
			setEvent(e.target.value)
			const index = eventArr.findIndex(el => el.key === e.target.value)
			const xVariables = eventArr[index]['variables']
			setTemplateVar(xVariables)
		}
	}

	const handleInputSearch = (e) => {

		let searchData = {
			startDate: startDate, endDate: endDate, input: e.target.value
		}
		setInputSearch(e.target.value)
		setToggle(!toggle)

	}

	const selectModuleAction = (e) => {
		console.log(e)
		const index = xEvents.findIndex(ev => ev.module === e.target.value)
		console.log(index)
		const events = xEvents[index]['events']
		setEventArr(events)
		// const casesArr = ["registration link"]
		// const paymentsAr = ["link", "bounced", "reject loan", "payment link"]
		// console.log(e)
		// console.log(e.target.value)
		setSelectedModule(e.target.value)
		setTemplateVar([])
		// if (e.target.value === 'cases') {
		//     setEventArr(casesArr)
		// }
		// else {
		//     setEventArr(paymentsAr)
		// }
	}

	const handleAddChip = async () => {
		const response = await getAllSmsChips()
		console.log(response)
		setAllSmsChips(response[0])
	}

	const dateFilterRemove = () => {
		setDateFilterApplied(false);
		var d = new Date();
		d.setFullYear(d.getFullYear() - 1);
		setStartDate(d.toISOString())
		setEndDate(new Date().toISOString())
	}


	return (
		<div className="h-full w-full overflow-auto py-4 px-8 ">
			<div className="flex items-center justify-between">
				<BreadCrumb data={sms} />
				<div className="flex items-center ">
					<div className="flex items-center">
						<InputLabel className='w-20' id="demo-simple-select-label">From</InputLabel>
						&nbsp;&nbsp;
						<DateInput from={true} white={true} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<InputLabel className='w-10' id="demo-simple-select-label">To</InputLabel>
						&nbsp;&nbsp;
						<DateInput to={true} white={true} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
						&nbsp;&nbsp;&nbsp;
						{dateFilterApplied ? <>
							<Tooltip title="Clear Date Filter">
								<IconButton aria-label="clear" onClick={dateFilterRemove}>
									<ClearIcon />
								</IconButton>
							</Tooltip>
						</> : null}
						&nbsp;&nbsp;
						<FormControl sx={{ width: "70%" }}>
							<TextField
								size="small"
								sx={{ backgroundColor: "white" }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon fontSize="sm" />
										</InputAdornment>
									),
								}}
								id="outlined-basic"
								placeholder="Search"
								variant="outlined"
								onChange={(e) => handleInputSearch(e)}
							/>
						</FormControl>
					</div>
				</div>
			</div>
			{dataSource === null ? <div className="h-full w-full items-center justify-center flex">
				<h1 className="h-60 w-60"> <CircularProgress /></h1>
			</div> : dataSource?.length > 0 ?
				(<div className="my-10" ref={inputRef}>
					<DynamicTable
						dataSource={dataSource}
						headCells={headCells}
						actionOnDelete={(e, data) => confirmDialogSms(e, data)}
						// actionOnChange={(e, i, data) => handleChange(e, i, data)}
						switchCase={true}
						actionOnEdit={(e, data) => openEditDialog(e, data)}
						status={(e, i, data) => changeStatus(e, i, data)}
						edit={smstemplateM?.update}
						delete={smstemplateM?.update}
						update={!smstemplateM?.update}
						// switchButton={true}
					/>
					<DialogueBox
						confirmDialog={confirmDialog}
						confirmDialogClose={() => setConfirmDialog(false)}
						confirmDelete={deleteSmsTemplate}
						message={'SMS Template'}
					/>
				</div>) : (<NodataInTable message="No SMS Template created yet.To create click on add icon." />)
			}

			{/* ADD SMS TEMPLATE DIALOG */}
			<Box sx={{ width: "100%" }}>
				<Dialog fullWidth={true} maxWidth="md" open={smsDialog}>
					<DialogTitle>
						{editDialog ? "UPDATE SMS" : "CREATE SMS"}
					</DialogTitle>
					<Divider />
					<DialogContent>
						{/* TITLE */}
						<div className='px-4 mb-4'>
							<FormControl fullWidth>
								<TextField
									value={smsTitle}
									onChange={(e) => setSmsTitle(e.target.value)}
									required={true}
									variant="outlined"
									label="SMS Title"
								/>
							</FormControl>
						</div>
						<div className='px-4 mb-4'>
							<FormControl fullWidth>
								<InputLabel required={true}>
									Select Section/Module
								</InputLabel>
								<Select
									required={true}
									label="Select Section/Module"
									value={selectedModule}
									onChange={selectModuleAction}>
									{xEvents?.map((_event, i) => {
										return (
											<MenuItem key={i} value={_event?.module}>
												{_event.title}
											</MenuItem>
										)
									})
									}
								</Select>
							</FormControl>
						</div>
						<div className='px-4 mb-4'>
							<FormControl fullWidth>
								<InputLabel required={true}>
									Event
								</InputLabel>
								<Select
									required={true}
									label="Event"
									value={editDialog ? eventUpdate : event}
									onChange={(e) => eventSelectChange(e)}>
									{eventArr?.map((item, i) => {
										return (<MenuItem key={i} value={item.key}>
											{item.event}
										</MenuItem>)
									})}
								</Select>
							</FormControl>
						</div>
						{templateVar?.length ?
							<div className='px-4 mb-4'>
								<FormControl fullWidth className='flex flex-row'>
									{templateVar?.map((item, index) =>
										<Chip
											key={index}
											label={item.var}
											variant="outlined"
											className='max-w-fit mr-3'
											onClick={(e) => handlOnChipClick(item)}
										/>
									)}
								</FormControl>
							</div> : null
						}
						<div className='px-4 mb-4'>
							<FormControl fullWidth>
								<TextField
									value={smsMessage}
									onChange={(e) => { handleMessageChange(e) }}
									variant="outlined"
									label="Message..."
								/>
							</FormControl>
						</div>
						<div className="my-2 px-4">
							<div className="mb-3">
								<p className="text-xs text-gray-600">SMS Template</p>
								<p className="text-sm text-slate-900">{dltTemplate || '--'}</p>
							</div>
							{/* <div>
								<p className="text-xs text-gray-600">SMS Gateway Template</p>
								<p className="text-sm text-slate-900">{textLocalTemplate}</p>
							</div> */}
						</div>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button sx={{ marginY: "10px" }} variant="outlined" onClick={closesmsDialog}>
							Cancel
						</Button>
						{!editDialog ?
							<Button onClick={addSmsTemplate} sx={{ marginY: "10px" }} variant="contained">
								Add SMS Template
							</Button> : <Button onClick={updateSMSTemplate} sx={{ marginY: "10px" }} variant="contained">
								Update SMS Template
							</Button>}
					</DialogActions>
				</Dialog>
			</Box>
			<div>
				<Button
					onClick={() => setSmsDialog(true)}
					variant="contained"
					disabled={smstemplateM?.write ? false : true}
					sx={{
						borderRadius: "50px",
						height: "60px",
						width: "60px",
						position: "fixed",
						bottom: "40px",
						right: "40px",
					}}
				>
					<AddIcon sx={{ fontSize: "30px" }} />
				</Button>
			</div>
			<AddSmsChipsDialog allChips={allSmsChips} addChipDialog={addChipDialog} handleAddChip={handleAddChip} closeAddSmsChipDialog={closeAddSmsChipDialog} />
			<CustomizedSnackbars
				message={snackBarOptions.message}
				open={snackBarOptions.show}
				close={() => {
					setSnackBarOptions({ ...snackBarOptions, show: false });
				}}
				hideDur={5000}
				severity={snackBarOptions.severity}
			/>
		</div >
	);
}
export default SmsTemplate;
