import { Button, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormLabel, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography, CircularProgress, Tooltip, IconButton } from "@mui/material";
import BreadCrumb from "../../Components/BreadCrumbs";
import DateSearchFilter from "../../Components/DateSearchFilter";
import DialogueBox from "../../Components/DialogueBox";
import DynamicTable from "../../Components/DynamicTable";
import NodataInTable from "../../Components/NoDataInTable";
import * as React from "react";
import { fetchCurrency, getAddons, httpGet, _addAddons, _deleteAddons, _updateAddons } from "../../Services/api-services";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomizedSnackbars from "../../Components/Snackbar";
import DateInput from "../../Components/DateInput";
import SearchIcon from "@mui/icons-material/Search";
import CONSTANTS from "../../constant.json";
import ClearIcon from '@mui/icons-material/Clear';
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import { updateLocalStorage } from "../../Services/services";

function AddOns() {

  const [allCurrencies, setAllCurrencies] = React.useState();
  const [addonsM, setAddonsM] = React.useState([]);
  const [addSubject, setAddSubject] = React.useState([]);
  const [allAddons, setAllAddons] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [addonsDialogue, setAddonsDialogue] = React.useState(false);
  const [addonCurrency, setAddonCurrency] = React.useState("");
  const [addonName, setAddonName] = React.useState("");
  const [addonPrice, setAddonPrice] = React.useState("");
  const [addonTax, setAddonTax] = React.useState();
  const [addAddonDetails, setAddAddonDetails] = React.useState([]);
  const [addonsDeletedialogue, setAddonsDeletedialogue] = React.useState(false);
  const [subIndexAddons, setSubIndexAddons] = React.useState(0);
  const [tableIndexAddons, setTableIndexaddons] = React.useState(0);
  const [addAddon, setAddAddon] = React.useState(false);
  const [updateAddon, setUpdateAddon] = React.useState(false);
  const [addOnDataSource, setAddOnDataSource] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false)
  var d = new Date('1/1/2021');
  // d.setMonth(d.getMonth() - 1);
  const [startDate, setStartDate] = React.useState(d.toISOString());
  const [endDate, setEndDate] = React.useState(new Date().toISOString());
  const [input, setInputSearch] = React.useState("")
  const [toggle, setToggle] = React.useState(false)
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  const [dateFilterApplied, setDateFilterApplied] = React.useState(false);

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const addonsArr = modules.find((m) => m?.name === "Addons");
    console.log(addonsArr)
    setAddonsM(addonsArr);
    fetchData();
    // eslint-disable-next-line
  }, [startDate, endDate]);
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [toggle]);
  // React.useEffect(() => {

  //   fetchData();
  //   // eslint-disable-next-line

  // }, [startDate, endDate, input]);
  async function fetchData() {
    try {
      let addons
      let data = {
        startDate: startDate,
        endDate: new Date().toISOString() || endDate,
        // endDate: endDate,
        input: input
      }
      console.log(data);
      addons = await getAddons(data);
      if (addons?.error?.statusCode === 401) {
        updateLocalStorage();
      }
      const currencies = await fetchCurrency();
      const _dataSource = await createTableData(addons);
      setAddOnDataSource(_dataSource);
      setAllCurrencies(currencies);
      setAllAddons(addons);
    } catch (err) {
      console.log(err);
    }
  }

  function clearAddonForm() {
    setAddonCurrency("");
    setAddonName("");
    setAddonPrice("");
    setAddAddonDetails([]);
    setAddAddon(false);
    setAddonTax("");
  }

  const _addonsDialogueOpen = (index) => {
    setSubIndexAddons(index);
    setAddonsDeletedialogue(true);
  };

  const fetchAddOns = async () => {
    const _addons = await getAddons();
    setAllAddons(_addons);
    const _dataSource = await createTableData(_addons);
    setAddOnDataSource(_dataSource);
  };

  function _deleteAddonsFromArray() {
    const _subject = addAddonDetails;
    _subject.splice(subIndexAddons, 1);
    setAddAddonDetails(_subject);
    CloseAddonsDeleteDialogue();
  }

  const verifyDBduplicacy = async () => {
    const url = `${CONSTANTS.baseUrl}/addOns?filter={"where":{"name":{"regexp":"/${addonName}/i"},"currency":"${addonCurrency}","deleted":false}}`;
    const checkAdd_on = await httpGet(url);
    console.log(checkAdd_on)
    return (checkAdd_on?.length > 0) ? true : false
  }

  const _addaddons = async () => {
    // CHECKS THE AVAILABILITY OF THE ADDON IN ARRAY
    console.log()
    if (addonName && addonPrice && addonTax && addonPrice !== "0") {
      let exists = false;
      exists = addonsExist(addonName);
      console.log(exists)
      if (!exists) {
        exists = await verifyDBduplicacy()
      }
      if (!exists) {
        const _addOn = {
          name: addonName,
          price: parseFloat(addonPrice),
          currency: addonCurrency,
          tax: parseFloat(addonTax)
        };
        let _arr = addAddonDetails;
        _arr.push(_addOn);
        setAddAddonDetails(_arr);
        setAddonName("");
        setAddonPrice("");
        setAddonTax('')
      } else {
        // ALREADY ADDED ADDON NAME
        setSnackBarOptions({
          message: "Addon name already exists",
          show: true,
          severity: "error",
          duration: 5000,
        });
      }
    } else {
      // SHOW ERROR IN TOAST FOR ADDON NAME AND PRICE
    }
  };

  const addonsExist = () => {
    // VERIFIES THE EXISTENCE OF SUBJECT IN addSubject ARRAY
    let found = false;
    if (addAddonDetails.length === 0) return false;
    for (let index = 0; index < addAddonDetails.length; index++) {
      const _subject = addAddonDetails[index];
      console.log(_subject)
      if (
        _subject.name &&
        _subject.name.toLowerCase() === addonName.toLowerCase()
        && !_subject.deleted
      ) {
        found = true;
        return found;
      }
      if (index === addAddonDetails.length - 1) {
        // LAST ITERATION
        return found;
      }
    }
  };

  async function _addAddon() {
    setIsLoading(true)
    if (addonCurrency && addAddonDetails.length > 0) {
      let obj = addAddonDetails;
      try {
        const addons = await _addAddons(obj);
        setIsLoading(false)
        fetchAddOns();
        setSnackBarOptions({
          message: "Addons added successfully!",
          show: true,
          severity: "success",
          duration: 5000,
        });
        clearAddonForm();
        setAddonsDialogue(false);
      } catch (err) {
        console.log(err);
      }
      // setCourseDialogue(false);
      // clearCourseForm();
    } else {
      setAddAddon(true);
    }
  }

  function _editAddons(index) {
    setSubIndexAddons(index);
    const _addons = addAddonDetails[subIndexAddons];
    setAddonName(_addons.name);
    setAddonPrice(_addons.price);
    setAddonTax(_addons.tax);
    _deleteAddonsFromArray();
  }

  function updateAddons() {
    setIsLoading(true)
    const _addOn = {
      name: addonName,
      price: parseFloat(addonPrice),
      currency: addonCurrency,
      tax: addonTax
    };
    _updateAddons(_addOn, tableIndexAddons).then((res) => {
      setIsLoading(false)
      fetchAddOns();
    });

    _closeAddonsDialogue();
  }

  function deleteAddons() {
    const body = {
      deleted: true,
    };
    _deleteAddons(body, tableIndexAddons).then((res) => {
      fetchAddOns();
    });
    setConfirmDialog(false);
  }

  const _openAddonsDialogue = (e, data) => {
    setUpdateAddon(true);
    setAddonName(data.name);
    setAddonPrice(data.addonPrice);
    setAddonTax(data.tax.props.children[0]);
    setAddonCurrency(data.curencyId);
    setAddonsDialogue(true);
    setTableIndexaddons(data.id);
  };

  const _closeAddonsDialogue = () => {
    clearAddonForm();
    setAddonsDialogue(false);
    setUpdateAddon(false);

  };

  function CloseAddonsDeleteDialogue() {
    setAddonsDeletedialogue(false);
    setUpdateAddon(false);
  }

  function _openConfirmDialog(e, data) {
    setTableIndexaddons(data.id);
    setConfirmDialog(true);
  }

  const headCells = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "S.no",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Addons's Name",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Price",
    },
    {
      id: "tax",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Tax",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Created On",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      sort: false,
      label: "Action",
    },
  ];
  if (addonsM?.update !== true) {
    headCells.pop()
  }

  const createTableData = (data) => {
    // CREATE TABLE DATA - ADDONS
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const row = data[index];
          const obj = {
            sno: index + 1,
            id: row.id,
            name: row.name,
            price: `${row.country.currencyName} ${row.price}`,
            createdAt: row.createdAt,
            action: true,
            tax: <span>{row.tax}%</span>,
            currency: row.country.currencyName,
            curencyId: row.currency,
            addonPrice: row.price,
          };
          returnArray.push(obj);
          if (index === data.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };

  const addons = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      Master
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Addons
    </Typography>,
  ];
  const handleDateChange = (data) => {
    if (data?.from == true) {
      const d = data?.date
      d.setHours(0, 0, 0);
      setStartDate(d.toISOString());
    }
    if (data?.to == true) {
      const d = data?.date
      d.setHours(23, 59, 59);
      setEndDate(d.toISOString());
    }
    let searchData = {
      startDate: startDate, endDate: endDate, input: input
    }
    setToggle(!toggle)
  };
  const handleInputSearch = (e) => {

    let searchData = {
      startDate: startDate, endDate: endDate, input: e.target.value
    }
    setInputSearch(e.target.value)
    setToggle(!toggle)

  }

  const dateFilterRemove = () => {
    setDateFilterApplied(false);
    var d = new Date('1/1/2021');
    // d.setMonth(d.getMonth() - 1);
    setStartDate(d.toISOString())
    setEndDate(new Date().toISOString())
  }

  return (
    <div className="h-full w-full overflow-auto py-4 px-8 ">
      <div className="flex items-center justify-between">
        <BreadCrumb data={addons} />
        <div className="flex items-center">
          <InputLabel className='w-20' id="demo-simple-select-label">From</InputLabel>
          &nbsp;&nbsp;
          <DateInput from={true} white={true} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <InputLabel className='w-10' id="demo-simple-select-label">To</InputLabel>
          &nbsp;&nbsp;
          <DateInput to={true} white={true} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
          &nbsp;&nbsp;&nbsp;
          {dateFilterApplied ? <>
            <Tooltip title="Clear Date Filter">
              <IconButton aria-label="clear" onClick={dateFilterRemove}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </> : null}
          &nbsp;&nbsp;
          <FormControl sx={{ width: "70%" }}>
            <TextField
              size="small"
              sx={{ backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="sm" />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
              placeholder="Search"
              variant="outlined"
              onChange={(e) => handleInputSearch(e)}
            />
          </FormControl>
        </div>
      </div>
      {addOnDataSource === null ? <div className="h-full w-full items-center justify-center flex">
        <h1 className="h-60 w-60"> <CircularProgress /></h1>
      </div> : addOnDataSource?.length > 0 ? (
        <div className="my-10">
          <DynamicTable
            dataSource={addOnDataSource}
            headCells={headCells}
            actionOnDelete={(e, data) => _openConfirmDialog(e, data)}
            actionOnEdit={(e, data) => _openAddonsDialogue(e, data)}
            pagination={true}
            edit={false}
            delete={addonsM?.update || addonsM?.write}
          />
          <DialogueBox
            confirmDialog={confirmDialog}
            confirmDialogClose={() => setConfirmDialog(false)}
            confirmDelete={deleteAddons}
            message={'Addons'}
          />
        </div>
      ) : (
        <NodataInTable message="No addons added yet.To add addon click on add icon." />
      )}
      <div style={{ display: addonsM?.write ? "block" : "none" }}>
        <Button
          onClick={() => setAddonsDialogue(true)}
          variant="contained"
          disabled={addonsM?.write ? false : true}
          sx={{
            borderRadius: "50px",
            height: "60px",
            width: "60px",
            position: "fixed",
            bottom: "40px",
            right: "40px",
          }}
        >
          <AddIcon sx={{ fontSize: "30px" }} />
        </Button>
      </div>
      <div>
        <Dialog fullWidth={true} maxWidth="lg" open={addonsDialogue}>
          {updateAddon ? <DialogTitle>Update ADDON</DialogTitle> :
            <DialogTitle>ADD ADDON</DialogTitle>}
          <Divider />
          <DialogContent>
            <div className="px-7">
              <FormControl required fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Currency
                </InputLabel>
                <Select
                  error={
                    addAddon && addonCurrency.length < 1 ? true : false
                  }
                  labelId="demo"
                  id="demo"
                  value={addonCurrency}
                  disabled={addonCurrency !== ''}
                  label="Select Currency"
                  onChange={(e) => setAddonCurrency(e.target.value)}
                >
                  {allCurrencies?.map((_currency, i) => {
                    return (
                      <MenuItem key={i} value={_currency.id}>
                        {_currency.currencyName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <div className="mt-10">
                <FormControl fullWidth>
                  <fieldset className="border-2">
                    <legend className="ml-7 mb-2 p-2 bg-gray-100 w-44 h-10">
                      Addon's Details
                    </legend>
                    <div>
                      {addAddonDetails?.length > 0 ? (
                        <List
                          sx={{
                            borderWidth: 1,
                            borderColor: "#eee",
                            width: "100%",
                            bgcolor: "rgb(243 244 246)",
                          }}
                        >
                          {addAddonDetails?.length > 0 &&
                            addAddonDetails?.map((x, index) => {
                              return (
                                <div key={index}>
                                  <ListItem
                                    key={index.toString()}
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <ListItemText sx={{ width: "100%" }}>
                                      {" "}
                                      <CheckCircleIcon
                                        color="success"
                                        fontSize="medium"
                                      />
                                      &nbsp;{x.name}&nbsp;&nbsp; {x.price}&nbsp;&nbsp;{x.tax}%
                                    </ListItemText>
                                    <ListItemText>
                                      <div className="flex">
                                        <Button
                                          onClick={() =>
                                            _editAddons(index)
                                          }
                                          size="small"
                                          sx={{ minWidth: "0px" }}
                                          variant="text"
                                        >
                                          <EditIcon fontSize="small" />
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            _addonsDialogueOpen(index)
                                          }
                                          sx={{ minWidth: "0px" }}
                                          variant="text"
                                        >
                                          <DeleteIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </Button>
                                      </div>
                                    </ListItemText>
                                  </ListItem>
                                  {index < addSubject.length - 1 ? (
                                    <Divider />
                                  ) : null}
                                </div>
                              );
                            })}
                        </List>
                      ) : null}
                      <DialogueBox
                        confirmDialog={addonsDeletedialogue}
                        confirmDialogClose={CloseAddonsDeleteDialogue}
                        confirmDelete={_deleteAddonsFromArray}
                      />
                    </div>
                    <div className="p-5 flex">
                      <FormControl fullWidth>
                        <FormLabel required>Addon Title / Name</FormLabel>
                        <TextField
                          value={addonName}
                          onChange={(e) => setAddonName(e.target.value)}
                          required={true}
                          id="outlined-basic"
                          variant="outlined"
                        />
                      </FormControl>
                      &nbsp;&nbsp;
                      <FormControl fullWidth>
                        <FormLabel required>Price</FormLabel>
                        <TextField
                          value={addonPrice}
                          onChange={(e) => setAddonPrice(e.target.value)}
                          required={true}
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                      &nbsp;&nbsp;
                      <FormControl fullWidth>
                        <FormLabel required>Tax(%)</FormLabel>
                        <TextField
                          value={addonTax}
                          onChange={(e) => setAddonTax(e.target.value)}
                          required={true}
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    </div>
                    {updateAddon === false ? (
                      <div className="text-right">
                        <Button disabled={addonCurrency === ''} onClick={_addaddons} variant="text">
                          <b>
                            add{" "}
                            <ArrowForwardIosIcon
                              sx={{ fontSize: "12px" }}
                            />
                          </b>
                        </Button>
                      </div>
                    ) : null}
                  </fieldset>
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              sx={{ marginY: "10px" }}
              variant="outlined"
              onClick={_closeAddonsDialogue}
            >
              Cancel
            </Button>
            {updateAddon === false ? (
              <Button
                sx={{ marginY: "10px" }}
                variant="contained"
                onClick={_addAddon}
                disabled={addAddonDetails?.length < 1 || addonCurrency === "" ? true : false}
              >
                {isLoading ? <CircularProgress size={16} /> :
                  "Add Addon"
                }
              </Button>
            ) : (
              <Button
                onClick={updateAddons}
                sx={{ marginY: "10px" }}
                variant="contained"
              >
                {isLoading ? <CircularProgress size={16} /> :
                  " Update Addon"
                }
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  )
}
export default AddOns