import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button, Divider, Switch, Tooltip,Dialog,DialogActions } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CONSTANTS from "../constant.json";
import {
  getGST,
  getTags,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  _updateAddmissionProcess,
} from "../Services/api-services";
import CustomizedSnackbars from "../Components/Snackbar";
import jsPDF from "jspdf";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import moment from "moment";

function InvoiceDialog(props) {
    
    let {gst, courseTotal, addonsTaxPrice, addonsTotal, requestDiscountTotalPrice} =props;
    let user = props?.props?.fetchCase?.case;
    const componentRef = React.useRef();
    const [invoiceNumber, setInvoiceNumber] = React.useState('');
    const [receiptNumber, setReceiptNumber] = React.useState('');
    const [paymentDate, setPaymentDate] = React.useState('');
    // props?.props={...props?.props.props?.props};

    React.useEffect(()=>{
        
        if(props?.invoiceTitle=="all"){
          fetchInvoice()
        }
        else{
          fetchReceiptNumber()
        }
 
    },[props?.props.invoiceCaseDialog])

    const fetchInvoice = async ()=>{

      const url = `${CONSTANTS.baseUrl}/subscribedCourses/${props?.props?.currentCourse?._id}?filter={"fields":{"invoiceNumber":"true","refundInvoiceNumber":"true"}}`;
      let invoiceNum = await httpGet(url);
      if(invoiceNum?.refundInvoiceNumber){
        setInvoiceNumber(invoiceNum?.refundInvoiceNumber)
      }else{
        if(invoiceNum?.receiptNumber){
          setPaymentDate()
        }else{
          
        }
      }
    }

    const fetchReceiptNumber = async ()=>{

      const url = `${CONSTANTS.baseUrl}/paymentSchedules/${props?.installment?.id}?filter={"fields":{"receiptNumber":"true", "updatedAt": "true"}}`;
      let url2  = `${CONSTANTS.baseUrl}/settings?filter={"where":{"type":"receipt"},"limit":"1"}`
      
      let ReceiptNum = await httpGet(url);
      let receiptNum = await httpGet(url2);
      if(Object.keys(ReceiptNum).length === 0){
        // setReceiptNumber(0);
        let settingReceipt,receipt;
        if(receiptNum.length==0){
          let obj = {type:"receipt",data:0};
          settingReceipt=await httpPost(`${CONSTANTS.baseUrl}/settings`,obj);
          receipt = Math.floor(new Date().getTime()/1000) + "" + 0
          setReceiptNumber(receipt)
          await httpPatch(`${CONSTANTS.baseUrl}/paymentSchedules/${props?.installment?.id}`,{receiptNumber:receipt})
        }else{
          let receiptNumberChange = parseInt(parseInt(receiptNum[0].data)+1);
          receipt = Math.floor(new Date().getTime()/1000) + "" + receiptNumberChange
          setReceiptNumber(receipt)
          let obj = {type:"receipt",data:receiptNumberChange};
          settingReceipt=await httpPatch(`${CONSTANTS.baseUrl}/settings/${receiptNum[0].id}`,obj);
          await httpPatch(`${CONSTANTS.baseUrl}/paymentSchedules/${props?.installment?.id}`,{receiptNumber:receipt})
        }
        
        
      }else{
        let nextReceiptNum =  ReceiptNum?.receiptNumber;
        setReceiptNumber(nextReceiptNum)
        setPaymentDate(ReceiptNum?.updatedAt)
      }
    }

    async function printDocument() {
        const input = document.getElementById('divToPrint');
        htmlToImage.toPng(input,{ quality: 0.95 })
          .then(async(dataUrl) => {
            var link = document.createElement('a');
              link.download = 'my-image-name.jpeg';
              const pdf = new jsPDF();
              const imgprops= pdf.getImageProperties(dataUrl);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (imgprops.height * pdfWidth) / imgprops.width;
              pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);
              pdf.save("download.pdf"); 
                // if(props?.invoiceTitle=="all"){
                //   await updateInvoice()
                // }else{
                //   await updateReceipt()
                // }
                props.closeInvoice();
          })
      }

      async function updateInvoice(){
        const url = `${CONSTANTS.baseUrl}/cases/${props?.props?.caseUser?.id}`;
        let updateInvo = await httpPatch(url,{"invoiceNumber":invoiceNumber+1})
      }

      async function updateReceipt(){
        const url = `${CONSTANTS.baseUrl}/subscribedCourses/${props?.props?.currentCourse?._id}`;
        let updateInvo = await httpPatch(url,{"receiptNumber":receiptNumber+1})
        onCancelAction()
      }

    const onCancelAction = ()=>{
        props.closeInvoice()
    }

    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });

    return(
        <>
        <Dialog fullWidth={true} fullScreen open={props?.props.invoiceCaseDialog} >
          
            <div className="w-100" id="divToPrint">
              {props?.invoiceTitle=='all'?<>
                <div className="mb-10"></div>
                <div className="mx-20 mb-20" >
            
                <div className="flex justify-between items-center mb-3">
                    <div className="text-3xl">
                        INVOICE
                    </div>
                    <div className="text-3xl">
                        APT
                    </div>
                </div>
                <div className="bg-slate-100 p-5">
                    <div className="flex justify-between">
                        <div>
                            <div className="text-slate-500 pb-3">
                                To
                            </div>
                            <div>
                                <div className="text-lg ">
                                    {user?.firstName} {user?.middleName}{" "}
                                    {user?.lastName}
                                </div>
                                <div>
                                    <div>
                                        {user?.streetAddress}
                                    </div>
                                    <div>
                                        {user?.city}, {user?.state}, {user?.country}
                                    </div>
                                    <div>
                                        {user?.mobile}
                                    </div>
                                    <div>
                                        {user?.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-slate-500 text-sm">Invoice no.</div>
                            <div className="mb-2">{`${props?.props?.caseUser?.mobile}I${invoiceNumber}`}</div>
                            <div className="text-slate-500 text-sm">Date</div>
                            <div className="mb-2">{moment(new Date()).format('DD/MM/YYYY')}</div>
                            <div className="text-slate-500 text-sm">Due Fee</div>
                            <div className="mb-2">{props?.props?.dueFee?.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            
            <div className=" border-gray-200 border-2 my-7 w-100" >
        <div className="flex justify-between items-center bg-gray-50 px-4 py-2 text-black text-lg">
          <h1 className="w-16">Course</h1>
          {props?.props?.fetchCase?.case?.state === "Madhya Pradesh" ? (
            <div>
              <h1 className="w-44 px-16">Taxes</h1>
              <Divider />
              <div className="flex justify-between w-44">
                <h1>SGST</h1>
                <h1>CGST</h1>
              </div>
            </div>
          ) : (
            <h1 className="w-16">IGST</h1>
          )}
          <h1 className="w-36">Price</h1>
        </div>

        <div className="flex justify-between items-center px-4 py-2 text-gray-500">
          <h1 className="w-16">Course</h1>
          {props?.props?.fetchCase?.case?.state === "Madhya Pradesh" ? (
            <div>
              <div className="flex justify-between w-44">
                <h1>{gst / 2}%</h1>
                <h1 className="w-11">{gst / 2}%</h1>
              </div>
            </div>
          ) : (
            <h1 className="w-16">{gst}%</h1>
          )}
          <h1 className="w-36">
            {props?.props?.currency} {props?.props?.currentCourse?.courses?.price?.toFixed(2)}
          </h1>
        </div>
        <div className="flex items-center justify-between bg-gray-50 px-4 py-2 text-black text-lg">
          <h1 className="">Subjects</h1>
          <div className="flex items-center justify-between">
            <div className="w-40">
              <h1 className="">Cost Price</h1>
            </div>
            <div className="w-36">
              <h1 className="">Price</h1>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between py-2 px-4 text-gray-500">
          <div>
            {props?.props?.currentCourse?.subjects?.map((sub, i) => {
              return (
                <p className="mt-2" key={i}>
                  {sub?.name}
                </p>
              );
            })}
          </div>
          <div className="flex items-center justify-between">
            <div className="w-40">
              {props?.props?.currentCourse?.subjects?.map((sub, i) => {
                return (
                  <p className="mt-2" key={i}>
                    {props?.props?.currency} {sub?.price?.toFixed(2)}
                  </p>
                );
              })}
            </div>
            <div className="w-36">
              {props?.props?.currentCourse?.subjects?.map((sub, i) => {
                return (
                  <p className="mt-2" key={i}>
                    {props?.props?.currency}{" "}
                    {(sub?.price - (sub?.price * gst) / 100)?.toFixed(2)}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex bg-gray-50 px-4 py-2 justify-between items-center text-lg text-black">
          <h1>Addons</h1>
          <h1 className="w-36">Price</h1>
        </div>
        <div className="flex justify-between items-center px-4 py-2 text-gray-500">
          <div>
            {props?.props?.currentCourse?.addOns?.map((add, i) => (
              <p
                key={i}
                className="w-30 text-ellipsis overflow-hidden whitespace-nowrap"
              >
                {add.name}
              </p>
            ))}
          </div>
          <div>
            {props?.props?.currentCourse?.addOns?.map((add, i) =>
              props?.props?.fetchCase?.case?.state === "Madhya Pradesh" ? (
                <div key={i} className="flex justify-between w-48">
                  <h1>{(add?.tax / 2).toFixed(2)}%</h1>
                  <h1 className="w-16">&nbsp;{(add?.tax / 2)?.toFixed(2)}%</h1>
                </div>
              ) : (
                <h1 key={i} className="w-20">
                  {add?.tax?.toFixed(2)}%
                </h1>
              )
            )}
          </div>
          <div>
            {props?.props?.currentCourse?.addOns?.map((add, i) => (
              <h1 key={i} className="w-36">
                {props?.props?.currency} {add.price?.toFixed(2)}
              </h1>
            ))}
          </div>
        </div>
        <div className="bg-gray-50 py-2 px-4 text-black text-lg">
          <h1>Description</h1>
        </div>
        <div className="flex justify-between items-center px-4 py-2 text-gray-500">
          <h1 className="w-16">Course</h1>
          <div>
            {props?.props?.fetchCase?.case?.state === "Madhya Pradesh" ? (
              <div className="flex justify-between w-44">
                <h1>
                  {((props?.props?.currentCourse?.totalFee * gst) / 2 / 100)?.toFixed(
                    2
                  )}
                </h1>
                <h1 className="w-11">
                  {((props?.props?.currentCourse?.totalFee * gst) / 2 / 100)?.toFixed(
                    2
                  )}
                </h1>
              </div>
            ) : (
              <h1 className="w-16">
                {((props?.props?.currentCourse?.totalFee * gst) / 100)?.toFixed(2)}
              </h1>
            )}
          </div>
          <h1 className="w-36">
            {props?.props?.currency} {courseTotal?.toFixed(2)}
          </h1>
        </div>
        <div className="flex justify-between items-center px-4 py-2 text-gray-500">
          <h1 className="w-16">Addons</h1>
          {props?.props?.fetchCase?.case?.state === "Madhya Pradesh" ? (
            <div className="flex justify-between w-44">
              <h1>{(addonsTaxPrice / 2).toFixed(2)}</h1>
              <h1 className="w-11">{(addonsTaxPrice / 2)?.toFixed(2)}</h1>
            </div>
          ) : (
            <h1 className="w-16">{addonsTaxPrice?.toFixed(2)}</h1>
          )}
          <h1 className="w-36">
            {props?.props?.currency} {addonsTotal?.toFixed(2)}
          </h1>
        </div>
        <div className="flex px-4 py-2 justify-between items-center text-black bg-gray-50 ">
          <h1 className="text-lg">Subtotal</h1>
          <h1 className="w-36">
            {props?.props?.currency} {(courseTotal + addonsTotal)?.toFixed(2)}
          </h1>
        </div>
        <div className="flex px-4 py-2 justify-between items-center text-gray-500">
          <h1>Student Discount</h1>
          <h1 className="w-36">
            {props?.props?.currency}{" "}
            {(props?.props?.currentCourse?.discount === null
              ? 0 + requestDiscountTotalPrice
              : props?.props?.currentCourse?.discount + requestDiscountTotalPrice
            )?.toFixed(2)}
          </h1>
        </div>
        <div className="flex px-4 py-2 justify-between items-center text-gray-500">
          <h1>Bundle Discount</h1>
          <h1 className="w-36">
            {props?.props?.currency}{" "}
            {(props?.props?.currentCourse?.courses?.price === courseTotal
              ? props?.props?.currentCourse?.courses?.bundleDiscount
              : 0
            )?.toFixed(2)}
          </h1>
        </div>
        <div className="flex bg-gray-50 px-4 py-4 justify-between items-center text-lg text-black">
          <h1>Total</h1>
          <div className="flex items-center w-44">
            <h1 className="">
              {props?.props?.currency} {props?.props?.currentCourse?.totalFee?.toFixed(2)}
            </h1>
            &nbsp;&nbsp;
            
          </div>
        </div>
        </div>
        <div className="mb-10">
                <div className="text-slate-500">Contact us</div>
                <div>APT, 161/1, Above Indian Bank,</div>
                <div>Near Vikramadiya college,MP Nagar – Zone II,</div> 
                <div>Bhopal, Madhya Pradesh 462011 </div>
                <div>+91 - 9898989000</div> 
                <div>contact@aptstudy.in</div>
            </div>
            
        </div>
        </>:null}
        {(props?.invoiceTitle=='download')?<>
              {/* {showReceipt()} */}
              <ComponentToPrint  props={{...props}} user={user} installment={props?.installment} receiptNumber={receiptNumber} paymentDate={paymentDate}/>
        </>:null}
        {(props?.invoiceTitle=='print')?<>
              {/* {showReceipt()} */}
          
      <ComponentToPrint ref={componentRef} props={{...props}} user={user} installment={props?.installment} receiptNumber={receiptNumber} paymentDate={paymentDate}/>
          <div className="flex justify-end items-center my-2 mx-20">
              <Button
                    sx={{ marginY: "10px", marginRight:'20px' }}
                    variant="contained"
                    onClick={handlePrint}>
                      Print
                </Button>
                <Button
                    sx={{ marginY: "10px" }}
                    variant="outlined"
                    onClick={onCancelAction}
                >
                    Cancel
                </Button>
          </div>
      
        </>:null}
        </div>
        {(props?.invoiceTitle=='all' || props?.invoiceTitle=='download')?<>
        <DialogActions className="flex justify-end items-center my-2 mx-20">
                <Button
                    sx={{ marginY: "10px" }}
                    variant="outlined"
                    onClick={onCancelAction}
                >
                    Cancel
                </Button>

                <Button
                    sx={{ marginY: "10px" }}
                    variant="contained"
                    onClick={()=>{printDocument()}}
                >
                    Export
                </Button>
            </DialogActions>
            </>:null}
        </Dialog>
        </>
    )

}

export default InvoiceDialog;

export const ComponentToPrint =  React.forwardRef((props, ref) => {

  const {user} = props;

  React.useEffect(()=>{
  
  },[0])

  const handleTx = ()=>{
    let obj={},totalAmount,tax,courseAmount;
    let addon = props?.props?.currentCourse?.addOns?.length>0?true:false
    totalAmount = parseFloat(props?.installment?.amount.split(' ')[1]);
    let currency = props?.installment?.amount.split(' ')[0]
    
    if(addon){
      tax=(36/100)* totalAmount;
      courseAmount = totalAmount-tax;
      obj={tax:tax,courseAmount:courseAmount, currency:currency}
      
      return obj;
    }else{
      tax=(18/100)* totalAmount;
      courseAmount = totalAmount-tax;
      obj={tax:tax,courseAmount:courseAmount,currency:currency}
      
      return obj;
    }
    
  }

  const showReceipt = (ref)=>{
    return(
      <>
        <div ref={ref} className="w-100">
        <div className="bgColor px-20 py-5 flex justify-between items-center" >
              <div>
                <div className="mb-3">Receipt No</div>
                <div className="text-2xl">#{props?.receiptNumber}</div>
              </div>
              <div className="text-6xl">
                APT
              </div>
            </div>
            <div className="flex justify-between px-20 py-5 text-xl">
              <div>
                <div className="text-slate-500 mb-5">Billed To</div>
                <div className="text-3xl font-bold">{user?.firstName} {user?.middleName}{" "}
                      {user?.lastName}
                </div>
                <div>{user?.email}</div>
                <div>{user?.mobile}</div>
              </div>
              <div>
                <div className="text-slate-500">Date</div>
                <div className="text-2xl font-bold">{moment(props?.paymentDate).format('DD/MM/YYYY')}</div>
              </div>
            </div>
            <div className="px-20  py-3 text-xl">
              <div className="text-slate-500 mb-1 text-2xl">
                Course
              </div>
              <div className="">
                <span className="font-bold mr-2">{props?.props?.props?.currentCourse?.courseName}</span>
                <span>{`(Installment ${props?.installment?.i+1})`}</span>
              </div>
              <div className="pb-4">
                <span className=" text-slate-500 mr-2 font-medium text-lg">{props?.props?.props?.currentCourse?.batch?.batchName}</span>
                <span className=" text-slate-500 mr-2 font-medium text-lg">{`( ${moment(props?.props?.props?.currentCourse?.batch?.batchStartDate).format('DD/MM/YYYY')} )`}</span>
              </div>
              <br/>
              <div className="text-slate-500 text-2xl">Payment Information</div>
              <div className="flex justify-between align-items mb-1 ">
                <div>Payment Mode</div>
                <div>{props?.installment?.mode}</div>
              </div>
              <div className="flex justify-between align-items mb-1">
                <div>Payment Status</div>
                <div>{props?.installment?.stats}</div>
              </div>
            </div>
            <div className="px-20  py-3">
              <div className="flex justify-between items-center">
                <div className="text-slate-500 text-xl">SubTotal</div>
                <div className="text-xl">{handleTx()?.currency} {handleTx()?.courseAmount}</div>
              </div>
              <div className="flex justify-between items-center">
                <div className="text-slate-500 text-xl">Tax</div>
                <div className="text-xl">{handleTx()?.currency} {handleTx()?.tax}</div>
              </div>
              <div style={{borderTop:'2px solid gray'}} className="my-2"></div>
              <div className="flex justify-between items-center">
              <div className="text-slate-500 text-2xl">Total Amount</div>
                <div className="text-3xl font-bold"> {props?.installment?.amount}</div>
              </div>
            </div>
          </div>  
      </>
    )
  }

  return(
    <>
      {showReceipt(ref)}
    </>

  )

})
