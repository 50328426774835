
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BreadCrumb from '../../Components/BreadCrumbs';
import { Switch } from '@mui/material';
import AddField from '../../Popups/AddField';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { capitalizeFirstLetter, get, patch, post } from '../../Services/services';
import { data } from 'autoprefixer';
import CustomizedSnackbars from '../../Components/Snackbar';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
export default function FieldSetting() {
  const [title, setTitle] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [url, setUrl] = React.useState();
  const [field, setField] = React.useState([]);
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const [access, setAccess] = React.useState();

  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  const courses = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      Master
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Field Setting
    </Typography>,
  ];
  const updateTilte = async () => {

    const res = await patch(`fields/${url._id}`, { title: title, })
    if (res.statusCode === 200) {
      setSnackBarOptions({
        message: " field successfully updated",
        duration: 5000,
        show: true,
        severity: "success",
      });
      getAllField();
      setOpen(false)
      setUrl(null)

    } else {
      setSnackBarOptions({
        message: "something went wrong try again later",
        duration: 5000,
        show: true,
        severity: "error",
      });
    }
  }
  const createTitle = async () => {

    const res = await post('fields', { title: title, fieldType: url.id })
    if (res.statusCode === 200) {
      setSnackBarOptions({
        message: "New field successfully added",
        duration: 5000,
        show: true,
        severity: "success",
      });
      getAllField();
      setOpen(false)
      setUrl(null)

    }
    else if (res.statusCode === 422) {
      setSnackBarOptions({
        message: `Title "${title}" should be unique`,
        duration: 5000,
        show: true,
        severity: "error",
      });
    }
    else {
      setSnackBarOptions({
        message: "something went wrong try again later",
        duration: 5000,
        show: true,
        severity: "error",
      });
    }

  }

  const getAllField = async () => {
    const res = await post("fieldTypes/getAllFieldTypeWithField", {})
    setField(res.data);
  }

  const updateStatus = async (data) => {
    const res = await patch(`fields/${data._id}`, { status: data?.status ? false : true });
    getAllField();
  }
  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const rolesArr = modules?.find((m) => m?.name === "Fields");
    setAccess(rolesArr);
    getAllField();
  }, [])

  // function capitalizeFirstLetter(str) {
  //   // Check if the input string is not empty
  //   if (str.length === 0) {
  //     return str;
  //   }

  //   // Capitalize the first letter and concatenate it with the rest of the string
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // }

  return (
    <div>
      <div className=" py-4 px-8  ">
        <BreadCrumb data={courses} />
      </div>



      <div className='px-6'>
        {field?.map((item) =>
          <Accordion Accordion >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className='flex justify-between w-full pr-3'>

                <Typography>{capitalizeFirstLetter(item.title)} {item?.title === 'reference' ? null : '*'}</Typography>
                <div className='flex gap-3 items-center'>

                  <AddCircleOutlineIcon
                    className={access?.write ? 'text-[#2B44FF] cursor-pointer' : "hidden"}
                    variant="outlined"
                    onClick={(event) => {

                      if (access?.write) {
                        event.stopPropagation();
                        setEdit(false)
                        setTitle(null)
                        setOpen(true);
                        setUrl(item)
                      }

                    }} />

                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='pl-5' >

                {item?.field?.map((items) =>
                  <div className='flex  justify-between '>
                    <div className='flex gap-1 items-center'>
                      {/* {items.ultimate ? (
                        <CheckCircleIcon sx={{ color: items.color === "red" ? "#BF5959" : items.color === 'green' ? '#73A870' : '#EFD520' }} />
                      ) :
                        (<CircleIcon sx={{ color: items.color === "red" ? "#BF5959" : items.color === 'green' ? '#73A870' : '#EFD520' }} />)} */}

                      <Typography >
                        {items.title}
                      </Typography>
                    </div>

                    <div className='flex gap-5 items-center' >
                      <Typography className='text-slate-500' sx={{ fontWeight: 300, fontSize: 12, }}>
                        {/* {items.createdOn?.slice(8, 10) + items.createdOn?.slice(4, 8) + items.createdOn?.slice(0, 4)} */}
                      </Typography>
                      <EditIcon
                        className={!(access?.write || access?.update) || items._id === "66054b280ec67f1d01252713" ? '' : 'text-[#2B44FF] cursor-pointer'}
                        variant="outlined"
                        onClick={(event) => {
                          if (access?.write || access?.update) {
                            if (items._id === "66054b280ec67f1d01252713") {

                            } else {

                              event.stopPropagation();
                              setEdit(true)
                              setTitle(items.title)
                              setOpen(true);
                              setUrl(items)
                            }
                          }
                        }} />


                      <Switch disabled={!(access?.write || access?.update)} checked={items.status} onClick={() => updateStatus(items)} />
                    </div>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <AddField
        setOpen={setOpen}
        open={open}
        title={title}
        setTitle={setTitle}
        edit={edit}
        setEdit={setEdit}
        updateTilte={updateTilte}
        createTitle={createTitle}
        url={url}
        setUrl={setUrl}
      />
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}