import React from 'react'
import { Outlet } from 'react-router-dom'

export default function RolesHome() {
    return (
     <div  className='p-4 flex flex-1'>
        <Outlet/>
     </div>
    )
}
