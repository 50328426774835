import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Checkbox, Fab, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StringWithSpace, emailRegex, phoneNumberRegexAed, phoneNumberRegexIndia } from '../../Regex/Regex';
import { baseUrl, get, patch, post } from '../../Services/services';
import EditIcon from '@mui/icons-material/Edit';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { set } from 'lodash';
import { successEvents } from '../../App';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddCourse(props) {
    //   const [open, setOpen] = React.useState(false);

    const [batchModeData, setBatchModeData] = React.useState([]);
    const [courseData, setcourseData] = React.useState([])
    const [subjectData, setSubjectData] = React.useState([])
    const [batchData, setBatchData] = React.useState([]);
    const [addonData, setAddOnData] = React.useState([]);
    const [countryData, setCountryData] = React.useState();
    const [bundelDis, setBundleDis] = React.useState(props?.courseFormData?.bundleDiscount);
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [studentDiscount, setStudentDiscount] = React.useState(0);
    const nameRef = React.useRef();
    const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)
    const [isLoading, setIsLoading] = React.useState(false)
    const [fields, setFields] = React.useState([]);
    const handleClickOpen = () => {
        // setOpen(true);
    };

    const handleAddCase = async () => {
        setIsLoading(true)
        let body = props.courseFormData;
        body.caseId = props.caseId;
        body.adminId = authState?.userId;
        console.log(body)
        const ress = await post('cases/addCase', body)
        setIsLoading(false)
        if (ress.statusCode === 200) {
            setSnackBarOptions({
                message: "successfully added course",
                show: true,
                severity: "success",
                duration: 5000,
            });
            props.fetchData();
            handleClose();
        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }
    const handleEditCase = async () => {
        setIsLoading(true)
        let body = props.courseFormData;
        body.caseId = props.caseId;
        body.subscribedCourseId = props.edit;

        console.log(props.courseFormData)
        const ress = await post(`cases/updateCourse`, body)
        setIsLoading(false)
        console.log(ress)
        if (ress.statusCode === 200) {
            setSnackBarOptions({
                message: "successfully updated course",
                show: true,
                severity: "success",
                duration: 5000,
            });
            props.fetchData();
            handleClose();
        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }
    const getCountryData = async () => {
        let res = await get(`countries/${props.casesData?.countryId}`);
        if (res?.statusCode === 200) {
            // setCountryData(res?.data)
        }
    }
    const getCountry = async () => {
        const res = await get("countries");

        if (res.statusCode === 200) {
            // setCountries(res.data);
        }
    }
    const getbatchMode = async () => {
        const res = await get(`batchModes?filter={"where":{"deleted":false}}`);

        if (res.statusCode === 200) {
            setBatchModeData(res.data);
        }
    }
    const getCourses = async (id) => {
        const res = await get(`batchModes/${id}/courses?filter={"where":{"currency":"${props.fetchCase?.case?.countryId}","deleted":false }}`);

        if (res.statusCode === 200) {
            setcourseData(res.data);
        }
    }
    const getbatch = async (id) => {
        const res = await get(`courses/${id}/batches?filter={"where":{"deleted":false }}`);

        if (res.statusCode === 200) {
            setBatchData(res.data);
        }
    }
    const getSubject = async (id) => {
        const res = await get(`courses/${id}/subjects`);

        if (res.statusCode === 200) {
            setSubjectData(res.data);
        }
    }
    const getAddons = async (id) => {


        const res = await get(`courses/${id}`);

        const ress = await get(`addons?filter={"where":{"currency":"${res.data.currency}","deleted":false }}`);
        if (ress.statusCode === 200) {

            setAddOnData(ress.data);
        }
    }
    const getReferrer = async () => {
        const res = await post("fieldTypes/getAllFieldTypeWithField", { status: true });


        if (res.statusCode === 200) {
            res.data?.map((item) => {
                if (item.title === "reference") {
                    setFields(item?.field);
                }
            }
            )

        }
    }
    const handleChangeSubject = (value) => {


        const __index = props.courseFormData?.subjectNames?.indexOf(value.name);
        const __indexG = props.courseFormData?.subjectIds?.indexOf(value.id);

        if (__indexG >= 0) {
            let array = [...props.courseFormData?.subjectNames];
            array.splice(__index, 1)
            props.setCourseFormData(prevState => (
                { ...prevState, subjectNames: array }))

            let arrays = [...props.courseFormData?.subjectIds];
            arrays.splice(__indexG, 1)
            props.setCourseFormData(prevState => (
                { ...prevState, subjectIds: arrays }))
            courseAmount(arrays)

        } else {
            const array = [...props.courseFormData?.subjectNames, value.name];
            props.setCourseFormData(prevState => (
                { ...prevState, subjectNames: array }))

            const arrays = [...props.courseFormData?.subjectIds, value.id];
            props.setCourseFormData(prevState => (
                { ...prevState, subjectIds: arrays }))
            courseAmount(arrays)
        }
    }

    const getBundleDiscount = async (id) => {
        courseData?.map((item) => {
            if (item.id === id) {
                setBundleDis(item?.bundleDiscount)
            }
        })
    }
    const courseAmount = async (data) => {
        let courseAmount = 0;

        data?.map((id) => {
            const __indexG = subjectData.map(e => e.id).indexOf(id);
            // const __indexG = subjectData?.indexOf(id);
            if (__indexG >= 0) {
                courseAmount = courseAmount + subjectData[__indexG].price
            }
        })
        props.setCourseFormData(prevState => (
            { ...prevState, courseAmount: courseAmount }))

        if (data?.length === subjectData?.length) {
            props.setCourseFormData(prevState => (
                { ...prevState, bundleDiscount: bundelDis }))
        } else {
            props.setCourseFormData(prevState => (
                { ...prevState, bundleDiscount: 0 }))
        }

    }
    const AddonAmount = async (data) => {
        let courseAmount = 0;

        data?.map((id) => {
            const __indexG = addonData.map(e => e.id).indexOf(id);

            if (__indexG >= 0) {
                courseAmount = courseAmount + addonData[__indexG].price
            }
        })
        props.setCourseFormData(prevState => (
            { ...prevState, addonAmount: courseAmount }))



    }
    const handleChangeAddOns = (value) => {


        const __index = props.courseFormData?.addOnsNames?.indexOf(value.name);
        const __indexG = props.courseFormData?.addOnsId?.indexOf(value.id);

        if (__indexG >= 0) {
            let array = [...props.courseFormData?.addOnsNames];
            array.splice(__index, 1)
            props.setCourseFormData(prevState => (
                { ...prevState, addOnsNames: array }))

            let arrays = [...props.courseFormData?.addOnsId];
            arrays.splice(__indexG, 1)
            props.setCourseFormData(prevState => (
                { ...prevState, addOnsId: arrays }))
            AddonAmount(arrays)
        } else {
            const array = [...props.courseFormData?.addOnsNames, value.name];
            props.setCourseFormData(prevState => (
                { ...prevState, addOnsNames: array }))

            const arrays = [...props.courseFormData?.addOnsId, value.id];
            props.setCourseFormData(prevState => (
                { ...prevState, addOnsId: arrays }))
            AddonAmount(arrays)

        }
    }
    // React.useEffect(() => {
    //    
    // }, [props?.courseFormData?.subjectIds])

    // React.useEffect(() => {

    //    
    // }, [props?.courseFormData?.addOnsId])

    React.useEffect(() => {
        getReferrer();
        getbatchMode();
        if (props.edit) {

            getbatchMode();
            getCourses(props.courseFormData?.batchModeId, props.courseFormData?.currency?._id)
            getSubject(props?.courseFormData?.courseId);
            getAddons(props?.courseFormData?.courseId);
            getbatch(props?.courseFormData?.courseId);
            getBundleDiscount(props?.courseFormData?.courseId);

        }
    }, [props.edit])

    const handleClose = () => {
        props.setOpen(false);
        props.setCourseFormData(
            {
                batchModeId: "",
                batchId: "",
                courseId: "",
                subjectIds: [],
                subjectNames: [],
                addOnsId: [],
                addOnsNames: [],
                note: "",
                currency: "",
                courseAmount: 0,
                bundleDiscount: 0,
                addonAmount: 0,
            }
        )
        props.setEdit();
    };


    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props.edit ? "Edit Course" : "Add course"}
                </DialogTitle>
                {console.log(bundelDis)}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-10 p-5'>


                        <Box className=' flex gap-4'>
                            <FormControl disabled={props.edit} required fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">BATCH MODE</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"

                                    value={props.courseFormData?.batchModeId}
                                    label="BATCH MODE"
                                    onChange={async (e) => {
                                        props.setCourseFormData({
                                            batchModeId: e.target.value,
                                            batchId: "",
                                            courseId: "",
                                            subjectIds: [],
                                            subjectNames: [],
                                            addOnsId: [],
                                            addOnsNames: [],
                                            note: "",
                                            currency: "",
                                            courseAmount: 0,
                                            bundleDiscount: 0,
                                            addonAmount: 0,
                                        })


                                        setcourseData([]);
                                        setBatchData([]);
                                        setSubjectData([]);
                                        setAddOnData([]);
                                        getCourses(e.target.value, props.courseFormData?.currency?._id);
                                    }}
                                >
                                    {batchModeData?.map((item) =>
                                        <MenuItem value={item?.id}>{item.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                            <FormControl disabled={props.edit} value={props.casesData?.countryId} required fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">COURSE</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"

                                    value={props?.courseFormData?.courseId}
                                    label="COURSE"
                                    onChange={async (e) => {
                                        props.setCourseFormData({
                                            batchModeId: props.courseFormData?.batchModeId,
                                            batchId: "",
                                            courseId: e.target.value,
                                            subjectIds: [],
                                            subjectNames: [],
                                            addOnsId: [],
                                            addOnsNames: [],
                                            note: "",
                                            currency: "",
                                            courseAmount: 0,
                                            bundleDiscount: 0,
                                            addonAmount: 0,
                                            studentDiscount: 0,
                                        })


                                        // props.setCourseFormData(prevState => (
                                        //     { ...prevState, courseId: e.target.value }));

                                        getAddons(e.target.value)
                                        getbatch(e.target.value);
                                        getSubject(e.target.value);
                                        getBundleDiscount(e.target.value)


                                    }}
                                >
                                    {courseData?.map((item) =>
                                        <MenuItem onClick={() => setStudentDiscount(item?.studentDiscount)} value={item?.id}>{item.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>



                        </Box>
                        <Box className=' flex gap-4'>
                            <FormControl disabled={props.edit} required fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">BATCH</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"

                                    value={props?.courseFormData?.batchId}
                                    label="BATCH"
                                    onChange={async (e) => {
                                        props.setCourseFormData(prevState => (
                                            { ...prevState, batchId: e.target.value }));

                                    }}
                                >
                                    {batchData?.map((item) =>
                                        <MenuItem value={item?.id}>{item.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                            <FormControl required fullWidth size='small'>
                                <InputLabel id="demo-multiple-checkbox-label">SUBJECT</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={props.courseFormData?.subjectNames}
                                    input={<OutlinedInput label="SUBJECT" />}
                                    renderValue={(selected) => selected.join(', ')}
                                // MenuProps={MenuProps}
                                >
                                    {subjectData?.map((item) => (
                                        <MenuItem key={item.name} value={item} onClick={() => handleChangeSubject(item)}>
                                            <Checkbox checked={props.courseFormData?.subjectIds?.includes(item?.id)} />

                                            <ListItemText primary={item.name} />
                                            <ListItemText primary={item.price} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>

                        <Box className=' flex gap-4'>
                            <FormControl required fullWidth size='small'>
                                <InputLabel id="demo-multiple-checkbox-label">ADDONS</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={props.courseFormData?.addOnsNames}
                                    input={<OutlinedInput label="ADDONS" />}
                                    renderValue={(selected) => selected.join(', ')}
                                // MenuProps={MenuProps}
                                >
                                    {addonData?.map((item) => (
                                        <MenuItem key={item.name} value={item} onClick={() => handleChangeAddOns(item)}>
                                            <Checkbox checked={props.courseFormData?.addOnsId?.indexOf(item?.id) > -1} />

                                            <ListItemText primary={item.name} />
                                            <ListItemText primary={item.price} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                        </Box>
                        <Box className=' flex gap-4'>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">REFERENCE</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"

                                    value={props?.courseFormData?.referenceId}
                                    label="REFFERER"
                                    onChange={async (e) => {
                                        props.setCourseFormData(prevState => (
                                            { ...prevState, referenceId: e.target.value }));

                                    }}
                                >
                                    {fields?.map((item) =>
                                        <MenuItem value={item?._id}>{item?.title}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Box>
                        <Box className=' flex gap-4'>
                            <TextField
                                fullWidth
                                size='small'
                                label='NOTE'
                                multiline
                                minRows={3}
                                value={props?.courseFormData?.note}
                                onChange={(e) => {
                                    props.setCourseFormData(prevState => (
                                        { ...prevState, note: e.target.value }));

                                }}
                            />
                        </Box>

                        <Box sx={{ display: props.edit ? "none" : "flex" }} className=' gap-4'>

                            <TextField
                                // disabled
                                label='STUDENT DISCOUNT'

                                InputProps={{
                                    endAdornment: <InputAdornment position="end">max discount-{studentDiscount}</InputAdornment>,
                                }}
                                fullWidth
                                error={studentDiscount < props?.courseFormData?.studentDiscount}
                                helperText={studentDiscount < props?.courseFormData?.studentDiscount ? `Max Discount: ${studentDiscount}. Kindly complete the add-course process and then request more discounts.` : ``}
                                size='small'
                                value={props?.courseFormData?.studentDiscount}
                                onChange={(e) => {
                                    props.setCourseFormData(prevState => (
                                        { ...prevState, studentDiscount: e.target.value }));
                                }}

                            />
                        </Box>
                        <Box className='flex gap-4'>

                            <TextField
                                disabled
                                label='COURSE FEE'
                                fullWidth
                                size='small'
                                value={props?.courseFormData?.courseAmount}

                            />

                            <TextField
                                disabled
                                label='BUNDLE DISCOUNT'
                                fullWidth
                                size='small'
                                value={props?.courseFormData?.bundleDiscount}

                            />

                        </Box>
                        <Box className='flex gap-4'>


                            <TextField
                                disabled
                                label='ADDONS PRICE'
                                fullWidth
                                size='small'
                                value={props?.courseFormData?.addonAmount}

                            />

                            <TextField
                                disabled
                                label='TOTAL FEE'
                                fullWidth
                                size='small'
                                value={(props?.courseFormData?.courseAmount - props?.courseFormData?.bundleDiscount) + props?.courseFormData?.addonAmount}

                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={
                            !props.courseFormData?.batchId
                            || !props.courseFormData?.courseId
                            || !props.courseFormData?.batchModeId
                            || !props.courseFormData?.subjectIds?.length > 0
                            || !props.courseFormData?.addOnsId?.length > 0
                            || props.courseFormData?.courseAmount === 0
                            || props.courseFormData?.AddonAmount === 0
                            || studentDiscount < props?.courseFormData?.studentDiscount
                            || isLoading
                        }
                        autoFocus
                        onClick={props.edit ? handleEditCase : handleAddCase}
                    >
                        {isLoading ? <CircularProgress size={16} /> :
                            props.edit ? "Update" : "Add"
                        }
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}