import { atom, } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist({
    key: 'misUserData', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
})

export const UserAuthState = atom({
    key: 'misUserData',
    default: {

        misUserData: null,

    },

    effects_UNSTABLE: [persistAtom],
})