import React, { useEffect } from 'react'
import { get, post } from '../../Services/services'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import { Button } from '@mui/material';
import { httpGet, storeWindow } from '../../Services/api-services';
import CONSTANTS from "../../constant.json";

export default function PaymentScreen() {
    const [data, SetData] = useState();
    let params = useParams();
    const { id } = params;

    const [mid, setMID] = React.useState('')
    const [txnToken, setTxnToken] = React.useState('')
    const [orderId, setOrderId] = React.useState('')
    const form = React.useRef(null)

    const getPaymentDetails = async () => {
        const res = await post("remoteMethods/paymentScheduleStatus", { paymentScheduleId: id });
        if (res?.statusCode === 200) {
            SetData(res?.data.data[0])

        }
    }
    const handlePay = async () => {
        console.log("hello")
        const URL = `remoteMethods/paymentProcess?caseId=${data?.caseId}&paymentScheduleId=${id}&subscribedCoursesId=${data?.subscribedCoursesId}&amount=${(data?.amount * data?.conversionRate)?.toFixed(2)}&paymentType=fee`
        // storeWindow(`${CONSTANTS.baseUrl}remoteMethods/paymentProcess?caseId=${data?.caseId}&paymentScheduleId=${id}&subscribedCoursesId=${data?.subscribedCoursesId}&amount=${(data?.amount* data?.conversionRate)?.toFixed(2)}&paymentType=fee`,"_self")
        // navigate(`/paymentProcess?caseId=${caseId}&paymentScheduleId=${paymentScheduleId}&subscribedCoursesId=${data?.subscribedCourseId}&amount=${(data?.paymentAmount?.INR)?.toFixed(2)}&paymentType=fee`)      
        const response = await get(URL)
        console.log(response.data)
        if (response && response?.data?.mid && response?.data?.orderId && response?.data?.token) {
            setOrderId(response?.data?.orderId)
            setMID(response?.data?.mid)
            setTxnToken(response?.data?.token)
            setTimeout(() => {
                form?.current?.submit()
            }, 2000);
        }
    }

    const handlePrint = async () => {

    }


    useEffect(() => {
        getPaymentDetails();

    }, [])

    return (
        <div className=' bg-gray-100 flex  h-screen w-screen justify-center items-center'>
            <div className='bg-white p-2'>
                <div className=' flex '>
                    <div className=' w-36    py-5  font-semibold text-[#2B2B2B]' >Name</div>
                    <div className='   py-5  text-[#2B2B2B]'>: {data?.cases?.name}</div>
                </div>
                <div className='flex '>
                    <div className=' w-36  py-5  font-semibold text-[#2B2B2B]' >Mobile</div>
                    <div className='  py-5  text-[#2B2B2B]'>: {data?.cases?.mobile}</div>
                </div>
                <div className='flex  '>
                    <div className=' w-36  py-5   font-semibold text-[#2B2B2B]' >Email</div>
                    <div className=' py-5  text-[#2B2B2B]'>: {data?.cases?.email}</div>
                </div>
                <div className='flex  '>
                    <div className=' w-36 py-5  font-semibold text-[#2B2B2B]' >Course</div>
                    <div className='py-5  text-[#2B2B2B]'>: {data?.subscribedCourses?.courses?.name}</div>
                </div>
                <div className='flex  '>
                    <div className=' w-36 py-5  font-semibold text-[#2B2B2B]' >Due Date</div>
                    <div className='py-5  text-[#2B2B2B]'>: {moment(data?.date).format("LL")}</div>
                </div>
                <div className='flex  '>
                    <div className=' w-36 py-5  font-semibold text-[#2B2B2B]' >Amount in INR</div>
                    <div className='py-5  text-[#2B2B2B]'>: {data?.amount * data?.conversionRate}</div>
                </div>
                <div className='flex  '>
                    <div className=' w-36 py-5  font-semibold text-[#2B2B2B]' >Payment status</div>
                    <div className='py-5  text-[#2B2B2B]'>: {data?.status}</div>
                </div>
                <div className='flex justify-center py-5'>
                    <Button variant='contained' onClick={data?.status === "paid" ? handlePrint : handlePay}>{data?.status === "paid" ? "Print" : "Pay"}</Button>
                </div>
            </div>
            {console.log(orderId)}

            <Hiddenfrom
                formRef={form}
                className='hidden'
                mid={mid}
                orderId={orderId}
                token={txnToken}
            />
        </div>
    )
}



const Hiddenfrom = (props) => {
    return (
        <div>

            {props?.mid && props?.orderId ?
                <form
                    ref={props?.formRef}
                    id="redFrom"
                    method="post"
                    action={
                        "https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=" +
                        props?.mid +
                        "&orderId=" +
                        props?.orderId
                    }
                    name="paytm"
                >
                    <input type="hidden" name="mid" value={props?.mid} />
                    <input type="hidden" name="orderId" value={props?.orderId} />
                    <input type="hidden" name="txnToken" value={props?.token} />
                </form>
                : null
            }
        </div>
    );
};