import { Button, TextField, Typography, FormControl, Snackbar } from "@mui/material";
import BreadCrumb from "../../Components/BreadCrumbs";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import * as React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import {
  fetchCurrency,
  getPdcCharges,
  _addPdcCharges,
  _deletePdcCharges,
  _updatePdcCharges,
  httpPost
} from "../../Services/api-services";
import CONSTANTS from "../../constant.json";
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";



function PdcCharges() {
  const [allCurrencies, setAllcurrencies] = React.useState([]);
  const [chequeBCM, setChequeBCM] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [allPdcCharges, setAllPdcCharges] = React.useState([]);
  const [showToast, setToast] = React.useState(false);
  const [form, setForm] = React.useState();
  const [dataSource, setDataSource] = React.useState([]);
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  const pdcCharges = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      Master
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Cheque Bouncing Charges
    </Typography>,
  ];

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const chequeBCArr = modules.find(
      (m) => m?.name === "Cheque bouncing charges"
    );
    setChequeBCM(chequeBCArr);
    fetchData();
    // eslint-disable-next-line
  }, []);
  
  async function fetchData() {
    try {
      const pdcCharges = await getPdcCharges();
      setAllPdcCharges(pdcCharges);
      _fetchCountries(pdcCharges)
    } catch (err) {
      console.log(err);
    }
  }

  const _fetchCountries = (bouncedCharges) => {
    // FETCH COUNTRY AND CURRENCY -- ALSO CREATES FORM WITH THE VALUES
    fetchCurrency().then(async (curr) => {
      setAllcurrencies(curr)
      const _form = await createForm(curr, bouncedCharges)
      setForm(_form)
    })
  }

  const createForm = (countries, bouncedCharges) => {
    // CREATES FORM FOR PDC CHARGES
    return new Promise(async (resolve) => {
      let _charges = []
      for(let index = 0; index < countries.length; index++) {
        const country = countries[index]
        const _form = {
          currency: country.id,
          currencyName: country.currencyName,
          price: await setBouncedCharges(bouncedCharges, country.currencyName)
        }
        _charges.push(_form)
        if(index == countries.length - 1) resolve(_charges)
      }
    })
  }

  const setBouncedCharges = (bouncedCharges, currencyName) => {
    return new Promise((resolve) => {
      for(let index = 0; index < bouncedCharges.length; index++) {
        const _charges = bouncedCharges[index]
        if(_charges.currencyName === currencyName) resolve(_charges.price || 0)
      }
    })
  }

  const priceChange = (val, key, index) => {
    const xForm = [...form]
    xForm[index]['price'] = parseFloat(val)
    setForm(xForm)
  }

  function editData() {
    setEdit(true);
  }

  function doneEdit() {
    setEdit(false);
  }

  const savePdcCharges = async () => {
    let index = 0
    while(index < form.length) {
      const _form = form[index]
      const res =  await updateCharges(_form)
      index++
      if(index === form.length - 1) {
        setToast(true)
      }
    }
    setEdit(false)
  } 

  const updateCharges = (body) => {
    return new Promise((resolve, reject) => {
      const url = `${CONSTANTS.baseUrl}/pdcCharges/upsertWithWhere?where={"currencyName": "${body.currencyName}"}`
      httpPost(url, body).then(response => {
        resolve(body)
      })
    })
  } 

  return (
    <div className="h-full w-full overflow-auto py-5 px-8 ">
      <BreadCrumb data={pdcCharges} />
      <div className="mt-5 border h-auto w-96 border-gray-200 bg-white px-4 py-3">
        <div className="flex justify-between items-center">
          <h1 className="text-gray-500">Cheque Bouncing Charges</h1>
          {!edit ? (
            <Button disabled={chequeBCM?.update ? false:true} onClick={editData} sx={{ minWidth: "0px", height: "15px" }}>
              <EditIcon fontSize="sm" />
            </Button>
          ) : (
            <Button onClick={() => savePdcCharges()} sx={{ minWidth: "0px", height: "15px" }}>
              <DoneIcon sx={{ fontSize: "18px" }} color="success" />
            </Button>
          )}
        </div>
        <div className="my-4">
            {form?.map((_form, index) => (
              <div className="mb-4" key={index}>
                <TextField 
                  variant="standard" 
                  label={_form.currencyName} 
                  id={`${index}-inp`} 
                  type="number"
                  disabled={!edit}
                  value={_form.price}
                  onChange={(ev) => priceChange(ev.target.value, _form.currencyName, index)}
                  />
              </div>
            ))}
        </div>
      </div>
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={() => setToast(false)}
        message="Cheque bouncing charges updated"
      />
    </div>
  )
}
export default PdcCharges;
