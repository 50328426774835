import { Avatar } from "@mui/material";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import moment from "moment";
import CONSTANTS from "../constant.json";
import ImgsViewer from "react-images-viewer";
import React from "react";

function CaseDetailsHeadedCard(props) {
  const [imageOpen, setImageOpen] = React.useState(false);
  return (
    <div className="border-gray-300 border h-fit w-auto mx-5 p-4">
      <div className="flex">
        <Avatar
          className='cursor-pointer'
          onClick={() => setImageOpen(true)}
          sx={{ width: "80px", height: "80px" }}
          alt={props.fetchCase?.case?.firstName}
          src={`${CONSTANTS.baseUrl}/profilePics/user/download/${props?.fetchCase?.case?.image}`}
        />
        <ImgsViewer
          imgs={[
            { src: `${CONSTANTS.baseUrl}/profilePics/user/download/${props?.fetchCase?.case?.image}` }
          ]}
          isOpen={imageOpen}
          onClose={() => setImageOpen(false)}
        />

        <div className="text-gray-500 ml-4">
          <span className="text-xl">

            {props.fetchCase?.case?.name}
          </span>
          <br />
          <p className="text-sm mt-2">
            <DraftsIcon fontSize="15px" />
            &nbsp;{props.fetchCase?.case?.email}
          </p>
          <p className="text-sm mt-1">
            <LocalPhoneIcon fontSize="15px" />
            &nbsp;{props.fetchCase?.case?.mobile}
          </p>
          {props.fetchCase?.notes?.length > 0 && (
            <p className="text-sm mt-1">
              Notes:&nbsp;{props.fetchCase?.case?.notes}
            </p>
          )}
        </div>

      </div>
      {props?.fetchCase?.case && <div className="flex flex-wrap text-sm mt-8">
        {props?.fetchCase?.case?.dob && <div className='w-64 p-2 py-2'>
          <h1 className="text-gray-500">Date Of Birth</h1>
          <h1>{moment(props?.fetchCase?.case?.dob).format('MMMM Do YYYY')}</h1>
        </div>}
        {props?.fetchCase?.case?.school && <div className='w-64 p-2'>
          <h1 className="text-gray-500">School</h1>
          <h1>{props?.fetchCase?.case?.school}</h1>
        </div>}

        {props?.fetchCase?.case?.board && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Board</h1>
          <h1>{props?.fetchCase?.case?.board}</h1>
        </div>}
        {props?.fetchCase?.case?.prevYearMark && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Previous Year %</h1>
          <h1>{props?.fetchCase?.case?.prevYearMark}%</h1>
        </div>}
        {props?.fetchCase?.proofNumber && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Id proof</h1>
          <h1>{props?.fetchCase?.case?.proofNumber}</h1>
        </div>}


        {props?.fetchCase?.case?.country && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Country</h1>
          <h1>{props?.fetchCase?.case?.country}</h1>
        </div>}
        {props?.fetchCase?.case?.state && <div className='w-64 p-2'>
          <h1 className="text-gray-500">State</h1>
          <h1>{props?.fetchCase?.case?.state}</h1>
        </div>}
        {props?.fetchCase?.case?.city && <div className='w-64 p-2'>
          <h1 className="text-gray-500">City</h1>
          <h1>{props?.fetchCase?.case?.city}</h1>
        </div>}
        {props?.fetchCase?.case?.aadharNo && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Aadhar Number</h1>
          <h1>{props?.fetchCase?.case?.aadharNo}</h1>
        </div>}
        {props?.fetchCase?.case?.fatherName && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Father's Name</h1>
          <h1>{props?.fetchCase?.case?.fatherName}</h1>
        </div>}
        {props?.fetchCase?.case?.fatherMobile && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Father's Mobile</h1>
          <h1>{props?.fetchCase?.case?.fatherMobile}</h1>
        </div>}
        {props?.fetchCase?.case?.fatherOccupation && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Father's Occupation</h1>
          <h1>{props?.fetchCase?.case?.fatherOccupation}</h1>
        </div>}
        {props?.fetchCase?.case?.motherName && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Mother's Name</h1>
          <h1>{props?.fetchCase?.case?.motherName}</h1>
        </div>}
        {props?.fetchCase?.case?.motherMobile && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Mother's Mobile</h1>
          <h1>{props?.fetchCase?.case?.motherMobile}</h1>
        </div>}
        {props?.fetchCase?.case?.motherOccupation && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Mother's Occupation</h1>
          <h1>{props?.fetchCase?.case?.motherOccupation}</h1>
        </div>}
        {props?.fetchCase?.case?.guardianName && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Guardian's Name</h1>
          <h1>{props?.fetchCase?.case?.guardianName}</h1>
        </div>}
        {props?.fetchCase?.case?.guardianMobile && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Guardian's Mobile</h1>
          <h1>{props?.fetchCase?.case?.guardianMobile}</h1>
        </div>}
        {props?.fetchCase?.case?.guardianOccupation && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Guardian's Occupation</h1>
          <h1>{props?.fetchCase?.case?.guardianOccupation}</h1>
        </div>}
        {props?.fetchCase?.case?.careerOption && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Career Option</h1>
          <h1>{props?.fetchCase?.case?.careerOption}</h1>
        </div>}
        {props?.fetchCase?.case?.source && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Source</h1>
          <h1>{props?.fetchCase?.case?.source}</h1>
        </div>}
        {props?.fetchCase?.case?.nextCourse && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Next Expected Course</h1>
          <h1>{props?.fetchCase?.case?.nextCourse}</h1>
        </div>}
     
        {props?.fetchCase?.case?.streetAddress && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Address</h1>
          <h1>{props?.fetchCase?.case?.streetAddress} ({props?.fetchCase?.case?.postalCode})</h1>
        </div>}
        {/* {props?.fetchCase?.case?.RegisteredAt && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Address</h1>
          <h1>{props?.fetchCase?.case?.RegisteredAt} ({props?.fetchCase?.case?.postalCode})</h1>
        </div>} */}
        {props?.fetchCase?.case?.RegisteredAt && <div className='w-64 p-2'>
          <h1 className="text-gray-500">Registration Date</h1>
          <h1>{moment(props?.fetchCase?.case?.RegisteredAt).format('MMMM Do YYYY')}</h1>
        </div>}
      </div>}
    </div>
  );
}
export default CaseDetailsHeadedCard;
