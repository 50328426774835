import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Fab, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DesimalValue, StringWithSpace, integetRegex, onlySingleString, phoneNumberRegexAed, phoneNumberRegexIndia } from '../../Regex/Regex';

import { patch, post } from './../../Services/services'
import { successEvents } from '../../App';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddCountry(props) {


    const { setSnackBarOptions } = React.useContext(successEvents)




    const handleCountryAdd = async () => {
        let temp = { ...props.countryForm }
        temp.status = true

        const res = await post('countries', temp)

        if (res.statusCode === 200) {
            handleClose();
            setSnackBarOptions({
                message: "   successfully Created",
                show: true,
                severity: "success",
                duration: 5000,
            });

            props.getCountry();
        } else if (res.statusCode === 422) {
            console.log(res.message)
            setSnackBarOptions({
                message: res.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }
    const handleEditCountry = async () => {
        const res = await patch(`countries/${props.edit.id}`, props.countryForm)
        if (res.statusCode === 200) {
            handleClose();
            setSnackBarOptions({
                message: "successfully updated",
                show: true,
                severity: "success",
                duration: 5000,
            });
            props.getCountry();
        } else if (res.statusCode === 422) {
            console.log(res.message)
            setSnackBarOptions({
                message: res.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }
    const handleClose = () => {
        props.setOpen(false);
        props.setCountryForm({
            name: "",
            currencyName: "",
            conversionRate: "",
            phoneCode: "",
            phoneLength: "",
            chequeBounceCharge: "",
        })
        props.setEdit();

    };
    React.useEffect(() => {
        // if (props.edit) {
        //     console.log(props.edit)
        //     setCheque(props?.edit?.chequeBounceCharge);
        //     setConversionRate(props?.edit?.conversionRate)
        // }
    }, [props.edit])

    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open || props.edit}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props.edit ? "Edit country" : "Add Country"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-5'>


                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                error={props.countryForm?.name?.length < 3 && props.countryForm?.name?.length !== 0}
                                helperText={props.countryForm?.name?.length < 3 && props.countryForm?.name?.length !== 0 ? "invalid country name must have at least 3 characters " : ""}
                                onChange={(e) => props.setCountryForm(prevState => (
                                    { ...prevState, name: e.target.value }))}
                                size='small' fullWidth label="COUNTRY NAME"
                                value={props?.countryForm?.name}
                            />

                        </Box>
                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                error={!onlySingleString.test(props.countryForm?.currencyName) && props.countryForm?.currencyName?.length !== 0}
                                helperText={!onlySingleString.test(props.countryForm?.currencyName) && props.countryForm?.currencyName?.length !== 0 ? "invalid currency name, only alphabets and spaces not allowed " : ""}
                                onChange={(e) => {

                                    props.setCountryForm(prevState => (
                                        { ...prevState, currencyName: e.target.value?.toUpperCase() }))
                                }
                                }
                                size='small'
                                fullWidth
                                label="CURRENCY NAME"
                                value={props?.countryForm?.currencyName}
                            />

                        </Box>
                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                error={!integetRegex.test(props.countryForm?.phoneCode) && props.countryForm?.phoneCode?.length !== 0}
                                helperText={!integetRegex.test(props.countryForm?.phoneCode) && props.countryForm?.phoneCode?.length !== 0 ? "invalid COUNTRY CODE, only number and spaces or dot not allowed " : ""}

                                onChange={(e) => {
                                    props.setCountryForm(prevState => (
                                        { ...prevState, phoneCode: e.target.value }))
                                }
                                }
                                size='small'
                                fullWidth
                                label="COUNTRY CODE"
                                value={props?.countryForm?.phoneCode}
                            />

                        </Box>
                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                error={!integetRegex.test(props.countryForm?.phoneLength) && props.countryForm?.phoneLength?.length !== 0}
                                helperText={!integetRegex.test(props.countryForm?.phoneLength) && props.countryForm?.phoneLength?.length !== 0 ? "invalid PHONE NUMBER LENGTH, only alphabets and  number and spaces or dot not allowed not allowed " : ""}

                                onChange={(e) => props.setCountryForm(prevState => (
                                    { ...prevState, phoneLength: e.target.value }))}
                                size='small'
                                fullWidth
                                label="PHONE NUMBER LENGTH"
                                value={props?.countryForm?.phoneLength}
                            />

                        </Box>
                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                error={!DesimalValue?.test(props.countryForm?.conversionRate) && props.countryForm?.conversionRate?.length !== 0}
                                helperText={!DesimalValue?.test(props.countryForm?.conversionRate) && props.countryForm?.conversionRate?.length !== 0 ? "invalid CONVERSION RATE,  only number  allowed " : ""}

                                onChange={(e) => props.setCountryForm(prevState => (
                                    { ...prevState, conversionRate: e.target.value }))}

                                size='small' fullWidth label="CONVERSION RATE"
                                value={props.countryForm?.conversionRate}
                            />

                        </Box>
                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                value={props.countryForm?.chequeBounceCharge}
                                error={!DesimalValue?.test(props.countryForm?.chequeBounceCharge) && props.countryForm?.chequeBounceCharge?.length !== 0}
                                helperText={!DesimalValue?.test(props.countryForm?.chequeBounceCharge) && props.countryForm?.chequeBounceCharge?.length !== 0 ? " invalid CHEQUE BOUNCING CHARGE,  only number  allowed " : ""}
                                onChange={(e) => props.setCountryForm(prevState => (
                                    { ...prevState, chequeBounceCharge: e.target.value }))}
                                size='small' fullWidth label="CHEQUE BOUNCING CHARGE " />

                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button

                        disabled={
                            props.countryForm?.name?.length < 3
                            || !onlySingleString?.test(props?.countryForm?.currencyName)
                            || !integetRegex.test(props.countryForm?.phoneCode)
                            || !integetRegex.test(props.countryForm?.phoneLength)
                            || !DesimalValue?.test(props.countryForm?.conversionRate)
                            || !DesimalValue?.test(props.countryForm?.chequeBounceCharge)

                        }
                        sx={{ bgcolor: '#3366ff' }}
                        autoFocus variant='contained'
                        onClick={props.edit ? handleEditCountry : handleCountryAdd}>
                        Add
                    </Button>

                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}