import React from "react";

import { Route, Routes } from "react-router-dom";
import Login from "../../Screens/Login";
import Home from "../../Components/Home";
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import RefuncRequest from "../../homeScreen/RefundRequests/RefuncRequest";
import Batch from "../../Screens/Batch";

import OpenCasesHome from "../../homeScreen/openCases/OpenCasesHome";
import Cases from "../../homeScreen/openCases/Cases";
import CourseCategoriesHome from "../../homeScreen/Coursecategories/CourseCategoriesHome";
import CourseCategories from "../../homeScreen/Coursecategories/CourseCategories";
import CoursesHome from "../../homeScreen/Courses/CoursesHome";
import Courses from "../../homeScreen/Courses/courses";
import AddonsHome from "../../homeScreen/Addons/AddonsHome";
import AddOns from "../../homeScreen/Addons/AddOns";
import TagsHome from "../../homeScreen/Tags/TagsHome";
import Tags from "../../homeScreen/Tags/Tags";
import ChequeBouncingChargesHome from "../../homeScreen/Chequebouncingcharges/ChequeBouncingChargesHome";
import PdcCharges from "../../homeScreen/Chequebouncingcharges/pdcCharges";
import SmsTemplateHome from "../../homeScreen/Smstemplate/SmsTemplateHome";
import SmsTemplate from "../../homeScreen/Smstemplate/SmsTemplate";
import CaseDetails from "../../homeScreen/openCases/CaseDetails";
import Analytics from "../../homeScreen/analytics/Analytics";
import AnalyticsHome from "../../homeScreen/analytics/AnalyticsHome";
import Reports from "../../homeScreen/reports/Reports";
import ReportsHome from "../../homeScreen/reports/ReportsHome";
import Enrollments from "../../homeScreen/Enrollments/Enrollments";
import EnrollmentsHome from "../../homeScreen/Enrollments/EnrollmentsHome";
import DiscountRequestsHome from "../../homeScreen/DiscountRequests/DiscountRequestsHome";
import DiscountReq from "../../homeScreen/DiscountRequests/DiscountReq";
import RefundRequestsHome from "../../homeScreen/RefundRequests/RefundRequestsHome";
import RolesHome from "../../homeScreen/Roles/RolesHome";
import StaffDetails from "../../Screens/StaffDetails";
import OldCasesHome from "../../homeScreen/OldCases/OldCasesHome";
import OldCases from "../../homeScreen/OldCases/OldCases";
import FieldSettingHome from "../../homeScreen/FieldSetting/FieldSettingHome";
import FieldSetting from "../../homeScreen/FieldSetting/FieldSetting";
import Roles from "../../homeScreen/Roles/Roles";
import CountryHome from "../../homeScreen/country/CountryHome";
import Countrys from "../../homeScreen/country/Countrys";
import RegistrationForm from "../../Screens/RegistrationForm";
import ApprovedRefundRequestHome from "../../homeScreen/ApprovedRefundRequests/ApprovedRefundRequestHome";
import ApprovedRefundRequests from "../../homeScreen/ApprovedRefundRequests/ApprovedRefundRequests";
import PaymentDueHome from "../../homeScreen/Paymentdue/PaymentDueHome";
import PaymentDue from "../../homeScreen/Paymentdue/PaymentDue";
import RagistrationForm from "../../homeScreen/RagistrationForm/RagistrationForm";
import PaymentScreen from "../../homeScreen/PaymentScreen/PaymentScreen";
import PaymentStatus from "../../Screens/PaymentStatus";



export default function MainRoutes() {
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  return (
    <Routes>
      <Route path="registration-form/:id" element={<RagistrationForm />} />
      <Route path="paymentScreen/:id" element={<PaymentScreen />} />
      <Route path="paymentStatus" element={<PaymentStatus />} />
      {/* <Route path="paymentScreen" element={<PaymentScreen />} /> */}
      <Route path="" element={<Login />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="registration-form/:id" element={<RegistrationForm />} /> */}


      {/* ###  Admin routes */}
      <Route path="/home" element={<Home />}>

        {authState?.firstPage === 'Analytics' ?
          <Route path="" element={<AnalyticsHome />}>
            <Route path="" element={<Analytics />} />
          </Route>

          :
          authState?.firstPage === 'Reports' ?
            <Route path="" element={<ReportsHome />}>
              <Route path="" element={<Reports />} />

            </Route>
            :
            authState?.firstPage === 'Open cases' ?
              <Route path="" element={<OpenCasesHome />}>
                <Route path="" element={<Cases />} />
                <Route path="opencases" element={<Cases />} />
                <Route path="caseDetails" element={<CaseDetails />} />
              </Route>
              :
              authState?.firstPage === 'Enrollments' ?
                <Route path="" element={<EnrollmentsHome />}>
                  <Route path="" element={<Enrollments />} />

                </Route>
                :
                authState?.firstPage === 'Discount Requests' ?
                  <Route path="" element={<DiscountRequestsHome />} >
                    <Route path="" element={<DiscountReq />} />

                  </Route>
                  :
                  authState?.firstPage === 'Refund Requests' ?
                    <Route path="" element={<RefundRequestsHome />} >
                      <Route path="" element={<RefuncRequest />} />
                    </Route>
                    :
                    authState?.firstPage === 'Roles' ?
                      <Route path="" element={<RolesHome />} >
                        <Route path="" element={<Roles />} />
                        <Route path="staffDetails" element={<StaffDetails />} />
                      </Route>
                      :
                      authState?.firstPage === 'Batchs' ?
                        <Route path="" element={<Batch />} />
                        : authState?.firstPage === 'Course categories' ?
                          <Route path="" element={<CourseCategoriesHome />} >
                            <Route path="" element={<CourseCategories />} />
                          </Route>
                          :
                          authState?.firstPage === 'Courses' ?
                            <Route path="" element={<CoursesHome />} >
                              <Route path="" element={<Courses />} />
                            </Route>
                            :
                            authState?.firstPage === 'Addons' ?
                              <Route path="" element={<AddonsHome />} >
                                <Route path="" element={<AddOns />} />
                              </Route>
                              :
                              authState?.firstPage === 'Tags' ?
                                <Route path="" element={<TagsHome />} >
                                  <Route path="" element={<Tags />} />
                                </Route>
                                :
                                authState?.firstPage === 'Cheque bouncing charges' ?
                                  <Route path="" element={<ChequeBouncingChargesHome />}>
                                    <Route path="" element={<PdcCharges />} />
refund_requests
                                  </Route>
                                  :
                                  authState?.firstPage === 'Country' ?
                                    <Route path="" element={<CountryHome />} >
                                      <Route path="" element={<Countrys />} />
                                    </Route>
                                    :
                                    authState?.firstPage === 'Fields' ?
                                      <Route path="" element={<FieldSettingHome />}>
                                        <Route path="" element={<FieldSetting />} />
                                        {/* <Route path="opencases" element={<Cases />} /> */}
                                      </Route>
                                      :
                                      authState?.firstPage === 'Sms templates' ?
                                        <Route path="" element={<SmsTemplateHome />} >
                                          <Route path="" element={<SmsTemplate />} />

                                        </Route>
                                        : null}

        <Route path="analytics" element={<AnalyticsHome />}>
          <Route path="" element={<Analytics />} />
          {/* <Route path="duplicate" element={<BufferStudent />} />
          <Route path="stage" element={<StageList />} /> */}
        </Route>

        <Route path="reports" element={<ReportsHome />}>
          <Route path="" element={<Reports />} />

        </Route>
        <Route path="open_cases" element={<OpenCasesHome />}>
          <Route path="" element={<Cases />} />
          <Route path="open_cases" element={<Cases />} />
          <Route path="caseDetails" element={<CaseDetails />} />
        </Route>

        <Route path="old_cases" element={<OldCasesHome />}>
          <Route path="" element={<OldCases />} />
          <Route path="caseDetails" element={<CaseDetails />} />
          {/* <Route path="opencases" element={<Cases />} /> */}
        </Route>
        <Route path="fields" element={<FieldSettingHome />}>
          <Route path="" element={<FieldSetting />} />
          {/* <Route path="opencases" element={<Cases />} /> */}
        </Route>
        <Route path="enrollments" element={<EnrollmentsHome />}>
          <Route path="" element={<Enrollments />} />

        </Route>


        <Route path="discount_requests" element={<DiscountRequestsHome />} >
          <Route path="" element={<DiscountReq />} />

        </Route>
        <Route path="refund_requests" element={<RefundRequestsHome />} >
          <Route path="" element={<RefuncRequest />} />
        </Route>
        <Route path="approved_refunds" element={<ApprovedRefundRequestHome />} >
          <Route path="" element={<ApprovedRefundRequests />} />
        </Route>
        <Route path="payment_due" element={<PaymentDueHome />} >
          <Route path="" element={<PaymentDue />} />
        </Route>


        <Route path="roles" element={<RolesHome />} >
          <Route path="" element={<Roles />} />
          <Route path="staffDetails" element={<StaffDetails />} />

        </Route>

        <Route path="batchs" element={<Batch />} />

        <Route path="course_categories" element={<CourseCategoriesHome />} >
          <Route path="" element={<CourseCategories />} />
        </Route>

        <Route path="courses" element={<CoursesHome />} >
          <Route path="" element={<Courses />} />
        </Route>


        <Route path="addons" element={<AddonsHome />} >
          <Route path="" element={<AddOns />} />
        </Route>



        <Route path="tags" element={<TagsHome />} >
          <Route path="" element={<Tags />} />
        </Route>

        <Route path="sms_templates" element={<SmsTemplateHome />} >
          <Route path="" element={<SmsTemplate />} />

        </Route>
        <Route path="country" element={<CountryHome />} >
          <Route path="" element={<Countrys />} />
        </Route>

        {/* <Route path="documents" element={<DocumentScreen />}>
          <Route path="" element={<DocumentList />} />
          <Route path="verify" element={<DocumentVerifiy />} />
        </Route>

        <Route path="groups" element={<GroupsScreen />}>
          <Route path="" element={<GroupList />} />
          <Route path="view" element={<GroupView />} />
          <Route path="addlead" element={<AddStudentThisGroup />} />
        </Route>



        <Route path="settings" element={<SettingHome />}>
          <Route path="" element={<Settings />} />
        </Route>

        <Route path="Schools" element={<SchoolScreen />}>
          <Route path="" element={<SchoolList />} />
        </Route>
        <Route path="Referrers" element={<ExternalUserAdminHome />}>
          <Route path="" element={<ExternalUserList />} />
          <Route path="view" element={<ExternalUserLeadsView />} />
        </Route>
        <Route path="team leaders" element={<ExternalUserAdminHome />}>
          <Route path="" element={<TeamLeaderList />} />
          <Route path="task" element={<TeamLeaderTaskVeiw />} />
          <Route path="view" element={<TaskView />} />

        </Route>
        <Route path="announcements" element={<AnnouncementScreen />}>
          <Route path="" element={<AnnouncementList />} />
          <Route path="view" element={<AnnouncementView />} />
          <Route path="addnew" element={<AddNewAnnouncement />} />
        </Route>

        <Route path="staffs" element={<StaffScreen />}>
          <Route path="" element={<StaffList />} />
          <Route path="view" element={<StaffView />} />
        </Route>

        <Route path="stages" element={<StageScreen />}>
          <Route path="" element={<StageList />} />
          <Route path="view" element={<StageView />} />
        </Route>
        <Route path="leads" element={<StudentScreen />}>
          <Route path="" element={<StudentList />} />
          <Route path="view" element={<StudentProfile />} />
          <Route path="buffer" element={<BufferStudent />} />
          <Route path="rejected" element={<RejectedLeads />} />
        </Route>
        <Route path="tasks" element={<TaskScreen />}>
          <Route path="" element={<Tasklist />} />
          <Route path="view" element={<TaskView />} />
          <Route path="assign" element={<AssingTl />} />
          <Route path="reassign" element={<ReAssignToTl />} />

        </Route>
        <Route path="counsellors" element={<CallerScreen />}>
          <Route path="" element={<CallerList />} />
          <Route path="task" element={<CallerView />} />
          <Route path="view" element={<CallerLeadsView />} />
        </Route> */}
      </Route>





    </Routes>
  );
}
