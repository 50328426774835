import CONSTANTS from "../constant.json";
import getCookie from "../Components/Utility/Utility";

const get = (authToken) => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
  };
};

const getWithoutToken = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const deleted = (authToken) => {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
  };
};

let post = (body, authToken) => {
  return {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
  };
};

let postImg = (body, authToken) => {
  return {
    method: "POST",
    body: body
  };
};

let patch = (body, authToken) => {
  return {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
  };
};

let postWithoutToken = (body) => {
  return {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
};

// -----------Start  SMS Template-------------

export const getSmsModulesAndEvents = () => {
  const filter = `?filter={"where": {"type": "SMSModuleAndEvents"}}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/settings${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const getAllSmsChips = () => {
  const filter = `?filter={"where": {"type": "SmsChips"}}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/settings${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const addSmsCip = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/settings/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const _updateAddmissionProcess = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/admissionProcesses/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const getGST = () => {
  const filter = `?filter={"where": {"deleted": {"ne": true}, "type": "GST"}}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/settings${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const _addSmsTemplate = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const _deleteSmsTemplate = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const _changeStatusOfSmsTemplate = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const getAllSmsTemplate = (data) => {
  let filter
  if (data == undefined) {
    filter = `?filter={"where":{"deleted":false},"order": "createdAt DESC"}`;
  } else {
    filter = `?filter={"where":{"deleted":false,"or":[{"title":{"regexp":"/${data?.input}/i"}},{"event":{"regexp":"/${data?.input}/i"}}],"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]},"order": "createdAt DESC"}`;
  }
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const getSingleSmsTemplate = (body) => {
  const filter = `?filter={"where":{"deleted":false,"status":true,"module":"${body.module}","event":"${body.event}"}}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const _addSmsLog = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsLogs`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const sendSms = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates/sendMessage`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const _updateSmsTemplate = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/smsTemplates/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

// -----------End SMS Template-------------

//-----------Start Reports-----------------

export const getReports = (body) => {

  const TOKEN = getCookie("tokenId");
  if (body != undefined) {
    console.log(body)
    let url = `${CONSTANTS.baseUrl}/remoteMethods/getReports`;
    return fetch(url, post(body, TOKEN)).then((res) => res.json());
  } else {
    let url = `${CONSTANTS.baseUrl}/remoteMethods/getReports`;
    return fetch(url, post(TOKEN)).then((res) => res.json());
  }
};
export const fetchSelectFilterData = (body) => {
  // const filter = `?filter={"where": {"type": "paymentStatus" }}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/remoteMethods/getReports`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const fetchReportsSelectFilterData = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/remoteMethods/getReportsSelectFilterData`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const fetchAnalyticData = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/remoteMethods/getAnalyticsRecord`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const fetchDiscountRequests = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/remoteMethods/getReports`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const fetchPendingSectionRequests = (body) => {
  console.log(body)
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/remoteMethods/selectFilterData`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const fetchPaymentDueRequests = (body) => {
  console.log(body)
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/remoteMethods/paymentDueRequest?filter={"where": {"deleted": {"neq": true}}, "order": "createdAt DESC"}`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const _addRefundRequest = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/refunds`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};
export const fetchRefundRequest = (id) => {
  const filter = `?filter={"where":{"deleted": {"neq": true},"caseId":"${id}"}}`;
  console.log(filter)
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/refunds${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const fetchAllRefundRequest = (body) => {
  let filter
  if (body == "all") {
    filter = `?filter={"where":{ "deleted": {"neq": true}}, "order": "createdAt DESC", "include": [{ "relation": "cases" },{ "relation": "subscribedCourses","scope":{
    "include": [
            { "relation": "course" }
        ]
      
  }  ]}`
  } else {
    filter = `?filter={"where":{"status":{"eq":"${body}"}},  "order": "createdAt DESC"
    "include": [ {
      "relation": "cases", "scope": {
        "include": [
          { "relation": "subscribedCourses" ,"scope": {"include":[{"relation":"course"}]}}
        ]

      }
    }]
  }`;
  };
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/refunds${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
//-----------Start Payment Status-----------------
export const fetchPaymentStatus = () => {
  const filter = `?filter={"where": {"type": "paymentMode" }}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/settings${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const _updateDiscountRequestStatus = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/discountRequests/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const _updateRefundRequestStatus = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/refunds/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const _deleteRefundRequest = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/refunds/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updateSubscribedTotalFee = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/subscribedCourses/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const fetchCurrency = () => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/countries?filter={"where":{"status":true}}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};


export const getCourses = (courseCatId, data) => {
  let filter
  if (data == undefined) {
    filter = `?filter={"where": {"courseCategoryId": "${courseCatId}"}, "include": [{ "relation": "batchmode" },{ "relation": "country" } ,{"relation":"subjects"}], "order": "createdAt DESC"}`;
  } else {
    filter = `?filter={"where": {"courseCategoryId": "${courseCatId}","name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]}, "include": [{ "relation": "batchmode" },{ "relation": "country" } ,{"relation":"subjects"}], "order": "createdAt DESC"}`;
  }
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courses${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
// export const getAllField = (courseCatId, data) => {
//   let filter = `?filter={ "include": [{ "relation": "field" }]}`;

//   const TOKEN = getCookie("tokenId");
//   const url = `${CONSTANTS.baseUrl}/courses${filter}`;
//   return fetch(url, get(TOKEN)).then((res) => res.json());
// };
export const getAllCourses = (data) => {
  let filter
  if (data != undefined) {
    filter = `?filter={"where": {"deleted": {"eq": false},"name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]},"include": [{ "relation": "batchmode" },{ "relation": "country" },{ "relation": "courseCategory" },{"relation": "subjects"} ], "order": "createdAt DESC"}`;
  } else {
    filter = `?filter={"where": {"deleted": {"eq": false}},"include": [{ "relation": "batchmode" },{ "relation": "country" },{ "relation": "courseCategory" },{"relation": "subjects","scope": {"where":{"deleted": false}} } ], "order": "createdAt DESC"}`;
  }

  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courses${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const _getAllCase = (data) => {
  console.log(data)

  let filter
  if (data != undefined) {
    filter = `?filter={"where": {"deleted": {"neq": true},"and":[{"or":[{"firstName":{"regexp":"/${data.input}/i"}},{"lastName":{"regexp":"/${data.input}/i"}},{"mobile":{"regexp":"/${data.input}/i"}}]},{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]},"include": [{ "relation": "subscribedCourses","scope": {
        "include": [
            { "relation": "discountRequests" },
            { "relation": "batches" },
            { "relation": "course","scope": {"include": "country"} },
            { "relation": "admissionProcesses" },
            { "relation": "batchmode" },
            { "relation": "subscribedSubjects", "scope": {"include": "subjects"} },
            { "relation": "subscribedAddons", "scope": {"include": "addOns"} }
        ]
    }
 }], "order": "createdAt DESC"}`;
  } else {
    filter = `?filter={"where": {"deleted": {"neq": true}},"include": [{ "relation": "subscribedCourses","scope": {
        "include": [
            { "relation": "discountRequests" },
            { "relation": "batches" },
            { "relation": "course","scope": {"include": "country"} },
            { "relation": "admissionProcesses" },
            { "relation": "batchmode" },
            { "relation": "subscribedSubjects", "scope": {"include": "subjects"} },
            { "relation": "subscribedAddons", "scope": {"include": "addOns"} }
        ]
    }
 }], "order": "createdAt DESC"}`;
  }
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/cases${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const _getCase = (body) => {
  //   const filter = `?filter={"where": {"deleted": {"neq": true}},"include": [{ "relation": "subscribedCourses","scope": {
  //         "include": [
  //             { "relation": "batches" },
  //             { "relation": "subscribedSubjects" },
  //             { "relation": "subscribedAddons" },
  //             { "relation": "course" },
  //             { "relation": "batchMode" },
  //             {"relation":"refund"}
  //         ]
  //     }
  //  }], "order": "createdAt DESC"}`;

  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/cases/caseDetails`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const getAddons = (data) => {
  let filter
  if (data == undefined) {
    filter = `?filter={"where": {"deleted": {"neq": true}}, "include": [{ "relation": "country" } ], "order": "createdAt DESC"}`;
  } else {
    filter = `?filter={"where": {"deleted": {"neq": true},"name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]}, "include": [{ "relation": "country" } ], "order": "createdAt DESC"}`;
  }
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/addOns${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const getBatchModes = (data) => {
  console.log(data, "--------------")
  let filter
  if (data != undefined) {
    filter = `?filter={"where": {"deleted": {"neq": true},"name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]}, "order": "createdAt DESC"}`;
  } else {
    filter = `?filter={"where": {"deleted": {"neq": true}}, "order": "createdAt DESC"}`;
  }

  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batchModes${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const getCourseCategory = () => {
  const filter = `?filter={"where": {"deleted": {"neq": true}}, "order": "createdAt DESC"}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courseCategories${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const getTags = (data) => {
  let filter
  if (data == undefined) {
    filter = `?filter={"where": {"deleted": {"neq": true}}, "order": "createdAt DESC", "include": {"relation": "subscribedTags", "scope": {"fields": ["name"]}}}`;
  } else {
    filter = `?filter={"where": {"deleted": {"neq": true},"name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]}, "order": "createdAt DESC", "include": {"relation": "subscribedTags", "scope": {"fields": ["name"]}}}`;
  }

  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/tags${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};
export const _changeStatusOfStaff = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/Admins/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};
export const _getAllStaff = (data) => {
  let filter
  if (data == undefined) {
    filter = `?filter={"where": {"deleted": {"neq": true}}, "order": "createdAt DESC", "include": "assignModules"}`;
  } else {
    filter = `?filter={"where": {"deleted": {"neq": true},"name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]}, "order": "createdAt DESC", "include": "assignModules"}`;
  }
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/Admins${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
  // return fetch(url).then((res) => res.json());
};

export const getBatches = (data) => {
  let filter
  const relation = `"include": [
            {
                "relation": "course",
                "scope": {
                    "include": [
                        { "relation": "country" },
                        { "relation": "batchmode" }
                    ]
                }
            }
        ]`; 
  if (data == undefined) {

    filter = `?filter={${relation},"where": {"deleted": {"neq": true}}, "order": "createdAt DESC"}`;

  } else {
    filter = `?filter={${relation},"where": {"deleted": {"neq": true},"name":{"regexp":"/${data?.input}/i"},"and":[{"createdAt":{"gte":"${data?.startDate}"}},{"createdAt":{"lte":"${data?.endDate}"} }]}, "order": "createdAt DESC"}`;
  }


  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batches${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const getPdcCharges = () => {
  const relation = `{
        "include": [{
            "relation": "country"
        }],
        "where": {
            "deleted": {
                "neq": true
            }
        },
        "order": "createdAt DESC"
    }`;
  const filter = `?filter=${relation}`;
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/pdcCharges${filter}`;
  return fetch(url, get(TOKEN)).then((res) => res.json());
};

export const addCourses = async (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courses/addCourse`;
  return await fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _addCase = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/cases/addCase`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const addDiscount = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/discountRequests`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _addCourseCategory = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courseCategories`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _addAddons = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/addOns`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _deleteAddons = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/addOns/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _deleteTags = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/tags/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _deleteCC = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courseCategories/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _deleteBatchMode = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batchModes/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _deleteBatches = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batches/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _deletePdcCharges = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/pdcCharges/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updateAddons = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/addOns/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updateBatchMode = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batchModes/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updateBatches = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batches/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updatetags = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/tags/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updateCourseCategory = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/courseCategories/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const _updatePdcCharges = (body, id) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/pdcCharges/${id}`;
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
};

export const sendOtp = (body) => {
  const url = `${CONSTANTS.baseUrl}/Admins/sendOtp`;
  return fetch(url, postWithoutToken(body)).then((res) => res.json());
};

export const loginConfirm = (body) => {
  const url = `${CONSTANTS.baseUrl}/Admins/adminLogin`;
  return fetch(url, postWithoutToken(body)).then((res) => res.json());
};

export const verifyOtp = (body) => {
  const url = `${CONSTANTS.baseUrl}/Admins/verifyOtp`;
  return fetch(url, postWithoutToken(body)).then((res) => res.json());
};

export const _updatePassword = (body) => {
  const url = `${CONSTANTS.baseUrl}/Admins/forgetPassword`;
  return fetch(url, post(body)).then((res) => res.json());
};

export const _logout = () => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/Admins/logout`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  return fetch(url, options).then((res) => {
    console.log(res);
  });
};

export const _addBatchModes = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batchModes`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _addTag = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/tags`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _addBatches = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/batches`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};

export const _addPdcCharges = (body) => {
  const TOKEN = getCookie("tokenId");
  const url = `${CONSTANTS.baseUrl}/pdcCharges`;
  return fetch(url, post(body, TOKEN)).then((res) => res.json());
};


export const httpGet = (url) => {
  const TOKEN = getCookie("tokenId");
  return fetch(url, get(TOKEN)).then((res) => res.json());
}

export const httpGetUnAuth = (url) => {
  return fetch(url, getWithoutToken).then((res) => res.json());
}

export const httpPost = (url, body) => {
  const TOKEN = getCookie("tokenId");
  return fetch(url, post(body, TOKEN)).then((res) => res.json()).catch(err => err);
}

export const httpPostImg = (url, body) => {
  console.log('image')
  const option = {
    method: 'POST',
    body: body
  }
  return fetch(url, option).then((res) => res.json());
}

export const httpPostunAuth = (url, body) => {
  const option = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  }
  return fetch(url, option).then((res) => res.json());
}

export const httpPatch = (url, body) => {
  const TOKEN = getCookie("tokenId");
  return fetch(url, patch(body, TOKEN)).then((res) => res.json());
}

export const httpUnPatch = (url, body) => {
  return fetch(url, { method: 'PATCH', body: JSON.stringify(body) }).then((res) => res.json());
}

export const httpDelete = (url) => {
  const TOKEN = getCookie("tokenId");
  return fetch(url, deleted(TOKEN)).then((res) => res.json());
}



let _xWindow;
export const storeWindow = (url) => {
  _xWindow = window.open(url, '_target', 'popup')
}

export const closeWindow = () => { _xWindow.close() }