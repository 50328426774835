import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { TextField } from '@material-ui/core';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddField(props) {



    const handleClose = () => {
        props.setOpen(false);
        props.setTitle(null)
        props.setUrl(null);
        props.setEdit(false)
    };

    return (
        <React.Fragment>
            {/* {props.edit ?
                <EditIcon className='text-[#2B44FF] cursor-pointer' variant="outlined" onClick={handleClickOpen} />
                : <AddCircleOutlineIcon className='text-[#2B44FF] cursor-pointer' variant="outlined" onClick={handleClickOpen} />
            } */}


            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props?.edit ? "Edit Field" : "Add New Field in " + props.url?.title}

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TextField
                        variant='outlined'
                        fullWidth
                        label="Title"
                        value={props.title}
                        onChange={(event) => {
                            props.setTitle(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        onClick={props.edit ? props.updateTilte : props.createTitle}>
                        {props.edit ? "Update" : "Add"}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}