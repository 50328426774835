import { Button, Dialog, DialogActions, DialogTitle, useScrollTrigger } from "@mui/material";
import * as React from "react";

function RandomDialog(props){

    const [open,setOpen] = React.useState(false);

    React.useEffect(()=>{

        setOpen(props?.openRandomDialog)

    },[props?.openRandomDialog])

    return(
        <>

            <Dialog open={open}>
                <DialogTitle>PAYMENT SCHEDULE</DialogTitle>
                <div className="px-7 flex flex-col text-lg">
                    {/* Are you sure to save Bounced Payment Schedule? */}
                    {/* <div className="text-sm">(Are you sure you want to save? After save you won't be able to update bounced payment schedule.)</div> */}
                    Are you sure you want to save? After save you won't be able to update bounced payment schedule.
                </div>
                <DialogActions className="flex justify-end items-center my-2 mx-7">
                <Button
                    // sx={{ marginY: "10px" }}
                    variant="outlined"
                    onClick={()=>{setOpen(false);props?.closeRandom(false)}}
                >
                    Cancel
                </Button>

                <Button
                    // sx={{ marginY: "10px" }}
                    variant="contained"
                    onClick={()=>{setOpen(false);props?.closeRandom(true)}}
                >
                    Save
                </Button>
            </DialogActions>
            </Dialog>

        </>
    )

}
export default RandomDialog