import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Button,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { baseUrl, frontEndUrl, patch, post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CasesDialog from '../../Popups/CasesDialog';
import AddCases from './AddCases';
import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DialogueBox from '../../Components/DialogueBox';
import CustomizedSnackbars from '../../Components/Snackbar';
import { successEvents } from '../../App';
export default function Cases() {
    const [access, setAccess] = useState();
    const [cases, setCases] = React.useState(null)

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
    const [searchInput, setSearchInput] = useState();
    const [dates, setDates] = useState();
    const [row, setRow] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterRowCount, setFilterRowCount] = useState(0);
    const [openCaseDialog, setOpenCaseDialog] = useState(false)
    const [edit, setEdit] = useState();
    const [confirmDialog, setConfirmDialog] = useState(null);
    const [tableLoading, setTableLoading] = useState(true);
    const [casesData, setCasesData] = useState(
        {
            name: "",
            email: "",
            countryId: "",
            mobile: "",
            gender: "",
            buddyId: "",
        }
    );
    const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)

    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const _currentModule = authState?.userData?.assignModules?.assignModule.find(module => module.name == "Open cases");
    console.log(_currentModule);

    // const [clearDate,setClearDate] =useState()

    const navigate = useNavigate();


    const handlesendRegistrationLink = async (id) => {
        try {
            const ress = await post('cases/registrationSMS', { "caseId": id })
            console.log(ress.data.message)
            if (ress.statusCode === 200) {

                setSnackBarOptions({
                    message: ress.data.message,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
            } else if (ress.statusCode === 422) {
                console.log(ress.message)
                setSnackBarOptions({
                    message: ress.data.message,
                    show: true,
                    severity: "error",
                    duration: 5000,
                });
            } else {
                setSnackBarOptions({
                    message: "somthing went wrong try again",
                    show: true,
                    severity: "error",
                    duration: 5000,
                });
            }
        } catch (e) {
            throw e
        }
    }
    const columns = [

        {
            field: 'name',
            headerName: 'NAME',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'gender',
            headerName: 'GENDER',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'mobile',
            headerName: 'MOBILE',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'email',
            headerName: 'EMAIL',

            minWidth: 150,
            flex: 1,
        },

        {
            field: "buddy",
            headerName: "BUDDY",
            minWidth: 150,
            flex: 1
        },


        {
            field: 'createdAt',
            headerName: 'CREATED AT',
            minWidth: 150,
            valueGetter: (params) => {


                return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
            },
        },
        {
            field: 'deleted',
            headerName: 'ACTION',

            minWidth: 150,
            flex: 1,
            renderCell: (params) => {


                return <div className='flex items-center gap-6' >

                    <Tooltip title={params?.row?.isRegistered ? "" : "send registration form"}>

                        <SendIcon className={params?.row?.isRegistered ? "" : 'text-[#3366FF] cursor-pointer'} onClick={(e) => { if (!params?.row?.isRegistered) { e.stopPropagation(); handlesendRegistrationLink(params?.row?.id); } }} />
                    </Tooltip>
                    <EditIcon style={{ display: access?.update || access?.write ? "block" : "none" }} className='text-[#3366ff] cursor-pointer'
                        onClick={(e) => {
                            e.stopPropagation();
                            setEdit(params?.row?.id)
                            setCasesData({
                                name: params?.row?.name,
                                email: params?.row?.email,
                                mobile: params?.row?.mobile,
                                gender: params?.row?.gender,
                                countryId: params?.row?.countryId,
                                buddyId: params?.row?.buddyId
                            })
                            setOpenCaseDialog(true)

                        }}
                    />
                    <Tooltip title='Delete from Open cases but you can access from old cases'>

                        <DeleteIcon className='text-[#3366ff]' onClick={(e) => {
                            e.stopPropagation();
                            setConfirmDialog(params?.row?.id)
                        }} style={{ display: access?.update || access?.write ? "block" : "none" }} />
                    </Tooltip>
                </div>
            },
        },

    ];

    // const cancelDialogAction = () => {
    //     setSearchActionDialog(false)
    //     setRefundReqActionDialog(false)
    // }
    const confirmDialogClose = () => {
        setConfirmDialog(null);
    }
    const confirmDelete = () => {
        handleDeleteCases(confirmDialog)
    }
    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }
    const getCases = async (search, dates, page) => {
        setTableLoading(true)
        const res = await post("cases/getAllActiveCases", { searchText: search, dateFilter: dates, page: page });
        if (res.statusCode === 200) {
            setRow(res?.data?.data);
            setTotalRowCount(res?.data?.totalCount);
            setFilterRowCount(res?.data?.filterCount)
        } else {
            setSnackBarOptions({
                message: "something went wrong refresh page",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
        setTableLoading(false)

    }
    const handleDeleteCases = async (id) => {
        const res = await patch(`cases/${id}`, { deleted: true })
        if (res.statusCode === 200) {
            getCases(searchInput, dates)
            setSnackBarOptions({
                message: "Case deleted successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "something went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
        setConfirmDialog(null)
    }
    const debounceSearch = useCallback(debounce(getCases, 300), []);
    const handleOnRowClick = async (row) => {
        console.log(row.row)
        navigate('caseDetails', { state: { id: row.row.id } })

    }
    useEffect(() => {
        debounceSearch(searchInput, dates, paginationModel);
    }, [searchInput, dates, paginationModel])
    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const rolesArr = modules?.find((m) => m?.name === "Open cases");
        console.log(rolesArr);

        setAccess(rolesArr)
    }, [])

    return (<>
        <div className=" flex flex-col flex-1" style={{ width: "100%" }}  >
            <div className='flex items-center justify-between  flex-wrap'>
                <Typography className='font-normal text-xl text-gray-500'>Open Cases ({totalRowCount})</Typography>
                <div className="flex items-center gap-5 flex-wrap">

                    <FormControl sx={{ width: "300px" }}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="sm" />
                                    </InputAdornment>
                                ),
                            }}
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </FormControl>
                    <DateRangePiker getData={getDate} />

                </div>
            </div>


            <DataGidTable loading={tableLoading} handleEvent={handleOnRowClick} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />

            {/* <SearchFilterDailog
                openSearchActionDialog={searchActionDialog}
                cancelDialogAction={() => cancelDialogAction()}
                handleDateChange={(data) => handleDateChange(data)}
                cases={cases}
                title={"REFUND REQUEST FILTER"}
            // onChangeHandler={(obj, selectOption) => onSelectFilterData(obj, selectOption)}
            /> */}

            <div style={{ display: access?.write ? "block" : "none" }} className=' fixed  bottom-32 right-20'>
                <Fab color="primary" aria-label="add" onClick={() => setOpenCaseDialog(true)}>
                    <AddIcon />
                </Fab>
            </div>

            <AddCases setSnackBarOptions={setSnackBarOptions} access={access} edit={edit} setEdit={setEdit} getCases={getCases} setCasesData={setCasesData} casesData={casesData} open={openCaseDialog} setOpen={setOpenCaseDialog} />
            <DialogueBox confirmDialog={confirmDialog} confirmDialogClose={confirmDialogClose} confirmDelete={confirmDelete} />
        </div>
    </>)

}

