import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Checkbox } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { patch } from '../../Services/services';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AssignModulesPanel(props) {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();
    // const [aj,setAj]= React.useState(1)
    const handleClickOpen = () => {
        setOpen(true);
        setData(props?.data?.assignModule)

    };
    const handleClose = () => {
        setOpen(false);
        setData(null)
        props.getRoles();
    };
 
    const save = async () => {
        const res = await patch(`assignModules/${props?.data?.id}`, { assignModule: data })
        if (res.statusCode === 200) {
            props.getRoles();
            handleClose();
        }
    }
    const handleWrite = async (item) => {
        const isIndex = (element) => element.name === item.name;
        const _index = data?.findIndex(isIndex);
        if (_index >= 0) {
            let temp = [...data];
            if ((!temp[_index].read || !temp[_index].update) && !temp[_index].write) {
                temp[_index].read = true;
                temp[_index].update = true;
            }
            temp[_index].write = temp[_index].write ? false : true;
            setData(temp);
            console.log(item, _index, temp[_index].write)
            // setAj(aj+1)
            // console.log(aj)
        }
    }
    const handleUpdate = async (item) => {
        const isIndex = (element) => element.name === item.name;
        const _index = data?.findIndex(isIndex);
        if (_index >= 0) {
            let temp = [...data];
            if ((!temp[_index].read && !temp[_index].update)) {
                temp[_index].read = true;

            }
            temp[_index].update = temp[_index].update ? false : true;
            setData(temp);
            // setAj(aj+1)
            // console.log(aj)
        }
    }
    const handleView = async (item) => {
        const isIndex = (element) => element.name === item.name;
        const _index = data?.findIndex(isIndex);
        if (_index >= 0) {
            let temp = [...data];

            temp[_index].read = temp[_index].read ? false : true;
            setData(temp);

        }
    }
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
            {props.access?.write || props.access?.update ?
                <AddCircleIcon className='text-[#4CAF50]' onClick={handleClickOpen} />
                :
                <RemoveRedEyeIcon className='text-[#4CAF50]' onClick={handleClickOpen} />}


            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Assign Modules
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className=' px-16 '>
                    <div className='flex justify-between  mb-10'>
                        <Typography className='text-[#36435044]' >Module name</Typography>
                        <div className='flex gap-3'>
                            <Typography className='text-[#36435044]'>Create</Typography>
                            <Typography className='text-[#36435044]'>Update</Typography>
                            <Typography className='text-[#36435044]'>View</Typography>
                        </div>



                    </div>

                    {data?.map((item, index) =>
                        <div className='flex justify-between items-center border-b-2'>
                            <Typography className='text-[#425A70]'>{item.name}</Typography>
                            <div className='flex gap-3'>
                                <Checkbox disabled={!(props.access?.write || props.access?.update)} onClick={() => handleWrite(item)} className='text-[#3366ff]' checked={item.write} />
                                <Checkbox disabled={!(props.access?.write || props.access?.update)} onClick={() => handleUpdate(item)} className='text-[#3366ff]' checked={item.update} />
                                <Checkbox disabled={!(props.access?.write || props.access?.update)} onClick={() => handleView(item)} className='text-[#3366ff]' checked={item.read} />
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' sx={{ backgroundColor: "#3366FF", display: (props.access?.write || props.access?.update) ? "black" : 'none' }} autoFocus onClick={save}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}