import { Box, Button, Paper, useScrollTrigger } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import DiscountDialog from "../Popups/DiscountDialog";
import * as React from "react";
import PaymentScheduleDialog from "../Popups/PaymentScheduleDialog";
import {
  httpDelete,
  httpGet,
  httpPatch,
  _deleteRefundRequest,
} from "../Services/api-services";
import CONSTANTS from "../constant.json";
import DynamicTable from "./DynamicTable";
import DialogueBox from "./DialogueBox";
import RefundRequestDialog from "../Popups/RefundRequestDialog";
import RefundPayDialog from "../Popups/RefundPayDialog";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import moment from "moment";
import InvoiceDialog from "../Popups/InvoiceDialog";
import { UserAuthState } from "../Services/RecoilService";
import { useRecoilState } from "recoil";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deletethis, get, post } from "../Services/services";
import DeleteDataConfirmDailog from "./DeleteDataConfirmDailog";
import { successEvents } from "../App";
import { useLocation } from "react-router-dom";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
function PaymentsPanel(props) {
  let currentCourse = props?.courseData;
  let fetchCase = props?.fetchCase;
  const location = useLocation();
  const _route = location?.pathname?.split('/')[2];
  const [refundCounfirm, setRefundCounfirm] = React.useState();
  const [confirm, setConfirm] = React.useState();
  const [discountDialog, setDiscountDialog] = React.useState(false);
  const [openCases, setOpenCases] = React.useState([]);
  const [refundCase, setRefundCase] = React.useState(false);
  const [refundDialog, setRefundDialog] = React.useState(false);
  const [discountId, setDiscountId] = React.useState("");
  const [refundId, setRefundId] = React.useState("");
  const [discountIndex, setDiscountIndex] = React.useState("");
  const [refundIndex, setRefundIndex] = React.useState("");
  const [paymentId, setPaymentId] = React.useState("");
  const [PaymentDialog, setPaymentDialog] = React.useState(false);
  const [excludeBounceSchedule, setExcludeBounceSchedule] = React.useState([]);
  const [SchedulePayments, setAllSchedulePayments] = React.useState([]);
  const [editData, setEditdata] = React.useState([]);
  const [SchedulePaymentsDataSource, setSchedulePaymentsDataSource] =
    React.useState([]);
  const [confirmDialogDiscount, setConfirmDialogDiscount] =
    React.useState(false);
  const [confirmDialogRefund, setConfirmDialogRefund] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);
  const [refundPayDialog, setRefundPayDialog] = React.useState(false);
  const [isRefundPaid, setIsRefundPaid] = React.useState(false);
  const [studenTotalDiscount, setStudentTotalDiscount] = React.useState({});
  const [adminBatchdiscount, setAdminBatchdiscount] = React.useState({});
  const [studenTotalRefund, setStudentTotalRefund] = React.useState({});
  const [adminBatchRefund, setAdminBatchRefund] = React.useState({});
  const [openInvoice, setOpenInvoice] = React.useState(false);
  const [installment, setInstallment] = React.useState(null);
  const [invoiceTitle, setInvoiceTitle] = React.useState('');
  const [subscribeCourses, setSubscribeCourses] = React.useState();
  const [amtPaid, setAmtPaid] = React.useState(0);
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const [discountr, setDiscountr] = React.useState(false);
  const { setSnackBarOptions } = React.useContext(successEvents)

  const _currentCaseStatus = _route === 'old_cases' ? true : false;
  const _currentCase = _route === 'open_cases' ? "Open cases" : "Old cases";
  const _currentModule = authState?.userData?.assignModules?.assignModule.find(module => module.name == _currentCase);

  React.useEffect(() => {
    getStudentTotaldiscount()
    getStudentTotalRefund();
    const modules = authState?.userData?.assignModules?.assignModule;
    const openCasesArr = modules.find((m) => m?.name === "Open cases");
    const discountR = props?.courseData?.discountRequest?.find((m) => m?.status === "pending")
    setDiscountr(discountR)
    setOpenCases(openCasesArr);
    let refund = props?.currentCourse?.refund?.find(
      (r) => r.status === "approved"
    );
    if (refund?.status === "approved") {
      setRefundCase(true);
    }
    // fetchSubscribe()
    // eslint-disable-next-line
    setData();

  }, [props?.showNav, props?.tabInd, props.courseData]);


  const setData = async () => {
    const res = await createTableDataPaymentSchedules(props?.courseData?.paymentSchedules)
    setSchedulePaymentsDataSource(res)
  }

  const currentURL = window.location.href;
  const currentPath = window.location.pathname;
  const domain = currentURL.split(currentPath)[0];

  const headCellsPaymentSchedules = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "S.no",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Amount",
    },
    {
      id: "mode",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Mode",
    },
    {
      id: "CreatedAt",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Date",
    },
    {
      id: "stats",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Status",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      sort: false,
      label: "Action",
    },
  ];

  const createTableDataPaymentSchedules = (data) => {
    // CREATE TABLE DATA - ADDONS
    console.log(data)
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const row = data[index];
          let obj;
          if (row?.type == "bounceCharges") {
            obj = {
              sno: index + 1,
              amount: props.courseData?.currency?.currencyName + ' ' + row.amount,
              mode: row.paymentModeName,
              CreatedAt: moment(new Date(row.date)).format('DD/MM/YYYY'),
              stats: row.status,
              id: row._id,
              action: true,
              type: row.type,
            };
          } else {
            obj = {
              sno: index + 1,
              amount: props.courseData?.currency?.currencyName + ' ' + row.amount,
              mode: row.paymentModeName,
              CreatedAt: moment(new Date(row.date)).format('DD/MM/YYYY'),
              stats: row.status,
              id: row._id,
              action: true,
            };
          }
          returnArray.push(obj);
          if (index === data.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };

  function cancelDialogAction() {
    setDiscountDialog(false);
  }

  function closePaymentDialog() {
    setPaymentDialog(false);
  }

  function requestDiscountObj(obj, discountReq) {
    props.paymentPanelDiscountReq(obj, discountReq);
  }

  function deleteDiscountReq(i, discountId) {
    props.deleteDiscountReq(i, discountId);
  }
  async function deleteRefundReq(refundId) {
    let body = {
      deleted: true,
    };
    let response = await _deleteRefundRequest(body, refundId);
    props.refreshCase();

  }

  function refreshCase() {
    props.refreshCase();
    getStudentTotaldiscount();
    getStudentTotalRefund();
    setData();
  }

  function deletePayment() {
    const url = `${CONSTANTS.baseUrl}/paymentSchedules/${paymentId}`;
    const body = {
      deleted: true,
    };
    const response = httpPatch(url, body);
    setConfirmDialog(false);
  }

  function editaction(e, data) {
    setPaymentId(data?.id);
    setPaymentDialog(true);
  }

 async function paymentLink(e, data) {
    console.log(
      `${domain}/paymentScreen/${data?.id}`
    );
    try{
   const ress = await post('cases/sendPaymentLink',{id:data?.id})
   if (ress.statusCode === 200) {
           
    setSnackBarOptions({
        message: ress.data.message,
        show: true,
        severity: "success",
        duration: 5000,
    });
} else if (ress.statusCode === 422) {
    console.log(ress.message)
    setSnackBarOptions({
        message:ress.data.message ,
        show: true,
        severity: "error",
        duration: 5000,
    });
} else {
    setSnackBarOptions({
        message: "somthing went wrong try again",
        show: true,
        severity: "error",
        duration: 5000,
    });
}
    }catch(e){
      console.log(e)
    }
  }
  function actionOnDelete(i, discountId) {
    setDiscountId(discountId);
    setDiscountIndex(i);
    setConfirmDialogDiscount(true);
  }

  function deleteRefundReqest(i, refundId) {
    setRefundId(refundId);
    setRefundIndex(i);
    setConfirmDialogRefund(true);
  }

  function openpdf(row, title) {
    setInstallment(row)
    setOpenInvoice(true)
    setInvoiceTitle(title)
  }

  function closepdf() {
    setOpenInvoice(false)
  }
  const getStudentTotaldiscount = async () => {

    const res = await get(`discountRequests?filter={"where":{"caseId":"${currentCourse?.caseId}","status":"approved"}}`);
    if (res?.statusCode === 200) {
      let totalDiscount = 0;
      res?.data?.map((item) => totalDiscount = totalDiscount + item?.approvedAmount)
      let body = { count: res?.data?.length, amount: totalDiscount }
      setStudentTotalDiscount(body);
    }
    const ress = await get(`discountRequests?filter={"where":{"adminId":"${authState?.userId}","batchId":"${currentCourse?.batchId}","status":"approved"}}`);
    if (ress?.statusCode === 200) {
      let totalDiscount = 0;
      ress?.data?.map((item) => totalDiscount = totalDiscount + item?.approvedAmount)
      let body = { count: ress?.data?.length, amount: totalDiscount }

      setAdminBatchdiscount(body)
    }

  }

  const getStudentTotalRefund = async () => {

    const res = await get(`refunds?filter={"where":{"caseId":"${currentCourse?.caseId}","status":"approved"}}`);
    if (res?.statusCode === 200) {
      let totalDiscount = 0;
      res?.data?.map((item) => totalDiscount = totalDiscount + item?.approvedAmount)
      let body = { count: res?.data?.length, amount: totalDiscount }
      setStudentTotalRefund(body);
    }
    const ress = await get(`refunds?filter={"where":{"adminId":"${authState?.userId}","batchId":"${currentCourse?.batchId}","status":"approved"}}`);
    if (ress?.statusCode === 200) {
      let totalDiscount = 0;
      ress?.data?.map((item) => totalDiscount = totalDiscount + item?.approvedAmount)
      let body = { count: ress?.data?.length, amount: totalDiscount }

      setAdminBatchRefund(body)
    }

  }
  const handleDeleteDiscountReq = async (data, id) => {
    if (data) {
      const ress = await deletethis(`discountRequests/${id}`);
      if (ress.statusCode === 200) {
        setSnackBarOptions({
          message: "Discount Deleted successfully!",
          show: true,
          severity: "success",
          duration: 5000,
        });
        props.refreshCase();
        setConfirm(null)
      } else if (ress.statusCode === 422) {
        console.log(ress.message)
        setSnackBarOptions({
          message: ress.message,
          show: true,
          severity: "error",
          duration: 5000,
        });
      } else {
        setSnackBarOptions({
          message: "somthing went wrong try again",
          show: true,
          severity: "error",
          duration: 5000,
        });
      }
    } else {
      setConfirm(null)
    }


  }
  const handleDeleteRefund = async (data, id) => {
    if (data) {
      const ress = await deletethis(`refunds/${id}`);
      if (ress.statusCode === 200) {
        setSnackBarOptions({
          message: "Refund Deleted successfully!",
          show: true,
          severity: "success",
          duration: 5000,
        });
        props.refreshCase();
        setRefundCounfirm(null)
      } else if (ress.statusCode === 422) {
        console.log(ress.message)
        setSnackBarOptions({
          message: ress.message,
          show: true,
          severity: "error",
          duration: 5000,
        });
      } else {
        setSnackBarOptions({
          message: "somthing went wrong try again",
          show: true,
          severity: "error",
          duration: 5000,
        });
      }
    } else {
      setRefundCounfirm(null)
    }


  }

  return (
    <div className="pb-10">
      <div>
        {currentCourse?.paymentSchedules?.length > 0 ? (
          <div>
            <DynamicTable
              dataSource={SchedulePaymentsDataSource}
              headCells={headCellsPaymentSchedules}
              actionOnEdit={(e, data) => editaction(e, data)}
              action={(openCases?.write || openCases?.update) && !currentCourse?.isRefund && !currentCourse?.caseData?.deleted ? true : false}
              actionOnSend={(e, data) => paymentLink(e, data)}
              pdf={true}
              download={true}
              openPDF={openpdf}
              _currentModule={_currentModule}
              tooltip={"send payment link"}
            />
          </div>
        ) : null}
        <DialogueBox
          message={'Delete Payment'}
          confirmDialog={confirmDialog}
          confirmDialogClose={() => setConfirmDialog(false)}
          confirmDelete={deletePayment}
        />
        {(!subscribeCourses?.feePaid) ? <>
          {!currentCourse?.refundStatus && !discountr ? (
            <Button
              sx={{ marginTop: "20px" }}
              onClick={() => setDiscountDialog(true)}
              variant="text"
              // disabled={!openCases?.write || currentCourse?.caseData?.deleted || !_currentModule?.update}
              disabled={_currentCaseStatus || !_currentModule?.update || currentCourse?.caseData?.deleted || !openCases?.write}
            >
              REQUEST DISCOUNT
              <KeyboardArrowRightIcon sx={{ fontSize: "18px" }} color={openCases?.write ? 'primary' : 'disabled'} />
            </Button>
          ) : (
            <Button sx={{ marginTop: "20px", color: "gray" }} variant="text">
              REQUEST DISCOUNT
              <KeyboardArrowRightIcon sx={{ fontSize: "18px", color: "gray" }} />
            </Button>
          )}</> : <></>}
        {!subscribeCourses?.feePaid ? <>
          <div className="mt-5">
            <div className="flex gap-5 flex-wrap mb-10">
              <div className="bg-[#F4F4F4] flex p-3 gap-2">
                <p className="text-[#606060]">Total discount requested by student- </p>  <p className="text-[#3366FF]">{studenTotalDiscount?.count}</p> <p className="text-[#EC4C47]">({currentCourse?.currency?.currencyName} {studenTotalDiscount?.amount})</p>
              </div>
              <div className="bg-[#F4F4F4] flex p-3 gap-2">
                <p className="text-[#606060]"> Discount accepted by counsellor in this batch-</p><p className="text-[#3366FF]">{adminBatchdiscount?.count}</p>  <p className="text-[#EC4C47]">({currentCourse?.currency?.currencyName} {adminBatchdiscount?.amount})</p>
              </div>
            </div>
            {currentCourse?.discountRequests?.map((_discount, i) => {

              return (
                <div className="mb-4" key={i}>

                  <div className="flex items-center gap-5">
                    <div className="flex items-center gap-3">
                      <p className="text-[#606060]">Requested :</p><p style={{ fontWeight: 600 }} className="text-[#2C2C2C]">{_discount?.requestedAmount}</p>
                    </div>

                    {_discount?.status === "approved" ?

                      <div className="flex items-center gap-3">
                        <p className="text-[#4CAF50]">Approved :</p><p style={{ fontWeight: 600 }} className="text-[#2C2C2C]">{_discount?.approvedAmount}</p>

                      </div>

                      : _discount?.status === "rejected" ?

                        <div className="flex items-center gap-3">
                          <p className="text-[#606060]">Rejected</p>
                        </div>
                        :
                        <div style={{ display: currentCourse?.caseData?.deleted || !(openCases?.write || openCases?.update) ? "none" : "flex" }} className=" items-center gap-3">
                          <DeleteIcon onClick={() => setConfirm(_discount._id)} className="text-[#EC4C47] cursor-pointer" />
                        </div>}

                  </div>

                </div>
              );
            })}
            <DialogueBox
              message={'Discount Request'}
              confirmDialog={confirmDialogDiscount}
              confirmDialogClose={() => setConfirmDialogDiscount(false)}
              confirmDelete={() => (
                props.deleteDiscountReq(discountIndex, discountId),
                setConfirmDialogDiscount(false)
              )}
            />
          </div>
        </> : <></>}
        {currentCourse?.paidAmount > 0 && !currentCourse?.isRefund ? (
          <Button
            sx={{ marginTop: "20px" }}
            onClick={() => setRefundDialog(true)}
            variant="text"
            // disabled={!openCases?.write || currentCourse?.caseData?.deleted || !_currentModule?.update}
            disabled={_currentCaseStatus || !_currentModule?.update || currentCourse?.caseData?.deleted || !openCases?.write}
          >
            REFUND REQUEST
            <KeyboardArrowRightIcon sx={{ fontSize: "18px" }} color={openCases?.write ? 'primary' : 'disabled'} />
          </Button>
        ) : (
          <Button sx={{ marginTop: "20px", color: "gray" }} variant="text">
            REFUND REQUEST
            <KeyboardArrowRightIcon sx={{ fontSize: "18px", color: "gray" }} />
          </Button>
        )}
        <div className="mt-5">
          <div className="flex gap-5 flex-wrap mb-10">
            <div className="bg-[#F4F4F4] flex p-3 gap-2">
              <p className="text-[#606060]">Total refund requested by student- </p>  <p className="text-[#3366FF]">{studenTotalRefund?.count}</p> <p className="text-[#EC4C47]">({currentCourse?.currency?.currencyName} {studenTotalRefund?.amount})</p>
            </div>
            <div className="bg-[#F4F4F4] flex p-3 gap-2">
              <p className="text-[#606060]"> Refund accepted by counsellor in this batch- </p><p className="text-[#3366FF]">{adminBatchRefund?.count}</p>  <p className="text-[#EC4C47]">({currentCourse?.currency?.currencyName} {adminBatchRefund?.amount})</p>
            </div>
          </div>
          {currentCourse?.refunds?.map((refund, i) => {

            return (
              <div className="mb-4" key={i}>

                <div className="flex items-center gap-5">
                  <div className="flex items-center gap-3">
                    <p className="text-[#606060]">Requested :</p><p style={{ fontWeight: 600 }} className="text-[#2C2C2C]">{refund?.requestedAmount}</p>
                  </div>

                  {refund?.status === "approved" ?

                    <div className="flex items-center gap-3">
                      <p className="text-[#4CAF50]">Approved :</p><p style={{ fontWeight: 600 }} className="text-[#2C2C2C]">{refund?.approvedAmount}</p>
                      {refund.paymentStatus === "paid" ?
                        <Box className="p-1 border-2  opacity-60 bg-[#1FAF381A] rounded-sm border-[#1FAF38] text-[#1FAF38]">paid</Box> : <Box className="p-1 border-2  opacity-60 bg-[#1FAF381A] rounded-sm border-[#1FAF38] text-[#1FAF38]">unpaid</Box>
                      }
                    </div>
                    : refund?.status === "rejected" ?

                      <div className="flex items-center gap-3">
                        <p className="text-[#606060]">Rejected</p>
                      </div>
                      :
                      <div style={{ display: currentCourse?.caseData?.deleted || !(openCases?.write || openCases?.update) ? "none" : "flex" }} className=" items-center gap-3">
                        <DeleteIcon onClick={() => setRefundCounfirm(refund?._id)} className="text-[#EC4C47] cursor-pointer" />
                      </div>}

                </div>

              </div>
            );
          })}
          {/* </div> */}
          <DialogueBox
            message={'Refund Request'}
            confirmDialog={confirmDialogRefund}
            confirmDialogClose={() => setConfirmDialogRefund(false)}
            confirmDelete={() => (
              deleteRefundReq(refundId), setConfirmDialogRefund(false)
            )}
          />
        </div>
        {/* <div>
          <div>
            {props?.currentCourse?.refund?.status}
            {props?.currentCourse?.refund?.requestedAmount}
            {props?.currentCourse?.refund?.status == "approved" ? (
              <Button
                sx={{ marginTop: "20px" }}
                onClick={() => setRefundPayDialog(true)}
                variant="text"
              >
                Pay
                <KeyboardArrowRightIcon
                  sx={{ fontSize: "18px" }}
                  color="primary"
                />
              </Button>
            ) : (
              <CancelIcon />
            )}
          </div>
        </div> */}
      </div>
      {
        !currentCourse?.isRefund && !currentCourse?.caseData?.deleted ? (
          <Button
            onClick={() => setPaymentDialog(true)}
            // disabled={!_currentModule?.update || openCases?.write || openCases?.update ? false : true}
            disabled={(!_currentModule?.update) || (openCases?.write || openCases?.update ? false : true) || _route === 'old_cases' ? true : false}
            variant="contained"
            sx={{
              borderRadius: "50px",
              height: "60px",
              width: "60px",
              position: "fixed",
              bottom: "40px",
              right: "40px",
            }}
          >
            {currentCourse?.SchedulePayments?.length < 1 ? (
              <AddIcon sx={{ fontSize: "30px" }} />
            ) : (
              <EditIcon sx={{ fontSize: "25px" }} />
            )}
          </Button>
        ) : ""
      }
      <DiscountDialog
        open={discountDialog}
        setDiscountDialog={setDiscountDialog}
        discountRequestObj={(obj, discountReq) =>
          requestDiscountObj(obj, discountReq)
        }
        discountAmount={true}
        courseData={props.courseData}
        refreshCase={refreshCase}
      // subscribedCourseId={props?.currentCourseId}
      // caseId={props?.currentCourse?.caseId}
      // batch={props?.currentCourse?.batchId}
      // totalFee={props?.currentCourse?.totalFee}
      // courseCategoryId={allCourses[course]?.courseCategoryId}
      />
      <PaymentScheduleDialog
        updateData={SchedulePayments}
        courseData={props.courseData}

        fetchCase={fetchCase}
        PaymentDialog={PaymentDialog}
        closePaymentDialog={closePaymentDialog}
        refreshCase={refreshCase}
        showNav={props?.showNav}
      />
      <RefundRequestDialog
        open={refundDialog}
        setDiscountDialog={setRefundDialog}
        discountRequestObj={(obj, discountReq) =>
          requestDiscountObj(obj, discountReq)
        }
        discountAmount={true}
        courseData={props.courseData}
        refreshCase={refreshCase}
      />
      <DeleteDataConfirmDailog Action={handleDeleteRefund} msg="Are you sure you want to delete the refund request? This action cannot be undone" heading={"Refund Request"} open={refundCounfirm} setOpen={setRefundCounfirm} />

      <DeleteDataConfirmDailog Action={handleDeleteDiscountReq} msg="Are you sure you want to delete the discount request? This action cannot be undone" heading={"Discount Request"} open={confirm} setOpen={setConfirm} />
      <InvoiceDialog props={{ ...props, invoiceCaseDialog: openInvoice }} installment={installment} closeInvoice={closepdf} invoiceTitle={invoiceTitle} />
    </div >
  );
}
export default PaymentsPanel;
