import React, { useEffect, useRef, useState } from 'react'
import CallIcon from '@mui/icons-material/Call';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GetAppIcon from '@mui/icons-material/GetApp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useReactToPrint } from "react-to-print";
import { deletethis, get, patch, post } from '../Services/services';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { UserAuthState } from "../Services/RecoilService";
import { useRecoilState } from 'recoil';

const aptLogo = require("../utilities/aptLogo.png");
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function OverviewPanel(props) {
  const [tegName, setTagName] = useState([]);
  const [tagId, setTagId] = useState([])
  const [callStatus, setCallstatus] = useState();
  const [tagData, setTagData] = useState([]);
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const invoiceRef = useRef()
  const _currentModule = authState?.userData?.assignModules?.assignModule.find(module => module.name == "Old cases");


  const addTag = async (item) => {
    let body = {
      subscribedCoursesId: props?.courseData?._id,
      tagId: item?.id,
      tag: item?.name,
    }
    const res = await post('subscribedTags', body);
    gettags();
  }
  const gettags = async () => {
    const res = await get(`subscribedTags?filter={"where":{"subscribedCoursesId":"${props.courseData?._id}"},"include": [{ "relation": "tags" }]}`);
    if (res.statusCode === 200) {
      setTagId(res?.data)
    }
  }

  const handleDeleteTag = async (id) => {
    const res = await deletethis(`subscribedTags/${id}`);
    if (res.statusCode === 200) {
      gettags();
    }
  }

  const getTag = async () => {
    const res = await get('tags?filter={"where":{"status":true}}')
    if (res?.statusCode === 200) {
      setTagData(res.data)
    }
  }
  const completedWelcomeCall = async () => {
    const res = await patch(`subscribedCourses/${props?.courseData?._id}`, { welcomeCall: true });

    if (res.statusCode === 200) {
      refreshCase();
    }
  }
  const addWhatsappGroup = async () => {
    const res = await patch(`subscribedCourses/${props?.courseData?._id}`, { whatsappGroup: true });

    if (res.statusCode === 200) {
      refreshCase();
    }
  }
  const getStatus = async () => {
    let call;
    let whatsapp;
    props.courseData?.admissionProcess?.map((item) => { if (item?.processName == "Whatsapp group joined" && item?.status !== "pending") { whatsapp = true }; if (item?.processName == "Welcome call" && item?.status !== "pending") { call = item.status } })
    setCallstatus({ call: call, whatsapp: whatsapp })
  }

  // const print = () => {
  //   var content = document.getElementById("divcontents");
  //   var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  //   pri.document.open();
  //   pri.document.write(content.innerHTML);
  //   pri.document.close();
  //   pri.focus();
  //   pri.print();
  // }
  const refreshCase = async () => {
    props.refreshCase();
    getTag();
    gettags();
    getStatus();
  }
  useEffect(() => {
    refreshCase()
  }, [props?.showNav, props.tabInd]);
  useEffect(() => {
    getStatus();
  }, [props.courseData])

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current
  });

  const invoiceColumn = props?.fetchCase?.case?.state === 'Madhya Pradesh' ? ["AMOUNT", "CGST", "SGST", "IGST", "SUBTOTAL"] : ["AMOUNT", "IGST", "SUBTOTAL"];

  return (
    <div >
      <Box className=' gap-5 flex justify-between flex-wrap'>
        <Box className='flex gap-2 items-center'>
          <CallIcon />
          <p>Have you completed Welcome call?</p>
          {props?.courseData?.welcomeCall ? <CheckCircleIcon className='text-[#4CAF50]' /> : <Button disabled={!(props?.openCases?.update || props?.openCases?.write) || props.courseData?.caseData?.deleted || props.courseData?.isRefund || !_currentModule?.update} onClick={() => completedWelcomeCall(props?.courseData?._id)} color="error" size='small' variant='outlined'>Yes</Button>}
        </Box>
        <Box className='flex gap-2 items-center '>
          <WhatsAppIcon className=' text-slate-500' />
          <p>Did student join WhatsApp group?</p>
          {props?.courseData?.whatsappGroup ? <CheckCircleIcon className='text-[#4CAF50]' /> : <Button disabled={!(props?.openCases?.update || props?.openCases?.write) || props.courseData?.caseData?.deleted || props.courseData?.isRefund || !_currentModule?.update} onClick={() => addWhatsappGroup(props?.courseData?._id)} color="error" size='small' variant='outlined'>Yes</Button>}
        </Box>
        <Box className='flex gap-2 items-center'>
          <FormControl className=' w-52' size='small'>
            <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
            <Select
              disabled={!(props?.openCases?.update || props?.openCases?.write) || props.courseData?.caseData?.deleted || props.courseData?.isRefund || !_currentModule?.update}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={tegName}
              input={<OutlinedInput label="Tags" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {tagData?.map((item) => (
                <MenuItem key={item.name} value={item}>
                  <Checkbox checked={tagId?.findIndex(i => i.tagId === item.id) > -1} disabled={tagId?.findIndex(i => i.tagId === item.id) > -1} onChange={() => addTag(item)} />

                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className='flex gap-2 py-5  flex-wrap'>
        {tagId?.map((item) =>
          <Box className='p-3 relative'>
            <Box className='p-2 bg-[#66788A1F] text-[#66788A] border-2 border-[#66788A] border-opacity-10 rounded-sm  '>
              <p> {item?.tags?.name}</p>

            </Box>
            {!props?.courseData?.refundStatus ? <ClearIcon className=' text-[#66788A] absolute top-0 right-0 cursor-pointer' onClick={() => handleDeleteTag(item?.id)} /> : ""}
          </Box>
        )}
      </Box>
      <Box className='mt-10 p-5 m-5 border-2 border-[#F1F1F1] ' ref={invoiceRef}>

        <div className='flex justify-between items-center'>
          <p className='text-[#585858] font-bold'>INVOICE</p>
          <img className='w-10' src={aptLogo} />
        </div>
        <div className='py-5 flex flex-col gap-1'>
          <p className='text-[#585858] text-sm'>Invoice no : {props?.courseData?.invoiceNumber} </p>
          <p className='text-[#585858] text-sm'>To : {props?.fetchCase?.case?.name} </p>
          <p className='text-[#585858] text-sm'>Date : {moment(new Date()).format("L")} </p>
        </div>
        <Box className='bg-[#F4F4F4] flex h-10 px-2 justify-between '>


          <Box className='flex gap-5 px-2'>
            <Box className='flex items-center'>
              <p className="text-[#585858] text-sm">Course Fee : </p>
              <p className="text-[#585858] text-lg">{props.courseData?.currency?.currencyName} {props?.courseData?.courseAmount}</p>
            </Box>
            <Box className='flex items-center'>
              <p className="text-[#585858] text-sm">Bundle Discount : </p>
              <p className="text-[#ED342D] text-lg">{props.courseData?.currency?.currencyName} {props?.courseData?.bundleDiscount}</p>
            </Box>
            <Box className='flex items-center'>
              <p className="text-[#585858] text-sm">Student Discount : </p>
              <p className="text-[#ED342D] text-lg"> {props.courseData?.currency?.currencyName} {props?.courseData?.studentDiscount}</p>
            </Box>
          </Box>
          <Box className='flex items-center'>
            <p className="text-[#585858] text-sm">After Discount : </p>
            <p className="text-[#585858] text-lg"> {props.courseData?.currency?.currencyName} {props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount}</p>
          </Box>
        </Box>
        <Box className='pt-5 '>
          {props?.fetchCase?.case?.state}
          <TableContainer component={Box}>
            <Table sx={{
              border: "2px solid #F1F1F1",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                borderRight: "2px solid #F1F1F1"
              },
            }} aria-label="simple table">
              <TableHead>
                <TableRow className='bg-[#F1F1F1]'>
                  <TableCell>DESCRIPTION</TableCell>
                  {invoiceColumn?.map((item) => {
                    return (
                      // item === 'CGST' || item === 'SGST' ?
                      //   (props?.fetchCase?.case?.state ?
                      //     <TableCell align="right">{item} ({props.courseData?.currency?.currencyName})</TableCell>
                      //     : null
                      //   )
                      //   :
                      <TableCell align="right">{item} ({props.courseData?.currency?.currencyName})</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow

                  sx={{ borderBottom: 'none', borderTop: 'none' }}
                >
                  <TableCell component="th" scope="row">
                    <p className='text-[#585858]'>Course:</p>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  {props?.fetchCase?.case?.state === 'Madhya Pradesh' && (
                    <>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow

                // sx={{ '&:last-child td, &:last-child th': { border: 0,  },}}
                >
                  <TableCell component="th" scope="row">
                    {props.courseData?.courses?.name}
                  </TableCell>
                  <TableCell align="right">{(props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount) - (((props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount) / 100) * parseFloat(props?.courseData?.courses?.tax))}</TableCell>
                  {/* <TableCell align="right">{(((props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount) / 100) * (parseFloat(props?.courseData?.courses?.tax) / 2))?.toFixed(4)} ({parseFloat(props?.courseData?.courses?.tax) / 2}%)</TableCell> */}
                  {props?.fetchCase?.case?.state === 'Madhya Pradesh' &&
                    <TableCell align="right">
                      {`${(((props?.courseData?.courseAmount || 0) - (props?.courseData?.studentDiscount || 0) - (props?.courseData?.bundleDiscount || 0)) / 100 * (parseFloat(props?.courseData?.courses?.tax || 0) / 2))?.toFixed(4)} (${parseFloat(props?.courseData?.courses?.tax || 0) / 2}%)`}
                    </TableCell>
                  }
                  {props?.fetchCase?.case?.state === 'Madhya Pradesh' &&
                    <TableCell align="right">
                      {`${(((props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount) / 100) * (parseFloat(props?.courseData?.courses?.tax) / 2))?.toFixed(4)} (${parseFloat(props?.courseData?.courses?.tax) / 2}%)`}
                    </TableCell>
                  }
                  {/* <TableCell align="right">{(((props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount) / 100) * (parseFloat(props?.courseData?.courses?.tax) / 2))?.toFixed(4)} ({parseFloat(props?.courseData?.courses?.tax) / 2}%)</TableCell> */}
                  <TableCell align="right">{(((props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount) / 100) * parseFloat(props?.courseData?.courses?.tax))?.toFixed(4)} ({parseFloat(props?.courseData?.courses?.tax)}%)</TableCell>
                  <TableCell align="right">{props.courseData?.currency?.currencyName} {props?.courseData?.courseAmount - props?.courseData?.studentDiscount - props?.courseData?.bundleDiscount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ borderTop: "1px solid #F1F1F1" }}
                >
                  <TableCell component="th" scope="row">
                    <p className='text-[#585858]'>Addons:</p>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  {props?.fetchCase?.case?.state === 'Madhya Pradesh' && (
                    <>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </>
                  )}
                </TableRow>

                {props.courseData?.addOns.map((row) => (
                  <TableRow
                    key={row.name}
                  // sx={{ borderBottom:"1px solid black"}}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>

                    <TableCell align="right">{row?.price - ((row?.price / 100) * row?.tax)}</TableCell>

                    {/* <TableCell align="right">{((row?.price / 100) * (row?.tax / 2))?.toFixed(4)} ({row?.tax / 2}%)</TableCell> */}
                    {props?.fetchCase?.case?.state === 'Madhya Pradesh' &&
                      <TableCell align="right">
                        {`${((row?.price / 100) * (row?.tax / 2))?.toFixed(4)} (${(row?.tax / 2)}%)`}
                      </TableCell>
                    }
                    {/* <TableCell align="right">{props?.fetchCase?.case?.state === 'Madhya Pradesh' ? {((row?.price / 100) * (row?.tax / 2))?.toFixed(4)} ({row?.tax / 2}%):'-'}</TableCell> */}
                    {/* <TableCell align="right">{((row?.price / 100) * (row?.tax / 2))?.toFixed(4)} ({row?.tax / 2}%)</TableCell> */}
                    {props?.fetchCase?.case?.state === 'Madhya Pradesh' &&
                      <TableCell align="right">
                        {`${((row?.price / 100) * (row?.tax / 2))?.toFixed(4)} (${(row?.tax / 2)}%)`}
                      </TableCell>
                    }
                    <TableCell align="right">{((row?.price / 100) * row?.tax)?.toFixed(4)} ({row?.tax}%)</TableCell>
                    <TableCell align="right">{row?.price}</TableCell>

                  </TableRow>
                ))}
                <TableRow
                  className='bg-[#F1F1F1]'
                  sx={{ borderTop: "1px solid #F1F1F1" }}
                >
                  <TableCell component="th" scope="row">
                    <p style={{ fontWeight: 400 }}> GRAND TOTAL</p>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  {props?.fetchCase?.case?.state === 'Madhya Pradesh' && (
                    <>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </>
                  )}
                  <TableCell align="right"><div className='flex gap-2 w-full justify-end'><p style={{ fontWeight: 700 }}>{props.courseData?.currency?.currencyName} {props?.courseData?.totalFee}</p>
                  </div></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <div className='flex justify-end'>
        <Button variant='contained' className="flex gap-2" onClick={() => { handlePrint() }}>

          <GetAppIcon className='text-[rgb(242,247,246)]' /> <p>download Invoice</p>
        </Button>
      </div>
      <div className="h-16 flex justify-end">

      </div>
    </div>
  )
}
