import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { patch } from '../../Services/services';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    // width:"50vw"
}));

export default function RefundRequestsApprove(props) {

    const [edit, setEdit] = React.useState(true);
    const [status, setStatus] = React.useState("pending");
    const [approvedAmount, setApprovedAmount] = React.useState();
    const [schedule, setSchedule] = React.useState();
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [isLoading, setIsLoading] = React.useState(false)

    const handleApprove = async () => {
        setIsLoading(true)
        const res = await patch(`refunds/${props?.selected?.id}`, { approvedAdminId: authState?.userId, status: status, paymentStatus: status === "rejected" ? null : "unpaid", approvedDate: status === "rejected" ? null : new Date(), approvedAmount: status === "rejected" ? null : approvedAmount, paymentScheduleDate: schedule })
        setIsLoading(false)
        if (res.statusCode === 200) {
            handleClose();
            props.getDiscount()
        }
    }
    const handleClose = () => {
        props.setOpen(false);
        setStatus("pending");
        setApprovedAmount();

    };

    return (
        <React.Fragment>
            {console.log(props?.selected)}
            <BootstrapDialog
                fullWidth

                // maxWidth={300}
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Refund approval
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent fullWidth dividers>
                    <Box className='flex flex-col gap-5'>
                        <Box className='flex gap-3 flex-wrap'>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Fee -</p><p className='text-[#707070] text-[15px]'>{props?.selected?.subscribedCourses?.currency?.currencyName}</p> <p className='text-[#2B44FF] text-[15px]'>{props?.selected?.totalFee}</p>
                            </Box>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Paid - </p> <p className='text-[#707070] text-[15px]'>{props?.selected?.subscribedCourses?.currency?.currencyName}</p> <p className='text-[#4CAF50] text-[15px]'> {props?.selected?.subscribedCourses?.paidAmount}</p>
                            </Box>

                        </Box>




                        <Box>
                            <p className='text-[#707070] text-[12px]'>Reason</p>
                            <p className='text-[#000000] text-[12px] ' style={{ fontWeight: 500 }}>{props?.selected?.refundReason} </p>
                        </Box>
                    </Box>
                    <Box className='flex gap-3 py-10'>
                        <TextField
                            disabled={edit}
                            size='small'
                            fullWidth
                            error={approvedAmount > props?.selected?.requestedAmount}
                            helperText={approvedAmount > props?.selected?.requestedAmount ? "greater than requested amount" : ""}
                            value={approvedAmount}
                            defaultValue={props?.selected?.requestedAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><BorderColorIcon className='text-[#000000]' onClick={() => setEdit(!edit)} /></InputAdornment>,
                            }}
                            onChange={(e) => setApprovedAmount(e.target.value)}
                        />
                        <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">STATUS</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                defaultValue={props?.selected?.status}
                                value={status}
                                label="STATUS"
                                onChange={(e) => {
                                    setStatus(e.target.value)
                                    console.log(e.target.value)
                                    if (e.target.value === "pending" || e.target.value === "rejected") {
                                        setApprovedAmount(props.selected?.requestedAmount);
                                        setEdit(true);

                                    }
                                }}
                            >

                                <MenuItem value={"pending"}>pending</MenuItem>
                                <MenuItem value={"approved"}>approved</MenuItem>
                                <MenuItem value={"rejected"}>rejected</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            type='date'
                            size='small'
                            label="Schedule"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setSchedule(e.target.value)}
                            value={schedule}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleApprove}
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={status === "pending" || approvedAmount > props?.selected?.requestedAmount || !approvedAmount || !schedule || isLoading}
                        autoFocus
                    >
                        {isLoading ?
                            <CircularProgress size={16} /> :
                            'approve'
                        }
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}