import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {

  _addSmsLog,

} from "../Services/api-services";

import CustomizedSnackbars from "../Components/Snackbar";

import RandomDialog from "./randomDialog";
import { debounce } from 'lodash'
import { get, patch, post } from "../Services/services";
import moment from "moment";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
function PaymentScheduleDialog(props) {
  const [formData, setFormData] = useState();
  const [totalSchedule, setTotalSchedule] = useState(0);
  const [paymentMethod, setPaymentMethod] = React.useState([]);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [history,setHistory] = useState([]);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const tempdata = {
    date: "",
    paymentMode: "",
    status: "",
    amount: "",
  }
  const getPaymentStatus = async () => {
    const res = await get('fields?filter={"where":{"status":true,"fieldType":"6549fec7d7788c592187b6c7"}}');
    if (res.statusCode === 200) {
      setPaymentMethod(res?.data)
    }
  }
  const updateschedulespaymentStatus = async (status) => {
    const res = await patch(`subscribedCourses/${props?.courseData?._id}`, { paymentSchedule: status });
    if (res.statusCode === 200) {
      // refreshCase();
    }
  }
  const handleSave = async () => {
    console.log(formData)
    if ((props?.courseData?.totalFee) - (totalSchedule) === 0) {
      updateschedulespaymentStatus(true)
    } else {
      updateschedulespaymentStatus(false)
    }
    for (let index = 0; index < formData.length; index++) {
      if (formData[index].status !== "paid" || formData[index].tempstatus === "paid" || formData[index]?.status === "bounce" || formData[index]?.status === "pending") {
        if (formData[index]._id) {
          let temp = formData[index];

          let body = {

            amount: temp.amount,
            date: temp?.date,
            paymentMode: temp?.paymentMode,
            status: temp.status,
            chequeBounce: temp?.chequeBounce,
          }

          const res = await patch(`paymentSchedules/${temp?._id}`, body)
          if (res?.statusCode === 200) {

            if (formData[index]?.tempstatus === "paid") {
              let tranBody = {
                caseId: res?.data?.caseId,
                subscribedCoursesId: res?.data?.subscribedCoursesId,
                currency: res?.data?.currency,
                conversionRate: res?.data.conversionRate,
                paymentMode: res?.data?.paymentMode,
                amount: res?.data?.amount,
                paymentType: "fee",

              }
              // const ress = await post('transactions', tranBody)
            }

            props.refreshCase();
            setFormData();
            props.closePaymentDialog();



          }



        } else {
          console.log("post", formData[index])
          let temp = formData[index];
          let course = props?.courseData;
          let body = {

            amount: temp.amount,
            date: temp?.date,
            caseId: course?.caseId,

            currency: course?.currency?._id,
            conversionRate: course?.conversionRate,
            disabled: false,
            isRefund: false,
            paymentMode: temp?.paymentMode,
            status: temp.status,
            subscribedCoursesId: course?._id,
            chequeBounce: temp?.chequeBounce,
          }
          const res = await post('paymentSchedules', body)
          if (res.statusCode === 200) {


            if (formData[index]?.tempstatus === "paid") {
              let tranBody = {
                caseId: res?.data?.caseId,
                subscribedCoursesId: res?.data?.subscribedCoursesId,
                currency: res?.data?.currency,
                conversionRate: res?.data.conversionRate,
                paymentMode: res?.data?.paymentMode,
                amount: res?.data?.amount,
                paymentType: "fee",

              }
              // const ress = await post('transactions', tranBody)
            }
            props.refreshCase();
            setFormData();
            props.closePaymentDialog()
          }
        }
      }

    }

  }

  const handleDateChange = (value, index) => {
    let temp = [...formData];
    temp[index].date = value;
    setFormData(temp)
  }
  const handleAmountChange = (value, index) => {
    let temp = [...formData];
    temp[index].amount = parseInt(value) ? parseInt(value) : 0;
    setFormData(temp)
  }

  const handleChangePaymentMode = (value, index) => {
    let temp = [...formData];
    temp[index].paymentMode = value;
    setFormData(temp)
  }


  const handleChangeStatus = (value, index) => {
    if (value === 'bounce') {
      setOpen({ value: value, index: index })
    } else {

      let temp = [...formData];
      temp[index].status = value;
      temp[index].tempstatus = "paid"

      setFormData(temp)
    }
  }


  const hanldeDelete = async (index) => {
    let temp = [...formData];
    temp.splice(index, 1);
    setFormData(temp);
  }
  const handleAddNew = async () => {
    let temp = [...formData];
    temp.push(tempdata);
    setFormData(temp);
  }
  const closeRandom = (d) => {
    console.log(d)
    if (d) {
      let temp = [...formData];
      temp[open.index].status = open?.value;
      temp[open.index].tempstatus = "paid"
      let data = { ...temp[open.index] }
      data.status = ""
      delete data._id
      let charge = {
        date: "",
        paymentMode: "",
        status: "",
        amount: props.courseData?.courses?.currencys?.chequeBounceCharge,
        chequeBounce: true,
      }
      temp = [...temp, charge, data]

      setFormData(temp)

    }
    setOpen(false)
  }

  useEffect(() => {
    if (props.PaymentDialog) {
      let temp = props?.courseData?.paymentSchedules.map((item) => { item.date = moment(item.date).format('YYYY-MM-DD'); return item })
      setFormData(temp)
      getPaymentStatus();
    }
  }, [props.PaymentDialog])

  useEffect(() => {
    let i = 0;
    let disabled = false;
    for (let index = 0; index < formData?.length; index++) {
      console.log(formData[index]?.amount !== 0)
      if (!(formData[index].date?.length > 0 && formData[index]?.status?.length > 0 && formData[index]?.paymentMode?.length > 0 && formData[index]?.amount !== 0)) {
        disabled = true;
      } else {

      }
      console.log(formData)
      if (!formData[index].chequeBounce && formData[index].status!=="bounce") {
        i = i + parseInt(formData[index].amount ? formData[index].amount : 0);
      }


    }
    setDisabled(disabled)
    setTotalSchedule(i);

  }, [formData])

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" open={props.PaymentDialog} >
        <DialogTitle sx={{ color: "gray" }}>PAYMENT SCHEDULE</DialogTitle>
        <Divider />
        <DialogContent>
          <Box>
            <Box className='flex gap-3 flex-wrap'>

              <Box className='flex gap-1 items-center'>
                <p className='text-[#707070] text-[12px]'>course - </p> <p className='text-[#4CAF50] text-[15px]'> {props?.courseData?.courseName}</p>
              </Box>
              <Box className='flex gap-1 items-center'>
                <p className='text-[#707070] text-[12px]'>Fee -</p><p className='text-[#707070] text-[15px]'>{props?.courseData?.currency?.currencyName}</p> <p className='text-[#2B44FF] text-[15px]'>{props?.courseData?.totalFee}</p>
              </Box>
            </Box>

            <Box className="bg-[#F7F7F7] p-2 ">
              {formData?.map((item, index) =>

                <Box className="flex gap-5 items-center py-5">
              


                    <TextField type="date"
                      disabled={(item.status === "paid" && item.tempstatus !== "paid") || item.status === "bounce"}
                      fullWidth
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      label='DATE'
                      value={item?.date}
                      onChange={(e) => handleDateChange(e.target.value, index)}
                    />
                
                 
                  <TextField
                    disabled={(item.status === "paid" && item.tempstatus !== "paid") || item.status === "bounce"}
                    size="small"
                    fullWidth
                    required
                    label={item?.chequeBounce ? "cheque Bounce Charge" : 'AMOUNT'}
                    value={item.amount}
                    onChange={(e) => handleAmountChange(e.target.value, index)}
                  />
                  <FormControl disabled={(item.status === "paid" && item.tempstatus !== "paid") || item.status === "bounce"} required fullWidth size="small">
                    <InputLabel id="demo-select-small-label">PAYMENT MODE</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"

                      value={item?.paymentMode}
                      label="PAYMENT MODE"
                      onChange={(e) => handleChangePaymentMode(e.target.value, index)}
                    >
                      {paymentMethod?.map((item) =>
                        <MenuItem value={item?.id}>{item?.title}</MenuItem>
                      )}


                    </Select>
                  </FormControl>
                  <FormControl disabled={(item.status === "paid" && item.tempstatus !== "paid") || item.status === "bounce"} required fullWidth size="small">
                    <InputLabel id="demo-select-small-label">STATUS</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"

                      value={item?.status}
                      label="STATUS"
                      onChange={(e) => handleChangeStatus(e.target.value, index)}
                    >

                      {item.paymentMode === "66054b280ec67f1d01252713" ?
                        ["pending", "paid", "bounce"]?.map((item) =>
                          <MenuItem value={item}>{item}</MenuItem>
                        )
                        :
                        ["pending", "paid"]?.map((item) =>
                          <MenuItem value={item}>{item}</MenuItem>
                        )}


                    </Select>
                  </FormControl>
                  <div  className={item?.dateArr?.length>0?"text-[#3366FF] cursor-pointer":""}>
                      <HistoryToggleOffIcon
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e)=>{handleClick(e); setHistory(item?.dateArr)}}
           
                      />
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {history?.map((items) =>
                          <MenuItem onClick={handleClose}>{new Date(items)?.toUTCString()?.slice(0,-12)}</MenuItem>

                        )}
                      </Menu>
                    </div>
                  <RemoveCircleOutlineIcon className={item?._id || item?.chequeBounce ? " " : "text-[#3366FF] cursor-pointer"} onClick={() => { if (!item._id && !item?.chequeBounce) { hanldeDelete(index) } }} />
                </Box>
              )
              }

              <Box className='flex justify-between py-5'>
                <Box>
                  <Box className="flex gap-1"><p>{totalSchedule}/{props?.courseData?.totalFee}</p><p className="text-[#707070]">Created</p> </Box>
                  <Box className="flex gap-1"><p className="text-[#4CAF50]">{((props?.courseData?.totalFee) - (totalSchedule))} </p><p className="text-[#707070]">Remaining</p></Box>
                </Box>
                <Button onClick={handleAddNew}><AddIcon /> Add</Button>
              </Box>

            </Box>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => { setFormData(); props.closePaymentDialog();  props.refreshCase(); }}
            sx={{ marginY: "10px", minWidth: "0px" }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={props?.courseData?.totalFee < totalSchedule || !(formData?.length >= 0) || disabled}
            onClick={handleSave}
            sx={{ marginY: "10px" }}
            variant="contained"
          >
            save
          </Button>
        </DialogActions>

      </Dialog>


      <RandomDialog openRandomDialog={open} closeRandom={closeRandom} />

    </div>
  );
}
export default PaymentScheduleDialog;
