import { Card } from "@mui/material";
function AnalyticsStatisticsCard(props) {

  // const currencyText = (am) => {
  //    let amount = am.toString()
  //  let lastThree = amount.substring(amount.length-3);
  //    let otherNumbers = amount.substring(0,amount.length-3);
  //   if(otherNumbers != '') lastThree = ',' + lastThree;
  //    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  //   return res;
  //   }


  return (
    <div>
      <Card sx={{ minWidth: 200, height: 168 }}>
        <div className="h-32 my-9 flex-col">
          <h2 className="text-xl ml-7 text-gray-500">{props.headText}</h2>
          {props.headText === 'Enrollements' ? <h2 className="text-3xl mt-3 ml-7">{props.number}</h2> :
            <h2 className="text-3xl mt-3 ml-7">&#8377;{props?.number?.toLocaleString()}</h2>}
        </div>
      </Card>
    </div>
  )
}
export default AnalyticsStatisticsCard;