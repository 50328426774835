import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import * as React from "react";
import CustomizedSnackbars from "../Components/Snackbar";
import Button from "@mui/material/Button";
import { DialogContent, Divider } from "@mui/material";
import { httpPatch } from "../Services/api-services";
import CONSTANTS from "../constant.json";

function ChangePasswordStaff(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState("");
  const [forgotReenterPassword, setForgotReenterPassword] = React.useState("");
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function clearForm() {
    setShowPassword(false);
    setForgotPassword("");
    setForgotReenterPassword("");
  }
  function onCancelAction() {
    clearForm();
    props.onCancelAction();
  }

  async function updatePassword() {
    if (forgotPassword.length < 6) {
      setSnackBarOptions({
        message: "Password length should be greater than 5 characters",
        duration: 5000,
        show: true,
        severity: "error",
      });
    } else {
      if (forgotPassword === forgotReenterPassword) {
        const body = {
          password: forgotPassword,
        };
        const url = `${CONSTANTS.baseUrl}/Admins/${props?.actionData?.id}`;
        const response = await httpPatch(url, body);
        onCancelAction();
        setSnackBarOptions({
          message: "Password Changed Successfully",
          duration: 5000,
          show: true,
          severity: "success",
        });
      } else {
        setSnackBarOptions({
          message: "Password didn't match",
          duration: 5000,
          show: true,
          severity: "error",
        });
      }
    }
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" open={props.changePasswordDialog}>
        <DialogTitle sx={{ color: "gray" }}> CHANGE PASSWORD</DialogTitle>
        <Divider />
        <DialogContent>
          <div>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="forgotpassword">Password</InputLabel>
              <OutlinedInput
                id="forgot password"
                type={showPassword ? "text" : "password"}
                value={forgotPassword}
                onChange={(e) => setForgotPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl
              sx={{ width: "100%", marginTop: "20px" }}
              variant="outlined"
            >
              <InputLabel htmlFor="forgotReEnterpassword">
                Re-enter Password
              </InputLabel>
              <OutlinedInput
                id="forgot reenter password"
                onKeyDown={(e) =>
                  e.key === "Enter" ? updatePassword() : ""
                }
                type={showPassword ? "text" : "password"}
                value={forgotReenterPassword}
                onChange={(e) => setForgotReenterPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Re-enter Password"
              />
            </FormControl>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={onCancelAction}
            sx={{ marginY: "10px", minWidth: "0px" }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={updatePassword}
            sx={{ marginY: "10px" }}
            variant="contained"
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default ChangePasswordStaff;
