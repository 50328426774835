import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Fab, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { DesimalValue, StringWithSpace, emailRegex, phoneNumberRegexAed, phoneNumberRegexIndia, wholeNumberRegex } from '../../Regex/Regex';
import { baseUrl, get, patch, post } from '../../Services/services';
import EditIcon from '@mui/icons-material/Edit';
import { successEvents } from '../../App';
import CircularProgress from '@mui/material/CircularProgress';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddRoles(props) {
    //   const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState();
    const imageRef = React.useRef();
    const [showPassword, setShowpassword] = React.useState(false);
    const [countries, setCountries] = React.useState([]);
    const [countryData, setCountryData] = React.useState();
    const [fields, setFields] = React.useState([]);
    const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)
    const [isLoading, setIsLoading] = React.useState(false)

    const modulesData = [
        {
            "name": "Analytics",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Reports",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Open cases",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Old cases",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Enrollments",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Roles",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Discount Requests",
            "read": false,
            "write": false,
            "update": false
        },

        {
            "name": "Refund Requests",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Approved Refunds",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Payment due",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Batchs",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Course categories",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Courses",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Addons",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Tags",
            "read": false,
            "write": false,
            "update": false
        },

        {
            "name": "Sms templates",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Fields",
            "read": false,
            "write": false,
            "update": false
        },
        {
            "name": "Country",
            "read": false,
            "write": false,
            "update": false
        }
    ]

    const nameRef = React.useRef();


    const handleClickOpen = () => {
        // setOpen(true);
    };

    const handleAddRole = async () => {
        setIsLoading(true)
        let body;
        if (image) {
            let formdata = new FormData();
            formdata.append("file", image, image.name);
            const res = await post(`uploads/staff/upload`, formdata)
            setIsLoading(false)
            if (res.statusCode === 200) {
                body = {
                    name: props.RoleData?.name,
                    email: props.RoleData?.email,
                    mobile: props.RoleData?.mobile,
                    password: props.RoleData?.password,
                    username: props.RoleData?.email,
                    countryId: props.RoleData?.countryId,
                    designation: props.RoleData?.designation,
                    image: res.data?.result?.files?.file[0].name
                }

            } else {
                props.setSnackBarOptions({
                    message: "image File Upload Field try again!",
                    show: true,
                    severity: "error",
                    duration: 5000,
                });
            }
            console.log(res.data?.result?.files?.file[0].name)
        } else {
            body = {
                name: props.RoleData?.name,
                email: props.RoleData?.email,
                mobile: props.RoleData?.mobile,
                password: props.RoleData?.password,
                username: props.RoleData?.email,
                countryId: props.RoleData?.countryId,
                designation: props.RoleData?.designation,

            }
        }
        const ress = await post('Admins', body)
        setIsLoading(false)
        if (ress.statusCode === 200) {
            const resss = await post('assignModules', { adminId: ress.data.id, assignModule: modulesData })
            props.getRoles();
            handleClose();
            setSnackBarOptions({
                message: "successfully Created Role",
                show: true,
                severity: "success",
                duration: 5000,
            });
        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }

    const handleEditRole = async () => {
        setIsLoading(true)
        let body;
        if (image) {
            let formdata = new FormData();
            formdata.append("file", image, image.name);
            const res = await post(`uploads/staff/upload`, formdata)
            setIsLoading(false)
            if (res.statusCode === 200) {
                body = {
                    name: props.RoleData?.name,
                    email: props.RoleData?.email,
                    mobile: props.RoleData?.mobile,

                    username: props.RoleData?.email,
                    countryId: props.RoleData?.countryId,
                    designation: props.RoleData?.designation,
                    image: res.data?.result?.files?.file[0].name


                }

            } else {
                props.setSnackBarOptions({
                    message: "image File Upload Field try again!",
                    show: true,
                    severity: "error",
                    duration: 5000,
                });
            }
            console.log(res.data?.result?.files?.file[0].name)
        } else {
            body = {
                name: props.RoleData?.name,
                email: props.RoleData?.email,
                mobile: props.RoleData?.mobile,

                username: props.RoleData?.email,
                countryId: props.RoleData?.countryId,
                designation: props.RoleData?.designation,

            }
        }
        if (props?.RoleData?.password?.length > 5) {
            console.log(props.RoleData)
            body.password = props?.RoleData?.password;
        }
        const ress = await patch(`Admins/${props?.edit}`, body)
        setIsLoading(false)
        if (ress.statusCode === 200) {
            setSnackBarOptions({
                message: "successfully updated Role",
                show: true,
                severity: "success",
                duration: 5000,
            });
            props.getRoles();
            handleClose();
        }
        else if (ress.statusCode === 422) {
            console.log(ress.message)
            setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }

    const handleClose = () => {
        props.setOpen(false);
        props.setRoleData({
            name: "",
            email: "",
            mobile: "",
            password: "",
            username: "",
            countryId: null,
            designation: ""
        })
        setImage();
        setCountryData();
        props.setEdit(null)

    };
    const getDesignation = async () => {
        const res = await post("fieldTypes/getAllFieldTypeWithField", { status: true });


        if (res.statusCode === 200) {
            res.data?.map((item) => {
                if (item.title === "designation") {
                    setFields(item);
                }
            }
            )

        }
    }
    const getCountryData = async () => {
        let res = await get(`countries/${props.RoleData?.countryId}`);
        if (res?.statusCode === 200) {
            setCountryData(res?.data)
        }
    }
    const getCountry = async () => {
        const res = await get('countries?filter={"where":{"status":true}}');

        if (res.statusCode === 200) {
            setCountries(res.data);
        }
    }
    React.useEffect(() => {
        getDesignation();
        getCountry();
    }, [])
    React.useEffect(() => {
        getCountryData()
    }, [props?.RoleData?.countryId])

    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props.edit ? "Edit Role" : "Add Role"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-5'>
                        <Box className=' flex gap-2 justify-center cursor-pointer'>

                            {image ? <div className='flex '>
                                <Avatar sx={{ width: 70, height: 70 }} src={URL.createObjectURL(image)} />
                                <CloseIcon className=' cursor-pointer' onClick={() => { imageRef.current.value = null; setImage(null) }} />
                            </div>
                                :
                                <div className=' relative'>
                                    <Avatar src={`${baseUrl}profilePics/staff/download/${props?.RoleData?.image}`} onClick={() => imageRef.current.click()} sx={{ width: 70, height: 70, }} />
                                    <EditIcon className=' absolute bottom-0 right-0' />
                                </div>
                            }
                            <input className=' hidden' ref={imageRef} type="file" accept="image/*" onChange={(e) => {
                                const fileType = e.target?.files[0]?.type?.split('/');

                                if (fileType[0] === 'image') {
                                    setImage(e.target.files[0]);
                                }
                                else {
                                    props.setSnackBarOptions({
                                        message: "Select Vaild image File!",
                                        show: true,
                                        severity: "error",
                                        duration: 5000,
                                    });
                                }
                            }} />
                        </Box>

                        <Box className=' flex gap-4'>
                            <TextField
                                size='small'
                                fullWidth
                                value={props.RoleData?.name}
                                label="NAME"
                                required
                                error={!StringWithSpace.test(props.RoleData?.name) && props.RoleData?.name?.length !== 0}
                                helperText={props.RoleData?.name?.length !== 0 && !StringWithSpace.test(props.RoleData?.name) ? "Name only contains letters and at least 3 characters" : ""}
                                onChange={(e) => {
                                    props.setRoleData(prevState => (
                                        { ...prevState, name: e.target.value }));

                                }}
                            />
                            <TextField size='small'
                                required
                                value={props.RoleData?.email}
                                onChange={(e) => {
                                    props.setRoleData(prevState => (
                                        { ...prevState, email: e.target.value }));
                                }}
                                error={!emailRegex?.test(props.RoleData?.email) && props.RoleData?.email?.length !== 0}
                                helperText={!emailRegex?.test(props.RoleData?.email) && props.RoleData?.email?.length !== 0 ? "enter valid email" : ""}
                                fullWidth
                                label="EMAIL" />



                        </Box>
                        <Box className=' flex gap-2'>

                            <FormControl value={props.RoleData?.countryId} required fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">COUNTRY CODE</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"

                                    value={props.RoleData?.countryId}
                                    label="COUNTRY CODE"
                                    onChange={async (e) => {
                                        props.setRoleData(prevState => (
                                            { ...prevState, countryId: e.target.value }));
                                        let res = await get(`countries/${e.target.value}`);
                                        if (res?.statusCode === 200) {
                                            setCountryData(res?.data)
                                        }
                                    }}
                                >
                                    {countries?.map((item) =>
                                        <MenuItem value={item?.id}>+{item.phoneCode}-{item.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                            <TextField
                                ref={nameRef}
                                disabled={!countryData}
                                sx={{ borderTopLeftRadius: 0 }}
                                size='small'
                                fullWidth
                                value={props.RoleData?.mobile}
                                label="CONTACT NO."
                                required
                                error={(!wholeNumberRegex.test(props.RoleData?.mobile) || props.RoleData?.mobile?.length !== countryData?.phoneLength) && props.RoleData?.mobile?.length !== 0}
                                helperText={(!wholeNumberRegex.test(props.RoleData?.mobile) || props.RoleData?.mobile?.length !== countryData?.phoneLength) && props.RoleData?.mobile?.length !== 0 ? `contact no. contains ${countryData?.phoneLength} digits` : ""}
                                onChange={(e) => {
                                    props.setRoleData(prevState => (
                                        { ...prevState, mobile: e.target.value }));
                                }}
                            //     InputProps={

                            //         {
                            //             style: {
                            //                 borderTopLeftRadius: "0px",
                            //                 borderBottomLeftRadius: "0px",
                            //                 borderLeftWidth: "0px",
                            //             },
                            //             startAdornment: <InputAdornment position='start' >
                            //                 {/* <UploadIcon onClick={() => imageRef.current.click()} className=' text-[#3366FF] cursor-pointer ' /> */}

                            //             </InputAdornment>,
                            //         }}
                            />

                        </Box>
                        <Box className=' flex gap-4'>

                            <div className='flex w-full'>

                                <FormControl required fullWidth sx={{ minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">DESIGNATION</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={props.RoleData?.designation}
                                        label="DESIGNATION"
                                        onChange={(e) => {
                                            props.setRoleData(prevState => (
                                                { ...prevState, designation: e.target.value }));
                                        }}                                    >
                                        {fields?.field?.map((item) =>

                                            <MenuItem value={item._id}>{item?.title}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>
                            <TextField required size='small' fullWidth label="PASSWORD"
                                type={showPassword ? "text" : "password"}
                                error={props.RoleData?.password?.length < 6 && props.RoleData?.password?.length !== 0}
                                helperText={props.RoleData?.password?.length < 6 && props.RoleData?.password?.length !== 0 ? "password contains at least 6 characters" : ""}
                                onChange={(e) => {
                                    props.setRoleData(prevState => (
                                        { ...prevState, password: e.target.value }));
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position='end'>
                                            {showPassword ?
                                                < VisibilityOff style={{ cursor: 'pointer' }} onClick={() => { showPassword ? setShowpassword(false) : setShowpassword(true) }} />
                                                :
                                                <Visibility style={{ cursor: 'pointer' }} onClick={() => { showPassword ? setShowpassword(false) : setShowpassword(true) }} />
                                            }</InputAdornment>,
                                }}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={!StringWithSpace.test(props.RoleData?.name)
                            || props.RoleData?.name?.length === 0
                            || !props.RoleData?.countryId
                            || props.RoleData?.mobile?.length !== countryData?.phoneLength
                            || props.RoleData?.mobile?.length === 0
                            || !wholeNumberRegex.test(props.RoleData?.mobile)
                            || (props.edit ? props.RoleData.password?.length < 6 && props.RoleData.password > 0 : props.RoleData.password?.length < 6)

                            || props.RoleData?.designation?.length === 0
                            || !emailRegex.test(props.RoleData?.email)
                            || isLoading
                        }
                        autoFocus onClick={props.edit ? handleEditRole : handleAddRole}>
                        {isLoading ? <CircularProgress size={16} /> :
                            props.edit ? "Update" : "Add"
                        }
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}