import React, { useCallback, useEffect, useState } from 'react'

import {
    FormControl,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";
import { post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import { successEvents } from '../../App';
import moment from 'moment';


export default function PaymentDue() {
    const [caseAccess, setCaseAccess] = useState();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 })
    const [searchInput, setSearchInput] = useState();
    const [dates, setDates] = useState();
    const [row, setRow] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterRowCount, setFilterRowCount] = useState(0);
    const { setCurrentPage, } = React.useContext(successEvents)
    const [authState] = useRecoilState(UserAuthState);
    const [tableLoading, setTableLoading] = useState(true);

    const navigate = useNavigate();
    const columns = [

        {
            field: 'name',
            headerName: 'NAME',
            minWidth: 150,
            flex: 1,
        },

        {
            field: 'mobile',
            headerName: 'MOBILE',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'payment',
            headerName: 'PAYMENT MODE',
            minWidth: 150,

            flex: 1,
        },

        {
            field: 'course',
            headerName: 'COURSE',

            minWidth: 200,
            flex: 1,
        },

        {
            field: 'batch',
            headerName: "BATCH",
            minWidth: 150,
            flex: 1
        },
        {
            field: 'totalFee',
            headerName: "COURSE FEE",
            minWidth: 150,
            flex: 1
        },
        {
            field: 'amount',
            headerName: "DUE FEE",
            minWidth: 150,
            flex: 1,

        },

        {
            field: 'date',
            headerName: 'DUE DATE',
            minWidth: 150,
            valueGetter: (params) => {


                return moment(params?.value).format("LL");
            },
        },



    ];

    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }
    const getCases = async (search, dates) => {
        setTableLoading(true)
        const res = await post("discountRequests/getPaymentDue", { searchText: search, dateFilter: dates });
        console.log(res);
        if (res.statusCode === 200) {
            setRow(res?.data?.data);
            setTotalRowCount(res?.data?.totalDataCount);
            setFilterRowCount(res?.data?.filterCount)
        }
        setTableLoading(false)
    }

    const debounceSearch = useCallback(debounce(getCases, 300), []);
    const handleOnRowClick = async (row) => {
        if (caseAccess?.read || caseAccess?.update || caseAccess?.write) {
            setCurrentPage("open_cases")
            navigate('/home/open_cases/caseDetails', { state: { id: row.row.caseId } })
        }
    }

    useEffect(() => {
        debounceSearch(searchInput, dates);
    }, [searchInput, dates])
    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const cases = modules?.find((m) => m?.name === "Open cases");

        // console.log(rolesArr);

        setCaseAccess(cases)
    }, [])

    return (<>
        <div className=" flex flex-col flex-1" style={{ width: "100%" }}  >
            <div className='flex items-center justify-between '>
                <Typography className='font-normal text-xl text-gray-500'>Payment due ({totalRowCount})</Typography>
                <div className="flex items-center gap-5">
                    <FormControl sx={{ width: "300px" }}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="sm" />
                                    </InputAdornment>
                                ),
                            }}
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </FormControl>
                    <DateRangePiker getData={getDate} />

                </div>
            </div>


            <DataGidTable loading={tableLoading} cursor={caseAccess?.read || caseAccess?.update || caseAccess?.write ? "pointer" : null} columnVisibilityModel={{}} handleEvent={handleOnRowClick} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />





        </div>
    </>)

}

