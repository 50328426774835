import BreadCrumb from "../../Components/BreadCrumbs";
import React, { useEffect, useState } from "react";
import { fetchAnalyticData } from "../../Services/api-services";
import CardsWD from "../../Components/CardsWD";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DateInput from "../../Components/DateInput1";
import SearchIcon from "@mui/icons-material/Search";
import AnalyticsPendingsCard from "../../Components/AnalyticsPendingsCard";
import AnalyticsStatisticsCard from "../../Components/AnalyticsStatisticsCard";
import ClearIcon from '@mui/icons-material/Clear';
import { post } from "../../Services/services";
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import { successEvents } from "../../App";
import DateRangePiker from "../../Components/DateRangePiker";
import { getDate } from "date-fns";

function Analytics() {
  const [analytics, setAnalytics] = React.useState();
  var d = new Date();
  d.setMonth(d.getMonth() - 1);
  const [dateFilterApplied, setDateFilterApplied] = React.useState(false);

  const [totalFee, setTotalFee] = React.useState();
  const [paymentDueStatus, setPaymentsDueStatus] = useState(true);
  const [discountStatus, setDiscountStatus] = useState(true);
  const [refundStatus, setRefundStatus] = useState(true);
  const [pendings, setPendings] = useState();
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const { setCurrentPage } = React.useContext(successEvents)
  const [dates, setDates] = useState();

  async function fetchData(date) {
    try {

      const response = await post('paymentSchedules/getstatisticData',{dateFilter:date});
     if(response.statusCode===200){
      setAnalytics(response?.data[0])
     }
    
      
    } catch (err) {
      console.log(err);
    }
  }


  const getAccessOFVeiw = async () => {
    const modules = authState?.userData?.assignModules?.assignModule;
    modules?.map((item) => {
      if (item?.name === "Payment due" && (item?.read === true || item?.update === true || item?.write === true)) {
        setPaymentsDueStatus(false);
      }
      if (item?.name === "Refund Requests" && (item?.read === true || item?.update === true || item?.write === true)) {
        setRefundStatus(false);
      }
      if (item?.name === "Discount Requests" && (item?.read === true || item?.update === true || item?.write === true)) {
        setDiscountStatus(false);
      }
    })
  }

  const getNotification = async () => {
    const res = await post("refunds/getPendingRequest", {});
    if (res.statusCode === 200) {
      setPendings(res.data)
    }
  }
  React.useEffect(() => {

    getNotification();
    getAccessOFVeiw();
  }, []);
  const analytic = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      DashBoard
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Analytics
    </Typography>,
  ];

  useEffect(() => {
    fetchData(dates);
  }, [dates]);

  const getDate = (data) => {
    console.log(data)
    if (data.startDate?.length === 0 && data.endDate?.length === 0) {
      setDates(data);
    }
    else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
      setDates(data)
    }

  }


  return (
    <>
      {/* {console.log(analytics?.dues[0]?.totalDue?.reduce((total, item) => total + item, 0))} */}

      <div className="h-full w-full overflow-auto">
        <div className="m-7">
          <h1 className="text-gray-500 text-2xl">Today</h1>
          <div className="my-7 ">
            <Grid container spacing={8}>
              <Grid item md={4}>
                <div>
                  <AnalyticsPendingsCard
                    number={pendings?.PaymentDueCount}
                    url="/home/payment_due"
                    headText={"Payment Due"}
                    ViewStatus={paymentDueStatus}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div>
                  <AnalyticsPendingsCard
                    url="/home/discount_requests"
                    number={pendings?.discountCount}
                    headText={"Discount Requests"}
                    ViewStatus={discountStatus}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div>
                  <AnalyticsPendingsCard
                    url="/home/refund_requests"
                    number={pendings?.refundCount}
                    headText={"Refund Requests"}
                    ViewStatus={refundStatus}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="flex justify-between mt-16">
            <h1 className="text-gray-500 text-2xl">Statistics</h1>
            <DateRangePiker getData={getDate} />

          </div>



          <div className="my-7">
            <Grid container spacing={8}>
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalSale?analytics?.totalSale:0}
                    headText={"Total Sales"} />
                </div>
              </Grid>
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalCourseFee?analytics?.totalCourseFee:0}
                    headText={"Courses Sales"} />
                </div>
              </Grid>
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalAddOnsFee?analytics?.totalAddOnsFee:0}
                    headText={"AddOns Sales"} />
                </div>
              </Grid>
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalBoundleDiscount?analytics?.totalBoundleDiscount:0}
                    headText={"Bundle Discount"} />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="my-16">
            <Grid container spacing={8}>
            <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.TotalstudentDiscount?analytics?.TotalstudentDiscount:0}
                    headText={"Student Discount"} />
                </div>
              </Grid>
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={pendings?.enrollement}
                    headText={"Enrollements"} />
                </div>
              </Grid>

              {/* <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalDiscount?analytics?.totalDiscount:analytics?.totalDiscount}
                    headText={"Discount Approved"} />
                </div>
              </Grid> */}
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalPaymentDue?analytics?.totalPaymentDue:0}
                    headText={"Dues"} />
                </div>
              </Grid>
              <Grid item md={3}>
                <div>
                  <AnalyticsStatisticsCard number={analytics?.totalPaid?analytics?.totalPaid:0}
                    headText={"Paid Amount"} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default Analytics;
