import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { patch } from '../../Services/services';
import { DesimalValue, wholeNumberRegex } from '../../Regex/Regex';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    // width:"50vw"
}));

export default function ApprovedDiscountForm(props) {

    const [edit, setEdit] = React.useState(true);
    const [status, setStatus] = React.useState();
    const [approvedAmount, setApprovedAmount] = React.useState();

    const handleApprove = async () => {

        const res = await patch(`discountRequests/${props?.selected?.id}`, { status: status, approvedDate: status === "rejected" ? null : new Date(), approvedAmount: status === "rejected" ? null : approvedAmount })
        if (res.statusCode === 200) {
            handleClose();
            props.getDiscount()
        }
    }
    const handleClose = () => {
        props.setOpen(false);
        setStatus();
        setApprovedAmount();

    };

    return (
        <React.Fragment>
            {console.log(props?.selected)}
            <BootstrapDialog
                fullWidth

                // maxWidth={300}
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Discount approval
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent fullWidth dividers>
                    <Box className='flex flex-col gap-5'>
                        <Box className='flex gap-3 flex-wrap'>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Course -</p> <p className='text-[#707070] text-[15px]'>{props?.selected?.course}</p>
                            </Box>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Course Fee - </p> <p className='text-[#2B44FF] text-[15px]'>{props?.selected?.country?.currencyName}</p> <p className='text-[#2B44FF] text-[15px]'> {props?.selected?.courseFee}</p>
                            </Box>

                        </Box>
                        <Box className='flex gap-5'>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Discount requested- </p><p className='text-[#2B44FF] text-[15px]'>{props?.selected?.country?.currencyName}</p>  <p className='text-[#EC4C47] text-[15px]'> {props?.selected?.requestedAmount}</p>
                            </Box>
                            <Box className='flex gap-3 items-center'>
                                <p className='text-[#707070] text-[12px]'>Past approved discount- {props?.selected?.country?.currencyName} </p> <p className='text-[#EC4C47] text-[15px]'> {props?.selected?.previousApprovedDiscount}</p>
                            </Box>
                        </Box>

                        <Box className='flex  gap-5 flex-wrap'>
                            <Box className='flex gap-1 bg-[#F4F4F4] px-2 py-2 items-center'>
                                <p className='text-[#707070] text-[12px]'>Total discount requested  by case:</p>
                                <p className=' text-[#2B44FF] text-[15px]'> {props.selected?.discountTotalCountCase?.totalDiscountCount?props.selected?.discountTotalCountCase?.totalDiscountCount:0} </p>
                                <p className='text-[#EC4C47] text-[15px]'>({props.selected?.discountTotalCountCase?.totalDiscount?props.selected?.discountTotalCountCase?.totalDiscount:0})</p>
                            </Box>
                            <Box className='flex gap-1 bg-[#F4F4F4] px-2 py-2 items-center'>
                                <p className='text-[#707070] text-[12px]'>Total discount request approved by {props?.selected?.admin?.name} in this batch</p>
                                <p className=' text-[#707070] text-[12px]' style={{fontWeight:600}}> ({props?.selected?.batches?.name}) :</p>
                                <p className=' text-[#2B44FF] text-[15px]'>{props.selected?.discountTotalCountAdmin?.totalDiscountCount?props.selected?.discountTotalCountAdmin?.totalDiscountCount:0}</p>
                                <p className='text-[#EC4C47] text-[15px]'>({props.selected?.discountTotalCountAdmin?.totalDiscount?props.selected?.discountTotalCountAdmin?.totalDiscount:0})</p>
                            </Box>
                        </Box>
                        <Box className='flex justify-between flex-wrap'>
                            <Box className='flex gap-3 items-center'> <p className='text-[#707070] text-[12px]'>Earning members- </p> <p className='text-[#656565] text-[15px]'>{props?.selected?.numberOfEarningMember}</p></Box>
                            <Box className='flex gap-3 items-center'> <p className='text-[#707070] text-[12px]'>Parent’s profession- </p> <p className='text-[#656565] text-[15px]'>{props?.selected?.parentOccupationName} </p></Box>
                            <Box className='flex gap-3 items-center'> <p className='text-[#707070] text-[12px]'>Family annual income- </p> <p className='text-[#656565] text-[15px]'>{props?.selected?.annualFamilyIncomeName} </p></Box>
                        </Box>
                        <Box>
                            <p className='text-[#707070] text-[12px]'>Reason</p>
                            <p className='text-[#000000] text-[12px] ' style={{ fontWeight: 500 }}>{props?.selected?.reason} </p>
                        </Box>
                    </Box>
                    <Box className='flex gap-3 py-10'>
                        <TextField
                            disabled={edit}
                            size='small'
                            fullWidth
                            error={(approvedAmount > props?.selected?.requestedAmount || !DesimalValue.test(approvedAmount)) && approvedAmount}
                            helperText={approvedAmount > props?.selected?.requestedAmount ? "greater than requested amount" : ""}
                            value={approvedAmount}
                            defaultValue={props?.selected?.requestedAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><BorderColorIcon className='text-[#000000]' onClick={() => setEdit(!edit)} /></InputAdornment>,
                            }}
                            onChange={(e) => setApprovedAmount(e.target.value)}
                        />
                        <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">STATUS</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                defaultValue={props?.selected?.status}
                                value={status}
                                label="STATUS"
                                onChange={(e) => {
                                    setStatus(e.target.value)
                                    console.log(e.target.value)
                                    if (e.target.value === "pending" || e.target.value === "rejected") {
                                        setApprovedAmount(props.selected?.requestedAmount);
                                        setEdit(true);

                                    }
                                }}
                            >

                                <MenuItem value={"pending"}>pending</MenuItem>
                                <MenuItem value={"approved"}>approved</MenuItem>
                                <MenuItem value={"rejected"}>rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleApprove}
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={status === "pending" || approvedAmount > props?.selected?.requestedAmount || !approvedAmount || !DesimalValue.test(approvedAmount)}
                        autoFocus
                    >
                        save
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}