import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import BreadCrumb from "../../Components/BreadCrumbs";
import CONSTANTS from "../../constant.json";
import Chip from '@mui/material/Chip';
import SideNav, { MenuIcon } from 'react-simple-sidenav';
import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, TextField, Tooltip } from "@mui/material";
import DateInput from "../../Components/DateInput";
import SearchIcon from "@mui/icons-material/Search";
import {
  fetchCurrency,
  _addAddons,
  getCourses,
  getAddons,
  _deleteAddons,
  _updateAddons,
  getBatchModes,
  getCourseCategory,
  getAllCourses,
  httpPatch,
} from "../../Services/api-services";
import CustomizedSnackbars from "../../Components/Snackbar";
import Cards from "../../Components/Cards";
import { useNavigate } from "react-router-dom";
import CourseDialog from "../../Popups/CourseDialog";
import DateSearchFilter from "../../Components/DateSearchFilter";
import DynamicTable from "../../Components/DynamicTable";
import CourseSideBar from "../../Components/CourseDetailSideBar";
import DialogueBox from "../../Components/DialogueBox";
import bundle from "react-simple-sidenav";
import NodataInTable from "../../Components/NoDataInTable";
import ClearIcon from '@mui/icons-material/Clear';
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import { updateLocalStorage } from "../../Services/services";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Courses() {
  const [courseDialogue, setCourseDialogue] = React.useState(false);
  const [coursesM, setCoursesM] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [showSideBar, setSideBar] = React.useState(false)
  const [editCourse, setEditCourse] = React.useState(false)
  const [course, setCourses] = React.useState([])
  const [updatedCourse, setUpdatedCourse] = React.useState([])
  const [allCourseCategory, setAllCourseCategory] = React.useState([]);
  const [addSubject, setAddSubject] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [allCurrencies, setAllCurrencies] = React.useState();
  const [allBatchModes, setAllBatchModes] = React.useState();
  const [courseIndex, setCourseIndex] = React.useState(0);
  const [addOnDataSource, setAddOnDataSource] = React.useState([]);
  const [coursesDataSource, setCoursesDataSource] = React.useState(null);
  const [updateCourse, setupdateCourse] = React.useState(false)
  var d = new Date();
  d.setMonth(d.getMonth() - 1);
  const [startDate, setStartDate] = React.useState(new Date(d).toISOString());
  const [endDate, setEndDate] = React.useState(new Date(new Date().setHours(23, 59)).toISOString());
  const [input, setInputSearch] = React.useState("")
  const [toggle, setToggle] = React.useState(false)
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  let navigate = useNavigate()
  const [dateFilterApplied, setDateFilterApplied] = React.useState(false);

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const coursesArr = modules.find((m) => m?.name === "Courses");
    setCoursesM(coursesArr);
    fetchData();
    // eslint-disable-next-line

  }, [startDate, endDate]);

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [toggle]);

  async function fetchData(param) {
    // console.log(param)
    try {
      let courses
      let currencies
      let batchModes
      let courseCategory

      let data = {
        startDate: startDate,
        endDate: param === 'added' ? endDate : endDate,
        input: input
      }

      courses = await getAllCourses(data);
      if(courses?.error?.statusCode===401){
        updateLocalStorage()
      }
    
      const filteredData = await removeDeletedSubjects(courses)
      currencies = await fetchCurrency();
      batchModes = await getBatchModes(data);
      courseCategory = await getCourseCategory();
      setCourses(filteredData)
      const _dataSourceCourses = await createTableCourses(courses);
      setCoursesDataSource(_dataSourceCourses);
      setAllCourseCategory(courseCategory);
      setAllCurrencies(currencies);
      setAllBatchModes(batchModes);
      if (param === 'added') {
        setupdateCourse(!updateCourse)
      }
    } catch (err) {
      console.log(err);
    }
  }

  function removeDeletedSubjects(data) {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < data.length; i++) {
        let sub = [...data[i].subjects];
        delete data[i].subjects;
        let getFiltered = sub.filter(e => !e.deleted)
        data[i]['subjects'] = getFiltered;
        if (i == data.length - 1) {
          resolve(data)
        }
      }
      resolve(data)
    })
  }

  function refreshcourse(param) {

    fetchData(param)
  }

  const _closeCourseDialogue = () => {
    setCourseDialogue(false);
    setEditCourse(false)
  }

  function clickAction(data, i) {
    setCourseIndex(i)
    setSideBar(true)
  }


  const courses = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      Master
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Courses
    </Typography>,
  ];

  function handleProceed(id, title) {
    // const {id} = useParams()
    navigate(`/courses/${id}/${title}/courseCard`)
  }

  const headCellsCourses = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "S.no",
    },
    {
      id: "courseCategory",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Course Category",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Course Name",
    },
    {
      id: "batchmode",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Batch Mode",
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Currency",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Price",
    },
    {
      id: "tax",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "tax",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Created On",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      sort: false,
      label: "Action",
    },
  ];
  if (coursesM?.update !== true) {
    headCellsCourses.pop()
  }

  const createTableCourses = (data) => {
    // CREATE TABLE DATA - ADDONS
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const row = data[index];
          const obj = {
            sno: index + 1,
            id: row.id,
            name: row?.name,
            courseCategory: row?.courseCategory?.name,
            price: row?.price,
            name: row.name,
            createdAt: row.createdAt,
            action: true,
            batchmode: row?.batchmode?.name,
            currency: row?.country?.currencyName,
            courseCategoryId: row?.courseCategory?.id,
            batchmodeId: row?.batchmode?.id,
            currencyId: row?.country?.id,
            bundleDiscount: row?.bundleDiscount,
            studentDiscount: row?.studentDiscount,
            tax: row?.tax,
            subjects: row?.subjects.map(s => s)
          };
          returnArray.push(obj);
          if (index === data.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };

  function _openConfirmDialogCourse(e, data) {
    e.stopPropagation()
    setCourseIndex(data.id);
    setConfirmDialog(true);
  }

  async function deleteCourse() {
    const body = {
      deleted: true,
    };
    let exactCourse = course.filter(e => e.id == courseIndex)[0]
    let obj = {
      purpose: 'delete',
      course: { id: exactCourse.id },
      subjects: exactCourse.subjects
    }
    console.log(obj)
    const url = `${CONSTANTS.baseUrl}/courses/updateCourse`;
    const response = await httpPatch(url, obj)
    if (response.statusCode == 200) {
      fetchData();
      setConfirmDialog(false);
      setSnackBarOptions({
        message: "Course deleted successfully!",
        show: true,
        severity: "success",
        duration: 5000,
      });
    }
    else {
      setSnackBarOptions({
        message: "Internal server error!",
        show: true,
        severity: "error",
        duration: 5000,
      });
    }
  }


  function editCourseDialog(e, data) {

    e.stopPropagation()
    setUpdatedCourse(data)
    setCourseIndex(data.id);
    setEditCourse(true);
    setCourseDialogue(true);
  }
  const handleDateChange = (data) => {
    if (data?.from == true) {
      const d = data?.date
      d.setHours(0, 0, 0);
      setStartDate(d.toISOString());
    }
    if (data?.to == true) {
      const d = data?.date
      d.setHours(23, 59, 59);
      setEndDate(d.toISOString());
    }
    let searchData = {
      startDate: startDate, endDate: endDate, input: input
    }
    setToggle(!toggle)
  };

  const handleInputSearch = (e) => {

    let searchData = {
      startDate: startDate, endDate: endDate, input: e.target.value
    }
    setInputSearch(e.target.value)
    setToggle(!toggle)
  }

  const dateFilterRemove = () => {
    setDateFilterApplied(false);
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    setStartDate(d.toISOString())
    setEndDate(new Date(new Date().setHours(23, 59)).toISOString())
  }

  return (
    <div className="h-full w-full overflow-auto py-4 px-8 ">
      <div className="flex items-center justify-between">
        <BreadCrumb data={courses} />
        <div className="flex items-center ">
          <div>
            <div className="flex items-center">
              <InputLabel className='w-20' id="demo-simple-select-label">From</InputLabel>
              &nbsp;&nbsp;
              <DateInput from={true} white={true} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <InputLabel className='w-10' id="demo-simple-select-label">To</InputLabel>
              &nbsp;&nbsp;
              <DateInput to={true} white={true} handleDateChange={(data) => handleDateChange(data)} filter={dateFilterApplied} filterChange={(val) => { setDateFilterApplied(val) }} />
              &nbsp;&nbsp;&nbsp;
              {dateFilterApplied ? <>
                <Tooltip title="Clear Date Filter">
                  <IconButton aria-label="clear" onClick={dateFilterRemove}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </> : null}
              &nbsp;&nbsp;
              <FormControl sx={{ width: "70%" }}>
                <TextField
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="sm" />
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  placeholder="Search"
                  variant="outlined"
                  onChange={(e) => handleInputSearch(e)}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      {coursesDataSource === null ? <div className="h-full w-full items-center justify-center flex">
        <h1 className="h-60 w-60"> <CircularProgress /></h1>
      </div> : coursesDataSource.length > 0 ? <div className="my-10">
        <DynamicTable
          dataSource={coursesDataSource}
          headCells={headCellsCourses}
          actionOnDelete={(e, data) => _openConfirmDialogCourse(e, data)}
          actionOnEdit={(e, data) => editCourseDialog(e, data)}
          pagination={true}
          delete={true}
          clickable={true}
          clickAction={(_data, i) => clickAction(_data, i)}
        />
        <DialogueBox
          confirmDialog={confirmDialog}
          message={'Course'}
          confirmDialogClose={() => setConfirmDialog(false)}
          confirmDelete={deleteCourse}
        />
      </div> :
        <NodataInTable message='No course is created yet.To create course click on add icon.' />}
      <div>
        <Button
          onClick={() => setCourseDialogue(true)}
          disabled={coursesM?.write ? false : true}
          variant="contained"
          sx={{
            borderRadius: "50px",
            height: "60px",
            width: "60px",
            position: "fixed",
            bottom: "40px",
            right: "40px",
          }}
        >
          <AddIcon sx={{ fontSize: "30px" }} />
        </Button>
      </div>
      <div>
        <CourseDialog refreshcourse={refreshcourse} editCourse={editCourse} updatedCourse={updatedCourse} courseDialogOpen={courseDialogue} courseDialogClose={_closeCourseDialogue} />
      </div>
      <SideNav className='z-10'
        style={{ zIndex: 100 }}
        navStyle={{ maxWidth: '65%' }}
        openFromRight={true}
        showNav={showSideBar}
        onHideNav={() => setSideBar(false)}
        title={null}
        children={[<div key={0} className='cursor-default'>
          <CourseSideBar showSideBar={showSideBar} courses={course[courseIndex]} refreshcourse={refreshcourse} updateCourse={updateCourse} />
        </div>]
        }
      />

      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default Courses;
