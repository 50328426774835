
import React, { useCallback, useEffect, useState } from 'react'

import {
    Avatar,
    Box,
    Button,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { baseUrl, patch, post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CasesDialog from '../../Popups/CasesDialog';
import AssignModulesPanel from './AssignModulesToRoles';
import AddRoles from './AddRoles';
import CustomizedSnackbars from '../../Components/Snackbar';
import EditIcon from '@mui/icons-material/Edit';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { successEvents } from '../../App';
export default function Roles() {

    const [access, setAccess] = useState();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 })
    const [searchInput, setSearchInput] = useState();
    const [dates, setDates] = useState();
    const [row, setRow] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterRowCount, setFilterRowCount] = useState(0);
    const [openCaseDialog, setOpenCaseDialog] = useState(false)
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState();
    const [data, setData] = React.useState();
    const [tableLoading, setTableLoading] = useState(true);

    const { snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)

    const [authState, setAuthState] = useRecoilState(UserAuthState);

    const [RoleData, setRoleData] = React.useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        username: "",
        countryId: null,
        designation: ""
    })
    // const [clearDate,setClearDate] =useState()

    const navigate = useNavigate();
    const columns = [

        {
            field: 'name',
            headerName: 'FULL NAME',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {


                return <div className='flex items-center gap-3' >
                    <Avatar alt={params?.row?.name?.toUpperCase()} src={`${baseUrl}profilePics/staff/download/${params?.row?.image}`} />
                    <div>
                        <Typography>{params?.row?.name}</Typography>
                        <Typography>{params?.row?.email}</Typography>
                    </div>
                </div>



            },
        },
        {
            field: 'mobile',
            headerName: 'MOBILE',
            minWidth: 150,

            flex: 1,
        },

        {
            field: 'assignModules',
            headerName: 'Modules',

            minWidth: 150,
            flex: 1,
            renderCell: (params) => {


                return <div className='flex items-center gap-3' >
                    <AssignModulesPanel access={access} getRoles={getRoles} data={params?.value} />
                </div>
            },
        },

        {
            field: 'createdAt',
            headerName: 'REGISTRATION DATE',
            minWidth: 200,
            valueGetter: (params) => {


                return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
            },
        },
        {
            field: 'status',
            headerName: 'ACTION',

            minWidth: 150,

            renderCell: (params) => {


                return <div className='flex items-center gap-6' >
                    <EditIcon style={{ display: access?.update || access?.write ? "block" : "none" }} className='text-[#3366ff] cursor-pointer'
                        onClick={() => {
                            setEdit(params?.row?.id)
                            setRoleData({
                                name: params?.row?.name,
                                email: params?.row?.email,
                                mobile: params?.row?.mobile,
                                password: null,
                                countryId: params?.row?.countryId,
                                designation: params?.row?.designation,
                                image: params?.row?.image

                            })
                            setOpen(true)

                        }}
                    />
                    <Switch onClick={() => updateStatus(params?.row?.id, params?.row?.status)} disabled={!(access?.update || access?.write)} checked={params.value} />
                </div>
            },
        },

    ];

    const updateStatus = async (id, status) => {
        const ress =await patch(`Admins/${id}`, { status: !status });
        
        if (ress.statusCode === 200) {

            getRoles(searchInput, dates);


        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }

    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }
    const getRoles = async (search, dates) => {
    setTableLoading(true)
        const res = await post("Admins/getRoles", { searchText: search, dateFilter: dates });
        if (res.statusCode === 200) {
            setRow(res?.data?.data);
            setTotalRowCount(res?.data?.totalDataCount);
            setFilterRowCount(res?.data?.filterCount)
        }
        setTableLoading(false)
    }
    const debounceSearch = useCallback(debounce(getRoles, 300), []);
    const handleOnRowClick = async (row) => {
        console.log(row.row)
        navigate('caseDetails', { state: { data: row.row } })

    }
    useEffect(() => {
        debounceSearch(searchInput, dates);

    }, [searchInput, dates])
    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const rolesArr = modules?.find((m) => m?.name === "Roles");
        console.log(rolesArr);

        setAccess(rolesArr)
    }, [])

    return (<>
        <div className=" flex flex-col flex-1"  style={{width:"100%"}} >
            <div className='flex items-center justify-between '>
                <Typography className='font-normal text-xl text-gray-500'>Roles</Typography>
                <div className="flex items-center gap-5">



                    <FormControl sx={{ width: "300px" }}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="sm" />
                                    </InputAdornment>
                                ),
                            }}
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </FormControl>
                    <DateRangePiker getData={getDate} />

                </div>
            </div>


            <DataGidTable loading ={tableLoading} rowHeight={100} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />


            {/* <SearchFilterDailog
                openSearchActionDialog={searchActionDialog}
                cancelDialogAction={() => cancelDialogAction()}
                handleDateChange={(data) => handleDateChange(data)}
                cases={cases}
                title={"REFUND REQUEST FILTER"}
            // onChangeHandler={(obj, selectOption) => onSelectFilterData(obj, selectOption)}
            /> */}

            <div style={{ display: access?.write ? "block" : "none" }} className=' fixed  bottom-32 right-20'>
                <Fab color="primary" aria-label="add" onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </div>
            <AddRoles setRoleData={setRoleData} RoleData={RoleData} setEdit={setEdit} edit={edit} setSnackBarOptions={setSnackBarOptions} open={open} setOpen={setOpen} getRoles={getRoles} />
            <CustomizedSnackbars
                message={snackBarOptions.message}
                open={snackBarOptions.show}
                close={() => {
                    setSnackBarOptions({ ...snackBarOptions, show: false });
                }}
                hideDur={5000}
                severity={snackBarOptions.severity}
            />
        </div>
    </>)

}

