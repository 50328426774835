import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Box, TextField } from '@mui/material';

import { patch, post } from './../../Services/services'
import { successEvents } from '../../App';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function MultiForm(props) {


    const { setSnackBarOptions } = React.useContext(successEvents)




    const handleCountryAdd = async () => {

        // let temp = { ...props.countryForm }
        // temp.status = true
        let res;
        if (props?.open?.name === "state") {
            let body = {
                countryId: props?.open?.countryId,
                title: props?.title

            }
            res = await post('states', body)
            if (res?.statusCode === 200) {

                setSnackBarOptions({
                    message: `successfully  ${props?.open?.name} created`,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                props.getState(props.selectedCountry?.id)
                handleClose();
            }
        } else if (props?.open?.name === "city") {

            let body = {
                countryId: props?.open?.countryId,
                title: props?.title,
                stateId: props.open?.stateId

            }
            res = await post('cities', body);
            if (res?.statusCode === 200) {
                setSnackBarOptions({
                    message: `successfully  ${props?.open?.name} created`,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                props.getState(props.selectedCountry?.id)
                handleClose();
            }

        } else if (props?.open?.name === "board") {

            let body = {
                countryId: props?.open?.countryId,
                title: props?.title,
                status: true

            }
            res = await post('boards', body);
            if (res?.statusCode === 200) {
                props.setTitle();
                setSnackBarOptions({
                    message: `successfully  ${props?.open?.name} created`,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                props.getBoard(props.selectedCountry?.id)
                handleClose();
            }

        } else if (props?.open?.name === "school") {
            let body = {
                countryId: props?.open?.countryId,
                title: props?.title,
                status: true

            }
            res = await post('school', body)
            if (res?.statusCode === 200) {
                setSnackBarOptions({
                    message: `successfully  ${props?.open?.name} created`,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                props.getSchool(props.selectedCountry?.id)
                handleClose();
            }
        }

        if (res?.statusCode === 422) {
            setSnackBarOptions({
                message: res.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else if (res?.statusCode !== 200) {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }
    const handleEditCountry = async () => {

        let res;
        if (props?.open?.name === "state") {

            res = await patch(`states/${props?.open?.id}`, { title: props?.title })
            if (res.statusCode === 200) {
                setSnackBarOptions({
                    message: `successfully update ${props?.open?.name} `,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                handleClose();
                props.getState(props.selectedCountry?.id)
            }
        } else if (props?.open?.name === "city") {

            res = await patch(`cities/${props?.open?.id}`, { title: props?.title })
            if (res.statusCode === 200) {
                setSnackBarOptions({
                    message: `successfully update ${props?.open?.name} `,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                handleClose();
                props.getState(props.selectedCountry?.id)
            }
        } else if (props?.open?.name === "board") {

            res = await patch(`boards/${props?.open?.id}`, { title: props?.title })
            if (res.statusCode === 200) {
                setSnackBarOptions({
                    message: `successfully update ${props?.open?.name} `,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                handleClose();
                props.getBoard(props.selectedCountry?.id)
            }
        } else if (props?.open?.name === "school") {

            res = await patch(`school/${props?.open?.id}`, { title: props?.title })
            if (res.statusCode === 200) {
                setSnackBarOptions({
                    message: `successfully update ${props?.open?.name} `,
                    show: true,
                    severity: "success",
                    duration: 5000,
                });
                handleClose();
                props.getSchool(props.selectedCountry?.id)
            }
        }

        if (res?.statusCode === 200) {



            // props.getCountry();
        } else if (res?.statusCode === 422) {
            setSnackBarOptions({
                message: res.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }
    const handleClose = () => {
        console.log("close")
        props.setOpen(false);

        props.setEditForm();
        props.setTitle();

    };


    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open || props.edit}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props.open?.name?.toUpperCase()}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-5'>


                        <Box className=' flex gap-2'>
                            <TextField
                                required
                                error={props.title?.length < 2 && props?.title?.length !== 0}
                                helperText={props.title?.length < 2 && props?.title?.length !== 0 ? "invalid country name must have at least 3 characters " : ""}
                                onChange={(e) => props.setTitle(e?.target?.value)}
                                size='small' fullWidth label={props?.open?.name?.toUpperCase() + " NAME"}
                                value={props?.title}
                            />

                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button

                        disabled={props.title?.length < 2}
                        sx={{ bgcolor: '#3366ff' }}
                        autoFocus variant='contained'
                        onClick={props.editForm ? handleEditCountry : handleCountryAdd}>
                        {props?.editForm ? "Edit" : "Add"}
                    </Button>

                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}