import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { get, patch } from '../../Services/services';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    // width:"50vw"
}));

export default function PayApprovedRefund(props) {
    const [authState, setAuthState] = useRecoilState(UserAuthState);

    const [paymentMode, setPaymentMode] = React.useState();
    const [paymentMethod, setPaymentMethod] = React.useState([]);

    const handleApprove = async () => {

        const res = await patch(`refunds/${props?.selected?.id}`, {paymentMode:paymentMode, paymentStatus: "paid", paymentDate: new Date(), paymentAdminId:authState?.userId })
        if (res.statusCode === 200) {
            handleClose();
            props.getDiscount()
        }
    }
    const handleClose = () => {
        props.setOpen(false);
        setPaymentMode();

    };

    const getPaymentStatus = async () => {
        const res = await get('fields?filter={"where":{"status":true,"fieldType":"6549fec7d7788c592187b6c7"}}');
        if (res.statusCode === 200) {
            setPaymentMethod(res?.data)
        }
        console.log(res)
    }
    React.useEffect(() => {
        getPaymentStatus();
    }, [])

    return (
        <React.Fragment>
            {console.log(props?.selected)}
            <BootstrapDialog
                fullWidth

                // maxWidth={300}
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Refund settlement
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent fullWidth dividers>
                    <Box className='flex flex-col gap-5'>
                        <Box className='flex gap-3 flex-wrap'>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Fee -</p><p className='text-[#707070] text-[15px]'>{props?.selected?.subscribedCourses?.currency?.currencyName}</p> <p className='text-[#2B44FF] text-[15px]'>{props?.selected?.totalFee}</p>
                            </Box>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Paid - </p> <p className='text-[#707070] text-[15px]'>{props?.selected?.subscribedCourses?.currency?.currencyName}</p> <p className='text-[#4CAF50] text-[15px]'> {props?.selected?.subscribedCourses?.paidAmount}</p>
                            </Box>
                            <Box className='flex gap-1 items-center'>
                                <p className='text-[#707070] text-[12px]'>Approved amount -</p> <p className='text-[#707070] text-[15px]'>{props?.selected?.subscribedCourses?.currency?.currencyName}</p> <p className='text-[#4CAF50] text-[15px]'> {props?.selected?.approvedAmount}</p>
                            </Box>
                        </Box>




                        <Box>
                            <p className='text-[#707070] text-[12px]'>Reason</p>
                            <p className='text-[#000000] text-[12px] ' style={{ fontWeight: 500 }}>{props?.selected?.refundReason} </p>
                        </Box>
                    </Box>
                    <Box className='flex gap-3 py-10'>

                        <FormControl sx={{ minWidth: 250 }} size="small">
                            <InputLabel id="demo-select-small-label">PAYMENT MODE</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"

                                value={paymentMode}
                                label="PAYMENT MODE"
                                onChange={(e) => {
                                    setPaymentMode(e.target.value)


                                }}
                            >
                                {paymentMethod?.map((item) =>
                                    <MenuItem value={item?.id}>{item?.title}</MenuItem>
                                )}


                            </Select>
                        </FormControl>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleApprove}
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={!paymentMode}
                        autoFocus
                    >
                        pay
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}