import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Button,
    Checkbox,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import DataGidTable from '../../Components/DataGridTable'
import DateRangePiker from '../../Components/DateRangePiker'
import SearchIcon from "@mui/icons-material/Search";

import ClearIcon from '@mui/icons-material/Clear';
import { patch, post } from '../../Services/services';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CasesDialog from '../../Popups/CasesDialog';

import { useRecoilState } from 'recoil';
import { UserAuthState } from '../../Services/RecoilService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DialogueBox from '../../Components/DialogueBox';
import CustomizedSnackbars from '../../Components/Snackbar';
import { successEvents } from '../../App';
import PayApprovedRefund from './PayApprovedRefund';


export default function ApprovedRefundRequests() {
    const [access, setAccess] = useState();
    const [cases, setCases] = React.useState(null)

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 })
    const [searchInput, setSearchInput] = useState();
    const [dates, setDates] = useState();
    const [row, setRow] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterRowCount, setFilterRowCount] = useState(0);
    const [openCaseDialog, setOpenCaseDialog] = useState(false)
    const [selected, setSelected] = useState();
    const [confirmDialog, setConfirmDialog] = useState(null);
    const [statusFilter, setStatusFilter] = useState(["unpaid"]);
    const { snackBarOptions, setSnackBarOptions, setCurrentPage } = React.useContext(successEvents)
    const [caseAcess, setCaseAcess] = useState()
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [tableLoading, setTableLoading] = useState(true);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const navigate = useNavigate();
    const columns = [

        {
            field: 'name',
            headerName: 'NAME',
            minWidth: 150,
            flex: 1,
        },

        {
            field: 'mobile',
            headerName: 'MOBILE',
            minWidth: 150,

            flex: 1,
        },
        {
            field: 'email',
            headerName: 'EMAIL',

            minWidth: 200,
            flex: 1,
        },

        {
            field: 'course',
            headerName: "COURSE",
            minWidth: 150,
            flex: 1
        },
        {
            field: 'totalFee',
            headerName: "COURSE FEE",
            minWidth: 150,
            flex: 1
        },

        {
            field: 'requestedAmount',
            headerName: "REQUESTED AMOUNT",
            minWidth: 200,
            flex: 1,

        },


        {
            field: 'approvedAmount',
            headerName: 'APPROVED AMOUNT',

            minWidth: 200,
            flex: 1,

        },
        {
            field: 'paymentScheduleDate',
            headerName: 'SCHEDULE',
            minWidth: 150,
            valueGetter: (params) => {


                return params?.value ? params?.value?.slice(8, 10) + params?.value?.slice(4, 8) + params?.value?.slice(0, 4) : "";
            },
        },
        {
            field: 'paymentStatus',
            headerName: 'PAYMENT STATUS',

            minWidth: 150,
            flex: 1,

        },
        {
            field: 'deleted',
            headerName: 'ACTION',

            minWidth: 150,
            flex: 1,
            renderCell: (params) => {


                return <div className='flex items-center gap-6' >
                    <SendIcon style={{ display: (access?.update || access?.write) && params.row.paymentStatus === "unpaid" ? "block" : "none" }} className='text-[#3366ff] cursor-pointer'
                        onClick={(e) => {
                            e.stopPropagation();

                            setSelected(params?.row)
                            setOpenCaseDialog(true)

                        }}
                    />

                </div>
            },
        },

    ];

    // const cancelDialogAction = () => {
    //     setSearchActionDialog(false)
    //     setRefundReqActionDialog(false)
    // }
    const confirmDialogClose = () => {
        setConfirmDialog(null);
    }
    const confirmDelete = () => {
        handleDeleteCases(confirmDialog)
    }
    const getDate = (data) => {
        console.log(data)
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }
    const getCases = async (search, dates, statusFilter) => {
        setTableLoading(true)
        const res = await post("discountRequests/getRefundRequest", { searchText: search, dateFilter: dates, statusFilter: ["approved"], paymentStatusFilter: statusFilter });
        if (res.statusCode === 200) {
            setRow(res?.data?.data);
            setTotalRowCount(res?.data?.totalDataCount);
            setFilterRowCount(res?.data?.filterCount)
        }
        setTableLoading(false)
    }
    const handleDeleteCases = async (id) => {
        const res = await patch(`cases/${id}`, { deleted: true })
        if (res.statusCode === 200) {
            getCases(searchInput, dates)
            setSnackBarOptions({
                message: "Case deleted successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
        }
        setConfirmDialog(null)
    }
    const debounceSearch = useCallback(debounce(getCases, 300), []);
    const handleOnRowClick = async (row) => {
        setCurrentPage("open_cases")
        navigate('/home/open_cases/caseDetails', { state: { id: row.row.caseId } })

    }
    const handleChange = (value) => {


        const __index = statusFilter?.indexOf(value);

        if (__index >= 0) {
            let array = [...statusFilter];
            array.splice(__index, 1)
            setStatusFilter(array);

        } else {
            const array = [...statusFilter, value];
            setStatusFilter(array);

        }
    }
    const refres = async () => {
        getCases(searchInput, dates, statusFilter)
    }
    useEffect(() => {
        debounceSearch(searchInput, dates, statusFilter);
    }, [searchInput, dates, statusFilter])
    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const rolesArr = modules?.find((m) => m?.name === "Approved Refunds");
        const cases = modules?.find((m) => m?.name === "Open cases");
        console.log(rolesArr);
        setCaseAcess(cases)
        setAccess(rolesArr)
    }, [])

    return (<>
        <div className=" flex flex-col flex-1" style={{ width: "100%" }}  >
            <div className='flex items-center justify-between  flex-wrap'>
                <Typography className='font-normal text-xl text-gray-500'>Approved Refunds ({totalRowCount})</Typography>
                <div className="flex items-center gap-5 flex-wrap">



                    <FormControl sx={{ width: "300px" }}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="sm" />
                                    </InputAdornment>
                                ),
                            }}
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </FormControl>
                    <DateRangePiker getData={getDate} />
                    <FormControl className=' w-40' size='small'>
                        <InputLabel id="demo-multiple-checkbox-label">Payment Status</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple

                            value={statusFilter}
                            input={<OutlinedInput label="Payment Status" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {["unpaid", "paid"]?.map((item) => (
                                <MenuItem key={item} value={item}>
                                    <Checkbox checked={statusFilter?.indexOf(item) > -1} onChange={() => handleChange(item)} />

                                    <ListItemText primary={item} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>


            <DataGidTable loading={tableLoading} cursor={null} columnVisibilityModel={{}} handleEvent={caseAcess?.read || caseAcess?.write || caseAcess?.update ? handleOnRowClick : ""} row={row} totalRowCount={filterRowCount} columns={columns} setPaginationModel={setPaginationModel} paginationModel={paginationModel} />



            <PayApprovedRefund setSnackBarOptions={setSnackBarOptions} access={access} selected={selected} setSelected={setSelected} getDiscount={refres} open={openCaseDialog} setOpen={setOpenCaseDialog} />

        </div>
    </>)

}

