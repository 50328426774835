import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import moment from 'moment';
import TablePagination from '@mui/material/TablePagination';
import SendIcon from '@mui/icons-material/Send';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import KeyIcon from '@mui/icons-material/Key';
import PrintIcon from '@mui/icons-material/Print';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
// import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';

function descendingComparator(a, b, orderBy) {
    if (orderBy == 'viewAccess' || orderBy == 'createAccess' || orderBy == 'updateAccess') {
        return
    }
    if (typeof a[orderBy] == 'object' && typeof b[orderBy] == 'object') {
        if (a[orderBy]['props'] && b[orderBy]['props']) {
            if (b[orderBy]['props'] && a[orderBy]['props'] && a[orderBy]['props']['children'] && a[orderBy]['props']['children']?.length > 0 && a[orderBy]['props']['children']?.length > 0) {
                let index1, index2;
                if (orderBy == 'tax' || orderBy == 'totalFee') {
                    index1 = a[orderBy]['props']['children'][0]
                    index2 = b[orderBy]['props']['children'][0]
                } else {
                    index1 = a[orderBy]['props']['children'];
                    index2 = b[orderBy]['props']['children'];
                }
                if (index2 < index1) {
                    return -1;
                }
                if (index2 > index1) {
                    return 1;
                }
                return 0;
            }

        }
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function DynamicTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, viewcreateupdate } = props;

    // Opening sub menu for Roles
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [viewCreateUpdate, setViewCreateUpdate] = React.useState(viewcreateupdate);
    const [outviewCreateUpdate, setOutViewCreateUpdate] = React.useState();
    const [accessFilter, setAccessFilter] = React.useState('')
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setViewCreateUpdate(viewcreateupdate);
        setOutViewCreateUpdate(viewcreateupdate)
    })

    const handleRoles = (label, e) => {
        if (label == 'View Access' || label == 'Create Access' || label == 'Update Access') {
            setAccessFilter(label);
            handleClick(e)
        }
    }

    function resetOptions(title) {
        let arr = { ...outviewCreateUpdate }, arr1 = { ...viewCreateUpdate };
        if (title == 'view') {
            arr = arr.view.map(e => e.view = false);
            arr1 = arr1.view.map(e => e.view = false)
        }
        else if (title == 'create') {
            arr = arr.create.map(e => e.create = false);
            arr1 = arr1.create.map(e => e.create = false)
        } else {
            arr = arr.update.map(e => e.update = false);
            arr1 = arr1.update.map(e => e.update = false)
        }
        setOutViewCreateUpdate(arr)
        setViewCreateUpdate(arr1)

    }

    function applyFilter(title) {
        console.log('hello')
        let arr = { ...outviewCreateUpdate }, arr1 = { ...viewCreateUpdate }, arr2 = [];
        if (title == 'view') {
            delete arr.view;
            arr['view'] = [...arr1.view];
        }
        else if (title == 'create') {
            delete arr.create;
            arr['create'] = [...arr1.create];
        } else {
            delete arr.update;
            arr['update'] = [...arr1.update];
        }
        setOutViewCreateUpdate(arr);
        setViewCreateUpdate(arr1)
        props.sendAccessFilter({ ...arr });
        handleClose();
    }

    const switchOptions = (title, e, arr1, i) => {

        let arr = { ...arr1 };
        if (title == 'view') {
            arr.view[i].view = e.target.checked;
        }
        else if (title == 'create') {
            arr.create[i].create = e.target.checked;
        }
        else {
            arr.update[i].update = e.target.checked;
        }
        setViewCreateUpdate(arr)
    }

    const createSortHandler = (property, event) => {
        onRequestSort(event, property);
    };

    const doubleHead = (headCell) => (
        <TableCell sx={{ backgroundColor: '#FAFAFA', boxShadow: '#435A6F', color: 'black', minWidth: '180px' }}
            key={headCell?.id}
            align={'left'}
            padding={headCell?.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell?.id ? order : false}>
            <div style={{ width: '180px', textAlign: 'center' }}>Due Fee</div>
            <div style={{ width: '250px', display: 'flex', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#FAFAFA', minWidth: '125px' }}>Cheque</div>
                <div style={{ backgroundColor: '#FAFAFA', minWidth: '125px' }}>Others</div>
            </div>
        </TableCell>
    )

    return (
        <>
            <TableHead>
                <TableRow>
                    {headCells.map((headCell, _index) => (
                        (headCell?.id === 'pdc') ? doubleHead(headCell) :
                            (headCell?.subLabel != undefined) ? <>
                                <div key={_index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>
                                        <TableSortLabel>{headCell?.label}</TableSortLabel>
                                    </div>
                                    <div>
                                        {headCell?.subLabel.map((headCell1, __index) => (
                                            <TableCell
                                                sx={{ backgroundColor: '#FAFAFA', boxShadow: '#435A6F', color: 'black', minWidth: '160px' }}
                                                key={headCell1.id}
                                                align={'left'}
                                                padding={headCell1.disablePadding ? 'none' : 'normal'}
                                                sortDirection={orderBy === headCell1.id ? order : false}>
                                                {headCell1.sort == false ? headCell1.label :
                                                    <TableSortLabel
                                                        active={orderBy === headCell1.id}
                                                        direction={orderBy === headCell1.id ? order : 'asc'}
                                                        onClick={createSortHandler(headCell1.id)}>
                                                        {headCell1.label}
                                                        {orderBy === headCell1.id ? (
                                                            <Box component="span" sx={visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                }
                                            </TableCell>
                                        ))}
                                    </div>
                                </div>
                            </> : <>
                                <TableCell
                                    sx={{ backgroundColor: '#FAFAFA', boxShadow: '#435A6F', color: 'black', minWidth: '160px' }}
                                    key={headCell?.id}
                                    align={'left'}
                                    padding={headCell?.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell?.id ? order : false}>
                                    {headCell?.sort == false ? headCell?.label :
                                        <TableSortLabel
                                            active={orderBy === headCell?.id}
                                            direction={orderBy === headCell?.id ? order : 'asc'}
                                            onClick={(e) => {
                                                createSortHandler(headCell?.id, e);
                                                handleRoles(headCell?.label, e)
                                            }}>
                                            {headCell?.label}
                                            {orderBy === headCell?.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    }
                                </TableCell>
                            </>
                    ))}
                </TableRow>
            </TableHead>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    {accessFilter == 'View Access' ? <>
                        {viewCreateUpdate?.view?.map((row, i) => {
                            return (<>
                                <FormControlLabel
                                    key={i}
                                    label={<Typography >{row?.label}</Typography>}
                                    control={<Checkbox checked={row?.view} size='small' onChange={(e) => { switchOptions('view', e, viewCreateUpdate, i) }} />}

                                />
                            </>
                            )
                        })}
                        <div className='flex items-center justify-end mx-3'>
                            <Button color="secondary" variant="outlined" className='mr-1' type='button' onClick={() => resetOptions('view')}>
                                Reset
                            </Button>
                            <Button variant="contained" type='button' onClick={() => { applyFilter('view') }}>
                                Apply
                            </Button>
                        </div>
                    </> : null}
                    {accessFilter == 'Create Access' ? <>
                        {viewCreateUpdate?.create?.map((row, i) => {
                            return (
                                <FormControlLabel
                                    label={<Typography >{row?.label}</Typography>}
                                    control={<Checkbox size='small' checked={row?.create} onChange={(e) => { switchOptions('create', e, viewCreateUpdate, i) }} />}
                                />
                            )
                        })}
                        <div className='flex items-center justify-end mx-3'>
                            <Button color="secondary" variant="outlined" className='mr-1' type='button' onClick={() => resetOptions('create')}>
                                Reset
                            </Button>
                            <Button variant="contained" type='button' onClick={() => { applyFilter('create') }}>
                                Apply
                            </Button>
                        </div>
                    </> : null}
                    {accessFilter == 'Update Access' ? <>
                        {viewCreateUpdate?.update?.map((row, i) => {
                            return (
                                <FormControlLabel
                                    label={<Typography >{row?.label}</Typography>}
                                    control={<Checkbox size='small' checked={row?.update} onChange={(e) => { switchOptions('update', e, viewCreateUpdate, i) }} />}
                                />
                            )
                        })}
                        <div className='flex items-center justify-end mx-3'>
                            <Button color="secondary" variant="outlined" className='mr-1' type='button' onClick={() => resetOptions('update')}>
                                Reset
                            </Button>
                            <Button variant="contained" type='button' onClick={() => { applyFilter('update') }}>
                                Apply
                            </Button>
                        </div>
                    </> : null}

                </Box>
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>
        </>
    );
}

DynamicTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function DynamicTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const checkVal = [];
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        setPage(0)
    }, [props?.candidateBy])

    const onEdit = (e, row) => {
        // EDIT EVENT FOR EDIT ICON - SENDS DATA TO PARENT

        props.actionOnEdit(e, row)
    }

    const onDelete = (e, row) => {
        // DELETE EVENT OF DELET ICONS BUTTON - SENDS DATA TO PARENT
        props.actionOnDelete(e, row)
    }
    const onPasswordChange = (e, row) => {
        // DELETE EVENT OF DELET ICONS BUTTON - SENDS DATA TO PARENT
        props.actionOnPassword(e, row)
    }
    const onSend = (e, row) => {
        // DELETE EVENT OF DELET ICONS BUTTON - SENDS DATA TO PARENT
        props.actionOnSend(e, row)
    }

    const sendAccessFilter = (filter) => {
        props.sendAccessFilter(filter)
    }

    const handleChange = (e, i, row) => {
        checkVal[i] = e.target.checked
        props.actionOnChange(e, row)
    }

    function actionColumns(row, i) {
        if (checkVal.length <= 0) checkVal.push(row.status)
        return (
            <div className='flex'>
                {props.action && !row?.pendingDiscountRequest ?
                    <div><Tooltip title={props.tooltip} ><Button disabled={!props?._currentModule?.update || (row?.stats === 'paid' || row?.pendingDiscountRequest ? true : false)} onClick={(e) => onSend(e, row)} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                        <SendIcon color={!props?._currentModule?.update || (row?.stats === 'paid' || row?.pendingDiscountRequest ? true : false) ? 'disabled' : 'success'} fontSize='sm' />
                    </Button></Tooltip>
                    </div>
                    :
                    props.action && row?.pendingDiscountRequest ?
                        <Button disabled={true} onClick={(e) => e.stopPropagation()} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                            <SendIcon className='text-gray-500' fontSize='sm' />
                        </Button>
                        :
                        ((props.action && row?.stats === 'paid') || (props.action && row?.stats === 'bounced') || (props.action && row?.stats === 'disabled') || (props.action && row?.stats === 'rejected')) ?
                            <Button disabled={true} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                                <SendIcon sx={{ color: 'gray' }} fontSize='sm' />
                            </Button>
                            :
                            props.action && <div><Tooltip title="Send Payment Link"><Button onClick={(e) => onSend(e, row)} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                                <SendIcon color='success' fontSize='sm' />
                            </Button></Tooltip></div>
                }
                {
                    props.switchButton ?
                        <FormGroup>
                            <FormControlLabel control={<Switch onChange={(e) => handleChange(e, i, row)} checked={props.status == false ? row.status : checkVal
                            [i]} />} /></FormGroup> : null
                }
                {
                    props.edit && <Button onClick={(e) => onEdit(e, row)} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                        <EditIcon fontSize='sm' />
                    </Button>
                }
                {
                    props.delete && <Button color='error' disabled={props.canDisableDel ? !row.delete : false} onClick={(e) => onDelete(e, row)} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                        <DeleteIcon fontSize='sm' />
                    </Button>
                }
                {
                    props.pdf ? <Tooltip title={'Download receipt'} placement="bottom-start"><Button sx={{ minWidth: '0px', padding: '0px 5px' }} onClick={() => { props.openPDF({ ...row, i: i }, 'download') }} disabled={row?.stats != 'paid'}>
                        <FileDownloadIcon sx={{ fontSize: '15px' }} />
                    </Button></Tooltip> : null
                }
                {
                    props.download ? <Tooltip title={'Print'} placement="bottom-start"><Button sx={{ minWidth: '0px', padding: '0px 5px' }} onClick={() => { props.openPDF({ ...row, i: i }, 'print') }} disabled={row?.stats != 'paid'}>
                        <PrintIcon sx={{ fontSize: '15px' }} />
                    </Button></Tooltip> : null
                }
                {
                    props.passwordChangeAction && <Button onClick={(e) => onPasswordChange(e, row)} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                        <KeyIcon sx={{ color: 'gray', fontSize: '18px' }} />
                    </Button>
                }
                {
                    props.paymentAction && row?.requestStatus === 'pending' ? <Button onClick={(e) => onSend(e, row)} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                        <ArrowForwardIosIcon sx={{ fontSize: '18px' }} />
                    </Button> :
                        props.paymentAction && <Button disabled={true} sx={{ minWidth: '0px', padding: '0px 5px' }}>
                            <ArrowForwardIosIcon sx={{ fontSize: '18px', color: 'gray' }} />
                        </Button>
                }

            </div >
        )
    }

    function onSwitchAction(e, i, data) {

        props.status(e, i, data)
    }

    function statusColumns(row, index) {
        // RETURNS STATUS COLUMNS TO THE TABLE FOR SWITCH BUTTON
        return (
            <>
                <Switch key={index} disabled={props.update} checked={row.status} onClick={(e) => onSwitchAction(e, index, row)} sx={{ marginLeft: '-10px' }} />
                <span>{row.status === true ? 'Active' : 'Deactive'}</span>
            </>
        )
    }

    function showDate(_date) {
        // SHOW DATE IN TABLE
        return moment(_date).format('MMMM Do YYYY, h:mm:ss a')
    }

    const TableRowValues = (columns, _rowData, _dataIndex) => {
        // console.log(columns,_rowData,_dataIndex)
        // MAP ON TABLE ROWS
        // @params - columns - HOLDS ALL COLUMNS IN TABLE
        // @params - _rowData - CURRENT CLICKED ROW DATA
        // @params - _dataIndex - CURRENT CLICKED ROW INDEX
        return (
            columns?.map((head, index) => {
                return (
                    (head?.id === 'pdc') ? doubleHeadVal(head, _rowData, _dataIndex) :
                        (head?.subLabel == undefined) ? <>
                            <TableCell padding="normal" sx={{ minWidth: '105px' }} key={index}>
                                {head?.id != 'action' ? (head?.id == 'createdAt' ? showDate(_rowData[head?.id]) : head?.id === 'status' ? statusColumns(_rowData, _dataIndex) : (_rowData?.type == 'bounceCharges' && head?.id == 'amount') ? <Tooltip title="Bounced Charges"><div style={{ backgroundColor: '#FFCCCB', height: '35px', width: '80px' }} className="flex justify-center items-center cursor-pointer"><div className='mr-1'>{_rowData[head?.id]}</div><div><KeyboardArrowDownOutlinedIcon style={{ fontSize: '18px' }} /></div></div></Tooltip> : (head?.id == 'DueFee' && _rowData?.PaymentStatus == 'Overdue') ? <div style={{ color: 'red' }}>{_rowData[head?.id]}</div> : <>{_rowData[head?.id]}</>) : actionColumns(_rowData, _dataIndex)}
                            </TableCell>
                        </> : <>
                            {head?.subLabel?.map((head, index) => (
                                <TableCell padding="normal" sx={{ minWidth: '10px', backgroundColor: (_rowData?.type == 'bounceCharges' && head?.id == 'amount') ? '#FFCCCB' : '#FFFFFF' }} key={index}>
                                    {head?.id != 'action' ? (head?.id == 'createdAt' ? showDate(_rowData[head.id]) : head.id === 'status' ? statusColumns(_rowData, _dataIndex) : _rowData[head.id]) : actionColumns(_rowData, _dataIndex)}
                                </TableCell>
                            ))}
                        </>
                )
            })
        )
    }

    function onClickAction(_data, i) {
        console.log("onclick", _data)
        props.clickAction(_data, i)
    }

    const doubleHeadVal = (head, _rowData, _dataIndex) => {
        // console.log(head,_rowData,_dataIndex)
        return (
            <TableCell padding="normal" sx={{ minWidth: '105px' }} key={_dataIndex}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TableCell sx={{ minWidth: '125px', borderBottomWidth: 0 }}>{_rowData?.PDC}</TableCell>
                    <TableCell sx={{ minWidth: '125px', borderBottomWidth: 0 }}>{_rowData?.Others}</TableCell>
                </div>
            </TableCell>
        )
    }



    return (
        <Box sx={{ width: '100%' }}>
            <Paper>
                <TableContainer>
                    <div style={{ maxHeight: '560px' }}>
                        <Table stickyHeader
                            aria-labelledby="tableTitle"
                        >
                            <DynamicTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                viewcreateupdate={props.viewcreateupdate}
                                headCells={props.headCells}
                                sendAccessFilter={sendAccessFilter}
                            />
                            <TableBody>
                                {stableSort(props.dataSource, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((_data, i) => {

                                        return (
                                            props.clickable === true ? <TableRow onClick={() => onClickAction(_data, i)} sx={{ backGroundColor: '#FFFFFF', cursor: 'pointer' }} key={i}>
                                                {TableRowValues(props.headCells, _data, i)}
                                            </TableRow> :
                                                <TableRow sx={{ backGroundColor: '#FFFFFF' }} key={i} >
                                                    {TableRowValues(props.headCells, _data, i)}
                                                </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                {props.pagination && <TablePagination
                    sx={{ backgroundColor: '#FAFAFA', boxShadow: '#435A6F', color: 'black' }}
                    rowsPerPageOptions={[15, 30, 50, 100]}
                    component="div"
                    count={props.dataSource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Paper>
        </Box>
    );
}

