import { useLocation, useNavigate, } from "react-router-dom";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import * as React from "react";
import { closeWindow } from "../Services/api-services";


function PaymentStatus(){

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');

    return(
        <div className='flex items-center justify-center h-screen'>
        <div className="flex flex-col items-center ">
        {status==='successful'?<CheckCircleRoundedIcon
            className="tColor"
            sx={{ fontSize: "48px",color:'green' }}
          />:
        <ErrorRoundedIcon
            sx={{ fontSize: "48px",color:'red' }}
          />}
          &nbsp;
        <h1 className='text-xl text-center'>Your payment is {status}.</h1>
        
          </div>
    </div>
    )
}
export default PaymentStatus;