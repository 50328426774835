import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import ValidateEmail from "../Components/ValidEmail";
import CustomizedSnackbars from "../Components/Snackbar";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import {
  sendOtp,
  loginConfirm,
  verifyOtp,
  _updatePassword,
} from "../Services/api-services";
import CountDownTimer from "../Components/CountDownTimer";
import { UserAuthState } from "../Services/RecoilService";
import { useRecoilState } from "recoil";

function Login(props) {
  let navigate = useNavigate();
  const [next, setNext] = useState("email");
  const [forgotNext, setForgotNext] = useState("forgotEmail");
  const [forgotOTP, setForgotOTP] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState("");
  const [forgotReenterPassword, setForgotReenterPassword] = useState("");
  const [otp, setOtp] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const [seconds, setSeconds] = useState(30);
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  const [snackBarOptions, setSnackBarOptions] = useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  const preModulesArray = [
    {
      name: 'Analytics'
    },
    {
      name: 'Reports'
    },
    {
      name: 'Open cases'
    },
    {
      name: 'Enrollments'
    },
    {
      name: 'Discount Requests'
    },
    {
      name: 'Refund Requests'
    },
    {
      name: 'Payment due'
    },
    {
      name: 'Roles'
    },
    {
      name: 'Batch'
    },
    {
      name: 'Course categories'
    },
    {
      name: 'Courses'
    },
    {
      name: 'Addons'
    },
    {
      name: 'Tags'
    },
    {
      name: 'Cheque bouncing charges'
    },
    {
      name: 'Sms template'
    },
  ]

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setForgotNext("forgotEmail");
    setEmail("");
    setForgotPassword("");
    setForgotReenterPassword("");
    setForgotOTP("");
    setOpen(false);
  };

  async function MisLogin() {
    if (!password) {
      setSnackBarOptions({
        message: "Please enter password!",
        duration: 5000,
        show: true,
        severity: "error",
      });
    } else {
      const body = {
        email: email,
        purpose: "loginOTP",
        password,
      };
      const response = await sendOtp(body);
      if (response.statusCode === 200) {
        console.log(response)
        setNext("a");
      } else {
        setSnackBarOptions({
          message: "Password didn't match",
          duration: 5000,
          show: true,
          severity: "error",
        });
      }
    }
  }

  async function MisOtp() {
    const body = {
      email: email,
      password: password,
      otp: otp,
    };
    const response = await loginConfirm(body);

    if (response.statusCode === 200) {
      let firstPage;
      response?.assignModules?.assignModule?.map((item) => {
        if (item?.read || item?.update || item?.write) {
          if (!firstPage) {
            firstPage = item?.name
          }
        }
      })
      if (response.assignModules && firstPage) {
        let data = {
          "token": response?.token,
          "userId": response?.userId,
          "userData": response,
          "firstPage": firstPage
        }
        setAuthState(data)
        const myTimeout = setTimeout(() => {
          navigate('/home')

        }, 500);

      
      }



      else {
        setSnackBarOptions({
          message: "No Modules Assigned!",
          duration: 5000,
          show: true,
          severity: "error",
        });
      }
    }
    else {
      setSnackBarOptions({
        message: "Incorrect OTP",
        duration: 5000,
        show: true,
        severity: "error",
      });
    }
  }

  async function CheckEmail() {
    try {
      let validEmail = ValidateEmail(email);
      if (validEmail) {
        const body = {
          email: email,
          purpose: "verifyEmail",
        };
        const response = await sendOtp(body);
        if (response.statusCode === 200) {
          setNext("password");
        } else {
          if (response?.error?.statusCode === 402) {
            setSnackBarOptions({
              message: response.error.message,
              duration: 5000,
              show: true,
              severity: "error",
            });
          }
          else {
            if (response.error.statusCode === 402) {
              setSnackBarOptions({
                message: response.error.message,
                duration: 5000,
                show: true,
                severity: "error",
              });
            }
            else {
              setSnackBarOptions({
                message: "Email address not registered!",
                duration: 5000,
                show: true,
                severity: "error",
              });
            }
          }
        }
      } else {
        setSnackBarOptions({
          message: "Please enter valid email address",
          duration: 5000,
          show: true,
          severity: "error",
        });
      }
    }
    catch (e) {
      console.log(e)
      setSnackBarOptions({
        message: "something went wrong!",
        duration: 5000,
        show: true,
        severity: "error",
      });
    }
  }

  async function forgotConfirmOtp() {
    let validEmail = ValidateEmail(email);
    if (validEmail) {
      const body = {
        email: email,
        purpose: "verifyEmail",
      };
      const response = await sendOtp(body);
      if (response.statusCode === 200) {
        const body = {
          email: email,
          purpose: "forgotOTP",
        };
        const response = await sendOtp(body);
        if (response.statusCode === 200) {
          setForgotNext("forgotOtp");
          setSeconds(30)
        } else {
          setSnackBarOptions({
            message: "Email address not registered!",
            duration: 5000,
            show: true,
            severity: "error",
          });
        }
      } else {
        if (response.error.statusCode === 402) {
          setSnackBarOptions({
            message: response.error.message,
            duration: 5000,
            show: true,
            severity: "error",
          });
        }
        else {
          setSnackBarOptions({
            message: "Email address not registered!",
            duration: 5000,
            show: true,
            severity: "error",
          });
        }
      }
    } else {
      setSnackBarOptions({
        message: "Please enter valid email address",
        duration: 5000,
        show: true,
        severity: "error",
      });
    }
  }
  async function forgotVerifyOtp() {
    const body = {
      email: email,
      otp: forgotOTP,
    };
    const response = await verifyOtp(body);

    if (response.statusCode === 200) {
      setForgotNext("a");
    } else {
      setSnackBarOptions({
        message: "OTP didn't match!",
        duration: 5000,
        show: true,
        severity: "error",
      });
    }
  }

  async function updatePassword() {
    if (forgotPassword.length < 6) {
      setSnackBarOptions({
        message: "Password length should be greater than 5",
        duration: 5000,
        show: true,
        severity: "error",
      });
    } else {
      if (forgotPassword === forgotReenterPassword) {
        const body = {
          email: email,
          password: forgotPassword,
          otp: forgotOTP,
        };
        const response = await _updatePassword(body);
        if (response.statusCode === 200) {
          navigate("/");
          handleClose();
          setSnackBarOptions({
            message: "Password Changed Successfully",
            duration: 5000,
            show: true,
            severity: "success",
          });
        } else {
          setSnackBarOptions({
            message: "internal server error",
            duration: 5000,
            show: true,
            severity: "error",
          });
        }
      } else {
        setSnackBarOptions({
          message: "Password didn't match",
          duration: 5000,
          show: true,
          severity: "error",
        });
      }
    }
  }
  React.useEffect(() => {
    if (authState?.token) {
      navigate('/home')
    }
  }, []);
  return (
    <div className="flex items-center justify-center h-screen bg-slate-100 0">
      <Card
        variant="outlined"
        sx={{
          width: "600px",
          height: "250px",
          padding: "20px 20px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
        }}
      >
        <Box component="span" sx={{ mx: "2px", transform: "scale(0.8)" }}>
          {next === "email" ? (
            <div>
              <h1 className="text-2xl">Log in to MIS</h1>
              <TextField
                autoFocus={true}
                onKeyDown={(e) => (e.key === "Enter" ? CheckEmail() : "")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginTop: "30px", width: "100%" }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />
              <div className=" cursor-pointer">
                <Button
                  onClick={handleClickOpen}
                  className="fg"
                  sx={{ color: "black", fontSize: "10px" }}
                >
                  Forgot Password ?
                </Button>
              </div>
              <Button
                onClick={CheckEmail}
                sx={{ float: "right" }}
                variant="contained"
              >
                Next
              </Button>
            </div>
          ) : next === "password" ? (
            <div>
              <h1 className="text-2xl">
                <ArrowBackIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setNext("email")}
                ></ArrowBackIcon>
                &nbsp;&nbsp;Log in to MIS
              </h1>
              <FormControl
                sx={{ marginTop: "30px", width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  onKeyDown={(e) => (e.key === "Enter" ? MisLogin() : "")}
                  id="outlined-adornment-password"
                  autoFocus={true}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <div className=" cursor-pointer">
                <Button
                  onClick={handleClickOpen}
                  className="fg"
                  sx={{ color: "black", fontSize: "10px" }}
                >
                  Forgot Password ?
                </Button>
              </div>
              <Button
                onClick={MisLogin}
                sx={{ float: "right" }}
                variant="contained"
              >
                Send OTP
              </Button>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl">
                <ArrowBackIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setNext("password")}
                ></ArrowBackIcon>
                &nbsp;&nbsp;Log in to MIS
              </h1>
              <TextField
                type="number"
                onKeyDown={(e) => (e.key === "Enter" ? MisOtp() : "")}
                value={otp}
                autoFocus={true}
                onChange={(e) => setOtp(e.target.value)}
                sx={{ marginTop: "30px", width: "100%" }}
                id="outlined-basic"
                label="Enter OTP"
                variant="outlined"
              />
              <Button
                onClick={MisOtp}
                sx={{ float: "right", marginTop: "28px" }}
                variant="contained"
              >
                Submit
              </Button>
              <div>
                <CountDownTimer duration={seconds} resendOtp={MisLogin} />
                {/* {seconds===0? <Button variant="text" onClick={forgotConfirmOtp}>
                    Resend OTP
                  </Button>:<h1>{seconds < 10 ?  `0${seconds}` : seconds}</h1>} */}
              </div>
            </div>

          )}
          <CustomizedSnackbars
            message={snackBarOptions.message}
            open={snackBarOptions.show}
            close={() => {
              setSnackBarOptions({ ...snackBarOptions, show: false });
            }}
            hideDur={snackBarOptions.duration}
            severity={snackBarOptions.severity}
          />
        </Box>
      </Card>
      <div>
        <Dialog maxWidth="sm" fullWidth={true} open={open}>
          <div className="py-7">
            <DialogTitle>Forgot Password</DialogTitle>

            {forgotNext === "forgotEmail" ? (
              <div className="px-6">
                <FormControl fullWidth>
                  <TextField
                    onKeyDown={(e) =>
                      e.key === "Enter" ? forgotConfirmOtp() : ""
                    }
                    value={email}
                    autoFocus={true}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="dense"
                    id="email"
                    label="Email"
                    type="name"
                    variant="outlined"
                  />
                </FormControl>

                <DialogActions sx={{ marginTop: "10px" }}>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={forgotConfirmOtp}>
                    Send OTP
                  </Button>
                </DialogActions>
              </div>
            ) : forgotNext === "forgotOtp" ? (
              <div className="px-6">
                <FormControl fullWidth>
                  <TextField
                    onKeyDown={(e) =>
                      e.key === "Enter" ? forgotVerifyOtp() : ""
                    }
                    value={forgotOTP}
                    onChange={(e) => setForgotOTP(e.target.value)}
                    autoFocus
                    margin="dense"
                    id="enter OTP"
                    label="Enter OTP"
                    type="name"
                    fullWidth
                    variant="outlined"
                  />
                </FormControl>
                <div>
                  <CountDownTimer duration={seconds} resendOtp={forgotConfirmOtp} />
                  {/* {seconds===0? <Button variant="text" onClick={forgotConfirmOtp}>
                    Resend OTP
                  </Button>:<h1>{seconds < 10 ?  `0${seconds}` : seconds}</h1>} */}
                </div>

                <DialogActions sx={{ marginTop: "10px" }}>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button onClick={forgotVerifyOtp} variant="contained">
                    Verify OTP
                  </Button>
                </DialogActions>
              </div>
            ) : (
              <div className="px-6">
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="forgotpassword">Password</InputLabel>
                  <OutlinedInput
                    id="forgot password"
                    autoFocus={true}
                    type={showPassword ? "text" : "password"}
                    value={forgotPassword}
                    onChange={(e) => setForgotPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <FormControl
                  sx={{ width: "100%", marginTop: "10px" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="forgotReEnterpassword">
                    Re-enter Password
                  </InputLabel>
                  <OutlinedInput
                    id="forgot reenter password"
                    onKeyDown={(e) =>
                      e.key === "Enter" ? updatePassword() : ""
                    }
                    type={showPassword ? "text" : "password"}
                    value={forgotReenterPassword}
                    onChange={(e) => setForgotReenterPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Re-enter Password"
                  />
                </FormControl>

                <DialogActions sx={{ marginTop: "20px" }}>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button onClick={updatePassword} variant="contained">
                    Update Password
                  </Button>
                </DialogActions>
              </div>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
}
export default Login;
