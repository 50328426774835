import axios from "axios";
import getCookie from "../Components/Utility/Utility";

export let _localStorage = {};

export const updateLocalStorage = async (data) => {
  // UPDATE LOCAL STORAGE

  _localStorage = data;

  if (localStorage) {
    localStorage.removeItem("misUserData");
    window.location.replace("/");
  }
};

// RETURN TOKEN ACCORDING TO CURRENT PANEL USER
export const returnToken = () => {

  let tokena = localStorage.getItem("misUserData");
  console.log("ashish", tokena?.misUserData)
  tokena = tokena ? JSON.parse(tokena) : null;
  return tokena?.misUserData?.token;

};

export const baseUrl = `https://doubts-api.aptstudy.com/api/`;
// export const baseUrl = `http://192.168.1.39:3100/api/`;
export const frontEndUrl = 'https://mis.apt.shiksha'

// export const baseUrl = `http://localhost:3100/api/`;
// export const frontEndUrl = 'http://localhost:3000'

// INTERCEPTORS FOR REQUESTS

axios.interceptors.request.use(
  async function (config) {
    config.headers.Authorization = await returnToken();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const post = async (url, data) => {
  try {
    const res = await axios.post(baseUrl + url, data);
    return { statusCode: res?.status, data: res.data };
  } catch (e) {
    if (e?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }

    return {
      statusCode: e?.response?.data?.error?.statusCode || 404,
      message:
        e.response.data === undefined
          ? e?.message
          : e?.response?.data?.error?.message,
    };
  }
};

export const patch = async (url, data) => {
  try {
    const res = await axios.patch(baseUrl + url, data);
    return { statusCode: res.status, data: res.data };
  } catch (e) {
    if (e?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }
    return {
      statusCode: e?.response?.data?.error?.statusCode || 404,
      message:
        e.response.data === undefined
          ? e?.message
          : e?.response?.data?.error?.message,
    };
  }
};

export const get = async (url) => {
  try {
    const res = await axios.get(baseUrl + url);
    return { statusCode: res.status, data: res.data };
  } catch (err) {
    if (err?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }
  }
};

export const deletethis = async (url) => {
  try {
    const res = await axios.delete(baseUrl + url);
    return { statusCode: res.status, data: res.data };
  } catch (err) {
    if (err?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }
  }
};


export const yyyymmdd = (date) => {
  console.log("hello jflkjslkjfkljklsjlfjjlsjlfjlkj")
  const _date = {
    y: new Date(date).getFullYear(),
    m: new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth(),
    d: new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate()
  }
  console.log(_date, "ashish")
  return `${_date.y}-${_date.m}-${_date.d}`
}

export function capitalizeFirstLetter(str) {
  // Check if the input string is not empty
  if (str.length === 0) {
    return str;
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}