import ErrorIcon from "@mui/icons-material/Error";
function NodataInTable(props){
    return(
        <div className=" flex items-center justify-center pt-72">
        <span>
          <ErrorIcon sx={{ color: "red", fontSize: "20px" }} />
        </span>
        &nbsp;&nbsp;
        <span className="pt-1">
          {props.message}
        </span>
      </div>
    )
}
export default NodataInTable;