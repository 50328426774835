import Typography from "@mui/material/Typography";
import BreadCrumb from "../Components/BreadCrumbs";
import InputLabel from "@mui/material/InputLabel";
import DateInput from "../Components/DateInput";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';

import {
  _addBatchModes,
  _addBatches,
  getBatchModes,
  getBatches,
  _deleteBatchMode,
  _deleteBatches,
  _updateBatchMode,
  _updateBatches,
  getAllCourses,
  httpGet,
} from "../Services/api-services";
import CustomizedSnackbars from "../Components/Snackbar";
import { getCourses } from "../Services/api-services";
import Autocomplete from "@mui/material/Autocomplete";
import DynamicTable from "../Components/DynamicTable";
import DialogueBox from "../Components/DialogueBox";
import moment from "moment";
import Panel from "../Components/Panel";
import NodataInTable from "../Components/NoDataInTable";
import DateSearchFilter from "../Components/DateSearchFilter";
import { letterSpacing } from "@mui/system";
import CONSTANTS from "../constant.json";
import { UserAuthState } from "../Services/RecoilService";
import { useRecoilState } from "recoil";
import { updateLocalStorage } from "../Services/services";

function Batch() {
  const [batchModeDataSource, setBatchModeDataSource] = React.useState(null);
  const [batchM, setBatchM] = React.useState([]);
  const [batchesDataSource, setBatchesDataSource] = React.useState(null);
  const [allBatches, setAllBatches] = React.useState([]);
  const [allBatchModes, setAllBatchModes] = React.useState([]);
  const [batchModeDialog, setBatchModeDialog] = React.useState(false);
  const [batchesDialog, setBatchesDialog] = React.useState(false);
  const [updateBatchMode, setUpdateBatchMode] = React.useState(false);
  const [updateBatches, setUpdateBatches] = React.useState(false);
  const [batchModeName, setBatchModeName] = React.useState("");
  const [batchModeIndex, setBatchModeIndex] = React.useState("");
  const [batchesIndex, setBatchesIndex] = React.useState("");
  const [selectCourse, setSelectCourse] = React.useState("");
  const [selectCourseData, setSelectCourseData] = React.useState("");
  const [allCourses, setAllCourses] = React.useState([]);
  const [batchName, setBatchName] = React.useState("");
  const [date, setDate] = React.useState("");
  const [timeFrom, setTimeFrom] = React.useState("");
  const [timeTo, setTimeTo] = React.useState("");
  const [courseId, setCourseId] = React.useState("");
  const [addbatchMode, setAddBatchMode] = React.useState(false);
  const [addBatches, setAddBatches] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  var d = new Date('1/1/2021');
  // d.setMonth(d.getMonth() - 1);
  const [startDate, setStartDate] = React.useState(d.toISOString());
  const [endDate, setEndDate] = React.useState(new Date().toISOString());
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const [isLoading, setIsLoading] = React.useState(false)

  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  React.useEffect(() => {

    const modules = authState?.userData?.assignModules?.assignModule;
    const batchArr = modules.find((m) => m?.name === "Batchs");
    setBatchM(batchArr);
    var d = new Date('1/1/2021');
    // d.setMonth(d.getMonth() - 1);
    // const [startDate, setStartDate] = React.useState(d.toISOString());
    let data = {
      startDate: d.toISOString(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      input: ""
    }

    async function fetchData(data) {
      try {

        const courses = await getAllCourses(data);
        setAllCourses(courses);
        const batchModes = await getBatchModes(data);
        console.log(batchModes, "hjkhfhshfhhshfhkjh")
        if (batchModes?.error?.statusCode === 401) {
          updateLocalStorage()
        }
        setAllBatchModes(batchModes);
        const _dataSourceBatchMode = await createTableDataBatchMode(batchModes);
        setBatchModeDataSource(_dataSourceBatchMode);
        const batches = await getBatches(data);
        setAllBatches(batches);
        if (batches?.error?.statusCode === 401) {
          updateLocalStorage()
        }
        const _dataSourceBatches = await createTableDataBatches(batches);
        setBatchesDataSource(_dataSourceBatches);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData(data);
    // eslint-disable-next-line
  }, []);

  const batch = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      Master
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Batch
    </Typography>,
  ];

  // BATCH MODE FLOW

  function openBatchModeDialog() {
    setBatchModeDialog(true);
  }

  function closeBatchModeDialog() {
    setUpdateBatchMode(false);
    setBatchModeDialog(false);
    setBatchModeName("");
    setAddBatchMode(false);
  }
  function clearAddBatchForm() {
    setBatchName("");
    setSelectCourse("");
    setDate("");
    setTimeFrom("");
    setTimeTo("");
    setAddBatches(false);
    setSelectCourseData("");
  }

  const fetchBatchMode = async (data = null) => {
    let _batchMode
    if (data == null) {
      let data = {
        startDate: startDate,
        endDate: endDate,
        input: ""
      }
      _batchMode = await getBatchModes(data);
      if (_batchMode?.error?.statusCode === 401) {
        updateLocalStorage()
      }

    } else {
      _batchMode = await getBatchModes(data);
      if (_batchMode?.error?.statusCode === 401) {
        updateLocalStorage()
      }
    }
    setAllBatchModes(_batchMode);
    const _dataSource = await createTableDataBatchMode(_batchMode);
    setBatchModeDataSource(_dataSource);
  };

  async function duplicateBatchMode() {
    let url = `${CONSTANTS.baseUrl}/batchModes?filter={"where":{"name":{"regexp":"/${batchModeName}/i"},"deleted":false}}`
    const req = await httpGet(url);
    return (req.length > 0) ? false : true;
  }
  async function addNewBatchMode() {
    if (!await duplicateBatchMode()) {
      setSnackBarOptions({
        message: "Duplicate Batch Mode!",
        show: true,
        severity: "error",
        duration: 5000,
      });
      closeBatchModeDialog();
      return
    }
    if (batchModeName) {
      setAddBatchMode(true);
      const body = {
        name: batchModeName,
        status: true,
      };
      try {
        const response = await _addBatchModes(body);
        console.log(response)
        const _bmFilter = { startDate, endDate: new Date(new Date().setDate(new Date().getDate() + 1)), input: '' }
        fetchBatchMode(_bmFilter);
        setSnackBarOptions({
          message: "Batch Mode added successfully!",
          show: true,
          severity: "success",
          duration: 5000,
        });
        closeBatchModeDialog();
      } catch (error) {
        console.log(error);
      }
    } else {
      setAddBatchMode(true);
    }
  }

  function editBatchModeDialog(e, data) {
    setBatchModeIndex(data.id);
    setUpdateBatchMode(true);
    setBatchModeDialog(true);
    setBatchModeName(data.name);
  }

  async function updateBatchModes() {
    const body = {
      name: batchModeName,
      id: batchModeIndex,
    };
    const response = await _updateBatchMode(body, batchModeIndex);
    fetchBatchMode();
    closeBatchModeDialog();
  }

  async function updateBatch() {
    setIsLoading(true)
    const body = {
      name: batchName,
      startDate: date,
      from: timeFrom,
      to: timeTo,
      courseId: courseId,
      id: batchesIndex,
      status: true,
    };
    const response = await _updateBatches(body, batchesIndex);
    setIsLoading(false)
    fetchBatches();
    closeBatchesDialog();
  }

  function _openConfirmDialogBatchMode(e, data) {
    setBatchModeIndex(data.id);
    setConfirmDialog(true);
  }

  async function deleteBatchMode() {
    const body = {
      deleted: true,
      status: false,
    };
    const response = await _deleteBatchMode(body, batchModeIndex).then(
      (res) => {
        fetchBatchMode();
      }
    );
    setConfirmDialog(false);
  }

  // BATCHES FLOW

  function openBatchesDialog() {
    setBatchesDialog(true);
  }

  function closeBatchesDialog() {
    setBatchesDialog(false);
    setUpdateBatches(false);
    clearAddBatchForm();
  }

  const fetchBatches = async (data = null) => {
    console.log(new Date());
    let _batches
    if (data == null) {
      console.log('here')
      let data = {
        startDate: startDate,
        // endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        endDate: new Date(new Date().toISOString()),
        input: ""
      }
      console.log(data);
      _batches = await getBatches(data);
      if (_batches?.error?.statusCode === 401) {
        updateLocalStorage()
      }
    } else {
      _batches = await getBatches(data);
      if (_batches?.error?.statusCode === 401) {
        updateLocalStorage()
      }
    }
    setAllBatches(_batches);
    const _dataSource = await createTableDataBatches(_batches);
    setBatchesDataSource(_dataSource);
  };

  async function addBatch() {
    setIsLoading(true)
    if (batchName && selectCourse && date && timeFrom && timeTo) {
      const body = {
        name: batchName,
        from: timeFrom,
        to: timeTo,
        courseId: courseId,
        status: true,
        startDate: date,

      };
      try {
        const response = await _addBatches(body);
        setIsLoading(false)
        setIsLoading(false)
        fetchBatches();
        setBatchesDialog(false);
        setSnackBarOptions({
          message: "Batch added successfully!",
          show: true,
          severity: "success",
          duration: 5000,
        });
        clearAddBatchForm();
      } catch (error) {
        console.log(error);
      }
    } else {
      setAddBatches(true);
    }
  }

  const fetchBatchById = (batches, _id) => {
    return new Promise((resolve, reject) => {
      for (let index = 0; index < batches.length; index++) {
        const _batch = batches[index];
        if (_id === _batch.id) {
          resolve(_batch);
          break;
        }
      }
    });
  };

  async function editBatchesDialog(e, data) {
    console.log(data)
    setBatchesIndex(data.id);
    data = await fetchBatchById(allBatches, data.id);
    const course = await fetchBatchById(allCourses, data.courseId);
    console.log(data)
    setSelectCourseData(course);
    setCourseId(data.courseId);
    setUpdateBatches(true);
    setSelectCourse(data.courseId);
    setUpdateBatches(true);
    setBatchesDialog(true);
    setBatchName(data.name);
    setDate(moment(data.startDate).format(moment.HTML5_FMT.DATE));
    setTimeFrom(data.from);
    setTimeTo(data.to);
  }

  function _openConfirmDialogBatches(e, data) {
    setBatchesIndex(data.id);
    setConfirmDialog(true);
  }

  async function deleteBatches() {
    const body = {
      deleted: true,
      status: false,
    };
    const response = await _deleteBatches(body, batchesIndex).then((res) => {
      fetchBatches();
    });
    setConfirmDialog(false);
  }

  function updateTime(e, type) {
    const time = e.target.value ? e.target.value.split(":") : [];

    if (type === "from") {
      if (timeTo) {
        // CHECK HERE IF TO VALUE IS AVAILABLE
        const _to = timeTo.split(":");
        if (parseInt(time[0]) > parseInt(_to[0])) {
          setSnackBarOptions({
            message: "From time should be less than to time",
            show: true,
            severity: "error",
            duration: 5000,
          });
        } else if (parseInt(time[0]) === parseInt(_to[0])) {
          if (parseInt(time[1]) > parseInt(_to[1])) {
            setSnackBarOptions({
              message: "From time should be less than to time",
              show: true,
              severity: "error",
              duration: 5000,
            });
          } else setTimeFrom(e.target.value);
        } else setTimeFrom(e.target.value);
      } else setTimeFrom(e.target.value);
    } else {
      if (timeFrom) {
        // CHECK VALUE
        const _from = timeFrom.split(":");
        if (parseInt(time[0]) < parseInt(_from[0])) {
          setSnackBarOptions({
            message: "From time should be less than to time",
            show: true,
            severity: "error",
            duration: 5000,
          });
        } else if (parseInt(time[0]) === parseInt(_from[0])) {
          if (parseInt(time[1]) < parseInt(_from[1])) {
            setSnackBarOptions({
              message: "From time should be less than to time",
              show: true,
              severity: "error",
              duration: 5000,
            });
          } else setTimeTo(e.target.value);
        } else setTimeTo(e.target.value);
      } else setTimeTo(e.target.value);
    }
  }

  const headCellsBatchMode = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "S.no",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Name",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Created On",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      sort: false,
      label: "Action",
    }

  ];

  if (batchM?.update !== true) {
    headCellsBatchMode.pop()
  }

  const headCellsBatches = [
    {
      id: "sno",
      numeric: false,
      disablePadding: false,
      sort: false,
      label: "S.no",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Batch Name",
    },
    {
      id: "course",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Course",
    },
    {
      id: "batchmode",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Batch Mode",
    },
    {
      id: "year",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Created on",
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: false,
      sort: true,
      label: "Currency",
    },
    {
      id: "time",
      numeric: true,
      disablePadding: false,
      sort: true,
      label: "Time",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      sort: false,
      label: "Action",
    },
  ];

  if (batchM?.update !== true) {
    headCellsBatches.pop()
  }

  const createTableDataBatchMode = (data) => {
    // CREATE TABLE DATA - ADDONS
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const row = data[index];
          const obj = {
            sno: index + 1,
            id: row.id,
            name: row.name,
            createdAt: row.createdAt,
            action: true,
          };
          returnArray.push(obj);
          if (index === data.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };

  const createTableDataBatches = (data) => {
    // CREATE TABLE DATA - ADDONS
    let returnArray = [];
    return new Promise((resolve, reject) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const row = data[index];
          const obj = {
            courseId: row.courseId,
            sno: index + 1,
            id: row.id,
            course: row.course?.name,
            year: moment(row.startDate).format('MMMM Do YYYY'),
            time: `${row.from}-${row.to}`,
            name: row.name,
            createdAt: row.createdAt,
            action: true,
            batchmode: row?.course?.batchmode?.name,
            currency: row.course?.country?.currencyName,
          };
          if (batchM?.write !== true) {
            delete obj.action

          }
          returnArray.push(obj);
          if (index === data.length - 1) resolve(returnArray);
        }
      } else resolve([]);
    });
  };
  const handleInputDateChange = (data) => {
    fetchBatches(data)
    fetchBatchMode(data)
  }

  return (
    <div className="h-full w-full overflow-auto py-4 px-8">
      <div className="flex items-center justify-between">
        <BreadCrumb data={batch} />
        <div className="flex items-center ">
          <DateSearchFilter handleInputDateChange={(data) => handleInputDateChange(data)} />
        </div>
      </div>

      <Box className='my-7'>
        <Panel
          tabIndex={0}
          labelOne="Batch Modes"
          labelTwo="Batches"
          secondPanel={true}
          tabOne={
            <div>
              {batchModeDataSource === null ? <div className="h-full w-full items-center justify-center flex">
                <h1 className="h-60 w-60"> <CircularProgress /></h1>
              </div> : batchModeDataSource?.length > 0 ? <div className="my-6">
                <DynamicTable
                  dataSource={batchModeDataSource}
                  headCells={headCellsBatchMode}
                  actionOnDelete={(e, data) => _openConfirmDialogBatchMode(e, data)}
                  actionOnEdit={(e, data) => editBatchModeDialog(e, data)}
                  pagination={true}
                  edit={true}
                  delete={true}
                />
                <DialogueBox
                  confirmDialog={confirmDialog}
                  message={'Batch Mode'}
                  confirmDialogClose={() => setConfirmDialog(false)}
                  confirmDelete={deleteBatchMode}
                />
              </div> :
                <NodataInTable message='No batch mode is created yet.To create batch mode click on add icon.' />}

              <Dialog fullWidth={true} maxWidth="md" open={batchModeDialog}>
                <DialogTitle>{updateBatchMode === false ? 'ADD' : 'UPDATE'} BATCH MODE</DialogTitle>
                <Divider />
                <DialogContent>
                  <div className="px-7">
                    <FormControl fullWidth>
                      <FormLabel required>Name</FormLabel>

                      <TextField
                        error={
                          addbatchMode && batchModeName.length < 1
                            ? true
                            : false
                        }
                        helperText={
                          addbatchMode && batchModeName.length < 1
                            ? "Batch Mode name cannot be empty"
                            : ""
                        }
                        value={batchModeName}
                        onChange={(e) => setBatchModeName(e.target.value)}
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    sx={{ marginY: "10px" }}
                    variant="outlined"
                    onClick={closeBatchModeDialog}
                  >
                    Cancel
                  </Button>
                  {updateBatchMode === false ? (
                    <Button
                      sx={{ marginY: "10px" }}
                      variant="contained"
                      onClick={addNewBatchMode}
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      onClick={updateBatchModes}
                      sx={{ marginY: "10px" }}
                      variant="contained"
                    >
                      Update
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
              <div>
                <Button
                  disabled={batchM?.write ? false : true}
                  onClick={openBatchModeDialog}
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    height: "60px",
                    width: "60px",
                    position: "fixed",
                    bottom: "40px",
                    right: "40px",
                  }}
                >
                  <AddIcon sx={{ fontSize: "30px" }} />
                </Button>
              </div>
            </div>
          }
          tabTwo={
            <div>
              {batchesDataSource === null ? <div className="h-full w-full items-center justify-center flex">
                <h1 className="h-60 w-60"> <CircularProgress /></h1>
              </div> : batchesDataSource?.length > 0 ? <div className="my-6">
                <DynamicTable
                  dataSource={batchesDataSource}
                  headCells={headCellsBatches}
                  actionOnDelete={(e, data) => _openConfirmDialogBatches(e, data)}
                  actionOnEdit={(e, data) => editBatchesDialog(e, data)}
                  pagination={true}
                  edit={true}
                  delete={true}
                />
                <DialogueBox
                  confirmDialog={confirmDialog}
                  message={'Batch'}
                  confirmDialogClose={() => setConfirmDialog(false)}
                  confirmDelete={deleteBatches}
                />
              </div> :
                <NodataInTable message='No batch is created yet.To create batch click on add icon.' />}
              <Dialog fullWidth={true} maxWidth="md" open={batchesDialog}>
                <DialogTitle>{updateBatches === false ? 'ADD' : 'UPDATE'} BATCH</DialogTitle>
                <Divider />
                <DialogContent>
                  <div className="px-7 my-7">
                    <FormControl fullWidth>
                      <FormLabel required>Batch Name</FormLabel>

                      <TextField
                        error={
                          addBatches && batchName.length < 1 ? true : false
                        }
                        helperText={
                          addBatches && batchName.length < 1
                            ? "Batch Mode name cannot be empty"
                            : ""
                        }
                        value={batchName}
                        onChange={(e) => setBatchName(e.target.value)}
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </FormControl>

                    <FormControl sx={{ marginTop: "30px" }} fullWidth>
                      <FormLabel required>Select Course</FormLabel>
                      <Autocomplete
                        value={selectCourseData || null}
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) =>
                          `${option?.name} - ${option?.batchmode?.name} - ${option?.country?.currencyName} `
                        }
                        options={allCourses}
                        inputValue={selectCourse}
                        onInputChange={(event, selectCourse) => {
                          setSelectCourse(selectCourse);
                        }}
                        isOptionEqualToValue={(_option, value) => {
                          return true;
                        }}
                        onChange={(event, val) => {
                          setCourseId(val.id);
                          setSelectCourseData(val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            key={params.id.toString()}
                            {...params}
                            error={
                              addBatches && selectCourse.length < 1
                                ? true
                                : false
                            }
                            required={true}
                            helperText={
                              addBatches && selectCourse.length < 1
                                ? "Course cannot be empty"
                                : ""
                            }
                            id="outlined-basic"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl sx={{ marginTop: "30px" }} fullWidth>
                      <FormLabel required>DD/MM/YY</FormLabel>
                      <TextField
                        error={addBatches && date.length < 1 ? true : false}
                        helperText={
                          addBatches && date.length < 1
                            ? "Date cannot be empty"
                            : ""
                        }
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required={true}
                        type="date"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </FormControl>
                    <div className="flex">
                      <FormControl sx={{ marginTop: "30px" }} fullWidth>
                        <FormLabel required>Time From</FormLabel>
                        <TextField
                          error={
                            addBatches && timeFrom.length < 1 ? true : false
                          }
                          helperText={
                            addBatches && timeFrom.length < 1
                              ? "Time cannot be empty"
                              : ""
                          }
                          value={timeFrom}
                          onChange={(e) => updateTime(e, "from")}
                          required={true}
                          id="outlined-basic"
                          type="time"
                          variant="outlined"
                        />
                      </FormControl>

                      <FormControl sx={{ marginTop: "30px" }} fullWidth>
                        <FormLabel required>Time To</FormLabel>
                        <TextField
                          error={addBatches && timeTo.length < 1 ? true : false}
                          helperText={
                            addBatches && timeTo.length < 1
                              ? "Time cannot be empty"
                              : ""
                          }
                          value={timeTo}
                          onChange={(e) => updateTime(e, "to")}
                          required={true}
                          type="time"
                          id="outlined-basic"
                          variant="outlined"
                        />
                      </FormControl>
                    </div>
                  </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    sx={{ marginY: "10px" }}
                    variant="outlined"
                    onClick={closeBatchesDialog}
                  >
                    Cancel
                  </Button>
                  {updateBatches === false ? (
                    <Button
                      onClick={addBatch}
                      disabled={isLoading}
                      sx={{ marginY: "10px" }}
                      variant="contained"
                    >
                      {isLoading ?
                        <CircularProgress size={20} />
                        : 'Add'
                      }
                    </Button>
                  ) : (
                    <Button
                      onClick={updateBatch}
                      disabled={isLoading}
                      sx={{ marginY: "10px" }}
                      variant="contained"
                    >
                      {isLoading ?
                        <CircularProgress size={20} />
                        : 'Update'
                      }
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
              <div>
                <Button
                  onClick={openBatchesDialog}
                  variant="contained"
                  disabled={batchM?.write ? false : true}
                  sx={{
                    borderRadius: "50px",
                    height: "60px",
                    width: "60px",
                    position: "fixed",
                    bottom: "40px",
                    right: "40px",
                  }}
                >
                  <AddIcon sx={{ fontSize: "30px" }} />
                </Button>
              </div>
            </div>
          }
        />
      </Box>
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default Batch;
