import * as React from 'react';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import StudentFilter from './StudentFilter';
import { post } from '../Services/services';
export default function FilterDrawer(props) {
  const [filterCount, setFilterCount] = React.useState(0)
  const [city, setCity] = React.useState([]);
  const [groupsList, setGroupsList] = React.useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const closeTheDrawer = () => {

  }
  const toggleDrawer = (anchor, open) => (event) => {

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const [filterData, setFilterData] = React.useState([]);


  const [filterDatas, setFilterDatas] = React.useState({
    selectedCourse: [],
    selectedAddmisonProcess: [],
    selectedPaymentModes: [],
    selectedFamilyIncome: [],
    selectedBuddy: [],
    selectedBatchMode: [],
    selectedTag: [],
    selectedBatches: [],
    selectedOccupation: [],
    selectedCountry: [],
    selectedPaymentStatus: [],
    selectedAddon: [],
    selectedCity: [],
    selectedState: [],
    selectedBoard: [],
    selectedReference: [],
    selectedCareerOptions: [],
    selectedSource: [],
    selectedNextExpectedCource: [],
    selectedSchool: [],
    selectedCreatedBy: [],
    excludedPaymentModes: [],
    excludedFamilyIncome: [],
    excludedBuddy: [],
    excludedTag: [],
    excludedBatches: [],
    excludedOccupation: [],
    excludedCountry: [],
    excludedPaymentStatus: [],
    excludedCourse: [],
    excludedAddmisonProcess: [],
    excludedAddon: [],
    excludedBatchMode: [],
    excludedState: [],
    excludedCity: [],
    excludedBoard: [],
    excludedReference: [],
    excludedCareerOptions: [],
    excludedSource: [],
    exculdedNextExpectedCource: [],
    excludedSchool: [],
    excludedCreatedBy: [],
    dateFilter: { startDate: '', endDate: '' },


  })
  const xfilterData = async () => {
    const res = await post('fieldTypes/getFilterData', {});
    console.log(res);
    setFilterData(res.data);
  }
  let data = filterDatas;

  // props.setFilterCount(selectedClass.length + selectedBatches.length + selectedBuddy.length + selectedSession.length + selectedFamilyIncome.length + selectedStage.length);
  const filterStudent = (d) => {

    props.getReport({ filter: d, searchText: props.searchText, page: props.paginationModel, datefilter: props.dates, groupBy: props?.statusFilter });
  }
  React.useEffect(() => {

    props.setPaginationModel({ page: 0, pageSize: 15 })
    props.getReport({ filter: data, searchText: props.searchText, page: props.paginationModel, datefilter: props.dates, groupBy: props?.statusFilter })
  }, [props.searchText, props.dates, props?.statusFilter]);
  React.useEffect(() => {

    props.getReport({ filter: data, searchText: props.searchText, page: props.paginationModel, datefilter: props.dates, groupBy: props?.statusFilter })
  }, [props.paginationModel]);
  React.useEffect(() => {
    xfilterData()
  }, [])

  const getCity = async (data) => {

    const res = await post(`fieldTypes/getCitybyState`, { stateIds: data });
    setCity(res.data)

}
const getGroups = async (data) => {
    const res = await post("fieldTypes/getfilterStateBoardSchool", { countryIds: data });
    setGroupsList(res.data);
    // const re = await get("stages");
    // setStageList(re.data);
}

  React.useEffect(() => {
    setFilterDatas(prevState => (
      { ...prevState, selectedState: [] }));
    setFilterDatas(prevState => (
      { ...prevState, excludedState: [] }));
    setFilterDatas(prevState => (
      { ...prevState, selectedSchool: [] }));
    setFilterDatas(prevState => (
      { ...prevState, excludedSchool: [] }));
    setFilterDatas(prevState => (
      { ...prevState, selectedBoard: [] }));
    setFilterDatas(prevState => (
      { ...prevState, excludedBoard: [] }));
    if (filterDatas.selectedCountry?.length > 0) {

      getGroups(filterDatas?.selectedCountry)
    }
  }, [filterDatas.selectedCountry])
  React.useEffect(() => {
    setFilterDatas(prevState => (
      { ...prevState, selectedCity: [] }));
    setFilterDatas(prevState => (
      { ...prevState, excludedSity: [] }));

    if (filterDatas.selectedState?.length > 0) {

      getCity(filterDatas?.selectedState)
    }
  }, [filterDatas.selectedState])

  return (
    <div  >
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>

          <Button ref={props.filterRef} onClick={toggleDrawer(anchor, true)} variant='outlined' sx={{ color: '#7B7B7B', textTransform: 'none' }} className=' border-cyan-400 ' >
            {props.filterCount === 0 ?
              <div>Filter </div>
              :
              <div >
                {props.filterCount + " "}
                Filters Applied
              </div>
            }

          </Button>
          <Drawer
            sx={{ zIndex: 1500 }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <StudentFilter
              setPaginationModel={props.setPaginationModel}
              paginationModel={props.paginationModel}
              filterDatas={filterDatas}
              setFilterDatas={setFilterDatas}
              setState={setState}
              state={state}
              setFilterCount={props.setFilterCount}
              filterCount={props.filterCount}
              getStudent={filterStudent}
              filterData={filterData}
              statusFilter={props?.statusFilter}
              city={city}
              groupsList={groupsList}

            />

          </Drawer>
        </React.Fragment>
      ))}

    </div>
  );
}