import { TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { yyyymmdd } from '../Services/services';

const tm = 7776000000;



function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


const today = formatDate(new Date())
const threeMonthsAgo = formatDate(new Date().getTime() - tm)
export default function DateRangePiker(props) {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const inputRef = React.useRef(null);
    const inputRefs = React.useRef(null);
    useEffect(() => {

        props.getData({ startDate: startDate, endDate: endDate });
    }, [startDate, endDate])
    useEffect(() => {

        setStartDate('');
        setEndDate('');

        if (inputRef.current) {
            inputRef.current.value = '';
            inputRefs.current.value = '';
        }

    }, [props.clearDate])
    useEffect(() => {
        console.log(threeMonthsAgo, "hksjhfjklklklfkl")

        inputRef.current.value = threeMonthsAgo;
        inputRefs.current.value = today;
        setStartDate(threeMonthsAgo);
        setEndDate(today);
    }, [])

    return (
        <div className='flex  justify-center items-center '>
            <TextField type='date' size='small' className=' w-40 bg-white'
                inpu={startDate}
                value={startDate}

                inputRef={inputRef}
                InputProps={{ inputProps: { max: endDate } }}
                onChange={(event) => setStartDate(event.target.value)}
            />
            <Typography>-</Typography>
            <TextField type='date' size='small' className=' w-40 bg-white' onChange={(event) => setEndDate(event.target.value)}
                value={endDate}
                inputRef={inputRefs}
                InputProps={{ inputProps: { min: startDate } }}
            />
            {/* {
                startDate || endDate ?
                    (<ClearIcon className='text-[#3366ff] cursor-pointer hover:text-black'
                        onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            if (inputRef.current) {
                                inputRef.current.value = '';
                                inputRefs.current.value = '';
                            }

                        }} />) :
                    (<div></div>)
            } */}
        </div>
    )
}