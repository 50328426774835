import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Dialogname,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import CustomizedSnackbars from "../Components/Snackbar";
import { httpGet, httpPost } from "../Services/api-services";
import { useParams } from "react-router-dom";
import CONSTANTS from "../constant.json";

const _modules = [
  {
    name: "Dashboard",
    id: false,
    no: 0,
    children: [
      {
        name: "Analytics",
        padding: true,
        id: false,
        no: 1,
      },
      {
        name: "Reports",
        padding: true,
        id: false,
        no: 2,
      },
    ],
  },
  {
    name: "Cases",
    id: false,
    no: 3,
    children: [
      {
        name: "Open cases",
        padding: true,
        id: false,
        no: 4,
      },
      {
        name: "Enrollments",
        padding: true,
        id: false,
        no: 5,
      },
    ],
  },
  {
    name: "Pendings",
    id: false,
    no: 6,
    children: [
      {
        name: "Discount Requests",
        padding: true,
        id: false,
        no: 7,
      },
      {
        name: "Refund Requests",
        padding: true,
        id: false,
        no: 8,
      },
      {
        name: "Payment due",
        padding: true,
        id: false,
        no: 9,
      },
    ],
  },
  {
    name: "Roles",
    id: false,
    no: 10,
  },
  {
    name: "Master",
    id: false,
    no: 11,
    children: [
      {
        name: "Batch",
        padding: true,
        id: false,
        no: 12,
      },
      {
        name: "Course categories",
        padding: true,
        id: false,
        no: 13,
      },
      {
        name: "Courses",
        padding: true,
        id: false,
        no: 14,
      },
    
      {
        name: "Addons",
        padding: true,
        id: false,
        no: 15,
      },
      {
        name: "Tags",
        padding: true,
        id: false,
        no: 16,
      },
      {
        name: "Cheque bouncing charges",
        padding: true,
        id: false,
        no: 17,
      },
      {
        name: "Sms template",
        padding: true,
        id: false,
        no: 18,
      },
    ],
  },
];

let _view = JSON.parse(JSON.stringify(_modules))
let _create = JSON.parse(JSON.stringify(_modules))
let _update = JSON.parse(JSON.stringify(_modules))

function AssignModulesDialog(props) {
  const [allStaff, setAllStaff] = React.useState([]);
  const [selectedViewModulesObject, setSelectedViewModulesObject] =
    React.useState([]);
  const [selectedCreateModulesObject, setSelectedCreateModulesObject] =
    React.useState([]);
  const [selectedUpdateModulesObject, setSelectedUpdateModulesObject] =
    React.useState([]);
  const [viewmodules, setViewModules] = React.useState(_view);
  const [writemodules, setWriteModules] = React.useState(_create);
  const [updateModules, setUpdateModules] = React.useState(_update);
  const [staff, setStaff] = React.useState("");
  const [adminId, setAdminId] = React.useState("");
  const [staffDetails, setStaffDetails] = React.useState([]);
  const [outputData, setOutputData] = React.useState([]);
  const [valueCheck, setValueCheck] = React.useState(false);
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });

  let params = useParams();
  const { staffId } = params;

  React.useEffect(() => {
    setSelectedViewModulesObject([])
    setSelectedCreateModulesObject([])
    setSelectedUpdateModulesObject([])
    getStaff();

    // eslint-disable-next-line
  }, [props?.assignModulesDialog]);

  async function getStaff() {
    const filter = `?filter={ "include": "assignModules"}`;
    const url = `${CONSTANTS.baseUrl}/Admins/${staffId}${filter}`;
    const response = await httpGet(url);
    setStaff(response?.id);
    setStaffDetails(response);
    const _v = await createAccessData(
      response.assignModules.assignModule,
      _view,
      "read"
    );
    const _w = await createAccessData(
      response.assignModules.assignModule,
      _create,
      "write"
    );
    const _u = await createAccessData(
      response.assignModules.assignModule,
      _update,
      "update"
    );
    setViewModules(_v);
    setWriteModules(_w);
    setUpdateModules(_u);
    let viewChildren = _v.map((d) => (d.children ? d.children : [d]));
    const _viewChild = await flatten(viewChildren);
    let viewCheckedData = _viewChild.filter((f) => f.id);
    let viewObj = [];
    for (let index = 0; index < viewCheckedData.length; index++) {
      const element = viewCheckedData[index];
      const obj = { ...element, read: true };
      viewObj.push(obj);
    }
    setSelectedViewModulesObject([])
    let viewModulesObject = selectedViewModulesObject;
    viewModulesObject.push(...viewObj);
    setSelectedViewModulesObject(viewModulesObject);
    let writeChildren = _w.map((d) => (d.children ? d.children : [d]));
    const _writeChild = await flatten(writeChildren);
    let writeCheckedData = _writeChild.filter((f) => f.id);
    let writeObj = [];
    for (let index = 0; index < writeCheckedData.length; index++) {
      const element = writeCheckedData[index];
      const obj = { ...element, write: true };
      writeObj.push(obj);
    }
    let writeModulesObject = selectedCreateModulesObject;
    writeModulesObject.push(...writeObj);
    setSelectedCreateModulesObject(writeModulesObject);

    let updateChildren = _u.map((d) => (d.children ? d.children : [d]));
    const _updateChild = await flatten(updateChildren);
    let updateCheckedData = _updateChild.filter((f) => f.id);
    let updateObj = [];
    for (let index = 0; index < updateCheckedData.length; index++) {
      const element = updateCheckedData[index];
      const obj = { ...element, update: true };
      updateObj.push(obj);
    }
    let updteModulesObject = selectedUpdateModulesObject;
    updteModulesObject.push(...updateObj);
    setSelectedUpdateModulesObject(updteModulesObject);
  }

  const flatten = (arrayOfArr) => {
    let holder = [];
    return new Promise((resolve) => {
      for (let index = 0; index < arrayOfArr.length; index++) {
        const array = arrayOfArr[index];
        if (array) {
          array.forEach((el) => {
            holder.push(el);
            if (index === arrayOfArr.length - 1) resolve(holder);
          });
        }
        // else resolve
      }
    });
  };

  const createAccessData = async (storedAccess, accessArr, accessType) => {
    return new Promise(async (resolve) => {
      for (let index = 0; index < accessArr.length; index++) {
        const _module = accessArr[index];
        if (_module.children) {
          let trueCount = 0;
          for (let _index = 0; _index < _module.children.length; _index++) {
            let child = _module.children[_index];
            child["id"] = await getAccess(child.name, accessType, storedAccess);
            if (child["id"]) trueCount++;
            if (trueCount == _module.children.length) _module["id"] = true;
          }
        } 
        else {
          // FOR ROLES
          _module["id"] = await getAccess(
            _module.name,
            accessType,
            storedAccess
          );
        }
        if (index === accessArr.length - 1) resolve(accessArr);
      }
    });
  };

  const getAccess = (name, access, stored) => {
    return new Promise((resolve) => {
      const index = stored.findIndex((sd) => {
        return sd.name === name;
      });
      if (index > -1) resolve(stored[index][access]);
      else resolve(false);
    });
  };

  function cleardata() {
    _view = JSON.parse(JSON.stringify(_modules))
    _create = JSON.parse(JSON.stringify(_modules))
    _update = JSON.parse(JSON.stringify(_modules))
    setViewModules(_view);
    setWriteModules(_create);
    setUpdateModules(_update);
    setValueCheck(false);
    setSelectedViewModulesObject([]);
    setSelectedCreateModulesObject([]);
    setSelectedUpdateModulesObject([]);
  }

  function resetPage() {
    cleardata();
  }

  function ParentViewCheck(e, _modules, index) {
    const __mod = _view;
    const selected = selectedViewModulesObject;
    __mod[index]["id"] = e.target.checked;
    if (e.target.checked && _modules.name === "Roles") {
      const object = {
        name: _modules.name,
        read: true,
        id: _modules.id,
        no: _modules.no,
      };
      selected.push(object);
      setSelectedViewModulesObject([...selected]);
      setViewModules([...__mod]);
    } else {
      if (e.target.checked) {
        __mod[index]?.children?.map((mod) => {
          return (mod.id = true);
        });
        const obj = _modules?.children?.map((mod) => {
          const object = { name: mod.name, id: mod.id, no: mod.no, read: true };
         let index = selected?.findIndex((s)=>s.name===object.name)
         if(index>-1){
           selected?.splice(index,1)
         }
         selected.push(object);
        return selected
        });
        setSelectedViewModulesObject([...selected]);

        setViewModules([...__mod]);
      } else {
        if (_modules.name === "Roles") {

          const currIndex = selected.findIndex(
            (_sel) => _sel.name === _modules.name
          );
          selected.splice(currIndex, 1);
          setSelectedViewModulesObject(selected);
          setViewModules([...__mod]);
        } else {
          __mod[index]?.children?.map((mod) => {
            return (mod.id = false);
          });
          const obj = _modules?.children?.map((mod) => {
            const currIndex = selected.findIndex((_sel) => _sel.no === mod.no);
            selected.splice(currIndex, 1);
            return;
          });
        }
      }
      setSelectedViewModulesObject([...selected]);
      setViewModules([...__mod]);
    }
  }

  function _modulesViewCheck(e, parentIndex, childIndex, _modules) {
    const __mod = _view;
    const selected = selectedViewModulesObject;
    __mod[parentIndex]["children"][childIndex]["id"] = e.target.checked;
    if (e.target.checked) {
      const object = {
        name: _modules.name,
        read: true,
        id: _modules.id,
        no: _modules.no,
      };
      selected.push(object);
      setSelectedViewModulesObject([...selected]);
      // CHECK FOR ALL CHILDREN - SELECTED
      const _filter = __mod[parentIndex]["children"].filter((_child) => {
        return _child.id === true;
      });
      if (_filter && _filter.length == __mod[parentIndex]["children"].length) {
        __mod[parentIndex]["id"] = true;
        setViewModules([...__mod]);
      } else {
        setViewModules([...__mod]);
      }
    } else {
      const currIndex = selected.findIndex(
        (_sel) => _sel.name === _modules.name
      );
      selected.splice(currIndex, 1);
      setSelectedViewModulesObject(selected);
      __mod[parentIndex]["id"] = false;
      setViewModules([...__mod]);
    }
  }

  function ParentCreateCheck(e, _modules, index) {
    const __mod = _create;
    const selected = selectedCreateModulesObject;
    __mod[index]["id"] = e.target.checked;
    if (e.target.checked && _modules.name === "Roles") {
      const object = {
        name: _modules.name,
        write: true,
        id: _modules.id,
        no: _modules.no,
      };
      selected.push(object);
      setSelectedCreateModulesObject([...selected]);
      setWriteModules([...__mod]);
    } else {
      if (e.target.checked) {
        __mod[index]?.children?.map((mod) => {
          return (mod.id = true);
        });
        const obj = _modules?.children?.map((mod) => {
          const object = { name: mod.name, id: mod.id, no: mod.no, write: true };
          let index = selected?.findIndex((s)=>s.name===object.name)
          if(index>-1){
            selected?.splice(index,1)
          }
          selected.push(object);
          return selected
        });
        setSelectedCreateModulesObject([...selected]);

        setWriteModules([...__mod]);
      } else {
        if (_modules.name === "Roles") {

          const currIndex = selected.findIndex(
            (_sel) => _sel.name === _modules.name
          );
          selected.splice(currIndex, 1);
          setSelectedCreateModulesObject(selected);
          setWriteModules([...__mod]);
        } else {
          __mod[index]?.children?.map((mod) => {
            return (mod.id = false);
          });
          const obj = _modules?.children?.map((mod) => {
            const currIndex = selected.findIndex((_sel) => _sel.no === mod.no);
            selected.splice(currIndex, 1);
            return;
          });
        }
      }
      setSelectedCreateModulesObject([...selected]);
      setWriteModules([...__mod]);
    }
  }

  function ParentUpdateCheck(e, _modules, index) {
    const __mod = _update;
    const selected = selectedUpdateModulesObject;
    __mod[index]["id"] = e.target.checked;
    if (e.target.checked && _modules.name === "Roles") {
      const object = {
        name: _modules.name,
        update: true,
        id: _modules.id,
        no: _modules.no,
      };
      selected.push(object);
      setSelectedUpdateModulesObject([...selected]);
      setUpdateModules([...__mod]);
    } else {
      if (e.target.checked) {
        __mod[index]?.children?.map((mod) => {
          return (mod.id = true);
        });
        const obj = _modules?.children?.map((mod) => {
          const object = { name: mod.name, id: mod.id, no: mod.no, update: true };
          let index = selected?.findIndex((s)=>s.name===object.name)
          if(index>-1){
            selected?.splice(index,1)
          }
          selected.push(object);
          return selected
        });
        setSelectedUpdateModulesObject([...selected]);

        setUpdateModules([...__mod]);
      } else {
        if (_modules.name === "Roles") {

          const currIndex = selected.findIndex(
            (_sel) => _sel.name === _modules.name
          );
          selected.splice(currIndex, 1);
          setSelectedUpdateModulesObject(selected);
          setUpdateModules([...__mod]);
        } else {
          __mod[index]?.children?.map((mod) => {
            return (mod.id = false);
          });
          const obj = _modules?.children?.map((mod) => {
            const currIndex = selected.findIndex((_sel) => _sel.no === mod.no);
            selected.splice(currIndex, 1);
            return;
          });
        }
      }
      setSelectedUpdateModulesObject([...selected]);
      setUpdateModules([...__mod]);
    }
  }

  function _modulesCreateCheck(e, parentIndex, childIndex, _modules) {
    const __mod = _create;
    const selected = selectedCreateModulesObject;
    __mod[parentIndex]["children"][childIndex]["id"] = e.target.checked;
    if (e.target.checked) {
      const object = {
        name: _modules.name,
        write: true,
        id: _modules.id,
        no: _modules.no,
      };
      selected.push(object);
      setSelectedCreateModulesObject([...selected]);
      // CHECK FOR ALL CHILDREN - SELECTED
      const _filter = __mod[parentIndex]["children"].filter((_child) => {
        return _child.id === true;
      });
      if (_filter && _filter.length == __mod[parentIndex]["children"].length) {
        __mod[parentIndex]["id"] = true;
        setWriteModules([...__mod]);
      } else {
        setWriteModules([...__mod]);
      }
    } else {
      const currIndex = selected.findIndex(
        (_sel) => _sel.name === _modules.name
      );
      selected.splice(currIndex, 1);
      setSelectedCreateModulesObject(selected);
      __mod[parentIndex]["id"] = false;
      setWriteModules([...__mod]);
    }
  }

  function _modulesUpdateCheck(e, parentIndex, childIndex, _modules) {
    const __mod = _update;
    const selected = selectedUpdateModulesObject;
    __mod[parentIndex]["children"][childIndex]["id"] = e.target.checked;
    if (e.target.checked) {
      const object = {
        name: _modules.name,
        update: true,
        id: _modules.id,
        no: _modules.no,
      };
      selected.push(object);
      setSelectedUpdateModulesObject([...selected]);
      // CHECK FOR ALL CHILDREN - SELECTED
      const _filter = __mod[parentIndex]["children"].filter((_child) => {
        return _child.id === true;
      });
      if (_filter && _filter.length == __mod[parentIndex]["children"].length) {
        __mod[parentIndex]["id"] = true;
        setUpdateModules([...__mod]);
      } else {
        setUpdateModules([...__mod]);
      }
    } else {
      const currIndex = selected.findIndex(
        (_sel) => _sel.name === _modules.name
      );
      selected.splice(currIndex, 1);
      setSelectedUpdateModulesObject(selected);
      __mod[parentIndex]["id"] = false;
      setUpdateModules([...__mod]);
    }
  }

  const _createBody = (read, write, update) => {
    const merged = [...read, ...write, ...update];
    const modStr = [];
    const body = [];
    for (let index = 0; index < merged.length; index++) {
      let obj = {};
      const element = merged[index];
      if (!modStr.includes(element.name)) {
        modStr.push(element.name);
        obj.name = element.name;
        obj.read = element.read || false;
        obj.write = element.write || false;
        obj.update = element.update || false;
        body.push(obj);
      } else {
        const _index = body.findIndex((_b) => _b.name === element.name);
        body[_index]["write"] = element.write || body[_index]["write"];
        body[_index]["update"] = element.update || body[_index]["update"];
      }
      if (index === merged.length - 1) return body;
    }
  };

  async function assignModules() {
    const data = _createBody(selectedViewModulesObject, selectedCreateModulesObject, selectedUpdateModulesObject)
    if (staff && data) {
      props.onUpdateClick(staffId,data)
      setSnackBarOptions({
        message: "Modules updated succesfully",
        show: true,
        severity: "success",
        duration: 5000,
      });
      cleardata();
    }
    else {
      setValueCheck(true)
    }

  }

  function closeDialog(){
    cleardata()
    props.oncloseAction()
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="lg" open={props.assignModulesDialog}>
        <DialogTitle sx={{ color: "gray" }}>
          <div>
            <h1>ASSIGN MODULE</h1>
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className="p-6">
            <h1>ASSIGN MODULE</h1>
            <FormControl sx={{ width: "30%", marginTop: "20px" }}>
              <FormLabel sx={{ paddingBottom: "10px" }} required>
                Select Staff
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={staff}
                onChange={(e) => setStaff(e.target.value)}
                error={valueCheck && staff.length < 1 ? true : false}
              >
                <MenuItem value={staffDetails?.id}>
                  {staffDetails?.name}
                </MenuItem>
              </Select>
              {valueCheck && staff.length < 1 ? (
                <FormHelperText sx={{ color: "red" }}>
                  Staff name cannot be empty
                </FormHelperText>
              ) : null}
            </FormControl>
            <div className="flex mt-10">
              <h1 className="text-gray-500">Accessibility</h1>
              <div className="flex justify-between pl-14 w-3/4">
                <div>
                  <h1 className="text-gray-500">Can View Modules</h1>
                  {viewmodules.map((_module, index) => {
                    return (
                      <div key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={_module.name}
                              checked={_module.id}
                              onChange={(e) =>
                                ParentViewCheck(e, _module, index)
                              }
                            />
                          }
                          label={_module.name}
                        />

                        {_module?.children?.map((module, _index) => {
                          return (
                            <div key={_index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={module.id}
                                    sx={{ marginLeft: 3 }}
                                    value={module.name}
                                    onChange={(e) =>
                                      _modulesViewCheck(
                                        e,
                                        index,
                                        _index,
                                        module
                                      )
                                    }
                                  />
                                }
                                label={module.name}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <h1 className="text-gray-500">Can Create Modules</h1>
                  {writemodules.map((_module, index) => {
                    return (
                      <div key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={_module.name}
                              checked={_module.id}
                              onChange={(e) =>
                                ParentCreateCheck(e, _module, index)
                              }
                            />
                          }
                          label={_module.name}
                        />

                        {_module?.children?.map((module, _index) => {
                          return (
                            <div key={_index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={module.id}
                                    sx={{ marginLeft: "30px" }}
                                    value={module.name}
                                    onChange={(e) =>
                                      _modulesCreateCheck(
                                        e,
                                        index,
                                        _index,
                                        module
                                      )
                                    }
                                  />
                                }
                                label={module.name}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <h1 className="text-gray-500">Can Update Modules</h1>
                  {updateModules.map((_module, index) => {
                    return (
                      <div key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={_module.name}
                              checked={_module.id}
                              onChange={(e) =>
                                ParentUpdateCheck(e, _module, index)
                              }
                            />
                          }
                          label={_module.name}
                        />

                        {_module?.children?.map((module, _index) => {
                          return (
                            <div key={_index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={module.id}
                                    sx={{ marginLeft: "30px" }}
                                    value={module.name}
                                    onChange={(e) =>
                                      _modulesUpdateCheck(
                                        e,
                                        index,
                                        _index,
                                        module
                                      )
                                    }
                                  />
                                }
                                label={module.name}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={resetPage} sx={{ marginY: "10px", minWidth: "0px" }} variant="outlined">
            Reset
          </Button>
          <Button onClick={assignModules} sx={{ marginY: "10px" }} variant="contained">
            Update &amp; assign
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default AssignModulesDialog;
