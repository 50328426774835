import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Checkbox, Fab, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilState } from 'recoil';
import { set } from 'lodash';
import { post } from '../Services/services';
import { wholeNumberRegex } from '../Regex/Regex';
import { UserAuthState } from '../Services/RecoilService';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DiscountDialog(props) {
  //   const [open, setOpen] = React.useState(false);

  const [discountReason, setDiscountReason] = React.useState([]);
  const [occupation, setOccupation] = React.useState([])
  const [familyIncome, setFamilyIncome] = React.useState([])
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  const [formData, setFormData] = React.useState(
    {
      requestedAmount: "",
      reasonId: "",
      parentOccupation: "",
      annualFamilyIncome: "",
      numberOfEarningMember: "",
      terms: false
    }
  )
  // const [batchData, setBatchData] = React.useState([]);
  // const [addonData, setAddOnData] = React.useState([]);
  // const [countryData, setCountryData] = React.useState();
  // const [bundelDis, setBundleDis] = React.useState(0);
  // const [authState, setAuthState] = useRecoilState(UserAuthState);


  // const nameRef = React.useRef();


  // const handleClickOpen = () => {
  //     // setOpen(true);
  // };

  // const handleAddCase = async () => {

  //     let body = props.courseFormData;
  //     body.caseId = props.caseId;
  //     console.log(body)
  //     const ress = await post('cases/addCase', body)
  //     if (ress.statusCode === 200) {

  //         // props.getCases();
  //         // handleClose();
  //     }

  // }
  // const handleEditCase = async () => {

  //     let body = {
  //         name: props.casesData?.name,
  //         email: props.casesData?.email,
  //         mobile: props.casesData?.mobile,
  //         username: props.casesData?.mobile,
  //         countryId: props.casesData?.countryId,
  //         gender: props?.casesData?.gender
  //     }
  //     const ress = await patch(`cases/${props?.edit}`, body)
  //     if (ress.statusCode === 200) {

  //         props.getCases();
  //         handleClose();
  //     }

  // }
  // const getCountryData = async () => {
  //     let res = await get(`countries/${props.casesData?.countryId}`);
  //     if (res?.statusCode === 200) {
  //         // setCountryData(res?.data)
  //     }
  // }
  // const getCountry = async () => {
  //     const res = await get("countries");

  //     if (res.statusCode === 200) {
  //         // setCountries(res.data);
  //     }
  // }
  // const getbatchMode = async () => {
  //     const res = await get("batchModes");

  //     if (res.statusCode === 200) {
  //         setBatchModeData(res.data);
  //     }
  // }
  // const getCourses = async (id) => {
  //     const res = await get(`batchModes/${id}/courses`);

  //     if (res.statusCode === 200) {
  //         setcourseData(res.data);
  //     }
  // }
  // const getbatch = async (id) => {
  //     const res = await get(`courses/${id}/batches`);

  //     if (res.statusCode === 200) {
  //         setBatchData(res.data);
  //     }
  // }
  // const getSubject = async (id) => {
  //     const res = await get(`courses/${id}/subjects`);

  //     if (res.statusCode === 200) {
  //         setSubjectData(res.data);
  //     }
  // }
  // const getAddons = async (id) => {


  //     const res = await get(`courses/${id}`);

  //     const ress = await get(`addons?filter={"where":{"currency":"${res.data.currency}"}}`);
  //     if (ress.statusCode === 200) {

  //         setAddOnData(ress.data);
  //     }
  // }
  // const handleChangeSubject = (value) => {


  //     const __index = props.courseFormData?.subjectNames?.indexOf(value.name);
  //     const __indexG = props.courseFormData?.subjectIds?.indexOf(value.id);

  //     if (__indexG >= 0) {
  //         let array = [...props.courseFormData?.subjectNames];
  //         array.splice(__index, 1)
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, subjectNames: array }))

  //         let arrays = [...props.courseFormData?.subjectIds];
  //         arrays.splice(__indexG, 1)
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, subjectIds: arrays }))
  //     } else {
  //         const array = [...props.courseFormData?.subjectNames, value.name];
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, subjectNames: array }))

  //         const arrays = [...props.courseFormData?.subjectIds, value.id];
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, subjectIds: arrays }))
  //     }

  // }

  // const getBundleDiscount = async (id) => {
  //     courseData?.map((item) => {
  //         if (item.id === id) {
  //             setBundleDis(item?.bundleDiscount)
  //         }
  //     })
  // }
  // const courseAmount = async () => {
  //     let courseAmount = 0;

  //     props.courseFormData?.subjectIds?.map((id) => {
  //         const __indexG = subjectData.map(e => e.id).indexOf(id);
  //         // const __indexG = subjectData?.indexOf(id);
  //         if (__indexG >= 0) {
  //             courseAmount = courseAmount + subjectData[__indexG].price
  //         }
  //     })
  //     props.setCourseFormData(prevState => (
  //         { ...prevState, courseAmount: courseAmount }))

  //     if (props.courseFormData?.subjectIds?.length === subjectData?.length) {
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, bundleDiscount: bundelDis }))
  //     } else {
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, bundleDiscount: 0 }))
  //     }

  // }
  // const AddonAmount = async () => {
  //     let courseAmount = 0;

  //     props.courseFormData?.addOnsId?.map((id) => {
  //         const __indexG = addonData.map(e => e.id).indexOf(id);

  //         if (__indexG >= 0) {
  //             courseAmount = courseAmount + addonData[__indexG].price
  //         }
  //     })
  //     props.setCourseFormData(prevState => (
  //         { ...prevState, addonAmount: courseAmount }))



  // }
  // const handleChangeAddOns = (value) => {


  //     const __index = props.courseFormData?.addOnsNames?.indexOf(value.name);
  //     const __indexG = props.courseFormData?.addOnsId?.indexOf(value.id);

  //     if (__indexG >= 0) {
  //         let array = [...props.courseFormData?.addOnsNames];
  //         array.splice(__index, 1)
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, addOnsNames: array }))

  //         let arrays = [...props.courseFormData?.addOnsId];
  //         arrays.splice(__indexG, 1)
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, addOnsId: arrays }))
  //     } else {
  //         const array = [...props.courseFormData?.addOnsNames, value.name];
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, addOnsNames: array }))

  //         const arrays = [...props.courseFormData?.addOnsId, value.id];
  //         props.setCourseFormData(prevState => (
  //             { ...prevState, addOnsId: arrays }))
  //     }
  // }
  // React.useEffect(() => {

  //     courseAmount()
  // }, [props?.courseFormData?.subjectIds])

  // React.useEffect(() => {

  //     AddonAmount()
  // }, [props?.courseFormData?.addOnsId])

  // React.useEffect(() => {

  //     getbatchMode();
  // }, [])

  // const handleClose = () => {
  //     props.setOpen(false);
  //     // props.setCasesData(
  //     //     {
  //     //         name: "",
  //     //         email: "",
  //     //         countryId: "",
  //     //         mobile: "",
  //     //         gender: "",
  //     //     }
  //     // )
  //     // props.setEdit();
  // };
  const getDesignation = async () => {
    const res = await post("fieldTypes/getAllFieldTypeWithField", {status:true});


    if (res.statusCode === 200) {
      res.data?.map((item) => {
        if (item.title === "discount request reasons" ) {
          setDiscountReason(item);
        }
        else if (item.title === "parent occupation" ) {
          setOccupation(item)
        }
        else if (item.title === "annual family income" ) {
          setFamilyIncome(item)
        }

      }
      )

    }
  }
  React.useEffect(() => {
    getDesignation()
  }, [props.open])

  const handleAddDiscountRequest = async () => {
    let body = formData;
    body.adminId = authState?.userId;
    body.status = "pending";
    body.subscribedCoursesId = props?.courseData?._id;
    body.batchId = props?.courseData?.batchId;
    body.preApproved = false;
    body.deleted = false;
    body.courseId = props?.courseData?.courseId;
    body.caseId = props?.courseData?.caseId
    console.log(body);
    const res = await post("discountRequests", body);
    if (res?.statusCode === 200) {
      props.setDiscountDialog(false);
      props.refreshCase();
    }

    // body.
  }
  return (
    <React.Fragment>

      <BootstrapDialog
        fullWidth
        onClose={() => { props.setDiscountDialog(false) }}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Discount request
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { props.setDiscountDialog(false) }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box className='flex flex-col gap-10 p-5'>

            <Box className='flex gap-4'>

              <TextField
                required
                label='DISCOUNT AMOUNT'
                fullWidth
                size='small'

                helperText={(!wholeNumberRegex?.test(formData?.requestedAmount) || formData?.requestedAmount >= props.courseData?.unpaidAmount) && formData?.requestedAmount?.length !== 0 ? `Discount amount only contains number and not greater then or equal unpaid fee ${props.courseData?.unpaidAmount}` : ""}

                error={(!wholeNumberRegex?.test(formData?.requestedAmount) || formData?.requestedAmount >= props.courseData?.unpaidAmount) && formData?.requestedAmount?.length !== 0}
                // value={props?.courseFormData?.courseAmount}
                onChange={(e) => {
                  setFormData(prevState => (
                    { ...prevState, requestedAmount: e.target.value }));
                }}
              />
            </Box>
            <Box className='flex gap-4'>
              <TextField
                required
                label='NO OF EARNING MEMBERS IN FAMILY'
                fullWidth
                size='small'
                error={!wholeNumberRegex?.test(formData?.numberOfEarningMember) && formData?.numberOfEarningMember?.length !== 0}
                helperText={!wholeNumberRegex?.test(formData?.numberOfEarningMember) && formData?.numberOfEarningMember?.length !== 0 ? "no of earning members in family only contain numbers" : ""}
                // value={props?.courseFormData?.bundleDiscount}
                onChange={(e) => {
                  setFormData(prevState => (
                    { ...prevState, numberOfEarningMember: e.target.value }));
                }}
              />
            </Box>
            <Box className=' flex gap-4'>
              <FormControl value={props.casesData?.countryId} required fullWidth sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">REASON</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"

                  value={props.formData?.reasonId}
                  label="REASON"

                  onChange={(e) => {
                    setFormData(prevState => (
                      { ...prevState, reasonId: e.target.value }));
                  }}


                >
                  {discountReason?.field?.map((item) =>
                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                  )}

                </Select>
              </FormControl>




            </Box>

            <Box>
              <FormControl required fullWidth sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">PARENT'S PROFESSION</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  onChange={(e) => {
                    setFormData(prevState => (
                      { ...prevState, parentOccupation: e.target.value }));
                  }}
                  value={props?.formData?.parentOccupation}
                  label="PARENT'S PROFESSION"
                >
                  {occupation?.field?.map((item) =>
                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">ANNUAL FAMILY INCOME</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  onChange={(e) => {
                    setFormData(prevState => (
                      { ...prevState, annualFamilyIncome: e.target.value }));
                  }}
                  value={props?.formData?.annualFamilyIncome}
                  label="ANNUAL FAMILY INCOME"
                >
                  {familyIncome?.field?.map((item) =>
                    <MenuItem value={item?._id}>{item.title}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Box>

            <Box className='flex'>
              <div>
                <Checkbox checked={formData.terms} onClick={() => {
                  setFormData(prevState => (
                    { ...prevState, terms: !formData?.terms }));
                }} className=' items-start' />
              </div>
              <p>I have informed student that he's responsible for the authenticity of the reason and related information produced here for additional discount. All the above information shall be found valid upon verification.</p>
            </Box>



          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "#3366FF" }}
            variant='contained'
            disabled={
              !wholeNumberRegex?.test(formData?.requestedAmount)
              || !wholeNumberRegex?.test(formData?.numberOfEarningMember)
              || !formData?.reasonId?.length > 0
              || !formData?.parentOccupation?.length > 0
              || !formData?.annualFamilyIncome?.length > 0
              || formData?.requestedAmount >= props.courseData?.unpaidAmount
              || !formData?.terms
            }
            autoFocus
            onClick={handleAddDiscountRequest}
          >
            add
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}