import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Fab, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StringWithSpace, emailRegex, phoneNumberRegexAed, phoneNumberRegexIndia } from '../../Regex/Regex';
import { baseUrl, get, patch, post } from '../../Services/services';
import EditIcon from '@mui/icons-material/Edit';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddTags(props) {
    //   const [open, setOpen] = React.useState(false);

    const [countries, setCountries] = React.useState([]);
    const [countryData, setCountryData] = React.useState();
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [buddyData, setBuddyData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)

    const nameRef = React.useRef();


    const handleClickOpen = () => {
        // setOpen(true);
    };

    const handleAddCase = async () => {
        setIsLoading(true)
        let body = {
            name: props.casesData?.name,

        }

        const ress = await post('tags', body)
        setIsLoading(false)
        if (ress.statusCode === 200) {

            props.getCases();
            handleClose();
            props.setSnackBarOptions({
                message: "tags Added successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
        } else if (ress.statusCode === 422) {
            props.setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            props.setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }
    const handleEditCase = async () => {
        setIsLoading(true)
        let body = {
            name: props.casesData?.name,

        }
        const ress = await patch(`tags/${props?.edit}`, body)
        setIsLoading(false)
        console.log(ress.statusCode)
        if (ress.statusCode === 200) {
            props.setSnackBarOptions({
                message: "Case updated successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
            props.getCases();
            handleClose();
        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            props.setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            props.setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }
    const getCountryData = async () => {
        let res = await get(`countries/${props.casesData?.countryId}`);
        if (res?.statusCode === 200) {
            setCountryData(res?.data)
        }
    }
    const getCountry = async () => {
        const res = await get("countries");

        if (res.statusCode === 200) {
            setCountries(res.data);
        }
    }



    const getBuddyName = async () => {
        const res = await post("fieldTypes/getAllFieldTypeWithField", {});


        if (res.statusCode === 200) {
            res.data?.map((item) => {
                if (item.title === "buddy") {
                    setBuddyData(item?.field)
                    console.log(item)
                }
            }
            )

        }
    }

    React.useEffect(() => {
        getBuddyName();
        getCountry();
    }, [])

    const handleClose = () => {
        props.setOpen(false);
        props.setCasesData(
            {
                name: "",
                email: "",
                countryId: "",
                mobile: "",
                gender: "",
                buddyId: "",
            }
        )
        props.setEdit();
    };
    React.useEffect(() => {
        getCountryData()
    }, [props?.casesData?.countryId])

    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props.edit ? "Edit Tag" : "Add Tag"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-5'>
                        <Box className=' flex gap-4'>
                            <TextField
                                size='small'
                                fullWidth
                                value={props.casesData?.name}
                                label="NAME"
                                required
                                error={props.casesData?.name?.length < 3 && props.casesData?.name?.length !== 0}
                                helperText={props.casesData?.name?.length < 3 && props.casesData?.name?.length !== 0 ? "Name  contains  at least 3 characters" : ""}
                                onChange={(e) => {
                                    props.setCasesData(prevState => (
                                        { ...prevState, name: e.target.value }));

                                }}
                            />
                        </Box>
                    </Box>
                    {console.log("ashish", props.casesData?.buddyId)}
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#3366FF" }}
                        variant='contained'
                        disabled={
                            isLoading
                        }
                        autoFocus
                        onClick={props.edit ? handleEditCase : handleAddCase}
                    >
                        {isLoading ? <CircularProgress size={16} /> :
                            props.edit ? "Update" : "Add"
                        }
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}