import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import BreadCrumb from "../../Components/BreadCrumbs";
import {
  fetchCurrency,
  _addAddons,
  getCourses,
  getAddons,
  _deleteAddons,
  _updateAddons,
  getBatchModes,
  getCourseCategory,
  _addCourseCategory,
  _updateCourseCategory,
  _deleteCC,
} from "../../Services/api-services";
import CustomizedSnackbars from "../../Components/Snackbar";
import Cards from "../../Components/Cards";
import { useNavigate } from "react-router-dom";
import CourseDialog from "../../Popups/CourseDialog";
import DateSearchFilter from "../../Components/DateSearchFilter";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import DialogueBox from "../../Components/DialogueBox";
import NodataInTable from "../../Components/NoDataInTable";
import { UserAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import { updateLocalStorage } from "../../Services/services";

function CourseCategories() {
  const [courseDialogue, setCourseDialogue] = React.useState(false);
  const [courseCategoriesM, setCourseCategoriesM] = React.useState([]);
  const [allCourseCategory, setAllCourseCategory] = React.useState(null);
  const [addSubject, setAddSubject] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [allCurrencies, setAllCurrencies] = React.useState();
  const [allBatchModes, setAllBatchModes] = React.useState();
  const [addOnDataSource, setAddOnDataSource] = React.useState([]);
  const [courseCategoryDialog, setCourseCategoryDialog] = React.useState(false);
  const [updateCC, setUpdateCC] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [addCC, setAddCC] = React.useState(false);
  const [courseCategoryName, setCourseCategoryName] = React.useState("");
  const [CCIndex, setCCindex] = React.useState("");
  const [dataSource, setDataSource] = React.useState([]);
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    message: "",
    duration: 5000,
    show: false,
    severity: "",
  });
  const [authState, setAuthState] = useRecoilState(UserAuthState);

  let navigate = useNavigate();

  React.useEffect(() => {
    const modules = authState?.userData?.assignModules?.assignModule;
    const courseCategoriesArr = modules.find(
      (m) => m?.name === "Course categories"
    );
    setCourseCategoriesM(courseCategoriesArr);
    fetchData();
    // eslint-disable-next-line
  }, []);

  const _closeCourseDialogue = () => {
    setCourseDialogue(false);
  };

  async function fetchData() {
    try {
      const currencies = await fetchCurrency();
      const batchModes = await getBatchModes();
      const courses = await getCourses();
      if(courses?.error?.statusCode===401){
        updateLocalStorage()
      }
      const courseCategory = await getCourseCategory();
      if(courseCategory?.error?.statusCode===401){
        updateLocalStorage()
      }
      setAllCourseCategory(courseCategory);
      setAllCurrencies(currencies);
      setAllBatchModes(batchModes);
    } catch (err) {
      console.log(err);
    }
  }

  function closeCourseCategoryDialog() {
    // CLOSE COURSE CATEGORY DIALOG AND RESET THE DIALOG FIELDS
    setCourseCategoryDialog(false);
    setCourseCategoryName("");
    setAddCC(false);
    setUpdateCC(false);
  }

  async function addCourseCategory() {
    // FUNCTION TO ADD NEW COURSE CATEGORY NAME AND SHOWING SUCCESS SNACKBAR DIALOG
    if (courseCategoryName) {
      const body = {
        name: courseCategoryName,
      };
      try {
        const response = await _addCourseCategory(body);
        fetchData();
        setSnackBarOptions({
          message: "Course category added successfully!",
          show: true,
          severity: "success",
          duration: 5000,
        });
        closeCourseCategoryDialog();
      } catch (error) {
        console.log(error);
      }
    } else {
      setAddCC(true);
    }
  }

  async function updateCourseCategory() {
    // API CALL FOR UPDATING THE COURSE CATEGORY NAME
    const body = {
      name: courseCategoryName,
      id: CCIndex,
      status: true,
    };
    const response = await _updateCourseCategory(body, CCIndex);
    fetchData();
    closeCourseCategoryDialog();
  }

  function openEditDialogCC(data) {
    // FUNCTION TO OPEN THE COURSE CATEGORY DIALOG FOR EDITING
    setCCindex(data.id);
    setCourseCategoryDialog(true);
    setCourseCategoryName(data.name);
    setUpdateCC(true);
  }

  async function updateCourseCategory() {
    // API CALL FOR UPDATING THE COURSE CATEGORY NAME
    const body = {
      name: courseCategoryName,
      id: CCIndex,
      status: true,
    };
    const response = await _updateCourseCategory(body, CCIndex);
    if (response.id) {
      fetchData();
      closeCourseCategoryDialog();
      setSnackBarOptions({
        message: "Course category updated successfully!",
        show: true,
        severity: "success",
        duration: 5000,
      });
    } else {
      setSnackBarOptions({
        message: "Internal server error!",
        show: true,
        severity: "error",
        duration: 5000,
      });
    }
  }

  function confirmDialogCC(data) {
    // FUNCTION TO OPEN CONFIRMATION DIALOG AND SETTING THEIR INDEX INTO A STATE FOR DELETING A TABLE ROW
    setCCindex(data.id);
    setConfirmDialog(true);
  }

  async function deleteCC() {
    // API CALL FOR DELETING THE COURSE CATEGORY ROW
    const body = {
      deleted: true,
      status: false,
    };
    const response = await _deleteCC(body, CCIndex)
    if (response.id) {
      fetchData();
      setConfirmDialog(false);
      setSnackBarOptions({
        message: "Course category deleted successfully!",
        show: true,
        severity: "success",
        duration: 5000,
      });
    } else {
      setSnackBarOptions({
        message: "Internal server error!",
        show: true,
        severity: "error",
        duration: 5000,
      });
    }
  }

  const courses = [
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="1">
      Master
    </Typography>,
    <Typography component={"div"} sx={{ fontSize: "20px" }} key="2">
      Course Categories
    </Typography>,
  ];

  function handleProceed(id, title) {
    // navigate(`/courseCategories/${id}/${title}/courseTable`);
  }

  return (
    <div className="h-full w-full overflow-auto">
      <div className="py-4 px-8 flex items-center justify-between">
        <BreadCrumb data={courses} />
        <div className="flex items-center ">
          {/* <DateSearchFilter /> */}
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <div className="h-96">
          {allCourseCategory === null ?
            <div className="h-full w-full items-center justify-center flex">
              <h1 className="h-60 w-60"> <CircularProgress /></h1>
            </div> : allCourseCategory?.length > 0 ? <div className="flex flex-wrap justify-start my-4 mx-5">
              {allCourseCategory?.map((_courseCategory, i) => {
                return (
                  <Cards
                
                    courseCategoryId={() =>
                      handleProceed(_courseCategory?.id, _courseCategory?.name)
                    }
                    editCourseCategory={() => openEditDialogCC(_courseCategory)}
                    deleteCoursesCategory={() => confirmDialogCC(_courseCategory)}
                    key={i.toString() + "_key"}
                    courseName={_courseCategory?.name}
                  />
                );
              })}
            </div> :
              <NodataInTable message='No course category is created yet.To create course category click on add icon.' />}
          <DialogueBox
            confirmDialog={confirmDialog}
            message={'Course Category'}
            confirmDialogClose={() => setConfirmDialog(false)}
            confirmDelete={deleteCC}
          />
          <div>
            <Button
              onClick={() => setCourseCategoryDialog(true)}
              variant="contained"
              disabled={courseCategoriesM?.write ? false : true}
              sx={{
                borderRadius: "50px",
                height: "60px",
                width: "60px",
                position: "fixed",
                bottom: "40px",
                right: "40px",
              }}
            >
              <AddIcon sx={{ fontSize: "30px" }} />
            </Button>
          </div>
          <Box sx={{ width: "100%" }}>
            <Dialog fullWidth={true} maxWidth="md" open={courseCategoryDialog}>
              <DialogTitle>{updateCC === false ? 'Add' : 'Update'} Course Category</DialogTitle>
              <Divider />
              <DialogContent>
                <div className="px-7">
                  <FormControl fullWidth>
                    <FormLabel className='mb-2' required>Course Category Name</FormLabel>
                    <TextField
                      error={
                        addCC && courseCategoryName.length < 1 ? true : false
                      }
                      helperText={
                        addCC && courseCategoryName.length < 1
                          ? "Course category name cannot be empty"
                          : ""
                      }
                      value={courseCategoryName}
                      onChange={(e) => setCourseCategoryName(e.target.value)}
                      required={true}
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </FormControl>
                </div>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  sx={{ marginY: "10px" }}
                  variant="outlined"
                  onClick={closeCourseCategoryDialog}
                >
                  Cancel
                </Button>
                {updateCC === false ? (
                  <Button
                    sx={{ marginY: "10px" }}
                    variant="contained"
                    onClick={addCourseCategory}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    onClick={updateCourseCategory}
                    sx={{ marginY: "10px" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      </Box>
      <CustomizedSnackbars
        message={snackBarOptions.message}
        open={snackBarOptions.show}
        close={() => {
          setSnackBarOptions({ ...snackBarOptions, show: false });
        }}
        hideDur={5000}
        severity={snackBarOptions.severity}
      />
    </div>
  );
}
export default CourseCategories;
