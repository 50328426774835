import React, { useCallback, useEffect, useState } from 'react'
import AddCountry from './AddCountry'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, CardHeader, Fab, FormControl, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { get, patch, post } from '../../Services/services';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { debounce, update } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MultiForm from './MultiForm';
import { Edit } from '@mui/icons-material';
import { successEvents } from '../../App';

export default function Countrys() {
    const [open, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState();
    const [countries, setCountries] = useState();
    const [edit, setEdit] = useState();
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [access, setAccess] = useState();
    const [value, setValue] = React.useState('state');
    const [selectedCountry, setSelectedCountry] = useState();
    const [openForm, setOpenForm] = useState();
    const [title, setTitle] = useState();
    const [state, setState] = useState([]);
    const [board, setBoard] = useState();
    const [school, setSchool] = useState();
    const [editForm, setEditForm] = useState();

    const [countryForm, setCountryForm] = useState(
        {
            name: "",
            currencyName: "",
            conversionRate: "",
            phoneCode: "",
            phoneLength: "",
            chequeBounceCharge: "",
        })
    const { setSnackBarOptions } = React.useContext(successEvents)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getCountry = async (searchText) => {
        const res = await post("countries/getCountrys", { searchText: searchText });

        if (res.statusCode === 200) {
            setCountries(res.data?.data);
            setSelectedCountry(res?.data?.data[0])
        }
    }
    const getState = async (id) => {
        const res = await post('states/getStateAndCity', { countryId: id })
        if (res.statusCode === 200) {
            setState(res?.data?.data)

        }
    }
    const getBoard = async (id) => {
        const res = await get(`boards?filter={"where":{"countryId":"${id}"}}`)
        if (res.statusCode === 200) {
            setBoard(res?.data)

        }
    }
    const getSchool = async (id) => {
        const res = await get(`school?filter={"where":{"countryId":"${id}"}}`)
        if (res.statusCode === 200) {
            setSchool(res?.data)

        }
    }


    const updateStatus = async (id, status, name) => {
        let res;
        if (name === "state") {

            res = await patch(`states/${id}`, { status: !status })
            if (res.statusCode === 200) {
                getState(selectedCountry?.id);
            }
        } else if (name === "country") {
            res = await patch(`countries/${id}`, { status: !status })
            if (res.statusCode === 200) {
                getCountry();
            }
        } else if (name === "school") {
            res = await patch(`school/${id}`, { status: !status })
            if (res.statusCode === 200) {
                getSchool(selectedCountry?.id);
            }
        } else if (name === "board") {
            res = await patch(`boards/${id}`, { status: !status })
            if (res.statusCode === 200) {
                getBoard(selectedCountry?.id);
            }
        }
        // else if (props?.open?.name === "city") {

        //     let body = {
        //         countryId: props?.open?.countryId,
        //         title: props?.title,
        //         stateId: props.open?.stateId

        //     }
        //     res = await post('cities', body)

        // } else if (props?.open?.name === "board") {

        //     let body = {
        //         countryId: props?.open?.countryId,
        //         title: props?.title,
        //         status: true

        //     }
        //     res = await post('boards', body)

        // }

        if (res?.statusCode === 200) {
            // handleClose();
            setSnackBarOptions({
                message: `successfully ${name} updated`,
                show: true,
                severity: "success",
                duration: 5000,
            });

            // props.getCountry();
        } else if (res?.statusCode === 422) {
            setSnackBarOptions({
                message: res.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }
    }
    const debounceSearch = useCallback(debounce(getCountry, 300), []);

    useEffect(() => {
        const modules = authState?.userData?.assignModules?.assignModule;
        const rolesArr = modules?.find((m) => m?.name === "Country");
        setAccess(rolesArr);

    }, [])
    useEffect(() => {
        debounceSearch(searchInput)
    }, [searchInput])

    useEffect(() => {
        if (selectedCountry?.id) { getState(selectedCountry?.id); getBoard(selectedCountry?.id); getSchool(selectedCountry?.id) }
    }, [selectedCountry])

    return (
        <div className=' flex flex-1 w-full overflow-hidden'>
            <div className='p-1 flex flex-1 flex-col overflow-auto' >
                <div className='p-1 border-2 border-[#D3D3D3] bg-[#FAFAFA] '>
                    <div className='py-3 pb-10 flex justify-between items-center'>
                        <div className='flex items-center '>
                            <p className=" text-xl">Country</p>
                            <p className=" text-xl" style={{ fontWeight: 700 }}> - {selectedCountry?.name}</p>
                        </div>
                        {access?.update ? <EditIcon
                            className='text-[#3366FF] cursor-pointer'
                            onClick={() => {
                                setEdit(selectedCountry)
                                setCountryForm(
                                    {
                                        name: selectedCountry?.name,
                                        currencyName: selectedCountry?.currencyName,
                                        conversionRate: selectedCountry?.conversionRate,
                                        phoneCode: selectedCountry?.phoneCode,
                                        phoneLength: selectedCountry?.phoneLength,
                                        chequeBounceCharge: selectedCountry?.chequeBounceCharge,
                                    }
                                )
                            }}
                        /> : <div></div>}
                    </div>
                    <div className='gap-5 flex  flex-wrap'>
                        <div className='flex gap-2'>
                            <p className='text-[#606060]'>Country Code :</p>
                            <p style={{ fontWeight: 600 }}>{selectedCountry?.phoneCode}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-[#606060]'>Currency :</p>
                            <p style={{ fontWeight: 600 }}>{selectedCountry?.currencyName}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-[#606060]'>Conversion rate :</p>
                            <p style={{ fontWeight: 600 }}>{selectedCountry?.conversionRate}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-[#606060]'>Cheque bouncing charges :</p>
                            <p style={{ fontWeight: 600 }}>{selectedCountry?.chequeBounceCharge}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='text-[#606060]'>Phone no. length :</p>
                            <p style={{ fontWeight: 600 }}>{selectedCountry?.phoneLength}</p>
                        </div>

                    </div>
                </div>

                <Box className='flex justify-between' sx={{ width: '100%', paddingTop: 10 }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="state" label="State" />
                        <Tab value="board" label="Board" />
                        <Tab value="school" label="School" />
                    </Tabs>

                    <Typography
                        className={access?.write ? 'text-[#2B44FF] cursor-pointer' : "hidden"}
                        variant="outlined"
                        onClick={(event) => {

                            if (access?.write) {
                                event.stopPropagation();
                                setOpenForm({ countryId: selectedCountry?.id, name: value })
                                // setEdit(false)
                                // setTitle(null)
                                // setOpen(true);
                                // setUrl(item)
                            }


                        }} >
                        + Add {value?.toUpperCase()}
                    </Typography>
                </Box>

                {value === "state" ?
                    <div>
                        {state?.map((item) =>
                            <Accordion Accordion elevation={0} className=" bg-[#FAFAFA] mt-5  border-2 border-[#D3D3D3]" >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='flex justify-between w-full pr-3'>

                                        <Typography >{item?.title}</Typography>
                                        <div className='flex gap-3 items-center'>

                                            <Typography
                                                className={access?.write ? 'text-[#2B44FF] cursor-pointer' : "hidden"}
                                                variant="outlined"
                                                onClick={(event) => {

                                                    if (access?.write) {
                                                        event.stopPropagation();
                                                        setOpenForm({ countryId: selectedCountry?.id, name: "city", stateId: item?._id })
                                                        // setEdit(false)
                                                        // setTitle(null)
                                                        // setOpen(true);
                                                        // setUrl(item)
                                                    }


                                                }} >
                                                + Add City
                                            </Typography>
                                            {access?.update ?
                                                <div>
                                                    <Switch onClick={(e) => { e.stopPropagation(); updateStatus(item._id, item?.status, "state") }} checked={item?.status} />
                                                    <Edit
                                                        className='text-[#3366FF] cursor-pointer'
                                                        onClick={(event) => {

                                                            event.stopPropagation();
                                                            setOpenForm({ name: "state", id: item?._id })
                                                            setEditForm(true)
                                                            setTitle(item?.title)


                                                        }}
                                                    />
                                                </div>

                                                : ""
                                            }

                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <div className=' flex pl-5 gap-3 flex-wrap ' >

                                        {item?.city?.map((items) =>
                                            <div className='p-3 flex  gap-2 items-center bg-white '>
                                                <Typography className='text-[#606060]'>{items?.title}</Typography>
                                                {access?.update ?
                                                    <div>
                                                        <Switch checked={items?.status} />
                                                        <Edit
                                                            className='text-[#3366FF] cursor-pointer'
                                                            onClick={(event) => {

                                                                event.stopPropagation();
                                                                setOpenForm({ name: "city", id: items?._id })
                                                                setEditForm(true)
                                                                setTitle(items?.title)


                                                            }}
                                                        />
                                                    </div> : ""
                                                }
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </div>
                    : value === "board" ?
                        <div className='flex gap-5 flex-col mt-5 '>
                            {board?.map((item) =>
                                <div className='flex justify-between p-3  border-2 border-[#D3D3D3] bg-[#FAFAFA] items-center'>
                                    <Typography>{item?.title}</Typography>
                                    {access?.update ?
                                        <div className='flex gap-3 items-center '>
                                            <Switch onClick={(e) => { e.stopPropagation(); updateStatus(item.id, item?.status, "board") }} checked={item?.status} />
                                            <Edit
                                                className='text-[#3366FF] cursor-pointer'
                                                onClick={(event) => {

                                                    event.stopPropagation();
                                                    setOpenForm({ name: "board", id: item?.id })
                                                    setEditForm(true)
                                                    setTitle(item?.title)
                                                }}
                                            />
                                        </div> : ""}
                                </div>
                            )}
                        </div>
                        :
                        <div className='flex gap-5 flex-col mt-5 '>
                            {school?.map((item) =>
                                <div className='flex justify-between p-3  border-2 border-[#D3D3D3] bg-[#FAFAFA] items-center'>
                                    <Typography>{item?.title}</Typography>
                                    {access?.update ? <div className='flex gap-3 items-center '>
                                        <Switch onClick={(e) => { e.stopPropagation(); updateStatus(item.id, item?.status, "school") }} checked={item?.status} />
                                        <Edit
                                            className='text-[#3366FF] cursor-pointer'
                                            onClick={(event) => {

                                                event.stopPropagation();
                                                setOpenForm({ name: "school", id: item?.id })
                                                setEditForm(true)
                                                setTitle(item?.title)


                                            }}
                                        />
                                    </div> : ""}
                                </div>
                            )}
                        </div>
                }

            </div>
            <div className=' border-2 border-[#D3D3D3] bg-[#FAFAFA]  w-1/5 '>
                <div className='flex flex-col item-center  gap-4 p-2 h-full overflow-auto  pb-24 '>
                    {countries?.map((item) =>
                        <div className={selectedCountry?.id === item.id ? ' cursor-pointer bg-[#3366FF] text-white px-2 flex justify-between items-center gap-5' : " cursor-pointer px-2 flex justify-between items-center gap-5"} onClick={() => { setSelectedCountry(item) }}>
                            <p style={{ fontWeight: 500 }}>  {item?.name}</p>
                            <Switch disabled={!access?.update} color={selectedCountry?.id === item.id ? "default" : "primary"} checked={item?.status} onClick={(e) => { e.stopPropagation(); updateStatus(item.id, item?.status, "country") }} />
                        </div>
                    )}
                </div>
                {access?.write ?
                    <div className=' bg-white sticky right-0 left-0 bottom-0   ' >

                        <div className='flex justify-center items-center py-5 '>
                            <Button onClick={() => setOpen(true)}>+ Add Country</Button>

                        </div>
                    </div> : ""}
            </div>
            <AddCountry setCountryForm={setCountryForm} countryForm={countryForm} setEdit={setEdit} edit={edit} getCountry={getCountry} open={open} setOpen={setOpen} />
            <MultiForm open={openForm} setOpen={setOpenForm} title={title} setTitle={setTitle} setEditForm={setEditForm} editForm={editForm} getState={getState} getSchool={getSchool} getBoard={getBoard} selectedCountry={selectedCountry} />
        </div >

    )
}
