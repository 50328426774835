import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, Fab, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StringWithSpace, emailRegex, phoneNumberRegexAed, phoneNumberRegexIndia, wholeNumberRegex } from '../../Regex/Regex';
import { baseUrl, get, patch, post } from '../../Services/services';
import EditIcon from '@mui/icons-material/Edit';
import { UserAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddCases(props) {
    //   const [open, setOpen] = React.useState(false);

    const [countries, setCountries] = React.useState([]);
    const [countryData, setCountryData] = React.useState();
    const [authState, setAuthState] = useRecoilState(UserAuthState);
    const [buddyData, setBuddyData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)
    const nameRef = React.useRef();


    const handleClickOpen = () => {
        // setOpen(true);
    };

    const handleAddCase = async () => {
        setIsLoading(true);
        let body = {
            name: props.casesData?.name,
            email: props.casesData?.email,
            mobile: props.casesData?.mobile,
            password: "123456",
            username: props.casesData?.mobile,
            countryId: props.casesData?.countryId,
            deleted: false,
            adminId: authState?.userId,
            gender: props?.casesData?.gender,
            buddyId: props?.casesData?.buddyId
        }

        const ress = await post('cases', body)
        setIsLoading(false);
        if (ress.statusCode === 200) {
            props.getCases();
            handleClose();
            props.setSnackBarOptions({
                message: "Case Added successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            props.setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            props.setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }

    const handleEditCase = async () => {
        setIsLoading(true)
        let body = {
            name: props.casesData?.name,
            email: props.casesData?.email,
            mobile: props.casesData?.mobile,
            username: props.casesData?.mobile,
            countryId: props.casesData?.countryId,
            gender: props?.casesData?.gender,
            buddyId: props?.casesData?.buddyId
        }
        const ress = await patch(`cases/${props?.edit}`, body)
        setIsLoading(false)
        console.log(ress.statusCode)
        if (ress.statusCode === 200) {
            props.setSnackBarOptions({
                message: "Case updated successfully!",
                show: true,
                severity: "success",
                duration: 5000,
            });
            props.getCases();
            handleClose();
        } else if (ress.statusCode === 422) {
            console.log(ress.message)
            props.setSnackBarOptions({
                message: ress.message,
                show: true,
                severity: "error",
                duration: 5000,
            });
        } else {
            props.setSnackBarOptions({
                message: "somthing went wrong try again",
                show: true,
                severity: "error",
                duration: 5000,
            });
        }

    }

    const getCountryData = async () => {
        let res = await get(`countries/${props.casesData?.countryId}`);
        if (res?.statusCode === 200) {
            setCountryData(res?.data)
        }
    }
    const getCountry = async () => {
        const res = await get('countries?filter={"where":{"status":true}}');

        if (res.statusCode === 200) {
            setCountries(res.data);
        }
    }

    const getBuddyName = async () => {
        const res = await post("fieldTypes/getAllFieldTypeWithField", { status: true });


        if (res.statusCode === 200) {
            res.data?.map((item) => {
                if (item.title === "buddy") {
                    setBuddyData(item?.field)
                    console.log(item)
                }
            }
            )

        }
    }

    React.useEffect(() => {
        getBuddyName();
        getCountry();
    }, [])

    const handleClose = () => {
        props.setOpen(false);
        props.setCasesData(
            {
                name: "",
                email: "",
                countryId: "",
                mobile: "",
                gender: "",
                buddyId: "",
            }
        )
        props.setEdit();
    };
    React.useEffect(() => {
        getCountryData()
    }, [props?.casesData?.countryId])

    return (
        <React.Fragment>

            <BootstrapDialog
                fullWidth
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {props.edit ? "Edit case" : "Add Case"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box className='flex flex-col gap-5'>


                        <Box className=' flex gap-4'>
                            <TextField
                                size='small'
                                fullWidth
                                value={props.casesData?.name}
                                label="NAME"
                                required
                                error={!StringWithSpace.test(props.casesData?.name) && props.casesData?.name?.length !== 0}
                                helperText={props.casesData?.name?.length !== 0 && !StringWithSpace.test(props.casesData?.name) ? "Name only contains letters and at least 3 characters" : ""}
                                onChange={(e) => {
                                    props.setCasesData(prevState => (
                                        { ...prevState, name: e.target.value }));

                                }}
                            />
                            <TextField size='small'
                                required
                                value={props.casesData?.email}
                                onChange={(e) => {
                                    props.setCasesData(prevState => (
                                        { ...prevState, email: e.target.value }));
                                }}
                                error={!emailRegex?.test(props.casesData?.email) && props.casesData?.email?.length !== 0}
                                helperText={!emailRegex?.test(props.casesData?.email) && props.casesData?.email?.length !== 0 ? "enter valid email" : ""}
                                fullWidth
                                label="EMAIL" />



                        </Box>
                        <Box className=' flex gap-4'>

                            <FormControl value={props.casesData?.countryId} required fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">COUNTRY CODE</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"

                                    value={props.casesData?.countryId}
                                    label="COUNTRY CODE"
                                    onChange={async (e) => {
                                        props.setCasesData(prevState => (
                                            { ...prevState, countryId: e.target.value }));
                                        let res = await get(`countries/${e.target.value}`);
                                        if (res?.statusCode === 200) {
                                            setCountryData(res?.data)
                                        }

                                    }}
                                >
                                    {countries?.map((item) =>
                                        <MenuItem value={item?.id}>+{item.phoneCode}-{item.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                            <TextField
                                ref={nameRef}
                                disabled={!countryData}
                                sx={{ borderTopLeftRadius: 0 }}
                                size='small'
                                fullWidth
                                value={props.casesData?.mobile}
                                label="CONTACT NO."
                                required
                                error={(!wholeNumberRegex.test(props.casesData?.mobile) || props.casesData?.mobile?.length !== countryData?.phoneLength) && props.casesData?.mobile?.length !== 0}
                                helperText={(!wholeNumberRegex.test(props.casesData?.mobile) || props.casesData?.mobile?.length !== countryData?.phoneLength) && props.casesData?.mobile?.length !== 0 ? `contact no. contains ${countryData?.phoneLength} digits` : ""}
                                onChange={(e) => {
                                    props.setCasesData(prevState => (
                                        { ...prevState, mobile: e.target.value }));
                                }}
                            //     InputProps={

                            //         {
                            //             style: {
                            //                 borderTopLeftRadius: "0px",
                            //                 borderBottomLeftRadius: "0px",
                            //                 borderLeftWidth: "0px",
                            //             },
                            //             startAdornment: <InputAdornment position='start' >
                            //                 {/* <UploadIcon onClick={() => imageRef.current.click()} className=' text-[#3366FF] cursor-pointer ' /> */}

                            //             </InputAdornment>,
                            //         }}
                            />

                        </Box>
                        <Box className=' flex gap-4'>


                            <div className='flex w-1/2'>

                                <FormControl required fullWidth sx={{ minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">GENDER</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={props.casesData?.gender}
                                        label="GENDER"
                                        onChange={(e) => {
                                            props.setCasesData(prevState => (
                                                { ...prevState, gender: e.target.value }));
                                        }}                                    >
                                        {["male", "female"].map((item) =>

                                            <MenuItem value={item}>{item}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>
                            <div className='flex w-1/2'>

                                <FormControl required fullWidth sx={{ minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">BUDDY</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={props.casesData?.buddyId}
                                        label="BUDDY"
                                        onChange={(e) => {
                                            props.setCasesData(prevState => (
                                                { ...prevState, buddyId: e.target.value }));
                                        }}                                    >
                                        {buddyData?.map((item) =>

                                            <MenuItem value={item?._id}>{item?.title}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>
                        </Box>
                    </Box>
                    {console.log("ashish", props.casesData?.buddyId)}
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#3366FF" }}
                        loading={isLoading}
                        variant='contained'
                        disabled={!StringWithSpace.test(props.casesData?.name)
                            || props.casesData?.name?.length === 0
                            || !props.casesData?.countryId
                            || props.casesData?.mobile?.length !== countryData?.phoneLength
                            || props.casesData?.mobile?.length === 0
                            || props.casesData?.designation?.length === 0
                            || !emailRegex.test(props.casesData?.email)
                            || !wholeNumberRegex.test(props.casesData?.mobile)
                            || props.casesData?.gender?.length === 0
                            || props.casesData?.buddyId?.length === 0
                            || !props.casesData?.buddyId
                            || isLoading
                        }
                        autoFocus
                        onClick={props.edit ? handleEditCase : handleAddCase}
                    >
                        {isLoading ?
                            <CircularProgress size={16} />
                            :
                            props.edit ? "Update" : "Add"
                        }
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}