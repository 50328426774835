import { useState, useEffect } from 'react';
import Button from "@mui/material/Button";
const CountDownTimer = (props) => {

    const [seconds, setSeconds ] =  useState(props.duration);
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(myInterval)
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    },[seconds]);


    const resendOtp = () => {
        props.resendOtp()
        setSeconds(props.duration)
    }
    
    return (
        <div>
        {seconds === 0
            ? <Button variant="text" onClick={resendOtp}>
            Resend OTP</Button>
            : <Button> <b>{seconds < 10 ?  `00:0${seconds}` : `00:${seconds}`}</b></Button> 
        }
        </div>
    )
}

export default CountDownTimer;