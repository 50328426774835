import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Avatar from "@mui/material/Avatar";

import { Outlet, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { _logout } from "../Services/api-services";

import ChangePasswordStaff from "../Popups/ChangePasswordStaff";
import getCookie from "./Utility/Utility";
import { Badge, ListItem, Typography } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { UserAuthState } from "../Services/RecoilService";
import { useRecoilState } from "recoil";
import { baseUrl, post } from "../Services/services";
import Icon from "./Icon";
import { successEvents } from "../App";
import CustomizedSnackbars from "./Snackbar";
const drawerWidth = 280;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100vh",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    fontFamily: "Poppins",
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  fontFamily: "Poppins",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  ...theme.mixins.toolbar,
}));


export default function Home(props) {
  const drawerRef = React.useRef(null)
  const userId = getCookie('userId')
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const openSetting = Boolean(anchorEl);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [dashboardList, setDashboardList] = React.useState(true);
  const [masterList, setMasterList] = React.useState(false);
  const [casesList, setCasesList] = React.useState(false);
  const [pendingList, setPendingList] = React.useState(false);
  const [notification, setNotification] = React.useState();
  const [openChangePwd, setOpenChangePwd] = React.useState(false);
  const [authState, setAuthState] = useRecoilState(UserAuthState);
  const location = useLocation();
  let dataR = location?.pathname?.split('/')[2];
  console.log("hskjkfj", dataR)
  const { currentPage, setCurrentPage, snackBarOptions, setSnackBarOptions } = React.useContext(successEvents)
  let navigate = useNavigate();
  const _currentPage = location?.pathname?.split('/')[2];
  const { firstPage, setFirstPage } = React.useContext(successEvents);
  React.useEffect(() => {
    if (authState?.token) {
      getNotification()
      setCurrentPage(dataR ? dataR : authState?.userData?.assignModules?.assignModule[0].name)
      // setCurrentPage(location?.pathname?.split('/')[2])
    } else {
      navigate('/login');
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleLogout = () => {
    setAnchorEl(false);
    setLogoutDialog(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const HandleDashboardList = () => {
    setDashboardList(!dashboardList);
  };

  const HandleMasterList = () => {
    setMasterList(!masterList);
  };
  const HandleCasesList = () => {
    setCasesList(!casesList);
  };
  const HandlePendingList = () => {
    setPendingList(!pendingList);
  };

  async function logout() {
    try {
      const res = await _logout();
      setAuthState(null);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  }
  const getNotification = async () => {
    const res = await post("refunds/getPendingRequest", {});
    if (res.statusCode === 200) {
      setNotification(res.data)
    }
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar className='border-b' elevation={0} position="fixed" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            color: "black",
            zIndex: "-1",
            fontFamily: "Poppins",
          }}
        >
          <div className="flex gap-2 items-center" sx={{ mr: 2, ...(open && { display: "none" }) }}>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >

              <MenuIcon />
            </IconButton>
            {open ? (
              <div></div>
            ) : (<h2 className="pl-4 font-bold text-2xl"> MIS</h2>)
            }
          </div>

          <div
            id="basic-button"
            aria-controls={openSetting ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSetting ? "true" : undefined}
            onClick={handleClick}
            className="flex items-center gap-3 cursor- ">
            <Avatar
              sx={{ width: "50px", height: "50px" }}
              alt={authState?.userData?.name?.toUpperCase()}
              src={`${baseUrl}/profilePics/staff/download/${authState?.userData?.image}`}
            />
            <div>

              <Typography className="text-lm text-black text font-bold">
                {authState?.userData?.name}
                {/* Shivani Chourasia */}
              </Typography>
              <Typography className="text-sm text-gray-500">
                {authState?.userData?.email}
              </Typography>
            </div>

            {openSetting ? (<ArrowDropUpIcon sx={{ fontSize: 33 }} />) : (<ArrowDropDownIcon sx={{ fontSize: 33 }} />)}
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSetting}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => setOpenChangePwd(true)}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

        </Toolbar>
      </AppBar>
      <Drawer
        ref={drawerRef}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <h2 className="pl-4 font-bold text-2xl"> MIS</h2>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        {/* {console.log(authState?.userData?.assign)} */}
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {authState?.userData?.assignModules?.assignModule?.map((item, index) =>
            <ListItem key={item} sx={{ paddingTop: 0, paddingBottom: 0, display: item?.read || item?.write || item?.update ? "block" : "none" }} >
              {
              }
              <ListItemButton
                sx={{
                  backgroundColor: _currentPage?.replace(/_/g, ' ') === item?.name?.toLowerCase() ? '#3366FF0A' : '',
                  borderLeft: _currentPage?.replace(/_/g, ' ') === item?.name?.toLowerCase() ? '5px solid #3366FF' : '5px solid transparent'
                }}
                onClick={() => {
                  getNotification()
                  setCurrentPage(item.name?.replace(/ /g, "_").toLowerCase())
                  navigate(`/home/${item.name?.replace(/ /g, "_").toLowerCase()}`);
                }}>
                {/* {console.log(item.name?.replace(/ /g, ''))} */}
                <ListItemIcon>
                  {item.name === "Discount Requests" && notification?.discountCount && notification?.discountCount > 0 ?
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={4} color="primary"
                      variant="dot"
                    >
                      <Icon sx={{ fontSize: 30, color: currentPage === item?.name ? '#3366FF' : '' }} icon={item.name?.replace(/ /g, '')} />
                    </Badge>
                    : item.name === 'Refund Requests' && notification?.refundCount && notification?.refundCount > 0 ?
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={4} color="primary"
                        variant="dot"
                      >
                        <Icon sx={{ fontSize: 30, color: currentPage === item?.name ? '#3366FF' : '' }} icon={item.name?.replace(/ /g, '')} />
                      </Badge>
                      : item.name === 'Approved Refunds' && notification?.ApprovedRefundCount && notification?.ApprovedRefundCount > 0 ?
                        <Badge
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          badgeContent={4} color="primary"
                          variant="dot"
                        >
                          <Icon sx={{ fontSize: 30, color: currentPage === item?.name ? '#3366FF' : '' }} icon={item.name?.replace(/ /g, '')} />
                        </Badge>
                        :
                        < Icon sx={{ fontSize: 30, color: currentPage === item?.name ? '#3366FF' : '' }} icon={item.name?.replace(/ /g, '')} />
                  }
                </ListItemIcon>
                {console.log(item.name)}
                <ListItemText className=" w-36" sx={{ color: currentPage === item?.name ? '#3366FF' : '' }} primary={item.name === "Batchs" ? "Batches" : item.name === "Country" ? "Countries" : item.name === "Open cases" ? "Open Cases" : item.name === "Old cases" ? "Old Cases" : item.name === "Payment due" ? "Payment Due" : item.name === "Course categories" ? "Course Categories" : item.name === "Sms templates" ? "Sms Templates" : item.name} />
              </ListItemButton>
            </ListItem>
          )}
        </List>

        {/* <Divider /> */}
        {/* <div className="py-10 pl-3 w-full cursor-pointer misC">
          <Button
            onClick={() => setLogoutDialog(true)}
            className="misC"
            sx={{ color: "black", fontSize: "16px" }}
            variant="text"
          >
            <LogoutIcon />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LOGOUT{" "}
          </Button>
        </div> */}
      </Drawer>
      {console.log("lskjklfjkljs;lkjfjskljfljklsjklfjkljsljfljljlfjfj", window?.innerWidth - drawerRef?.current?.offsetWidth)}
      <Main className="home flex flex-col overscroll-none " open={open}  >
        <DrawerHeader />

        <Outlet />

      </Main>
      <div>
        <Dialog open={logoutDialog} onClose={() => setLogoutDialog(false)}>
          <DialogTitle>Logout</DialogTitle>
          <Divider />
          <DialogContent>
            <div className='my-2'>
              Are you sure you want to logout?
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <div className=' '>
              <Button variant="outlined" onClick={() => setLogoutDialog(false)}>
                No
              </Button>
              &nbsp;&nbsp;
              <Button variant="contained" onClick={logout}>
                Yes
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <ChangePasswordStaff
          actionData={{ id: authState?.userId }}
          changePasswordDialog={openChangePwd}
          onCancelAction={() => setOpenChangePwd(false)}
        />
        <CustomizedSnackbars
          message={snackBarOptions.message}
          open={snackBarOptions.show}
          close={() => {
            setSnackBarOptions({ ...snackBarOptions, show: false });
          }}
          hideDur={5000}
          severity={snackBarOptions.severity}
        />
      </div>
    </Box>
    // <div>home</div>
  );
}
